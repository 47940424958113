<template>
  <v-row
    :class="[ $device.mobile ? 'mr-1 ml-1' : 'mr-9 ml-9' ]"
  >
    <v-col
      cols="12"
    >
      <h4
        :class="[ !$device.mobile ? 'mt-6' : '' ]" class="text-color"
      >
        {{ faq.question }}
      </h4>
    </v-col>

    <v-col
      cols="12"
    >
      <p
        :class="[ !$device.mobile ? '' : 'box mb-2' ]" class="text-color" style="white-space: pre-line"
      >
        {{ faq.answer }}
      </p>
    </v-col>

    <v-col
      cols="12"
    >
      <span
        class="text-color"
      >
        {{ this.$t('faq.your_problem_is_solved') }}
      </span>
    </v-col>

    <v-col
      cols="12"
    >
      <div
        :class="[ !$device.mobile ? 'd-flex' : 'd-flex flex-column' ]"
      >
        <v-btn
          :class="{
            'mb-4': $device.mobile && !confirm_option,
            'fake-button mr-3 col-sm-2 col-12 mb-4': $device.mobile && confirm_option,
            'mr-3 col-sm-2 col-12': !confirm_option,
            'fake-button mr-3 col-sm-11 col-md-7 col-lg-5 col-4 flex-grow-1': confirm_option,
          }"
          @click="yes"
          class="yes-btn"
          rounded
        >
          {{ !confirm_option ? this.$t('faq.yes') : this.$t('faq.we_are_happy_to_help') }}
        </v-btn>

        <v-btn
          v-if="!confirm_option"
          @click="no"
          class="no-btn col-sm-2 col-12"
          rounded
        >
          {{ this.$t('faq.no') }}
        </v-btn>

        <v-btn
          v-if="confirm_option"
          @click="back"
          class="back-btn col-sm-2 col-12"
          flat
          outlined
          rounded
        >
          {{ this.$t('faq.back') }}
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>

export default {
  props: {
    // Function to handle selection action
    faq: {
      type: Object,
      default: null
    },
  },

  data: () => ({
    confirm_option: false
  }),

  created() {
    // 
  },

  mounted() {
    // 
  },

  methods: {
    yes() {
      this.confirm_option = true
    },

    no() {
      this.$emit('showModalHelp')
    },

    back() {
      this.confirm_option = false
      this.show_modal_help = false
      this.$emit('handleBack')
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/styles/colors.scss';

  .yes-btn {
    background-color: $primary !important;
    color: $antiFlashWhite !important;
  }

  .box {
    border: 1px solid;
    border-color: $silverSand;
    padding: 0.7em !important;
  }

  .no-btn {
    background-color: $outerSpace !important;
    color: $antiFlashWhite !important;
  }

  .back-btn {
    border-color: $primary !important;
    color: $primary !important;
  }

  .text-color {
    color: $outerSpace;
  }

  .fake-button {
    pointer-events: none;
  }
</style>