export default {
  reset(state) {
    state.company_id = null;
    state.flavour_id = null;
    state.group_id = null;
    state.groups = [];
    state.member_id = null;
    state.token = null;
  },

  setCompanyId(state, payload) {
    state.company_id = payload;
  },

  setCompanyStatusId(state, payload) {
    state.company_status_id = payload;
  },

  setCompanyStatusName(state, payload) {
    state.company_status_name = payload;
  },

  setFlavourId(state, payload) {
    state.flavour_id = payload;
  },

  setGroupId(state, payload) {
    state.group_id = payload;
  },

  setPatientGroups(state, payload) {
    state.groups = payload;
  },

  setMemberId(state, memberId) {
    state.member_id = memberId;
  },

  setToken(state, payload) {
    state.token = payload;
  },
};
