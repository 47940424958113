import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment/moment";

const calendar = {
  methods: {
    ...mapActions('data/attendance', [
      'getMemberAttendances',
      'caseAttendanceReschedule',
    ]),

    ...mapActions('data/schedule', {
      checkDoubleBooking: 'checkDoubleBooking',
      scheduleSlots: 'scheduleSlots',
    }),

    ...mapMutations('data/schedule', {
      setDatetime: 'setDatetime',
      setCheckDoubleBooking: 'setCheckDoubleBooking',
      setProfessional: 'setProfessional',
      setUserSchedulesIds: 'setUserSchedulesIds',
      resetSlots: 'resetSlots'
    }),

    ...mapMutations('data/general', [
      'setGeneralError',
    ]),

    ...mapGetters('data/attendance', {
      getPatientLastAttendance: 'getPatientLastAttendance',
    }),

    ...mapGetters('data/login', {
      getCompanyId: 'getCompanyId',
    }),

    ...mapGetters('data/patient', {
      getFirstAvailableDate: 'getFirstAvailableDate',
    }),

    mergeSlotsWithSameStartTime() {
      const schedules = this.getScheduleSlot();
      const availableSlots = schedules.slots.reduce((result, slot) => {
        const index = result.findIndex((item) => item.day === slot.compare);

        if (index === -1) {
          result.push({
            day: slot.compare,
            slots: [slot.start_time],
            user_schedules: new Set(slot.user_schedule_id),
          });
        } else {
          if (!result[index].slots.includes(slot.start_time)) {
            result[index].slots.push(slot.start_time);
            result[index].user_schedules = new Set([
              ...result[index].user_schedules,
              ...slot.user_schedule_id,
            ]);
          }

          result[index].slots.sort();
        }

        return result;
      }, []);

      this.available_slots_days = availableSlots.map((item, index) => {
        item.index = index;
        return item;
      });

      if (this.schedule_role_name === 'programCalendar') {
        this.professionals = schedules.scheduleInfo.map((item) => ({
          ...item,
          enable: false,
        }));
      }

      if (this.schedule_role_name === 'scheduleUserCalendar') {
        this.select_professional = true;
      }

      this.loading = false;
      this.loadingCalendar = false;
    },

    mergeDaysCalendar() {
      const schedules = this.getScheduleSlot()

      this.available_slots_days = schedules.available_days.reduce((result, day) => {
        result.push({
          day: day
        });
        return result;
      }, []);
      this.loading = false;
      this.loadingCalendar = false;
    },

    getScheduleSlot() {
      return this.getScheduleSlotsStorage[this.schedule_role_name]
    },

    resetDate() {
      this.select_schedule_date = null;

      if (!this.isMultipleAttendance) {
        this.select_professional = false;
      }
    },

    resetHour() {
      this.select_schedule_hour = null;

      if (!this.isMultipleAttendance) {
        this.select_professional = false;
      }
    },

    resetDateAndHour() {
      this.select_schedule_date = null;
      this.select_schedule_hour = null;
      this.select_professional = false;
    },

    resetProfessional() {
      this.available_slots_days = null;
      this.select_professional = false;
      this.select_schedule_date = null;
      this.select_schedule_hour = null;
    },

    allowedDates: function (val) {
      let isDateAllowed = true;

      if (this.isMultipleAttendance) {
        this.lastAttendanceForProgramList.forEach(attendance => {
          const lastAttendanceDate = this.$moment(attendance.due_date + ' ' + attendance.due_time);
          const inputDate = this.$moment(val);
          const interval = this.program.attendance_interval;
          const minDate = lastAttendanceDate.clone().subtract(interval + 1, 'days');
          const maxDate = lastAttendanceDate.clone().add(interval, 'days');

          if (inputDate.isAfter(minDate) && inputDate.isBefore(maxDate)) {
            isDateAllowed = false;
          }
        });
      }

      return isDateAllowed && this.available_slots_days.some(item => item.day === val);
    },



    resetSelectDate() {
      if (this.selected_reset_option === 'select_new_hour') {
        this.resetHour();
      }

      if (this.selected_reset_option === 'select_new_date') {
        this.resetDate();
        this.resetHour();
        this.selected_reset_option = null;
      }

      if (this.selected_reset_option === 'select_new_professional') {
        this.resetProfessional();
      }
    },

    customFormatHeader(isoDate) {
      const currentDate = this.$moment(isoDate).locale('pt-br')
      const date = currentDate.format('MMMM Y')

      return date[0].toUpperCase() + date.slice(1);
    },

    async verifyDoubleBooking(reschedule) {
      const programs_with_double_booking_ids = [4, 5, 10, 54];

      if (programs_with_double_booking_ids.includes(this.program.id)) {
        let patient = this.getPatientInfo();
        await this.checkDoubleBooking({
          service_line: 'b2b',
          program_id: this.program.id,
          reschedule: reschedule,
          member_id: patient.id
        });
      } else {
        this.setCheckDoubleBooking({ double_booking: false })
      }
    },

    async scheduleSlotsDays(reschedule) {
      await this.verifyDoubleBooking(reschedule);

      this.scheduleSlots(await this.getDaysFilter()).then(() => {
        this.mergeDaysCalendar()
      })
        .catch(error => {
          console.log(error)
        })
    },

    async scheduleSlotsReview(reschedule) {
      await this.verifyDoubleBooking(reschedule);
      this.scheduleSlots(await this.getSingleDayFilter())
        .then(() => {
          this.mergeSlotsWithSameStartTime()
        })
        .catch(error => {
          console.log(error)
        })
    },

    async getSingleDayFilter() {
      const doubleBooking = this.getCheckDoubleBooking.double_booking;

      return {
        "rules": [
          {
            name:this.schedule_role_name,
            filters:{
              user_schedule_id: this.attendance.user_schedule_id,
              program_id: this.program.id,
              occupation_id: this.program.occupation_id,
              speciality_id: this.program.speciality_id,
              timestamp: this.firstScheduleTimestamp || this.$moment().unix(),
              days_ahead: 1,
              double_booking: doubleBooking,
              company_id: this.getCompanyId
            }
          }
        ]
      };
    },

    validatingAvailableTime(timestamp) {
      return this.scheduled_appointments.some(schedule => {
        const init = schedule.clone().subtract(1, 'hour').utc(true).unix();
        const end = schedule.clone().add(1, 'hour').utc(true).unix();
        const hour = moment.unix(timestamp).utc(false).unix();

        return hour >= init && hour <= end;
      });
    },

    handleCurrentAttendances() {
      this.attendances
        .forEach(o => this.scheduled_appointments.push(this.$moment(o.sla)));
    },

    getPatientInfo() {
      return this.getPatient;
    },
  },


  computed: {
    ...mapGetters('data/general', {
      attendance: 'getAttendance', // -> this.attendance
    }),

    ...mapGetters('data/schedule', {
      getProgram: 'getProgram',
      getCheckDoubleBooking: 'getCheckDoubleBooking',
      getScheduleSlotsStorage: 'getScheduleSlots',
      getPatient: 'getPatient'
    }),

    ...mapGetters('data/login', {
      getCompanyId: 'getCompanyId'
    }),

    schedule_date_format: function () {
      return this.select_schedule_date.split("-").reverse().join("/")
    },

    range_days: function () {
      let now = this.$moment();
      let firstAvailableDate = this.$moment.unix(this.getFirstAvailableDate());

      let usedDate = firstAvailableDate.unix() > now.unix() ? firstAvailableDate : now;
      return {
        correct_day: usedDate.format('YYYY-MM-DD'),
        min: usedDate.clone().format('YYYY-MM-DD'),
        max: usedDate.clone().add(this.program.days_ahead ?? 16, 'd').format('YYYY-MM-DD')
      }
    },

    list_schedule_hours: function () {
      if (!this.available_slots_days || !Array.isArray(this.available_slots_days)) {
        return [];
      }
      
      const day = this.available_slots_days.find((item) => item.day === this.select_schedule_date);

      if (!day || !day.slots || !Array.isArray(day.slots)) {
        return [];
      }

      return day.slots.map((item) => {
        //1.0 capture schedules
        const schedules = this.getScheduleSlot()

        //1.1 filter the slots and separate by key 'user_schedule_id' according to 'start_time'
        let slots = Array.from(new Set(schedules.slots.filter(slot => item === slot.start_time).map(({ user_schedule_id }) => user_schedule_id)));

        //1.2 I reduce the ids of schedules and transform them into a single array
        let user_schedules_ids = slots.reduce((result, slot) => (result.push(...slot), result), []);

        //1.3 I mark the unique ids in the array
        user_schedules_ids = user_schedules_ids.filter(function (item, pos) {
          return user_schedules_ids.indexOf(item) === pos;
        });
        return {
          user_schedules_ids: user_schedules_ids,
          available_slot_index: day.index,
          timestamp: item,
          hour: this.$moment(item * 1000).utc(true).format('HH:mm'),
        }
      }).sort()
    },

    daysAhead() {
      let endOfMonth = this.$moment().endOf('month');
      let daysToEndOfMonth = endOfMonth.diff(this.$moment(), 'days');

      if (this.calendarInCurrentMonth) {
        if(daysToEndOfMonth === 0) {
          return 1;
        } else {
          return this.program.days_ahead > daysToEndOfMonth
            ? daysToEndOfMonth
            : this.program.days_ahead;
        }
      } else {
        return this.program.days_ahead - daysToEndOfMonth;
      }
    },
  },

  watch: {
    selected_reset_option() {
      this.resetSelectDate()
    }
  }
}

export default calendar;