export default {
  reset(state) {
    state.attendances = [];
    state.profile = null;
    state.dependents = [];
  },

  setAttendances(state, payload) {
    state.attendances = payload;
  },

  setProfile(state, payload) {
    state.profile = payload;
  },

  setDependents(state, payload) {
    state.dependents = payload;
  },

  setDependentsRaw(state, payload) {
    state.dependents_raw = payload;
  },

  setEmailVerified(state, payload) {
    state.email_verified = payload;
  },

  setFirstAvailableDate(state, payload) {
    state.first_available_date = payload;
  },

  setUpdate(state, payload) {
    state.update_info = payload;
  },

  setProgramsScheduled(state, payload) {
    state.programs_scheduled = payload;
  }
};