export default {
  validate_phone_number: {
    change_number: 'Alterar número',
    code_sent_to: 'Estamos enviando o código para',
    enter_new_mobile_number: 'Digite o novo número de celular:',
    invalid_code: 'Código introduzido não é válido.',
    phone_changed: 'Número de celular alterado com sucesso.',
    phone_number_invalid: 'Número de celular inválido.',
    resend_code: 'Reenviar código',
    success_code: 'Código reenviado com sucesso.',
    type_validation_code: 'Digite o código de validação',
  }
};