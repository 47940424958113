export default {
  register: {
    accept_terms_above: 'Aceito os termos acima',
    birthdate: 'Data de nascimento',
    cpf: 'CPF',
    cpf_invalid: 'CPF inválido.',
    create_login_password: 'Crie seu login e senha',
    email: 'E-mail',
    email_confirmation: 'Confirmar e-mail',
    email_confirmation_different: 'O endereço de confirmação de e-mail introduzido não é igual ao E-mail informado. Por favor verifique.',
    email_invalid: 'Endereço de e-mail introduzido não é válido. Por favor corrigir.',
    email_invalid_characters: 'O endereço de e-mail introduzido não deve conter caracteres especiais. Por favor corrigir.',
    gender: 'Sexo',
    name: 'Nome Completo',
    name_min_invalid: 'Nome inválido, minimo 3 characteres.',
    name_max_invalid: 'Nome inválido, máximo 100 characteres.',
    phone: 'Número de celular',
    phone_invalid: 'Número de celular inválido.',
    password: 'Senha',
    password_confirmation: 'Confirme sua nova senha',
    password_confirmation_invalid: 'A senha de confirmação introduzida não é igual ao Senha informado. Por favor verifique.',
    password_rule_min: 'Maior que 8 caracteres',
    password_rule_number: 'Um número',
    password_rule_uppercase: 'Uma letra maiúscula',
    password_rule_lowercase: 'Uma letra minúscula',
    password_rule_special_character: 'Um caracter especial (!, @, #, $ ou &)',
    password_rules: 'Regras para criação de senha:',
    read_and_accept_terms: 'Li e aceito os termos e condições de uso',
    register: 'Cadastro',
    register_success: 'Cadastro efetuado\ncom sucesso!',
    user_already_registered: 'Esse paciente já está cadastrado em nosso sistema. Retorne a tela inicial e insira os dados de login e senha para continuar.',
    user_under_age: 'O cadastro em nosso aplicativo é permitido apenas para maiores de 16 anos. Menores, podem ser vinculados como dependentes.',
    user_invalid_RF: 'Os dados informados não conferem com os da Receita Federal. Verifique a sua situação cadastral no site da Receita Federal.',
    user_not_in_base_lives: '', // Sem texto por falta de definição no refinamento e planning.
    social_name: 'Nome social',
    user_not_registered: 'Paciente não cadastrado na nossa base de vidas'
  }
};