<template>
  <v-container fluid class="waiting-view">
    <v-row>
      <v-col class="attendance-image-container">
        <v-img
          :src="require('@/assets/general/waiting_attendance.svg')"
          class="attendance-image"
          contain
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="attendance-image-container attendance-image-container-border">
        <v-img
          :src="require('@/assets/logos/teladoc_health.svg')"
          class="attendance-instructions-logo"
          contain
        />
      </v-col>
    </v-row>

    <v-row
      class="text-center instructions"
      justify="center"
      md="10"
    >
      <v-col md="8">
        <div class="title-attendance white--text mb-2">
          {{ $t('attendance.title') }}
        </div>
        <div class="subtitle-attendance white--text">
          {{ $t('attendance.subtitle') }}
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-10">
      <v-col md="8" offset-md="2">
        <VideoCallTips :is-list="$device.mobile"/>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  import VideoCallTips from '@/components/hardwareCheck/VideoCallTips.vue';
  import {mapGetters, mapMutations} from 'vuex';

  export default {
    name: 'WaitingAttendance',

    components: {VideoCallTips},

    data: () => ({
      isEnableStartAttendanceBtn: false,
    }),

    methods: {
      ...mapMutations('data/attendance', [
        'setCaseAttendanceId',
      ]),

      ...mapMutations('data/general', [
        'setGeneralError',
        'setGeneralErrorRedirectRoute',
      ]),
      handleHardwareAvailable(isHardwareAvailable) {
        this.isEnableStartAttendanceBtn = !isHardwareAvailable
      },
      checkHasCaseAttendanceId() {
        let caseAttendanceId = this.getCaseAttendanceId;

        if (!caseAttendanceId) {
          caseAttendanceId = localStorage.getItem('case_attendance_id');

          if (!caseAttendanceId) {
            this.setGeneralError(true);
            this.setGeneralErrorRedirectRoute('home');
          }

          this.setCaseAttendanceId(caseAttendanceId);
        }
      },
    },

    computed: {
      ...mapGetters('data/attendance', {
        getCaseAttendanceId: 'getCaseAttendanceId',
      }),
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/styles/colors.scss';

  .waiting-view {
    background-color: $outerSpace;
    height: 100vh;
    max-height: 100vh;
    max-width: 100vw;
    //width: 100vw;
    overflow: auto;
  }

  .attendance-image-container {
    background-color: $cultured;
    height: 250px;
    position: relative;
    display: flex;
    justify-content: center;
  }

  .attendance-image-container-border {
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
  }

  .attendance-image {
    height: 120px;
    position: absolute;
    bottom: 0;
    margin-bottom: -8px;
  }

  .attendance-instructions-container {
    margin-top: 10vh;
    position: relative;
    display: flex;
    justify-content: center;
  }

  .attendance-instructions-logo {
    max-width: 350px;
  }

  .title-attendance {
    font-size: 26px;
    font-weight: 700;
  }

  .subtitle-attendance {
    font-size: 14px;
    font-weight: 400;
  }

  .instructions {
    margin-top: 4rem;
  }

  @media(max-width: 959px) {
    .attendance-image-container {
    background-color: $cultured;
    height: 170px;
    position: relative;
    display: flex;
    justify-content: center;
    }

    .attendance-image {
      width: 250px;
      position: absolute;
      bottom: 0;
      margin-bottom: -22px;
    }

    .title-attendance {
    font-size: 20px;
    font-weight: 700;
    }
    .subtitle-attendance {
    font-size: 13px;
    font-weight: 500;
    }

    .attendance-instructions-logo {
    max-width: 250px;
    }
  }
</style>