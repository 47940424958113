export default {
  getAttendances: state => {
    return state.attendances;
  },

  getProfile: state => {
    return state.profile;
  },

  getDependents: state => {
    return state.dependents;
  },

  getDependentsRaw: state => {
    return state.dependents_raw;
  },

  getEmailVerified: state => {
    return state.email_verified;
  },

  getProgramsScheduled: state => {
    return state.programs_scheduled
  },

  getFirstAvailableDate: state => {
    return state.first_available_date;
  },
};
