import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import VueAxios from 'vue-axios';
import moment from 'moment-timezone';
import VueMoment from 'vue-moment';
import VueI18n from 'vue-i18n'
import Lang from '@/lang/lang';
import VueTheMask from 'vue-the-mask';
import Vuelidate from 'vuelidate';
import Fullscreen from 'vue-fullscreen';
import VueSocketIO from 'vue-socket.io';
import device from 'vue-device-detector';
import VueGtm from '@gtm-support/vue2-gtm';

Vue.config.productionTip = false;
require('moment/locale/br');

Vue.use(VueAxios, axios);
Vue.use(VueMoment, { moment });
Vue.use(VueI18n);
Vue.use(VueTheMask);
Vue.use(Vuelidate);
Vue.use(Fullscreen);
Vue.use(new VueSocketIO(
  {
    debug: !!+process.env.VUE_APP_SOCKET_DEBUG, //Convert "1" to true
    connection: process.env.VUE_APP_SOCKET_URL,
  }
));
Vue.use(device);

Vue.use(VueGtm, {
  id: process.env.VUE_APP_TOKEN_TAG_MANAGER,
  enabled: process.env.VUE_APP_GTM_ENABLED
});

const i18n = Lang.init();

new Vue({
  router,
  store,
  vuetify,
  i18n,
  sockets: {
    connect: function () {
      console.log('Socket connected');
    }
  },
  render: h => h(App)
}).$mount('#app');
