export default {
  reset(state) {
    state.bannerIcon = null;
    state.bannerTitle = null;
    state.company = null;
    state.companyLogo = '';
    state.flavour = null;
    state.generalError = false;
    state.generalErrorRedirectRoute = 'login';
    state.consentTermDialog = false;
    state.privacyPolicyDialog = false;
    state.socketInfo = null;
  },

  setAttendance(state, payload) {
    state.attendance = payload;
  },

  setAttendanceAttachments(state, payload) {
    state.attendance_attachments = payload;
  },

  setCompany(state, payload) {
    state.company = payload;
  },

  setCompanyLogo(state, payload) {
    state.companyLogo = payload;
  },

  setConsentTerm(state, payload) {
    state.consentTerm = payload;
  },

  setFlavour(state, payload) {
    state.flavour = payload;
  },

  setPrivacyPolicy(state, payload) {
    state.privacyPolicy = payload;
  },

  setBannerIcon(state, payload) {
    state.bannerIcon = payload || null;
  },

  setBannerTitle(state, payload) {
    state.bannerTitle = payload || null;
  },

  setGeneralError(state, payload) {
    state.generalError = payload || false;
    state.generalErrorRedirectRoute = !payload ? 'login' : state.generalErrorRedirectRoute;
  },

  setGeneralErrorRedirectRoute(state, payload = 'login') {
    state.generalErrorRedirectRoute = !state.generalError ? 'login' : payload;
  },

  setConsentTermDialog(state, payload) {
    state.consentTermDialog = payload || false;
  },

  setPrivacyPolicyDialog(state, payload) {
    state.privacyPolicyDialog = payload || false;
  },

  setShowSplashScreen(state) {
    state.showSplashScreen = false;
  },

  setSocketInfo(state, payload) {
    state.socketInfo = payload;
  },

  setDoctorsInfo(state, payload) {
    state.doctors_info = payload;
  },

  setProgramsInfo(state, payload) {
    state.programs_info = payload;
  },

  setHistoryAttendance(state, payload) {
    state.history_attendance = payload;
  },
};