import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = () => ({
  attendances: [],
  profile: null,
  dependents: [],
  dependents_raw: [],
  email_verified: null,
  first_available_date: null,
  programs_scheduled: [],
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};