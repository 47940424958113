<template>
  <v-container
    :class="{ 'pa-0': $device.mobile }"
    class="attendance-summary"
  >
    <h6
      v-if="!$device.mobile"
      class="text-h6 mb-5"
    >
      {{ $t('attendances_details.attendance_summary').toUpperCase() }}
    </h6>

    <div
      class="d-flex flex-wrap"
    >
      <!-- Specialty -->
      <v-card
        :class="[ $device.mobile ? 'ma-0' : 'mb-4 mr-4' ]"
        :width="$device.mobile ? '100%' : '300px'"
        class="d-flex info-box py-4 px-6"
        outlined
      >
        <v-icon
          class="mr-4"
          color="secondary"
        >
          fa-stethoscope 
        </v-icon>

        <div
          class="d-flex flex-column"
        >
          <h4
            class="font-weight-bold"
          >
            {{ $t('attendances_details.specialty').toUpperCase() }}
          </h4>

          <p
            class="ma-0 text-caption"
          >
            {{ attendance.program_translate }}
          </p>
        </div>
      </v-card>

      <!-- Patient -->
      <v-card
        :class="[ $device.mobile ? 'ma-0' : 'mb-4 mr-4' ]"
        :width="$device.mobile ? '100%' : '300px'"
        class="d-flex info-box py-4 px-6"
        outlined
      >
        <v-icon
          class="mr-4"
          color="secondary"
        >
          fa-regular fa-user
        </v-icon>

        <div
          class="d-flex flex-column"
        >
          <h4
            class="font-weight-bold"
          >
            {{ $t('attendances_details.patient').toUpperCase() }}
          </h4>

          <p
            class="ma-0 text-caption text-capitalize"
          >
            {{ getPatientName() }}
          </p>
        </div>
      </v-card>

      <!-- Date and Time -->
      <v-card
        :class="[ $device.mobile ? 'ma-0' : 'mb-4' ]"
        :width="$device.mobile ? '100%' : '300px'"
        class="d-flex info-box py-4 px-6"
        outlined
      >
        <v-icon
          class="mr-4"
          color="secondary"
        >
          fa-regular fa-user
        </v-icon>

        <div
          class="d-flex flex-column"
        >
          <h4
            class="font-weight-bold"
          >
            {{ $t('attendances_details.date_and_time').toUpperCase() }}
          </h4>

          <p
            class="ma-0 text-caption"
          >
            {{ convertUtcOffset(attendance.sla).format('DD/MM - HH:mm') }}
          </p>
        </div>
      </v-card>
    </div>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex';
  import helpers from '@/mixins/helpers';

  export default {
    name: 'AttendanceSummary',

    data: () => ({
      name: null,
    }),

    mixins: [helpers],

    methods: {
      getPatientName() {
        let memberInfo = this.attendance.attendance_patient_info
          ? this.attendance.attendance_patient_info.member_info
          : this.dependents.find(o => o.id == this.attendance.member_id);

        if (memberInfo.social_name || memberInfo.name) {
          return (memberInfo.social_name || memberInfo.name).toLowerCase();
        } else if (this.attendance.social_name || this.attendance.name) {
          return (this.attendance.social_name || this.attendance.name).toLowerCase()
        } else {
          let dependent = this.dependents.find(o => this.attendance.member_id == o.id);

          if (dependent) {
            return dependent.social_name
              ? dependent.social_name.toLowerCase()
              : (dependent.name || dependent.nome).toLowerCase();
          } else {
            return (this.profile.social_name || this.profile.name).toLowerCase();
          }
        }
      },
    },

    computed: {
      ...mapGetters('data/general', {
        attendance: 'getAttendance', // -> this.attendance
      }),

      ...mapGetters('data/patient', {
        dependents: 'getDependents', // this.dependents
        profile: 'getProfile', // -> this.profile
      }),
    },
  }
</script>

<style lang="scss" scoped>
  .attendance-summary {
    .info-box {
      border-radius: 50px !important;
      min-width: 300px;

      h4, p {
        height: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    .attendance-summary {
      // 
    }
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .attendance-summary {
      .info-box {
        border-radius: 0 !important;
        min-width: auto;
      }
    }
  }

  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .attendance-summary {
      .info-box {
        border-radius: 0 !important;
        min-width: auto;
      }
    }
  }

  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .attendance-summary {
      .info-box {
        border-radius: 0 !important;
        min-width: auto;
      }
    }
  }
</style>