<template>
  <v-container
    class="schedule-details-view d-flex flex-column pa-0"
    max-width="100%"
  >
    <v-card>
      <div
        :class="[ $device.mobile ? 'pa-0' : 'pa-8' ]"
        class="content mb-10"
      >
        <v-row
          :class="[ $device.mobile ? 'mr-1 ml-1' : 'mr-9 ml-9' ]"
        >
          <v-col cols="12" class="p-0">
            <h2
              :class="$device.mobile ? 'text-center mt-6 text-h6' : 'text-h5'"
              class="mb-8 outerSpace--text font-weight-medium"
            >
              {{ $t('attendances_details.my_informations') }}
            </h2>

            <v-card
              class="d-flex flex-column mb-8"
              color="antiFlash"
              flat
              width="auto"
            >
              <v-container class="pt-0">
                <div
                  :class="$device.mobile ? '' : 'pl-10 pr-10'"
                  class="d-flex flex-column mt-5"
                >
                  <h4 class="mb-4 outerSpace--text">
                    {{ $t('attendances_details.attendance_summary').toUpperCase() }}
                  </h4>

                  <div class="d-inline-flex justify-space-start flex-wrap">
                    <!-- Specialty -->
                    <v-list
                      rounded
                      class="information-item mr-4 my-1"
                    >
                      <v-list-item
                        class="list-item prevent-interaction"
                      >
                        <v-list-item-avatar>
                          <v-icon color="secondary">
                            fa-stethoscope
                          </v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            class="title font-weight-bold"
                          >
                            {{ $t('attendances_details.specialty').toUpperCase() }}
                          </v-list-item-title>

                          <v-list-item-subtitle class="text-wrap">
                            {{ doctor_info.speciality }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>

                    <!-- Patient -->
                    <v-list
                      class="information-item mr-4 my-1"
                      rounded
                    >
                      <v-list-item
                        :class="{ 'prevent-interaction': !patients || !patients.length || qtdConsultation > 0 }"
                        @click="handlePatientSelection"
                        append
                        class="list-item"
                      >
                        <v-list-item-avatar>
                          <v-icon color="secondary">
                            fa-regular fa-user
                          </v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            class="title font-weight-bold"
                          >
                            {{ $t('attendances_details.patient').toUpperCase() }}
                          </v-list-item-title>

                          <v-list-item-subtitle class="text-wrap">
                            {{
                              patient.social_name
                                ? capitalized(patient.social_name)
                                : capitalized(patient.name || patient.nome)
                            }}
                          </v-list-item-subtitle>
                        </v-list-item-content>


                        <v-icon
                          v-if="patients?.length > 0 && qtdConsultation == 0"
                          color="black"
                        >
                          mdi-chevron-right
                        </v-icon>
                      </v-list-item>
                    </v-list>

                    <!-- Date and Time: Previous schedules -->
                    <v-list
                      v-if="lastSchedules.length && programInfo.attendances_qty > 1"
                      class="information-item mr-4 my-1"
                      rounded
                    >
                      <v-list-item
                        v-for="(itemSchedule, index) in lastSchedules"
                        :key="index"
                        append
                        class="list-item prevent-interaction"
                      >
                        <v-list-item-avatar>
                          <v-icon color="secondary">
                            fa-regular fa-calendar-days
                          </v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            class="title font-weight-bold"
                          >
                            {{ $t('attendances_details.date_and_time').toUpperCase() }}

                            <span>
                              - {{ `${index+1}${$t('attendances_details.number_attendance').toUpperCase()}` }}
                            </span>
                          </v-list-item-title>

                          <v-list-item-subtitle class="text-wrap">
                            {{ convertUtcOffset(`${itemSchedule.due_date} ${itemSchedule.due_time}`).format('DD/MM/YYYY - HH:mm') }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>

                    <!-- Date and Time: Current schedule -->
                    <v-list
                      class="information-item mr-4 my-1"
                      rounded
                    >
                      <v-list-item
                        @click="handleSelectDateHour"
                        append
                        class="list-item"
                      >
                        <v-list-item-avatar>
                          <v-icon color="secondary">
                            fa-regular fa-calendar-days
                          </v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            class="title font-weight-bold"
                          >
                            {{ $t('attendances_details.date_and_time').toUpperCase() }}

                            <span
                              v-if="programInfo.attendances_qty > 1"
                            >
                              - {{ `${qtdConsultation+1}${$t('attendances_details.number_attendance').toUpperCase()}` }}
                            </span>
                          </v-list-item-title>

                          <v-list-item-subtitle class="text-wrap">
                            {{ immediate ? $t('schedule.immediate_care') : datetime && formatDate(datetime) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-icon color="black">
                          mdi-chevron-right
                        </v-icon>
                      </v-list-item>
                    </v-list>

                    <!-- Date and Time: Next schedule -->
                    <v-list
                      v-if="lastSchedules.length+1 < programInfo.attendances_qty"
                      class="information-item mr-4 my-1"
                      rounded
                    >
                      <v-list-item
                        append
                        class="list-item"
                      >
                        <v-list-item-avatar>
                          <v-icon color="red">
                            fa-regular fa-calendar-days
                          </v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title
                            class="title font-weight-bold red--text"
                          >
                            {{ $t('attendances_details.date_and_time').toUpperCase() }}

                            <span>
                              - {{ `${qtdConsultation+2}${$t('attendances_details.number_attendance').toUpperCase()}` }}
                            </span>
                          </v-list-item-title>

                          <v-list-item-subtitle class="text-wrap">
                            {{ $t('general.select') }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </div>
              </v-container>

              <v-container v-if="!is_program_well_being">
                <div class="d-flex flex-column mt-5 mb-5" :class="$device.mobile ? '' : 'pl-10 pr-10'">
                  <h4 class="mb-2">
                    {{ $t('attendances_details.orders_exams_photos').toUpperCase() }}
                  </h4>

                  <div class="d-flex flex-column">
                    <h4 class="mb-8 spanishGray--text">
                      <span class="font-weight-medium">
                        {{ $t('attendances_details.attach_up_to') }}
                      </span>

                      <span class="font-weight-bold">
                        {{ $t('attendances_details.five_files_types') }}
                      </span>
                    </h4>

                    <v-alert
                      ref="error"
                      v-if="errors.length > 0"
                      class="mb-3 mt-2"
                      border="left"
                      type="warning"
                      close-text="Fechar alerta"
                      color="#FCAA42"
                      dismissible
                      max-width="350"
                    >
                      <span v-for="(error, i) in errors" :key="i">{{ error }}</span>
                    </v-alert>

                    <div class="align-center d-inline-flex flex-wrap attachments">
                      <!-- Files -->
                      <v-list
                        v-for="(file, index) in schedule.files"
                        :key="index"
                        class="information-item"
                        rounded
                      >
                        <v-list-item
                          @click="handleFileExcludeDialog(index)"
                          class="list-item"
                        >
                          <v-list-item-avatar>
                            <v-icon color="secondary">
                              fa-regular fa-file
                            </v-icon>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title
                              class="title font-weight-bold"
                            >
                              {{ file.name }}
                            </v-list-item-title>
                          </v-list-item-content>

                          <v-icon size="15" color="spanishGray">
                            fa-trash
                          </v-icon>
                        </v-list-item>
                      </v-list>

                      <!-- Add Button -->
                      <v-btn
                        v-if="schedule.files.length < 5"
                        :loading="loadingFiles"
                        @click="addFiles"
                        class="px-12"
                        color="primary"
                        rounded
                      >
                        <v-icon class="mr-2" color="white" small>
                          fa-circle-plus
                        </v-icon>

                        {{ $t('general.add') }}
                      </v-btn>
                      <v-file-input
                        v-model="fileInputs"
                        class="d-none"
                        accept="image/jpeg,image/jpg,image/png,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        ref="uploader"
                        chip
                        multiple
                        clearable
                      />
                    </div>
                  </div>
                </div>
              </v-container>

              <v-container v-if="!(programId == 7)">
                <div class="d-flex flex-column mb-5" :class="$device.mobile ? '' : 'pl-10 pr-10'">
                  <h4 class="mb-5">
                    {{ $t('attendances_details.professional').toUpperCase() }}
                  </h4>

                  <!-- professional -->
                  <v-list
                    class="information-item"
                    rounded
                  >
                    <v-list-item
                      :class="{ 'prevent-interaction': qtdConsultation > 0 }"
                      @click="handleSelectProfessional"
                    >
                      <v-list-item-avatar>
                        <v-progress-circular v-if="loading" :width="3" color="white" indeterminate/>

                        <v-img v-else-if="doctor_info.doctor_avatar" :src="doctor_info.doctor_avatar" alt="avatar"/>

                        <v-icon v-else color="outerSpace">
                          fa-user
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          class="title font-weight-bold"
                        >
                          {{ getProfessionalName(doctor_info) }}
                        </v-list-item-title>

                        <v-list-item-subtitle
                          v-if="doctor_info.crm || !doctor_info.occupation"
                          class="text-wrap"
                        >
                          {{ doctor_info?.prof_council??'' }}: {{ doctor_info?.crm??''}}
                        </v-list-item-subtitle>

                        <v-list-item-subtitle
                          v-else
                          class="text-wrap"
                        >
                          {{ doctor_info.occupation }}
                        </v-list-item-subtitle>
                      </v-list-item-content>

                      <v-icon
                        v-if="qtdConsultation == 0"
                        color="black"
                      >
                        mdi-chevron-right
                      </v-icon>
                    </v-list-item>
                  </v-list>
                </div>
              </v-container>
            </v-card>

            <div>
              <div>
                <p>
                  <b>{{ $t('general.attention') }} </b>{{ $t('general.attention_text') }}

                  <a
                    @click="handleTermsConfirm"
                    class="secondary--text"
                  >
                    {{ $t('general.attention_terms') }}
                  </a>
                </p>
              </div>

              <!-- Buttons: Schedule more attendances -->
              <v-row
                v-if="lastSchedules.length+1 < programInfo.attendances_qty"
                class="buttons-row"
              >
                <v-col cols="12" sm="12" md="4">
                  <v-btn
                    :loading="loadingSchedule"
                    @click="handleScheduleAttendance(false)"
                    color="primary"
                    block
                    height="46"
                    max-width="320"
                    outlined
                    rounded
                  >
                    <span>
                      {{ $t('attendances_details.schedule_only') }}
                    </span>

                    <span
                      class="px-1"
                    >
                      {{qtdConsultation+1}}
                    </span>

                    <span>
                      {{ qtdConsultation+1 <= 1 ? $t('attendances_details.appointment') : $t('attendances_details.appointments') }}
                    </span>
                  </v-btn>
                </v-col>

                <v-col cols="12" sm="12" md="4">
                  <v-btn
                    :loading="loadingSchedule"
                    @click="handleScheduleNextAttendance"
                    block
                    color="primary"
                    dark
                    height="46"
                    max-width="320"
                    outline-border
                    rounded
                  >
                    <span>
                      {{ $t('attendances_details.schedule') }}
                    </span>

                    <span
                      class="px-1"
                    >
                      {{qtdConsultation+2}}
                    </span>

                    <span>
                      {{ $t('attendances_details.appointments') }}
                    </span>
                  </v-btn>
                </v-col>
              </v-row>

              <!-- Buttons: Last schedule -->
              <v-row
                v-if="lastSchedules.length+1 >= programInfo.attendances_qty"
                class="buttons-row"
              >
                <v-col cols="4" sm="4" md="3" lg="2">
                  <v-btn
                    :loading="loadingSchedule"
                    @click="handleAcceptedCancelScheduled"
                    color="outerSpace"
                    block
                    outlined
                    rounded
                  >
                    {{ $t('general.cancel') }}
                  </v-btn>
                </v-col>

                <v-col cols="8" sm="5" md="5" lg="4">
                  <v-btn
                    :loading="loadingSchedule"
                    @click="handleScheduleAttendance(false)"
                    color="secondary"
                    block
                    dark
                    outline-border
                    rounded
                  >
                    <v-icon class="mr-2" small>
                      mdi-arrow-right
                    </v-icon>

                    {{ $t('general.schedule_confirm') }}
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <!-- Footer -->
    <HomeFooter/>

    <!-- Patient Selection -->
    <GeneralModal
      :dialog="patientSelectionDialog"
      :header-title="$t('general.patient_selection_title')"
      @handleClose="closePatientSelectionDialog"
      @handleHeaderInfo="openWarningDialog"
      has-close
      has-header-info
      width="549"
    >
      <PatientSelection
        @handleCancel="closePatientSelectionDialog"
        @handleSelect="handleSelectPatient"
      />
    </GeneralModal>

    <!-- Warning info for remove file -->
    <GeneralModal
      :dialog="fileExcludeDialog"
      :header-title="$t('general.warning')"
      @handleCancel="handleCloseFileExclude"
      @handleClose="handleCloseFileExclude"
      @handleConfirm="removeFile(excludeFile)"
      background-color="antiFlash"
      has-cancel
      has-confirm
      has-header-close
      invert-actions
      width="470">
      <h3 class="text-center pb-2 outerSpace--text">
        {{ $t('general.warning') }}
      </h3>
      <span>
        {{ $t('general.file_exclude') }}
      </span>
    </GeneralModal>

    <!-- Warning info for cancel schedule -->
    <GeneralModal
      :dialog="cancelScheduleDialog"
      :header-title="$t('general.warning')"
      @handleCancel="handleCancelScheduled"
      @handleClose="handleCancelScheduled"
      @handleConfirm="handleConfirmCancelScheduled"
      background-color="antiFlash"
      has-cancel
      has-confirm
      has-header-close
      invert-actions
      width="470"
    >
      <h3 class="text-center pb-2 outerSpace--text">
        {{ $t('general.warning') }}
      </h3>

      <span>
        {{ $t('general.schedule_cancel') }}
      </span>
    </GeneralModal>

    <!-- Confirmation current schedule for next one -->
    <GeneralModal
      :dialog="confirmCurrentScheduleDialog"
      :confirm-text="$t('general.yes')"
      @handleCancel="handleCancelCurrentSchedule"
      @handleClose="handleCancelCurrentSchedule"
      @handleConfirm="handleConfirmCurrentSchedule"
      background-color="antiFlash"
      has-cancel
      has-confirm
      success
      width="470"
    >
      <h3 class="text-center pb-2 outerSpace--text">
        {{ $t('general.confirmation') }}
      </h3>

      <span>
        {{ $t('attendances_details.confirm_your_appointment_of') }}
      </span>

      <span>
        {{ doctor_info.speciality.toLowerCase() }}
      </span>

      <span>
        {{ $t('attendances_details.and_schedule_next') }}
      </span>
    </GeneralModal>

    <!-- Confirmation current schedule date and time -->
    <GeneralModal
      :dialog="confirmedCurrentScheduleDialog"
      persistent
      background-color="antiFlash"
      success
      width="470"
    >
      <h3 class="text-center pb-2 outerSpace--text">
        {{ $t('attendances_details.schedule_confirmed_success') }}
      </h3>

      <p
        class="mt-6 text-center"
      >
        <span
          class="pr-2"
        >
          <v-icon color="secondary">
            fa-regular fa-calendar-days
          </v-icon>
        </span>

        <span>
          {{ formatDate(datetime) }}
        </span>
      </p>
    </GeneralModal>
  </v-container>
</template>

<script>
  import {mapActions, mapGetters, mapMutations} from 'vuex';
  import HomeFooter from '@/components/home/HomeFooter';
  import helpers from '@/mixins/helpers';
  import GeneralModal from '@/components/general/GeneralModal';
  import PatientSelection from '@/components/general/PatientSelection';
  import consts from '@/static/js/consts';

  export default {
    name: 'ScheduleDetailsView',

    mixins: [helpers],

    components: {
      HomeFooter,
      GeneralModal,
      PatientSelection
    },

    data: () => ({
      patientSelectionDialog: false,
      cancelScheduleDialog: false,
      fileExcludeDialog: false,
      warningDialog: false,
      confirmCurrentScheduleDialog: false,
      confirmedCurrentScheduleDialog: false,
      lastSchedules: [],
      qtdConsultation: 0,
      confirmationCloseTimer: 5000,
      excludeFile: null,
      selectedFiles: null,
      maxFilesLimit: 5,
      doctor_info: {
        name: null,
        speciality: null,
        crm: null,
        doctor_avatar: null,
        occupation: null,
        prof_council: null
      },
      datetime: null,
      programId: null,
      is_program_well_being: null,
      immediate: false,
      loading: false,
      loadingSchedule: false,
      caseAttendanceId: null,
      errors: [],
      fileInputs: [],
      typeFilesAccept: ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
      loadingFiles: false,
      user_schedules_ids: null
    }),

    created() {
      // Get patient profile if needed
      if (!this.patient) {
        this.getPatientProfile();
      }

      if (this.schedule &&
          (this.schedule.program) &&
          (this.schedule.professional || this.schedule.professional == null)
      ) {
        this.programId = this.schedule.program.id;
        this.is_program_well_being = this.schedule.program.is_program_well_being;
        this.doctor_info.speciality = this.schedule?.program?.name??'';
        this.doctor_info.name = this.schedule?.professional?.name??'';
        this.doctor_info.crm = this.schedule?.professional?.crm??'';
        this.doctor_info.doctor_avatar = this.schedule?.professional?.image_path??'';
        this.doctor_info.prof_council = this.schedule?.professional?.prof_council??'';
        this.datetime = this.schedule?.datetime??'';
        this.user_schedules_ids = this.schedule?.user_schedules_ids??'';

        if (!this.schedule.datetime) {
          this.immediate = true;
          this.datetime = this.$moment().unix();
        }

        if (consts.PROGRAM_WELL_BEING_IDS.includes(this.programId)) {
          this.doctor_info.occupation = this.schedule?.professional?.roles[0]?.role_name??'';
        }

        const patientID = this.lastAttendances.attendances.patient_info?.id;

        this.lastSchedules = (this.lastAttendances.attendances?.programs_scheduled
            || []).filter(program => program.program_id === this.programId && program.member_id === patientID);
        this.qtdConsultation = this.lastSchedules.length;
      } else {
        this.setGeneralError(true);
        this.setGeneralErrorRedirectRoute('home');
      }
    },

    mounted() {
      this.setBannerTitle(this.$t('general.banner_need_attendance'));
      this.setBannerIcon('fa-solid fa-hand-holding-medical');
    },

    methods: {
      ...mapActions('data/patient', [
        'patientProfile', // -> map `this.patientProfile()` to `this.$store.dispatch('patientProfile')`
      ]),

      ...mapActions('data/schedule', [
        'caseAttendanceAdd',
        'caseCreate',
        'programAttendanceAdd',
        'verifySchedule'
      ]),

      ...mapActions('data/general', [
        'attendanceSaveAttachment',
      ]),

      ...mapMutations('data/general', [
        'setBannerTitle',
        'setBannerIcon',
        'setConsentTermDialog',
        'setGeneralError',
        'setGeneralErrorRedirectRoute',
      ]),

      ...mapMutations('data/patient', [
        'setProfile'
      ]),

      ...mapMutations('data/schedule', [
        'removeFileIndex',
        'resetDatetime',
        'resetFiles',
        'resetSchedule',
        'resetProgram',
        'resetPrograms',
        'setDatetime',
        'setFile',
        'setPatient',
        'setProfessional',
        'setSchedule',
      ]),

      getPatientProfile() {
        this.loading = true;
        this.patientProfile(this.schedule.patient.id).catch((error) => {
          console.error(error);
          this.setGeneralError(true);
          this.setGeneralErrorRedirectRoute('home');
        }).finally(() => {
          this.loading = false;
        });
      },

      addFiles() {
        this.fileInputs = [];
        this.loadingFiles = true
        this.$refs.uploader.$refs.input.click()
      },

      setFilesInStorage() {
        this.errors = [];

        for (let j = 0; j < this.fileInputs.length; j++) {
          if (!this.typeFilesAccept.includes(this.fileInputs[j].type)) {
            this.errors.push('Tipo de arquivo não permitido!');
            break;
          }
        }

        if (this.fileInputs.some(file => file.size > 15000000)) {
          this.errors.push('Arquivo não pode ser maior do que 15MB!');
        }

        if ((this.fileInputs.length + this.schedule.files.length) > 5) {
          this.errors.push('Não é possível anexar mais do 5 arquivos!');
        }

        if (this.errors.length > 0) {
          return false;
        } else {
          this.fileInputs.forEach(fileInput => {
            this.setFile(fileInput);
          });
        }

        this.loadingFiles = false;
      },

      removeFile(index) {
        this.removeFileIndex(index);
        this.handleCloseFileExclude();
      },

      closePatientSelectionDialog() {
        this.patientSelectionDialog = false;
      },

      openWarningDialog() {
        this.warningDialog = true;
      },

      handlePatientSelection() {
        if (!this.patients || !this.patients.length || this.qtdConsultation > 0) return;

        this.patientSelectionDialog = true;
      },

      handleSelectPatient() {
        if (this.loadingSchedule) return;

        this.resetProgram();
        this.resetSchedule();
        this.$router.push('/programs');
      },

      handleSelectDateHour() {
        if (this.loadingSchedule) return;

        this.resetSchedule();
        this.$router.push('/schedule');
      },

      handleSelectProfessional() {
        if (this.loadingSchedule || this.qtdConsultation > 0) return;

        this.resetSchedule();
        this.$router.push('/schedule');
      },

      handleCloseFileExclude() {
        this.fileExcludeDialog = false;
        this.excludeFile = null;
      },

      handleFileExcludeDialog(file) {
        if (this.loadingSchedule) return;

        this.excludeFile = file;
        this.fileExcludeDialog = true;
      },

      handleTermsConfirm() {
        this.setConsentTermDialog(true);
      },

      handleCancelScheduled() {
        this.cancelScheduleDialog = false;
      },

      handleAcceptedCancelScheduled() {
        this.cancelScheduleDialog = true;
      },

      handleConfirmCancelScheduled() {
        this.resetSchedule();
        this.resetPrograms();
        this.$router.push('/');
      },

      handleScheduleNextAttendance() {
        this.confirmCurrentScheduleDialog = true;
      },

      handleCancelCurrentSchedule() {
        this.confirmCurrentScheduleDialog = false;
      },

      handleConfirmCurrentSchedule() {
        this.handleScheduleAttendance(true);
        this.confirmCurrentScheduleDialog = false;
      },

      async handleScheduleAttendance(scheduleNextAttendance = false) {
        let payload = {};
        this.loadingSchedule = true;
        const attachments = this.schedule.files;

        if (this.immediate && this.programId === 7) {
          payload = {
            member_id: this.patient.id,
            case_status: 'ACTIVE',
            company_id: this.companyId,
            group_id: this.groupId,
            is_aso: false,
            emergency_pass: false
          };

          await this.caseCreate(payload).then((response) => {
            payload = {
              case_id: response,
              timestamp: this.datetime,
              user_id: this.doctor_info.id,
              patient_id: this.patient.id,
              program_id: this.programId,
              program_step: 1,
              bricks: '{}'
            };
          }).catch((error) => {
            console.error(error);
            this.setGeneralError(true);
            this.setGeneralErrorRedirectRoute('home');
          })

          await this.caseAttendanceAdd(payload).then((response) => {
            this.caseAttendanceId = response.newest_attendance_id;
          }).catch((error) => {
            console.error(error);
            this.setGeneralError(true);
            this.setGeneralErrorRedirectRoute('home');
          }).finally(() => {
            this.loadingSchedule = false;
          });

          await this.saveAttachment(attachments);

          this.resetPrograms();
          this.resetSchedule();
          this.loadingSchedule = false;
          
          await this.$router.push('/schedule/success');
        } else {
          if (this.programId == 7) {
            await this.verifySchedule({
              user_schedule_ids: this.user_schedules_ids || [],
              date: this.datetime,
            });
          }

          const {professional, verify_schedule} = this.schedule || {};

          const {case_id, double_booking} = this.getCheckDoubleBooking || {};

          payload = {
            timestamp: this.datetime,
            user_id: (professional?.id || verify_schedule?.user_schedule_info?.user_id),
            user_schedule_id: (professional?.schedule_id || verify_schedule?.user_schedule_info?.id),
            member_id: this.patient.id,
            program_id: this.programId,
            company_id: this.companyId,
            bricks: '{}',
            double_booking: double_booking
          };

          if (case_id) {
            payload.case_id = case_id;
          }

          await this.programAttendanceAdd(payload).then((response) => {
            this.caseAttendanceId = response.newest_attendance_id;
          }).catch((error) => {
            console.error(error);
            this.setGeneralError(true);
            this.setGeneralErrorRedirectRoute('home');
          });

          await this.saveAttachment(attachments);

          if (scheduleNextAttendance) {
            await this.openConfirmationModal();
          } else {
            this.resetPrograms();
            this.resetSchedule();
            this.loadingSchedule = false;

            await this.$router.push('/schedule/success');
          }
        }
      },

      openConfirmationModal() {
        this.confirmedCurrentScheduleDialog = true;

        setTimeout(() => {
          this.loadingSchedule = false;
          this.confirmedCurrentScheduleDialog = false;
          this.resetDatetime();
          this.resetFiles();
          this.$router.push('/schedule');
        }, this.confirmationCloseTimer);
      },

      padTo2Digits(num) {
        return num.toString().padStart(2, '0');
      },

      formatDate(date) {
        return this.$moment.unix(date).format('DD/MM/YYYY - HH:mm');
      },

      getProfessionalName(item) {
        if (!item) return '';

        let programList = [48, 57]; // "Psicologia" and "Psicologia por Vídeo"
        if (programList.includes(this.programId)) {
          return item?.name??'';
        }
        
        return item.crm || !this.is_program_well_being
          ? `${this.$t('schedule.MD')} ${item.name}`
          : item?.name??'';
      },

      saveAttachment(files) {
        if (files.length > 0) {
          files.forEach((i) => {
            this.attendanceSaveAttachment({
              case_attendance_id: this.caseAttendanceId,
              attachment: [i]
            });
          })
        }
      }
    },

    computed: {
      ...mapGetters('data/attendance', {
        lastAttendances: 'getPatientLastAttendance',
      }),

      ...mapGetters('data/general', {
        companyLogo: 'getCompanyLogo', // -> this.companyLogo
        attachments: 'getAttendanceAttachments'
      }),

      ...mapGetters('data/login', {
        companyId: 'getCompanyId',  // -> this.companyId
        groupId: 'getGroupId', // -> this.groupId
      }),

      ...mapGetters('data/patient', {
        patients: 'getDependents',  // -> this.patients
      }),

      ...mapGetters('data/schedule', {
        schedule: 'getSchedule', // -> this.getSchedule
        patient: 'getPatient', // -> this.patient
        programInfo: 'getProgram',
        getCheckDoubleBooking: 'getCheckDoubleBooking',
      }),
    },

    watch: {
      fileInputs() {
        this.setFilesInStorage()
      },
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/styles/colors.scss';

  .schedule-details-view {
    max-width: 100%;

    .content {
      font-size: 16px;
      padding: 1em !important;
      min-height: calc(100vh - 246px - 97px);
      margin-top: 2em;

      .list-item {
        padding: 0 10px;

        .title {
          font-size: 14px !important;
          letter-spacing: 0em !important;
        }
      }

      .buttons-row {
        max-width: 1024px;
      }
    }
  }

  @media (min-width: 960px) {
    .container {
      max-width: 100% !important;
    }
  }

  .attachments {
    gap: 12px;
  }

  .v-list {
    padding: 0 !important;
  }

  .information-item {
    width: 326px;
    border: 0.1px solid !important;
    border-color: $silverSand !important;
    border-radius: 20em !important;

    .prevent-interaction {
      pointer-events: none;
    }

    @media screen and (max-device-width: 1024px) {
      width: 100%
    }
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px) and (max-device-width: 1024px) {
    .schedule-details-view {
      .content {
        min-height: calc(100vh - 216px - 97px);
        padding-bottom: 85px !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 74px !important;
      }
    }
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .schedule-details-view {
      padding-bottom: 54px !important;

      .content {
        min-height: calc(100vh - 129px - 76px);
        padding-bottom: 38px !important;
        padding-top: 0 !important;
      }
    }
  }

  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
    .schedule-details-view {
      padding-bottom: 54px !important;

      .content {
        min-height: calc(100vh - 129px - 76px);
        padding-bottom: 38px !important;
        padding-top: 0 !important;
      }
    }
  }

  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {
    .schedule-details-view {
      padding-bottom: 54px !important;

      .content {
        min-height: calc(100vh - 129px - 76px);
        padding-bottom: 38px !important;
        padding-top: 0 !important;
      }
    }
  }
</style>