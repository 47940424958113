export default {
  feedback: {
    between_0_10_indicate_this_professional_to_friend: {
      part_1: 'Em uma escala de ',
      part_2: '0 a 10 ',
      part_3: ', qual a probabilidade de recomendar este profissional a um amigo ou colega?'
    },
    between_0_10_indicate_our_service_to_friend: {
      part_1: 'Em uma escala de ',
      part_2: '0 a 10 ',
      part_3: 'qual a probabilidade de recomendar nossos serviços a um amigo ou colega?'
    },
    how_can_we_improve: "Em que podemos melhorar?",
    not_evaluate: "Não avaliar",
    evaluate: "Avaliar",
  }
};