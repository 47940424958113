<template>
  <v-container
    class="store-buttons ma-0 pa-0"
  >
    <v-btn
      v-for="(btn, index) in listButtons"
      :class="[ $device.mobile ? 'mx-1' : 'mx-4' ]"
      :height="$device.mobile ? 51 : 76"
      :href="btn.url"
      :key="index"
      :width="$device.mobile ? 162 : 241"
      class="store-button px-15 py-4"
      color="primary"
    >
      <v-icon
        class="mr-2"
        color="$secondaryFlavourDefault"
        x-large
      >
        {{ btn.icon }}
      </v-icon>

      <div
        class="text-left"
      >
        <p
          class="subtitle text-caption ma-0"
        >
          {{ $t(`general.${btn.subtitle}`) }}
        </p>

        <p
          class="title text-h5 ma-0"
        >
          {{ $t(`general.${btn.title}`) }}
        </p>
      </div>
    </v-btn>
  </v-container>
</template>

<script>
  export default {
    name: 'HomeButtons',

    data: () => ({
      listButtons: [
        {
          icon: 'fa-brands fa-google-play',
          subtitle: 'available_on',
          title: 'google_play',
          url: 'https://play.google.com/store/apps/details?id=com.tdocop.teladoc',
        },
        {
          icon: 'fa-brands fa-apple',
          subtitle: 'download_at',
          title: 'apple_store',
          url: 'https://apps.apple.com/br/app/teladoc/id1506041273',
        },
      ],
    }),
  };
</script>

<style lang="scss" scoped>
  @import '@/styles/colors.scss';

  .store-buttons {
    .store-button {
      border-radius: 10px;

      .subtitle {
        font-size: 20px !important;
      }

      .title {
        font-size: 25px !important;
      }
    }
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    .store-buttons {
      .store-button {
        height: 58px !important;
        width: 184px !important;

        .subtitle {
          font-size: 15px !important;
        }

        .title {
          font-size: 20px !important;
          line-height: 1.5rem;
        }
      }
    }
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .store-buttons {
      .store-button {
        .subtitle {
          font-size: 10px !important;
        }

        .title {
          font-size: 15px !important;
          line-height: 1rem;
        }
      }
    }
  }

  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .store-buttons {
      .store-button {
        .subtitle {
          font-size: 10px !important;
        }

        .title {
          font-size: 15px !important;
          line-height: 1rem;
        }
      }
    }
  }

  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .store-buttons {
      .store-button {
        .subtitle {
          font-size: 10px !important;
        }

        .title {
          font-size: 15px !important;
          line-height: 1rem;
        }
      }
    }
  }
</style>