import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = () => ({
  patient: null,
  program: null,
  programs: null,
  warning_types: {
    63: 'general.restricted_medications',
    57: 'general.psychology_under_age'
  },
  restricted_programs_age: [57],
  warning_programs: [63],
  datetime: null,
  professional: null,
  check_double_booking: null,
  schedules_slots: null,
  files: [],
  verify_schedule: null,
  user_schedules_ids: null,
  scheduleType: null,
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
