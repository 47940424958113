import '@fortawesome/fontawesome-free/css/all.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from '../static/js/colors';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  theme: {
    themes: {
      dark: {
        primary: colors.primary,
        secondary: colors.secondary,
        outerSpace: colors.outerSpace,
        silverSand: colors.silverSand,
        spanishGray: colors.spanishGray,
        antiFlash: colors.antiFlash,
        antiFlashWhite: colors.antiFlashWhite,
        cultured: colors.cultured,
        error: colors.error,
        folly: colors.folly,
        deepPurple: colors.deepPurple,
        electricRed: colors.electricRed,
        lightSilver: colors.lightSilver,
      },
      light: {
        primary: colors.primary,
        secondary: colors.secondary,
        outerSpace: colors.outerSpace,
        silverSand: colors.silverSand,
        spanishGray: colors.spanishGray,
        antiFlash: colors.antiFlash,
        antiFlashWhite: colors.antiFlashWhite,
        cultured: colors.cultured,
        error: colors.error,
        folly: colors.folly,
        deepPurple: colors.deepPurple,
        electricRed: colors.electricRed,
        lightSilver: colors.lightSilver,
      }
    }
  }
});