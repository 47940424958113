<template>
  <v-container
      class="attendances-details-view ma-0 pa-0"
  >
    <div
        :class="[ $device.mobile ? 'py-12 px-6' : 'pa-16' ]"
    >
      <h3
          :class="[ $device.mobile ? 'font-weight-bold mb-3' : 'text-h5 mb-8' ]"
      >
        {{
          $device.mobile
              ? $t('attendances_details.attendance_summary').toUpperCase()
              : $t('attendances_details.my_attendance')
        }}
      </h3>

      <v-card
          :class="[ $device.mobile ? 'pa-0' : 'py-10 px-12' ]"
          :color="$device.mobile ? 'white' : 'antiFlash'"
          class="informations d-flex flex-column mb-8"
          flat
          width="auto"
      >
        <!-- Summary -->
        <AttendanceSummary/>

        <!-- Professional: MOBILE -->
        <AttendanceProfessional
            v-if="$device.mobile && hasProfessional"
        />

        <!-- Files -->
        <AttendanceFiles
            v-if="hasFiles"
        />

        <!-- Professional -->
        <AttendanceProfessional
            v-if="!$device.mobile && hasProfessional"
        />
      </v-card>

      <div
          :class="{ 'd-flex': $device.mobile }"
      >
        <v-btn
            :class="{ 'flex-grow-1': $device.mobile }"
            :width="$device.mobile ? 'auto' : '167'"
            @click="handleBack"
            outlined
            height="46"
            rounded
        >
          {{ $t('general.back') }}
        </v-btn>

        <v-btn
            :class="{
            'flex-grow-1': $device.mobile,
          }"
            :width="$device.mobile ? 'auto' : '167'"
            class="mx-3"
            color="outerSpace"
            dark
            height="46"
            rounded
            @click="handleReschedule"
        >
          <v-icon
              v-if="!$device.mobile"
              class="mr-2"
              small
          >
            fa-rotate
          </v-icon>

          {{ $t('general.reschedule') }}
        </v-btn>

        <v-btn
            :class="{ 'flex-grow-1': $device.mobile }"
            :width="$device.mobile ? 'auto' : '167'"
            @click="cancelAttendanceDialog = true"
            color="error"
            height="46"
            outlined
            rounded
        >
          {{ $t('general.cancel') }}
        </v-btn>
      </div>

      <!-- Cancel Modal -->
      <GeneralModal
          :dialog="cancelAttendanceDialog"
          :has-close="!loadingCancelAttendance"
          :header-title="$t('attendances_details.cancel_attendance')"
          :persistent="loadingCancelAttendance"
          @handleClose="cancelAttendanceDialog = false"
          width="500"
      >
        <CancelReasonsForm
            :loading="loadingCancelAttendance"
            @handleConfirm="handleCancelAttendance"
        />
      </GeneralModal>
      <!-- reschedule-->
      <GeneralModal
          persistent
          :dialog="dialogModalLessThe24h"
          :alert="true"
          width="500"
          @handleClose="handleCloseModalCantRescheduleLess24h"
      >
        <div :class="[ $device.mobile ? 'text-h6' : 'text-h5' ]">
          <h3 class="text-center mb-3">
            {{ $t('general.warning') }}
          </h3>
        </div>

        <p :class="[ $device.mobile ? 'body-2' : 'body-1' ]">
          {{ $t('reschedule.warning_cant_reschedule_less_then_24h') }}
        </p>

        <div class="text-center">
          <v-btn
              rounded
              color="outerSpace"
              dark
              @click="dialogModalLessThe24h = !dialogModalLessThe24h"
          >
            {{ $t('general.ok_got_it') }}
          </v-btn>
        </div>
      </GeneralModal>
      <GeneralModal
          :dialog="dialogModalEmptySlots"
          :alert="true"
          width="500"
          @handleClose="handleCloseModalEmptySlots"
      >
        <div :class="[ $device.mobile ? 'text-h6' : 'text-h5' ]">
          <h3 class="text-center mb-3">
            {{ $t('general.warning') }}
          </h3>
        </div>

        <p :class="[ $device.mobile ? 'body-2' : 'body-1' ]">
          {{ $t('reschedule.warning_empty_slots') }}
        </p>

        <p class="text-center">
          <v-icon
              color="secondary"
              small
              class="mb-2"
          >
            fa-sharp fa-solid fa-phone-flip
          </v-icon>
          <b class="text-h6 ml-2 font-weight-black">
            {{ $t('general.number_attendance_center') }}
          </b>
        </p>

        <div class="text-center">
          <v-btn
              rounded
              color="outerSpace"
              dark
              @click="dialogModalEmptySlots = !dialogModalEmptySlots"
          >
            {{ $t('general.ok_got_it') }}
          </v-btn>
        </div>
      </GeneralModal>

      <GeneralModal
          :headerTitle="$t('general.reschedule_medical')"
          :dialog="dialogReschedule"
          width="430px"
          has-close
          persistent
          @handleClose="handleCloseModalReschedule"
      >
        <AttendanceRescheduleModal v-if="dialogReschedule" @handleEmptySlots="emptySlots"/>
      </GeneralModal>
    </div>

    <!-- Footer -->
    <HomeFooter/>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import helpers from '@/mixins/helpers';
import AttendanceSummary from '@/components/attendancesDetails/AttendanceSummary';
import AttendanceFiles from '@/components/attendancesDetails/AttendanceFiles';
import AttendanceProfessional from '@/components/attendancesDetails/AttendanceProfessional';
import CancelReasonsForm from '@/components/attendancesDetails/CancelReasonsForm';
import GeneralModal from '@/components/general/GeneralModal';
import HomeFooter from '@/components/home/HomeFooter';
import AttendanceRescheduleModal from "@/components/attendancesDetails/AttendanceRescheduleModal.vue";

export default {
  name: 'AttendancesDetailsView',

  components: {
    AttendanceRescheduleModal,
    AttendanceSummary,
    AttendanceFiles,
    AttendanceProfessional,
    CancelReasonsForm,
    GeneralModal,
    HomeFooter,
  },

  mixins: [helpers],

  data: () => ({
    cancelAttendanceDialog: false,
    loadingCancelAttendance: false,
    dialogModalLessThe24h: false,
    dialogReschedule: false,
    omvProgramId: 7,
    program_well_being_ids: [
      4, // Esportivo
      5, // Nutrição
      57, // Psicologia
    ],
    dialogModalEmptySlots: false,
    program: null
  }),

  mounted() {
    this.setBannerTitle(this.$t('general.banner_my_attendances'));
    this.setBannerIcon('fa-calendar-days');
  },

  methods: {
    ...mapActions('data/attendance', [
      'partnerAttendanceCancel', // -> map `this.partnerAttendanceCancel()` to `this.$store.dispatch('partnerAttendanceCancel')`
      'getPatientLastAttendance',
    ]),

    ...mapMutations('data/general', [
      'setAttendance', // -> this.setAttendance
      'setBannerTitle', // -> this.setBannerTitle
      'setBannerIcon', // -> this.setBannerIcon
      'setGeneralError', // -> this.setGeneralError
      'setGeneralErrorRedirectRoute', // -> this.setGeneralErrorRedirectRoute
    ]),

    ...mapMutations('data/schedule', [
      'setProgram', // -> this.setAttendance
      'setPatient', // -> this.setPatient
    ]),

    ...mapActions('data/patient', [
      'patientAttendances' // -> map `this.patientAttendances()` to `this.$store.dispatch('patientAttendances')`
    ]),

    handleBack() {
      this.$router.go(-1);
    },

    handleCancelAttendance(payload) {
      this.loadingCancelAttendance = true;

      payload = {
        ...payload,
        case_attendance_id: this.attendance.case_attendance_id
      }

      this.partnerAttendanceCancel(payload).then(() => {
        this.setAttendance(null);
        this.patientAttendances({
          limit: 20,
          offset: 0
        }).catch((error) => {
          console.error(error)
        });
        this.$router.push('/attendances/cancelsuccess');
      }).catch((error) => {
        console.error(error);
        this.setGeneralError(true);
        this.setGeneralErrorRedirectRoute('home');
      }).finally(() => {
        this.loadingCancelAttendance = false;
      });
    },

    async handleReschedule() {
      if (this.checkAttendanceHasLessThe24hoursToStart()) {
        this.dialogModalLessThe24h = !this.dialogModalLessThe24h;
        return
      }

      this.setSelectProgram();

      await this.loadLastAttendancesThisProgram();

      this.dialogReschedule = true;
    },

    setSelectProgram(){
      const { program_info, program_well_being } = this.getPrograms.data;

      const combinedList = program_info.concat(program_well_being);

      const foundProgram = combinedList.find(program => program.program_id === this.attendance.program_id);

      this.program = foundProgram;
      this.program.id = foundProgram.program_id

      this.setProgram(this.program)
    },

    async loadLastAttendancesThisProgram(){
      const dependents = this.getDependents
          .filter(item => !item.has_login && item.id != this.getProfile.id)

      let dataPatientLastAttendance = {
        member_id: this.attendance.member_id,
        program_id: this.program.program_id,
        company_id: this.getCompanyId,
        group_id: this.getGroupId,
        holder_dependents: dependents,
        limit: this.program.attendances_qty * (dependents.length + 1)
      }

      await this.getPatientLastAttendance(dataPatientLastAttendance).then()
    },

    checkAttendanceHasLessThe24hoursToStart() {
      let duration = this.convertUtcOffset(this.attendance.sla).diff(this.$moment(), 'hours');
      return (duration < 24);
    },

    handleCloseModalCantRescheduleLess24h() {
      this.dialogModalLessThe24h = false
    },

    emptySlots() {
      this.dialogModalEmptySlots = true;
      this.dialogReschedule = false;
    },

    handleCloseModalEmptySlots() {
      this.dialogModalEmptySlots = false
    },

    handleCloseModalReschedule() {
      this.dialogReschedule = false;
    },
  },

  computed: {
    ...mapGetters('data/general', {
      attendance: 'getAttendance',
    }),

    ...mapGetters('data/schedule', {
      getPrograms: 'getPrograms',
    }),

    ...mapGetters('data/login', {
      getCompanyId: 'getCompanyId',
      getGroupId: 'getGroupId',
    }),

    ...mapGetters('data/patient', {
      getProfile: 'getProfile',
      getDependents: 'getDependents',
    }),

    hasProfessional() {
      return this.attendance.program_id !== this.omvProgramId;
    },

    hasFiles() {
      return !this.program_well_being_ids.includes(this.attendance.program_id);
    },
  },
}
</script>

<style lang="scss" scoped>
.attendances-details-view {
  .informations {
    max-width: 1080px;
  }
}

/* For Tablet View */
@media screen and (min-device-width: 641px) and (max-device-width: 1024px) {
  .attendances-details-view {
    //
  }
}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
  .attendances-details-view {
    padding-bottom: 54px !important;
  }
}

/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  .attendances-details-view {
    padding-bottom: 54px !important;
  }
}

/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {
  .attendances-details-view {
    padding-bottom: 54px !important;
  }
}
</style>