export default {
  getCaseAttendanceId: state => {
    return state.case_attendance_id;
  },

  getFeedback: state => {
    return state.feedback;
  },

  getProgramsInfo: state => {
    return state.programs_info;
  },
};