import general from './general';
import login from './login';
import patient from './patient';
import register from './register';
import schedule from './schedule';
import attendance from "./attendance";
import attendances from './attendances';
import feedback from "./feedback";
import faq from "./faq";
import themes from "@/store/data/themes";

export default {
  namespaced: true,
  modules: {
    general,
    login,
    patient,
    register,
    schedule,
    attendance,
    attendances,
    feedback,
    faq,
    themes
  },
};