export default {
  attendances: {
    title: 'Minhas consultas',
    subtitle: 'Selecione abaixo: ',
    no_itens_found: 'Não existem consultas agendadas no momento.',
    see: "VER",
    selectables: {
      scheduled: 'Agendadas',
      history: 'Histórico',
    },
    headers: {
      date: 'Data',
      type: 'Tipo',
      specialty: 'Especialidade',
      professional: 'Profissional',
      patient: 'Paciente',
      scheduled_hour: 'Horário',
      action: 'Ação',
    },
    history: {
      subtitle: 'Legenda',
      phone: 'Telefone',
      video: 'Vídeo',
      canceled: 'Canceladas',
      order_by: 'Ordenar por',
      date: 'Data',
      type: 'Tipo',
    }
  }
};