<template>
  <v-container
      class="profile-view d-flex flex-column pa-0"
      max-width="100%"
  >
    <v-card
      :class="[ $device.mobile ? 'pa-5' : 'py-5 px-16' ]"
      class="content"
      flat
      height="auto"
      min-height="590"
    >
      <v-row
          class="title-programs ma-0 pt-10 pb-4"
          no-gutters
      >
        <h2>
          {{ $t('profile.title') }}
        </h2>
      </v-row>

      <v-form
          class="pa-0"
      >
        <v-row
            :class="[ $device.mobile ? 'pa-0' : 'pt-8 px-8 background--antiFlash' ]"
            class="list-buttons ma-0"
            no-gutters
        >
          <!-- Social Name -->
          <v-col
              class="px-2"
              cols="12"
              md="4"
          >
            <v-text-field
                v-model="holder.social_name"
                :placeholder="$t('general.not_informed')"
                :label="$t('profile.social_name').toUpperCase()"
                @click="handleSocialNameChange()"
                append-icon="mdi-chevron-right"
                background-color="white"
                dense
                outlined
                persistent-placeholder
                readonly
                rounded
            />
          </v-col>

          <!-- Complete Name -->
          <v-col
              class="px-2"
              cols="12"
              md="4"
          >
            <v-text-field
                :value="holder.name"
                :label="$t('profile.name')"
                background-color="white"
                class="no-click"
                dense
                outlined
                readonly
                rounded
            />
          </v-col>

          <!-- Gender -->
          <v-col
              cols="12"
              md="4"
              class="px-2"
          >
            <v-text-field
                v-model="holder.gender"
                :label="$t('profile.gender')"
                @click="handleGenderChange"
                append-icon="mdi-chevron-right"
                background-color="white"
                dense
                outlined
                readonly
                rounded
                variant="underlined"
            />
          </v-col>

          <!-- CPF -->
          <v-col
              cols="12"
              md="4"
              class="px-2"
          >
            <v-text-field
                v-mask="'###.###.###-##'"
                :value="holder.cpf"
                :label="$t('profile.cpf')"
                background-color="white"
                class="no-click"
                dense
                outlined
                readonly
                rounded
            />
          </v-col>

          <!-- Birthdate -->
          <v-col
              cols="12"
              md="4"
              class="px-2"
          >
            <v-text-field
                v-mask="'##/##/####'"
                :value="holder.birthdate"
                :label="$t('profile.birthdate')"
                background-color="white"
                class="no-click"
                dense
                outlined
                readonly
                rounded
            />
          </v-col>

          <!-- Phone Number -->
          <v-col
              cols="12"
              md="4"
              class="px-2"
          >
            <v-text-field
                v-mask="'(##) #####-####'"
                v-model="holder.phone"
                :label="$t('profile.phone')"
                @click="handlePhoneChange"
                append-icon="mdi-chevron-right"
                background-color="white"
                dense
                outlined
                readonly
                rounded
            />
          </v-col>

          <v-col
              cols="12"
              md="12"
              class="px-2"
          >
            <v-text-field
                :value="holder.email"
                :label="$t('profile.email_address')"
                background-color="white"
                dense
                outlined
                readonly
                rounded
            >
              <template
                  v-if="$device.mobile"
                  #label
              >
                <label>
                  {{ $t('profile.email_address') }}

                  <a
                      v-if="holder.email && !emailVerified"
                      @click="handleVerifyEmail()"
                      class="pl-3 secondary--text"
                  >
                    {{ $t('profile.verify') }}
                  </a>
                </label>
              </template>

              <a
                  v-if="!$device.mobile && holder.email && !emailVerified"
                  @click="handleVerifyEmail()"
                  slot="append"
                  class="pl-3 secondary--text caption"
              >
                {{ $t('profile.verify') }}
              </a>
            </v-text-field>
          </v-col>
        </v-row>

        <v-row
            no-gutters
            class="list-buttons mb-0 mt-8 mx-0 pa-0"
        >
          <v-col
              cols="12"
              md="4"
              class="px-2"
          >
            <h2>
              {{ $t('profile.password') }}
            </h2>

            <v-text-field
                v-model="password"
                @click="handlePasswordChange"
                append-icon="mdi-chevron-right"
                class="pa-0 ma-0"
                dense
                outlined
                readonly
                rounded
                type="password"
            />
          </v-col>

          <v-col
              cols="12"
              md="8"
              class="px-2"
          >
            <h2>
              {{ $t('profile.benefit') }}
            </h2>

            <v-text-field
                v-model="benefits"
                @click="openBenefitModal"
                append-icon="mdi-chevron-right"
                class="pa-0 ma-0"
                dense
                outlined
                readonly
                rounded
            />
          </v-col>
        </v-row>
      </v-form>

      <v-row
          v-if="dependentsRaw && dependentsRaw.length"
          class="title-programs ma-0 pt-10 pb-4"
          no-gutters
      >
        <h2>
          {{ $t('profile.dependents') }}
        </h2>
      </v-row>

      <!-- Dependents Form -->
      <v-form
        class="pa-0"
        :key="componentKey"
      >
        <v-row
            v-for="(dependent, index) in dependentsRaw"
            :class="[ $device.mobile ? 'pa-0' : 'pt-8 px-8 background--antiFlash' ]"
            :key="index"
            class="list-buttons mb-12 mt-0 mx-0"
            no-gutters
        >
          <!-- Dependent Social Name -->
          <v-col
              class="px-2"
              cols="12"
              md="4"
          >
            <v-text-field
                v-model="dependent.social_name"
                :append-icon="!dependent.has_login ? 'mdi-chevron-right' : ''"
                :class="{ 'no-click': dependent.has_login }"
                :label="$t('profile.social_name').toUpperCase()"
                :placeholder="$t('general.not_informed')"
                @click="handleSocialNameChange(dependent)"
                background-color="white"
                dense
                outlined
                persistent-placeholder
                readonly
                rounded
            />
          </v-col>

          <!-- Dependent Complete Name -->
          <v-col
              class="px-2"
              cols="12"
              md="4"
          >
            <v-text-field
                :label="$t('profile.name')"
                :value="getDependentName(dependent)"
                background-color="white"
                class="no-click"
                dense
                outlined
                readonly
                rounded
            />
          </v-col>

          <!-- Dependent Gender -->
          <v-col
              cols="12"
              md="4"
              class="px-2"
          >
            <v-text-field
                :label="$t('profile.gender')"
                :value="dependent.gender"
                background-color="white"
                class="no-click"
                dense
                outlined
                readonly
                rounded
                variant="underlined"
            />
          </v-col>

          <!-- Dependent CPF -->
          <v-col
              cols="12"
              md="4"
              class="px-2"
          >
            <v-text-field
                v-mask="'###.###.###-##'"
                :label="$t('profile.cpf')"
                :value="dependent.cpf"
                background-color="white"
                class="no-click"
                dense
                outlined
                readonly
                rounded
            />
          </v-col>

          <!-- Dependent Birthdate -->
          <v-col
              cols="12"
              md="4"
              class="px-2"
          >
            <v-text-field
                v-mask="'##/##/####'"
                :label="$t('profile.birthdate')"
                :value="$moment(dependent.birthdate).format('DD/MM/YYYY')"
                background-color="white"
                class="no-click"
                dense
                outlined
                readonly
                rounded
            />
          </v-col>

          <!-- Dependent Phone Number -->
          <v-col
              cols="12"
              md="4"
              class="px-2"
          >
            <v-text-field
                v-mask="'(##) #####-####'"
                v-model="dependent.phone"
                :label="$t('profile.phone')"
                background-color="white"
                class="no-click"
                dense
                outlined
                readonly
                rounded
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card>

    <!-- MODAL PHONE CHANGED -->
    <GeneralModal
        :dialog="dialogPhone"
        :header-title="$t('profile.phone_change')"
        @handleClose="handleClosePhone"
        has-close
        invert-actions
        width="515"
    >
      <v-form autocomplete="off">
        <v-row class="ma-0">
          <v-col v-if="successPhoneChange" class="px-0 pt-0" cols="12" md="12">
            <span>
              {{ $t('profile.updated_info') }}
            </span>
          </v-col>

          <v-col class="px-0 pt-0" cols="12" md="12">
            <v-text-field
                v-if="!successPhoneChange"
                v-mask="['(##) #####-####']"
                v-model.trim="holder.phone"
                :backgroundColor="successPhoneChange ? 'antiFlashWhite' : 'white'"
                :hide-details="successPhoneChange"
                :label="successPhoneChange ? '' : $t('validate_phone_number.enter_new_mobile_number')"
                :placeholder="$t('validate_phone_number.enter_new_mobile_number')"
                :readonly="successPhoneChange"
                background-color="transparent"
                class="cy-mobile-number-valid-text mobile-number pa-0"
                persistent-placeholder
                height="50"
                outlined
            />
          </v-col>
        </v-row>

        <v-row v-if="!successPhoneChange" class="ma-0">
          <v-col class="px-0 pt-0" cols="6" md="6">
            <v-btn :min-width="$device.mobile ? 165 : 175" @click="closePhoneChange"
                   class="cy-cancel-button cancel text-capitalize outerSpace--text outline-border" depressed
                   max-width="195" min-height="46" rounded :loading="loading">
              {{ $t('general.cancel') }}
            </v-btn>
          </v-col>

          <v-col class="px-0 pt-0" cols="6" md="6">
            <v-btn :min-width="$device.mobile ? 165 : 175" :loading="loading" @click="confirmPhoneChange"
                   class="cy-send-button confirm text-capitalize ma-0" color="outerSpace" dark depressed
                   max-width="195" height="46" rounded>
              {{ $t('general.confirm') }}
            </v-btn>
          </v-col>
        </v-row>

        <v-row v-else>
          <v-col class="px-0 pt-0 text-center" cols="12" md="12">
            <v-btn :min-width="$device.mobile ? 165 : 175" @click="donePhoneChange"
                   class="cy-done-phone-change-button done-phone-change confirm text-capitalize" color="outerSpace"
                   dark depressed max-width="195" height="46" rounded :loading="loading">
              {{ $t('general.ok') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </GeneralModal>

    <!-- MODAL GENDER CHANGED -->
    <GeneralModal
        :dialog="dialogGender"
        :header-title="$t('profile.gender_change')"
        :header-icon="headerIcon"
        @handleClose="handleCloseGender"
        has-close
        invert-actions
        width="515"
    >
      <v-form autocomplete="off">
        <v-row class="ma-0">
          <v-col v-if="successGenderChange" class="px-0 pt-0" cols="12" md="12">
            <span>
              {{ $t('profile.updated_info') }}
            </span>
          </v-col>

          <v-col :class="{ 'py-0': $device.mobile }" class="px-0 pt-0 text-center" cols="12" md="12">
            <v-select v-if="!successGenderChange" v-model="holder.gender" :items="genderOptions"
                      :label="$t('register.gender')" class="cy-input-gender input-gender pa-0" height="37"
                      item-value="value" item-text="text" required/>
          </v-col>
        </v-row>

        <v-row v-if="!successGenderChange" class="ma-0">
          <v-col class="px-0 pt-0" cols="6" md="6">
            <v-btn :min-width="$device.mobile ? 165 : 175" @click="closeGenderChange"
                   class="cy-cancel-button cancel text-capitalize outerSpace--text outline-border" depressed
                   max-width="195" min-height="46" rounded :loading="loading">
              {{ $t('general.cancel') }}
            </v-btn>
          </v-col>

          <v-col class="px-0 pt-0" cols="6" md="6">
            <v-btn :min-width="$device.mobile ? 165 : 175" :loading="loading" @click="confirmGenderChange"
                   class="cy-send-button confirm text-capitalize ma-0" color="outerSpace" dark depressed
                   max-width="195" height="46" rounded>
              {{ $t('general.confirm') }}
            </v-btn>
          </v-col>
        </v-row>

        <v-row v-else>
          <v-col class="px-0 pt-0 text-center" cols="12" md="12">
            <v-btn :min-width="$device.mobile ? 165 : 175" @click="doneGenderChange"
                   class="cy-done-phone-change-button done-phone-change confirm text-capitalize" color="outerSpace"
                   dark depressed max-width="195" height="46" rounded :loading="loading">
              {{ $t('general.ok') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </GeneralModal>

    <!-- MODAL SOCIAL NAME CHANGED -->
    <GeneralModal
        :dialog="dialogSocialName"
        has-close
        :header-title="loadingDeleteSocialName ? $t('profile.social_name_delete') : $t('profile.social_name_change')"
        :header-icon="headerIcon"
        @handleClose="handleCloseSocialName"
        invert-actions
        width="515"
    >
      <v-form
          autocomplete="off"
      >
        <!-- Message -->
        <v-row
            class="ma-0"
        >
          <v-col
              v-if="successSocialNameChange"
              class="pb-6 px-0 pt-0 text-center"
              cols="12"
              md="12"
          >
            <span
                class="body-1"
            >
              {{ $t('profile.updated_info') }}
            </span>
          </v-col>

          <v-col
              v-if="!successSocialNameChange && loadingDeleteSocialName"
              class="pb-6 px-0 pt-0 text-center"
              cols="12"
              md="12"
          >
            <span
                class="body-1"
            >
              {{ $t('profile.sure_want_delete_social_name') }}
            </span>
          </v-col>

          <v-col
              v-if="!successSocialNameChange && !loadingDeleteSocialName"
              :class="{ 'py-0': $device.mobile }"
              class="px-0 pt-0 text-center"
              cols="12"
              md="12"
          >
            <p
                class="social-name-label ma-0 outerSpace--text subtitle-1 text-start"
            >
              {{ $t('profile.enter_social_name') }}
            </p>

            <!-- Dependent -->
            <v-text-field
                v-if="dependentToChange"
                v-model.trim="dependentToChange.social_name"
                :backgroundColor="successSocialNameChange ? 'antiFlashWhite' : 'white'"
                :error-messages="dependantSocialNameErrors"
                :hide-details="successSocialNameChange"
                :placeholder="$t('profile.social_name')"
                :readonly="successSocialNameChange"
                @blur="$v.dependentToChange.social_name.$touch()"
                background-color="transparent"
                class="cy-social-name-valid-text social-name pa-0"
                height="50"
                persistent-placeholder
            />

            <!-- Owner -->
            <v-text-field
                v-else
                v-model.trim="holder.social_name"
                :backgroundColor="successSocialNameChange ? 'antiFlashWhite' : 'white'"
                :error-messages="holderSocialNameErrors"
                :hide-details="successSocialNameChange"
                :placeholder="$t('profile.social_name')"
                :readonly="successSocialNameChange"
                @blur="$v.holder.social_name.$touch()"
                background-color="transparent"
                class="cy-social-name-valid-text social-name pa-0"
                height="50"
                persistent-placeholder
            />
          </v-col>
        </v-row>

        <!-- Buttons -->
        <v-row
            v-if="!successSocialNameChange || loadingDeleteSocialName"
            class="ma-0"
        >
          <v-col class="px-0 pt-0" cols="6" md="6">
            <v-btn
                :color="socialName && !loadingDeleteSocialName ? 'error' : 'outerSpace'"
                :loading="loading"
                :min-width="$device.mobile ? 165 : 175"
                @click="() => {
                socialName && !loadingDeleteSocialName
                  ? loadDeleteSocialNameChange()
                  : closeSocialNameChange()
              }"
                class="cy-cancel-button cancel text-capitalize"
                depressed
                min-height="46"
                max-width="195"
                outlined
                rounded
            >
              <span
                  class="outerSpace--text"
              >
                {{ socialName && !loadingDeleteSocialName ? $t('general.delete') : $t('general.cancel') }}
              </span>
            </v-btn>
          </v-col>

          <v-col class="px-0 pt-0" cols="6" md="6">
            <v-btn
                :loading="loading"
                :min-width="$device.mobile ? 165 : 175"
                :class="{ 'disabled-button-dark': disableEditSocialName }"
                @click="() => {
                loadingDeleteSocialName
                  ? confirmSocialNameDelete()
                  : confirmSocialNameChange()
              }"
                class="cy-send-button confirm text-capitalize ma-0"
                color="outerSpace"
                dark
                depressed
                min-height="46"
                max-width="195"
                rounded
            >
              {{ $t('general.confirm') }}
            </v-btn>
          </v-col>
        </v-row>

        <v-row v-else>
          <v-col class="px-0 pt-0 text-center" cols="12" md="12">
            <v-btn :min-width="$device.mobile ? 165 : 175" @click="doneSocialNameChange"
                   class="cy-done-phone-change-button done-phone-change confirm text-capitalize" color="outerSpace"
                   dark depressed max-width="195" height="46" rounded :loading="loading">
              {{ $t('general.ok') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </GeneralModal>

    <!-- MODAL VERIFY EMAIL -->
    <GeneralModal
        :dialog="dialogEmail"
        :header-close="true"
        :header-title="$t('profile.verify_email')"
        @handleClose="closeVerifyEmailDialog"
        background-color="antiFlash"
        has-close
        invert-actions
        width="470"
    >
      <v-row>
        <v-col class="px-0 pt-0" cols="12" md="12">
          <span>{{ $t('profile.verify_email_message_1') }} <b>{{ $t('profile.verify_email_message_bold') }}</b>{{
              $t('profile.verify_email_message_2')
            }}</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="px-0 pt-0 text-center" cols="12" md="12">
          <v-btn
              :loading="loading"
              :min-width="$device.mobile ? 165 : 175"
              @click="closeVerifyEmailDialog"
              class="cy-done-phone-change-button done-phone-change confirm text-capitalize"
              color="outerSpace"
              dark
              depressed
              max-width="195"
              height="46"
              rounded
          >
            {{ $t('profile.verify_email_agree') }}
          </v-btn>
        </v-col>
      </v-row>
    </GeneralModal>

    <!-- MODAL CHANGE PASSWORD -->
    <GeneralModal
        :dialog="dialogPassword"
        :header-title="$t('profile.password_change')"
        :fullscreen="$device.mobile"
        width="470"
        height="500"
        @handleCancel="handleClosePassword"
        @handleClose="handleClosePassword"
        background-color="antiFlash"
        has-close
        persistent
        :header-icon="headerIcon"
    >
      <v-container class="cy-login-info-form login-info-form ma-o pa-0">
        <v-row class="ma-0">
          <v-col v-if="successPasswordChange" class="px-0 pt-0" cols="12" md="12">
            <span>
              {{ $t('profile.updated_info') }}
            </span>
          </v-col>
        </v-row>

        <!-- Form -->
        <v-form v-if="!successPasswordChange" autocomplete="off" :class="{ 'px-4': $device.mobile }">
          <v-row class="ma-0 pt-6">
            <v-col :class="{ 'py-0': $device.mobile }" class="px-0 pt-0" cols="12" md="12">
              <v-text-field v-model="form.password" :class="{ 'my-0 py-0': $device.mobile }"
                            :error-messages="passwordErrors" :label="$t('register.password')"
                            :type="isPasswordVisible ? 'text' : 'password'" @blur="$v.form.password.$touch()"
                            class="cy-input-password input-password pa-0" height="37" required>
                <v-icon @click="isPasswordVisible = !isPasswordVisible" color="secondary" slot="append"
                        small>
                  {{ isPasswordVisible ? 'fa-eye-slash' : 'fa-eye' }}
                </v-icon>
              </v-text-field>
            </v-col>

            <v-col :class="{ 'py-0': $device.mobile }" class="px-0 pt-0" cols="12" md="12">
              <v-text-field v-model="passwordConfirmation" :class="{ 'my-0 py-0': $device.mobile }"
                            :error-messages="passwordConfirmationErrors" :label="$t('register.password_confirmation')"
                            :type="isPasswordConfirmationVisible ? 'text' : 'password'"
                            @blur="$v.passwordConfirmation.$touch()"
                            class="cy-input-password-confirmation input-password-confirmation pa-0" height="37"
                            required>
                <v-icon @click="isPasswordConfirmationVisible = !isPasswordConfirmationVisible"
                        color="secondary" slot="append" small>
                  {{ isPasswordConfirmationVisible ? 'fa-eye-slash' : 'fa-eye' }}
                </v-icon>
              </v-text-field>
            </v-col>
          </v-row>

          <!-- Password Rules -->
          <v-row :class="{ 'mt-1': $device.mobile }">
            <v-col class="d-flex pa-0" cols="12" md="12">
              <v-card :height="$device.mobile ? '210' : '167'" class="pa-4 d-flex flex-column" color="#F7F6F1"
                      width="444" flat>
                <!-- Password Rules title -->
                <h3 :class="{ 'text-subtitle-2': $device.mobile }"
                    class="secondary--text text-uppercase text-center mb-3">
                  {{ $t('register.password_rules') }}
                </h3>

                <!-- Password Rules box -->
                <div :class="[$device.mobile ? 'flex-column' : 'flex-row']"
                     class="d-flex justify-space-between">
                  <div class="d-flex flex-column">
                    <!-- Password minLength rule -->
                    <p :class="{
                      'text-subtitle-2 py-0 mb-2': $device.mobile,
                      'spanishGray--text': !form.password || !$v.form.password.minLength,
                      'outerSpace--text': form.password && $v.form.password.minLength,
                    }" class="d-flex align-center">
                      <v-icon
                          :color="!form.password || !$v.form.password.minLength ? 'spanishGray' : 'secondary'"
                          class="mr-2" small>
                        {{
                          !form.password || !$v.form.password.minLength ? 'fa-circle-minus' :
                              'fa-circle-check'
                        }}
                      </v-icon>

                      {{ $t('register.password_rule_min') }}
                    </p>

                    <!-- Password uppercase rule -->
                    <p :class="{
                      'text-subtitle-2 py-0 mb-2': $device.mobile,
                      'spanishGray--text': !form.password || !$v.form.password.uppercase,
                      'outerSpace--text': form.password && $v.form.password.uppercase,
                    }" class="d-flex align-center">
                      <v-icon
                          :color="!form.password || !$v.form.password.uppercase ? 'spanishGray' : 'secondary'"
                          class="mr-2" small>
                        {{
                          !form.password || !$v.form.password.uppercase ? 'fa-circle-minus' :
                              'fa-circle-check'
                        }}
                      </v-icon>

                      {{ $t('register.password_rule_uppercase') }}
                    </p>
                  </div>

                  <div class="d-flex flex-column">
                    <!-- Password number rule -->
                    <p :class="{
                      'text-subtitle-2 py-0 mb-2': $device.mobile,
                      'spanishGray--text': !form.password || !$v.form.password.number,
                      'outerSpace--text': form.password && $v.form.password.number,
                    }" class="d-flex align-center">
                      <v-icon
                          :color="!form.password || !$v.form.password.number ? 'spanishGray' : 'secondary'"
                          class="mr-2" small>
                        {{
                          !form.password || !$v.form.password.number ? 'fa-circle-minus' :
                              'fa-circle-check'
                        }}
                      </v-icon>

                      {{ $t('register.password_rule_number') }}
                    </p>

                    <!-- Password lowercase rule -->
                    <p :class="{
                      'text-subtitle-2 py-0 mb-2': $device.mobile,
                      'spanishGray--text': !form.password || !$v.form.password.lowercase,
                      'outerSpace--text': form.password && $v.form.password.lowercase,
                    }" class="d-flex align-center">
                      <v-icon
                          :color="!form.password || !$v.form.password.lowercase ? 'spanishGray' : 'secondary'"
                          class="mr-2" small>
                        {{
                          !form.password || !$v.form.password.lowercase ? 'fa-circle-minus' :
                              'fa-circle-check'
                        }}
                      </v-icon>

                      {{ $t('register.password_rule_lowercase') }}
                    </p>
                  </div>
                </div>

                <!-- Password specialCharacter rule -->
                <p :class="{
                  'text-subtitle-2 py-0 mb-2': $device.mobile,
                  'spanishGray--text': !form.password || !$v.form.password.specialCharacter,
                  'outerSpace--text': form.password && $v.form.password.specialCharacter,
                }" class="d-flex align-center">
                  <v-icon
                      :color="!form.password || !$v.form.password.specialCharacter ? 'spanishGray' : 'secondary'"
                      class="mr-2" small>
                    {{
                      !form.password || !$v.form.password.specialCharacter ? 'fa-circle-minus' :
                          'fa-circle-check'
                    }}
                  </v-icon>

                  {{ $t('register.password_rule_special_character') }}
                </p>
              </v-card>
            </v-col>
          </v-row>

          <!-- Action -->
          <v-row class="ma-0">
            <v-col class="d-flex justify-center px-0" cols="12" md="12">
              <v-btn :class="{ 'disabled-button-dark': disableNext }" @click="donePasswordChange"
                     class="cy-confirm outline-border mb-0 mt-8 px-8" color="outerSpace" dark depressed
                     height="46" rounded width="230" :loading="loading">
                {{ $t('general.next') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-row v-else>
          <v-col class="px-0 pt-0 text-center" cols="12" md="12">
            <v-btn :min-width="$device.mobile ? 165 : 175" @click="confirmPasswordChange"
                   class="cy-done-phone-change-button done-phone-change confirm text-capitalize" color="outerSpace"
                   dark depressed max-width="195" height="46" rounded :loading="loading">
              {{ $t('general.ok') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </GeneralModal>

    <!-- Change phone number modal -->
    <GeneralModal
        :dialog="dialogPhoneCode"
        :header-close="false"
        @handleClose="handleClosePhoneCode"
        background-color="antiFlash"
        has-close
        logo
        invert-actions
        width="550"
    >
      <PhoneCodeVerification @handleConfirm="dialogPhoneCode=false"/>
    </GeneralModal>

    <!-- BENEFITS SELECTION -->
    <GeneralModal
        :dialog="benefitsDialog"
        :header-title="$t('general.benefits')"
        :success="!loading && successBenefitsChange"
        :has-close="!loading"
        :has-confirm="!loading && successBenefitsChange"
        :confirm-text="$t('general.ok')"
        :width="!successBenefitsChange ? '549px' : '446px'"
        @handleConfirm="handleConfirmChangeBenefit"
        @handleClose="handleCloseBenefit"
        persistent
    >
      <BenefitsSelection
          v-if="!successBenefitsChange"
          @handleSelect="handleSelectBenefit"
      />

      <div
          v-else-if="loading"
          class="d-flex justify-center"
      >
        <v-progress-circular
            :size="70"
            :width="5"
            color="primary"
            indeterminate
        />
      </div>

      <div
          v-else
          class="d-flex justify-center"
      >
        <p
            class="text-body-1 pt-3 ma-0"
        >
          {{ $t('profile.updated_info') }}
        </p>
      </div>
    </GeneralModal>

    <!-- Footer -->
    <HomeFooter/>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {maxLength, minLength, required, sameAs} from 'vuelidate/lib/validators';
import {helpers} from 'vuelidate/lib/validators';
import consts from '@/static/js/consts';
import HomeFooter from '@/components/home/HomeFooter';
import GeneralModal from '@/components/general/GeneralModal';
import PhoneCodeVerification from '@/components/general/PhoneCodeVerification';
import BenefitsSelection from '@/components/general/BenefitsSelection';

// eslint-disable-next-line
const specialChars = /[0-9`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~°ºª¨]/;
const uppercase = helpers.regex('uppercase', /[A-Z]/);
const number = helpers.regex('uppercase', /[0-9]/);
const lowercase = helpers.regex('lowercase', /[a-z]/);
const specialCharacter = helpers.regex('specialCharacter', /[^A-Za-z0-9 ]/);

export default {
  name: 'ProfileView',

  components: {
    BenefitsSelection,
    HomeFooter,
    GeneralModal,
    PhoneCodeVerification
  },

  data: () => ({
    form: {
      password: null,
    },
    loadingDeleteSocialName: false,
    passwordConfirmation: null,
    isPasswordVisible: false,
    isPasswordConfirmationVisible: false,
    loading: false,
    profile: false,
    clicked: false,
    company_id: null,
    group_id: null,
    emailVerified: false,
    message: '',
    birthdate: null,
    socialName: null,
    password: '*********',
    benefits: null,
    dependentToChange: null,
    holderToChange: null,
    holder: {
      social_name: null,
      cpf: null,
      name: null,
      phone: null,
      gender: null,
      email: null,
      birthdate: null,
      member_id: null
    },
    genderOptions: null,
    dialogPhoneCode: false,
    dialogPhone: false,
    dialogGender: false,
    dialogSocialName: false,
    dialogEmail: false,
    dialogPassword: false,
    benefitsDialog: false,
    successPhoneChange: false,
    successGenderChange: false,
    successSocialNameChange: false,
    successPasswordChange: false,
    successBenefitsChange: false,
    headerIcon: '',
    componentKey: 0,
  }),

  created() {
    this.genderOptions = [
      {
        text: this.$t('general.female'),
        value: 'Feminino'
      },
      {
        text: this.$t('general.male'),
        value: 'Masculino'
      }
    ];
    // Get patient profile if needed
    if (!this.profile) {
      this.getPatientProfile();
    }
  },

  mounted() {
    this.setBannerTitle(this.$t('general.my_profile'));
    this.setBannerIcon('fa-regular fa-user');
  },

  methods: {
    ...mapActions('data/patient', [
      'patientProfile',
      'patientEmailVerify',
      'patientUpdate',
      'patientResendEmail',
      'patientResetPassword',
    ]),

    ...mapActions('data/general', [
      'flavourSettingsGet',
    ]),

    ...mapActions('data/login', [
      'getPatientGroups',
    ]),

    ...mapMutations('data/general', [
      'setBannerTitle',
      'setBannerIcon',
      'setCompanyLogo',
      'setGeneralError',
      'setGeneralErrorRedirectRoute',
    ]),

    ...mapMutations('data/patient', [
      'setAttendances',
      'setDependents',
      'setDependentsRaw',
      'setProgramsScheduled',
      'setProfile',
    ]),

    ...mapMutations('data/register', [
      'setPersonalInfo',
    ]),

    ...mapMutations('data/login', [
      'setCompanyId',
      'setCompanyStatusId',
      'setCompanyStatusName',
      'setFlavourId',
      'setGroupId',
    ]),

    getPatientProfile() {
      this.loading = true;
      this.patientProfile(this.memberId).catch((error) => {
        console.error(error);
        this.setGeneralError(true);
        this.setGeneralErrorRedirectRoute('home');
      }).finally(() => {
        this.loading = false;
        if (this.patient) {
          this.holder.member_id = this.memberId
          this.holder.cpf = this.patient.document.replace(/\D/g, "")
              .replace(/(\d{3})(\d)/, "$1.$2")
              .replace(/(\d{3})(\d)/, "$1.$2")
              .replace(/(\d{3})(\d{1,2})/, "$1-$2")
              .replace(/(-\d{2})\d+?$/, "$1");
          this.holder.name = this.patient.name;
          this.holder.birthdate = this.formatDate(this.patient.birthdate);
          this.holder.social_name = this.patient.social_name;
          this.holder.phone = this.patient.phone.replace(/\D/g, "")
              .replace(/(\d{2})(\d)/, "($1) $2")
              .replace(/(\d{5})(\d{4})/, "$1-$2");
          this.holder.email = this.patient.email;
          this.holder.gender = this.patient.gender;
          this.company_id = this.companyId;
          this.group_id = this.groupId;
          this.benefits = this.groups.find(group => group.company_id === this.companyId).name;
          this.patientEmailVerify(this.memberId).finally(() => {
            this.emailVerified = this.email_verified;

            this.holderToChange = {...this.holder};
          }).catch((error) => {
            console.error(error);
            this.setGeneralError(true);
            this.setGeneralErrorRedirectRoute('home');
          })
        }
      });
    },

    formatDate(inputDate) {
      return this.$moment(inputDate).format('DD/MM/YYYY');
    },

    getDependentName(dependent) {
      return dependent.name || dependent.nome;
    },

    handlePhoneChange() {
      this.dialogPhone = true;
    },

    handleClosePhoneCode() {
      this.successPhoneChange = false;
      this.dialogPhoneCode = false;
    },

    handleClosePhone() {
      this.closePhoneChange()
    },

    donePhoneChange() {
      this.successPhoneChange = false;
      this.dialogPhone = false;
      this.dialogPhoneCode = true;
      this.updateInfo();
    },

    confirmPhoneChange() {
      this.successPhoneChange = true;
      this.headerIcon = 'mdi-checkbox-marked-circle';
    },

    closePhoneChange() {
      this.dialogPhone = false;
      this.successPhoneChange = false;
      this.holder.phone = this.holderToChange.phone;
    },

    handleGenderChange() {
      this.dialogGender = true;
    },

    handleCloseGender() {
      this.closeGenderChange();
    },

    closeGenderChange() {
      this.dialogGender = false;
      this.successGenderChange = false;
      this.holder.gender = this.holderToChange.gender;
    },

    confirmGenderChange() {
      this.successGenderChange = true;
      this.headerIcon = 'mdi-checkbox-marked-circle';
    },

    doneGenderChange() {
      this.successGenderChange = false;
      this.dialogGender = false;
      this.loading = true;
      this.updateInfo();
    },

    handleSocialNameChange(dependent = null) {
      if (dependent) {
        if (dependent.has_login) return;

        this.dependentToChange = {...dependent};
        this.socialName = this.dependentToChange.social_name
      } else {
        this.dependentToChange = null
        this.socialName = this.holder.social_name
      }

      this.dialogSocialName = true;
    },

    handleCloseSocialName() {
      this.closeSocialNameChange()
    },

    closeSocialNameChange() {
      this.dialogSocialName = false;
      this.successSocialNameChange = false;
      this.loadingDeleteSocialName = false;
      this.dependentToChange = null;
      this.socialName = null;
      this.holder.social_name = this.holderToChange.social_name
    },

    loadDeleteSocialNameChange() {
      this.loadingDeleteSocialName = true;
    },

    confirmSocialNameDelete() {
      if (!this.dependentToChange){
        this.holder.social_name = null;
      }else{
        this.dependentToChange.social_name = null;
      }

      this.socialName = null;
      this.confirmSocialNameChange();
    },

    confirmSocialNameChange() {
      if (this.dependentToChange) {
        this.$v.dependentToChange.$touch();
        if (this.$v.dependentToChange.$invalid || specialChars.test(this.dependentToChange.social_name)) {
          return;
        }
      } else if (this.holder) {
        this.$v.holder.social_name.$touch();
        if (this.$v.holder.social_name.$invalid || specialChars.test(this.holder.social_name)) {
          return;
        }
      }

      this.successSocialNameChange = true;
      this.loadingDeleteSocialName = false;
      this.headerIcon = 'mdi-checkbox-marked-circle';
    },

    doneSocialNameChange() {
      this.successSocialNameChange = false;
      this.dialogSocialName = false;
      this.loading = true;
      this.updateInfo();
    },

    closeVerifyEmailDialog() {
      this.dialogEmail = false;
    },

    handleVerifyEmail() {
      this.dialogEmail = true;
      let payload = {
        member_id: this.memberId
      };
      this.patientResendEmail(payload).catch((error) => {
        console.error(error);
        this.setGeneralError(true);
        this.setGeneralErrorRedirectRoute('home');
      });
    },

    handlePasswordChange() {
      this.dialogPassword = true;
    },

    handleClosePassword() {
      this.dialogPassword = false;
    },

    confirmPasswordChange() {
      this.dialogPassword = false;
      this.successPasswordChange = false;
    },

    donePasswordChange() {
      this.loading = true;
      let payload = {
        password: this.form.password
      };
      this.patientResetPassword(payload).finally(() => {
        this.loading = false;
        this.getPatientProfile();
        this.successPasswordChange = true;
        this.headerIcon = 'mdi-checkbox-marked-circle';
      }).catch((error) => {
        console.error(error);
        this.setGeneralError(true);
        this.setGeneralErrorRedirectRoute('home');
      });
    },

    updateInfo() {
      let newInfo = null;

      if (this.dependentToChange) {
        newInfo = {
          ...this.dependentToChange,
          birthdate: this.dependentToChange.birthdate,
          cpf: this.dependentToChange.cpf,
          gender: this.dependentToChange.gender,
          email: this.dependentToChange.email,
          id: this.dependentToChange.id,
          name: this.dependentToChange.name,
          nome: this.dependentToChange.nome,
          phone: this.dependentToChange.phone,
          social_name: this.dependentToChange.social_name
        }

        let newDependents = [...this.dependents].map(obj => [newInfo].find(o => o.id === obj.id) || obj);
        let newDependentsRaw = [...this.dependentsRaw].map(obj => [newInfo].find(o => o.id === obj.id) || obj);

        this.setDependents(newDependents);
        this.setDependentsRaw(newDependentsRaw);
      } else {
        newInfo = {
          birthdate: this.holder.birthdate,
          document: this.holder.cpf,
          cpf: this.holder.cpf,
          gender: this.holder.gender,
          email: this.holder.email,
          id: this.holder.member_id,
          name: this.holder.name,
          phone: this.holder.phone,
          social_name: this.holder.social_name
        }

        this.setProfile(newInfo);
        this.setPersonalInfo(newInfo);
      }

      let payload = {
        member_info: {
          email: newInfo.email,
          phone: newInfo.phone,
          gender: newInfo.gender,
          social_name: newInfo.social_name
        },
        member_id: newInfo.id
      }

      this.patientUpdate(payload).finally(() => {
        this.getPatientProfile();
        this.loading = false;
      }).catch((error) => {
        console.error(error);
        this.setGeneralError(true);
        this.setGeneralErrorRedirectRoute('home');
      }).finally(() => {
        this.dependentToChange = null;
      });

    },

    openBenefitModal() {
      this.successBenefitsChange = false;
      this.benefitsDialog = true;
    },

    handleSelectBenefit() {
      this.loading = true;
      this.successBenefitsChange = true;
      this.benefits = this.groups.find(group => group.company_id === this.companyId).name;
      this.benefitsId = this.groups.find(group => group.company_id === this.companyId);

      if (this.benefitsId.flavour.length > 0) {
        this.setFlavourId(this.benefitsId.flavour[0].id);
        this.setGroupId(this.benefitsId.id);
        this.setAttendances([]);
        this.setDependents([]);
        this.setProgramsScheduled([]);
        this.getFlavour();
      } else {
        // Change theme colors to default
        let newTheme = {
          primary: consts.DEFAULT_COLORS.PRIMARY,
          secondary: consts.DEFAULT_COLORS.SECONDARY
        }

        this.$vuetify.theme.themes.dark = {
          ...this.$vuetify.theme.themes.dark,
          ...newTheme
        };

        this.$vuetify.theme.themes.light = {
          ...this.$vuetify.theme.themes.light,
          ...newTheme
        };

        document.documentElement.style.setProperty('--primary', newTheme.primary);
        document.documentElement.style.setProperty('--secondary', newTheme.secondary);

        this.setCompanyLogo(null);

          this.loading = false;
          this.forceRerender();
        }
      },

    handleConfirmChangeBenefit() {
      this.benefitsDialog = false;
    },

    handleCloseBenefit() {
      this.benefitsDialog = false;
    },

    getFlavour() {
      if (!this.flavour_id) {
        document.documentElement.style.setProperty('--primary', consts.DEFAULT_COLORS.PRIMARY);
        document.documentElement.style.setProperty('--secondary', consts.DEFAULT_COLORS.SECONDARY);

        return this.loading = false;
      }

      this.loading = true;

      let payload = {
        flavour_id: this.flavour_id
      };

      this.flavourSettingsGet(payload).then((response) => {
        // Change theme colors
        let newTheme = {
          primary: response.main_color,
          secondary: response.secondary_color
        }

        this.$vuetify.theme.themes.dark = {
          ...this.$vuetify.theme.themes.dark,
          ...newTheme
        };

        this.$vuetify.theme.themes.light = {
          ...this.$vuetify.theme.themes.light,
          ...newTheme
        };

        document.documentElement.style.setProperty('--primary', newTheme.primary);
        document.documentElement.style.setProperty('--secondary', newTheme.secondary);

          this.setCompanyLogo(response.splash_asset);
        }).catch((error) => {
          console.error(error);
          this.setGeneralError(true);
          this.setGeneralErrorRedirectRoute('home');
        }).finally(() => {
          this.loading = false;
        });
      },
      forceRerender() {
      this.componentKey += 1;
      }
    },

  computed: {
    ...mapGetters('data/login', {
      memberId: 'getMemberId',
    }),

    ...mapGetters('data/patient', {
      patient: 'getProfile',
    }),

    ...mapGetters('data/patient', {
      dependents: 'getDependents',
      dependentsRaw: 'getDependentsRaw',
      email_verified: 'getEmailVerified',
    }),

    ...mapGetters('data/login', {
      companyId: 'getCompanyId',
      groupId: 'getGroupId',
      groups: 'getPatientGroups', //this.groups
      flavour_id: 'getFlavourId',
    }),

    ...mapGetters('data/general', {
      company: 'getCompany',
      companyLogo: 'getCompanyLogo',
      flavour: 'getFlavour',
    }),

    disableNext() {
      return !this.$v.form.password.required
          || !this.$v.passwordConfirmation.required
          || !this.$v.form.password.uppercase
          || !this.$v.form.password.number
          || !this.$v.form.password.lowercase
          || !this.$v.form.password.specialCharacter
          || !this.$v.form.password.minLength;
    },

    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.uppercase && errors.push('');
      !this.$v.form.password.number && errors.push('');
      !this.$v.form.password.lowercase && errors.push('');
      !this.$v.form.password.specialCharacter && errors.push('');
      !this.$v.form.password.maxLength && errors.push('');
      !this.$v.form.password.minLength && errors.push('');
      !this.$v.form.password.required && errors.push(this.$t('general.required'));
      return errors;
    },

    passwordConfirmationErrors() {
      const errors = [];
      if (!this.$v.passwordConfirmation.$dirty) return errors;
      !this.$v.passwordConfirmation.required && errors.push(this.$t('general.required'));
      !this.$v.passwordConfirmation.sameAsPassword && errors.push(this.$t('register.password_confirmation_invalid'));
      return errors;
    },

    dependantSocialNameErrors() {
      const errors = [];
      if (!this.$v.dependentToChange.social_name.$dirty) return errors;
      !this.$v.dependentToChange.social_name.maxLength && errors.push(this.$t('profile.invalid_social_name'));
      !this.$v.dependentToChange.social_name.minLength && errors.push(this.$t('profile.invalid_social_name'));

      if (specialChars.test(this.dependentToChange.social_name)) {
        errors.push(this.$t('profile.invalid_social_name'));
      }
      return errors;
    },

    holderSocialNameErrors() {
      const errors = [];
      if (!this.$v.holder.social_name.$dirty) return errors;
      !this.$v.holder.social_name.maxLength && errors.push(this.$t('profile.invalid_social_name'));
      !this.$v.holder.social_name.minLength && errors.push(this.$t('profile.invalid_social_name'));

      if (specialChars.test(this.holder.social_name)) {
        errors.push(this.$t('profile.invalid_social_name'));
      }
      return errors;
    },

    disableEditSocialName() {
      if (this.dependentToChange && !this.dependentToChange.social_name) {
        return this.$v.dependentToChange.$invalid ||
            specialChars.test(this.dependentToChange.social_name);
      } else if (this.holder && !this.holder.social_name) {
        return this.$v.holder.social_name.$invalid ||
            specialChars.test(this.holder.social_name);
      } else {
        return false
      }
    }
  },

  validations: {
    form: {
      password: {
        uppercase,
        number,
        lowercase,
        specialCharacter,
        maxLength: maxLength(20),
        minLength: minLength(8),
        required,
      },
    },
    dependentToChange: {
      social_name: {
        maxLength: maxLength(40),
        minLength: minLength(3),
      },
    },
    holder: {
      social_name: {
        maxLength: maxLength(40),
        minLength: minLength(3),
      },
    },
    passwordConfirmation: {
      required,
      sameAsPassword: sameAs(function () {
        return this.form.password;
      }),
    },
  }

};
</script>

<style lang="scss" scoped>
.profile-view {
  max-width: 100%;

  .content {
    min-height: calc(100vh - 246px - 97px);
    max-width: 1240px;
    padding-bottom: 107px !important;
    padding-top: 74px !important;

    .list-buttons {
      &:last-child {
        margin-bottom: 0 !important;
      }
    }

    .image {
      margin-top: 80px !important;
      z-index: 2;
    }

    .logo {
      z-index: 2;
    }

    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 268px;
    }

    .title {
      font-size: 40px !important;
    }

    .subtitle {
      font-size: 20px !important;
      margin: 40px auto 62px auto !important;
    }
  }
}

.subtitle-programs {
  font-family: 'Roboto';
  font-style: normal;
  color: rgba(68, 68, 68, 0.7);
}

.title-programs {
  font-family: 'Roboto';
  font-style: normal;
  line-height: 29px;
  color: #444444;
}

.text-program {
  position: absolute;
  width: 150px;
  height: 20px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
  /* identical to box height */
  color: #444444;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;

}

.button-item {
  box-sizing: border-box;
  width: 344px;
  height: 74px;
  left: 1px;
  top: 0px;
  background: #FFFFFF;
  border: 1px solid #D8D8D8;
  border-radius: 100px;
}

.img-program {
  right: 35%;
}

.icon-button {
  left: 30%;
}

.programs-button-bem-estar {
  left: 20px;
}

.list-programs {
  padding-top: 20px;
}

.container {
  padding-top: 30px;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

/* For Tablet View */
@media screen and (min-device-width: 641px) and (max-device-width: 1024px) {
  .profile-view {
    .content {
      min-height: calc(100vh - 216px - 97px);
      padding-bottom: 85px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      padding-top: 74px !important;

      .image {
        margin: 0 auto 56px auto !important;
      }

      .components {
        flex-direction: column-reverse !important;
      }

      .title {
        font-size: 30px !important;
      }

      .subtitle {
        font-size: 17px !important;
        margin: 30px auto 40px auto !important;
      }
    }
  }
}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
  .profile-view {
    padding-bottom: 54px !important;

    .content {
      min-height: calc(100vh - 129px - 76px);
      padding-bottom: 54px !important;
      padding-top: 0 !important;

      .image {
        margin-top: 1px !important;
      }

      .title {
        font-size: 23px !important;
        white-space: pre-line;
      }

      .subtitle {
        font-size: 15px !important;
        margin: 27px auto 33px auto !important;
      }
    }
  }
}

/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  .profile-view {
    padding-bottom: 54px !important;

    .content {
      min-height: calc(100vh - 129px - 76px);
      padding-bottom: 54px !important;
      padding-top: 0 !important;

      .image {
        margin-top: 1px !important;
      }

      .title {
        font-size: 23px !important;
        white-space: pre-line;
      }

      .subtitle {
        font-size: 15px !important;
        margin: 27px auto 33px auto !important;
      }
    }
  }
}

/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {
  .profile-view {
    .content {
      min-height: calc(100vh - 129px - 76px);
      padding-bottom: 54px !important;
      padding-top: 0 !important;

      .image {
        margin-top: 1px !important;
      }

      .title {
        font-size: 23px !important;
        white-space: pre-line;
      }

      .subtitle {
        font-size: 15px !important;
        margin: 27px auto 33px auto !important;
      }
    }
  }
}
</style>
