<template>
    <div class="general-error">
        <h2 class="text-center outerSpace--text mb-3">
            {{ $t('general.warning') }}
        </h2>
      <p class="p-exist-attendance text-left pt-4">
        {{ $t('hardware_check.alter_permissions') }}
      </p>
    </div>
</template>

<script>
export default {
    name: "AlterPermissionsDevicesModal"
}
</script>

<style scoped>

</style>