import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView';
import LoginView from '../views/LoginView';
import PlaygroundView from '../views/PlaygroundView';
import HardwareCheck from '@/views/HardwareCheck';
import ProgramsView from '../views/ProgramsView';
import FeedbackSuccessView from '@/views/FeedbackSuccessView';
import FeedbackView from '@/views/FeedbackView';
import AttendancesDetailsView from '@/views/AttendancesDetailsView';
import ProfileView from '../views/ProfileView';
import AttendanceView from '@/views/AttendanceView';
import AttendancesView from '@/views/AttendancesView';
import ScheduleDetailsView from '@/views/ScheduleDetailsView';
import AttendanceRescheduleSuccessView from '@/views/AttendanceRescheduleSuccessView';
import ScheduleSuccessView from '@/views/ScheduleSuccessView';
import ScheduleView from '@/views/ScheduleView';
import AttendanceHistoryDetailsView from '@/views/AttendanceHistoryDetailsView';
import AttendanceCancelSuccessView from '@/views/AttendanceCancelSuccessView';
import FaqView from '@/views/FaqView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter(to, from, next) {
      if (!localStorage.getItem('token')) {
        localStorage.clear();
        next({
          path: 'login',
          replace: true
        });
      } else {
        next();
      }
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/playground',
    name: 'playground',
    component: PlaygroundView
  },
  {
    path: '/hardwarecheck',
    name: 'hardwarecheck',
    component: HardwareCheck
  },
  {
    path: '/programs',
    name: 'programs',
    component: ProgramsView
  },
  {
    path: '/feedback/success',
    name: 'feedbackSuccess',
    component: FeedbackSuccessView
  },
  {
    path: '/attendance',
    name: 'attendance',
    component: AttendanceView
  },
  {
    path: '/attendances',
    name: 'attendances',
    component: AttendancesView
  },
  {
    path: '/attendance/feedback',
    name: 'feedbackView',
    component: FeedbackView
  },
  {
    path: '/attendances/details',
    name: 'attendancesDetails',
    component: AttendancesDetailsView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/schedule/details',
    name: 'scheduleDetails',
    component: ScheduleDetailsView
  },
  {
    path: '/attendances/reschedulesuccess',
    name: 'AttendanceRescheduleSuccessView',
    component: AttendanceRescheduleSuccessView
  },
  {
    path: '/schedule/success',
    name: 'ScheduleSuccess',
    component: ScheduleSuccessView
  },
  {
    path: '/schedule',
    name: 'schedule',
    component: ScheduleView
  },
  {
    path: '/attendances/cancelsuccess',
    name: 'AttendanceCancelSuccess',
    component: AttendanceCancelSuccessView
  },
  {
    path: '/attendances/historydetails',
    name: 'AttendanceHistoryDetails',
    component: AttendanceHistoryDetailsView
  },
  {
    path: '/faq',
    name: 'faq',
    component: FaqView
  },
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
});

// new Vue({
//   el: '#app',
//   router
// });

export default router;
