export default {
    hardware_check: {
        title: 'Sala de vídeo',
        subtitle_warning: 'Atenção: Verifique e habilite sua camera e microfone nas opções abaixo para iniciar sua consulta. Você poderá desabilitar a câmera durante atendimento, caso desejar.',
        tips_title: 'Dicas:',
        camera_disabled: 'Câmera desabilitada',
        btn_start_video_call: 'Iniciar chamada de vídeo',
        unavailable_mic_and_cam: 'Não foi possível entrar na sala de vídeo, sua câmera ou microfone não foram detectados. Por favor, verifique e tente novamente.',
        tips: [
            {
                icon: 'fa-wifi',
                description: 'Conecte-se a uma internet banda larga ou 4g.',
            },
            {
                icon: 'fa-light fa-lightbulb',
                description: 'Esteja em um local privativo e bem iluminado.',
            },
            {
                icon: 'fa-rotate-right',
                description: 'Caso a consulta por vídeo não iniciar, reincie seu navegador de internet.',
            },
        ],
        alter_permissions: 'Houver uma modificação nas permissões de câmera/microfone, para continuar recarregue a página!'
    }
};