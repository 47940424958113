import Vue from 'vue';
import Vuex from 'vuex';
import data from './data';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    data,   
  },
  state: {
    token: null,
  },
  actions: {},
  mutations: {
    setToken(state, payload) {
      state.token = payload;
      localStorage.setItem('token', payload);
    },
  },
  getters: {
    getToken: state => {
      return state.token;
    },
  }
});