export default {
  getPatient: state => {
    return state.patient
  },

  getSchedule: state => {
    return state
  },

  getProgram: state => {
    return state.program
  },

  getPrograms: state => {
    return state.programs
  },

  getWarningTypes: state => {
    return state.warning_types
  },

  getRestrictedPrograms: state => {
    return state.restricted_programs_age
  },

  getWarningPrograms: state => {
    return state.warning_programs
  },

  getCheckDoubleBooking: state => {
    return state.check_double_booking
  },

  getScheduleSlots: state => {
    return state.schedules_slots
  },

  getDatetime: state => {
    return state.datetime
  },

  getProfessional: state => {
    return state.professional
  },

  getFiles: state => {
    return state.files
  },

  getVerifySchedule: state => {
    return state.verify_schedule
  },

  getScheduleType: state => {
    return state.scheduleType
  },
}
