<template>
  <v-container
    class="playground"
  >
    <TestComponent
      prop-c="test"
    />

    <!-- TEST YOUR STUFF -->
  </v-container>
</template>

<script>
  import TestComponent from '@/components/general/TestComponent';

  export default {
    name: 'PlaygroundView',

    components: {
      TestComponent,
    },

    data: () => ({
      // 
    }),
  };
</script>

<style lang="scss" scoped>
  .playground {}
</style>
