import axios from 'axios';
import consts from '@/static/js/consts';

export default {
    /*
    * GET
    */
    getRatingQuestions({commit}) {
        return new Promise((resolve, reject) => {
            axios.get(consts.ENDPOINTS.RATING_QUESTIONS, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            }).then(response => {
                commit('setQuestions', response.data.data);
                resolve()
            }).catch(error => {
                commit('setQuestions', null);
                reject(error.response);
            });
        })
    },

    sendRatingQuestions({commit}, payload = null) {
        return new Promise((resolve, reject) => {
            axios.post(consts.ENDPOINTS.SEND_RATING_QUESTIONS, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            }).then(response => {
                commit('setQuestions', response.data.data);
                resolve()
            }).catch(error => {
                commit('setQuestions', null);
                reject(error.response);
            });
        })
    },
};