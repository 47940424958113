var helpers = {
  methods: {
    validateCpf(cpf) {
      let sum = 0;
      let rest;

      if (!cpf) return false;

      cpf = cpf.replace(/[-.\s]/g, '');

      if (cpf == '00000000000') return false;

      for (let i = 1; i <= 9; i++) {
        sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
        rest = (sum * 10) % 11;
      }

      if ((rest == 10) || (rest == 11)) rest = 0;
      if (rest != parseInt(cpf.substring(9, 10))) return false;

      sum = 0;
      for (let i = 1; i <= 10; i++) {
        sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
        rest = (sum * 10) % 11;
      }

      if ((rest == 10) || (rest == 11)) rest = 0;

      if (rest != parseInt(cpf.substring(10, 11))) return false;

      return true;
    },

    convertUtcOffset(datetime) {
      //sets default for brasilia, to fix incorrect times for different timezones. THIS IS A WORKAROUND, it won't work when we go GLOBAL
      this.$moment.tz.setDefault('America/Sao_Paulo');
      let startOffset = this.$moment(datetime).utcOffset();
      return this.$moment(this.$moment(datetime).utcOffset(startOffset)._d)
    },

    parseJsonRecursive(data) {
      if (typeof data !== 'string' || data.trim() === '') {
        return null;
      }
      try {
        data = JSON.parse(data);
        return this.parseJsonRecursive(data);
      } catch (error) {
        console.error('Erro ao fazer o parse JSON:', error);
        return null;
      }
    },    

    capitalized(name) {
      let capitalized = []

      name.split(' ').forEach(word => {
        capitalized.push(
          word.charAt(0).toUpperCase() +
          word.slice(1).toLowerCase()
        )
      })
      return capitalized.join(' ')
    },
  }
}

export default helpers;