<template>
    <div>
        <v-list disabled color="outerSpace" v-if="isList">
            <v-subheader class="white--text font-weight-bold">
                <div> {{ $t('hardware_check.tips_title') }}</div>
            </v-subheader>
            <v-list-item-group>
                <v-list-item
                        v-for="(item, i) in $t('hardware_check.tips')"
                        :key="i"
                >
                    <v-list-item-icon>
                        <v-icon class="icon-tips" color="white" v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <div class="tips-hardwarecheck white--text">
                            {{ item.description }}
                        </div>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>

        <v-row v-if="!isList">
            <v-col cols="12">
                <div class="tips-text-line">
                        <span>
                            {{ $t('hardware_check.tips_title') }}
                        </span>
                </div>
            </v-col>
            <v-col v-for="(item, i) in $t('hardware_check.tips')" :key="i">
                <div class="tips-hardwarecheck white--text">
                    <v-icon class="icon-tips mr-2" color="white" v-text="item.icon"></v-icon>
                    {{ item.description }}
                </div>
            </v-col>
        </v-row>
    </div>

</template>

<script>
export default {
    name: "VideoCallTips",
    props: {
        isList: {
            type: Boolean,
            required: false,
            default: true
        },
    }
}
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.tips-text-line {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid $cultured;
  line-height: 0.1em;
  margin: 10px 0 20px;
  color: $cultured;
  font-size: 20px;
}

.tips-text-line span {
  background: $outerSpace;
  padding: 0 10px;
}

.tips-hardwarecheck {
  font-size: 14px;
  font-weight: 400;
}

@media (max-width: 959px) {
  .tips-hardwarecheck {
    font-size: 14px;
    font-weight: 400;
  }
}

.icon-tips {
  width: 24px;
}


</style>