import { render, staticRenderFns } from "./AlterPermissionsDevicesModal.vue?vue&type=template&id=3ed1f020&scoped=true"
import script from "./AlterPermissionsDevicesModal.vue?vue&type=script&lang=js"
export * from "./AlterPermissionsDevicesModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ed1f020",
  null
  
)

export default component.exports