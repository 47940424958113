export default {
  feedbackSuccess: {
    tanks: 'Obrigado',
    tanks_title: 'Agradecemos por ter realizado sua consulta com a',
    for_the_feedback: 'pelo feedback!',
    teladoc_name: 'Teladoc Health',
    evaluation_text: 'e pela avaliação, para que estejamos sempre melhorando nossos serviços e a qualidade do atendimento.',
    back_to_start: "Voltar ao inicio"
  }
};
