<template>
    <GeneralModal
            :dialog="dialog"
            :alert="true"
            width="360"
            @handleClose="closeModal"
    >
        <div :class="[ $device.mobile ? 'text-h6' : 'text-h5' ]">
            <h3 class="text-center mb-3">
                {{ $t('general.warning') }}
            </h3>
        </div>

        <p :class="[ $device.mobile ? 'body-2' : 'body-1' ]">
            {{ $t('hardware_check.unavailable_mic_and_cam') }}
        </p>

        <div class="text-center">
            <v-btn
                    rounded
                    color="outerSpace"
                    dark
                    @click="closeModal"
            >
                {{ $t('general.ok_got_it') }}
            </v-btn>
        </div>
    </GeneralModal>
</template>

<script>
import GeneralModal from "@/components/general/GeneralModal.vue";

export default {
    name: "HardwareUnavailableModal",
    components: {GeneralModal},
    props:{
        dialog: {
            type: Boolean,
            required: true,
            default: false
        },
    },
    methods: {
        closeModal() {
            this.$router.push('/');
        },
    }
}
</script>

<style scoped>

</style>