<template>
  <v-container
    :class="{ 'full': $device.mobile && bannerTitle }"
    class="central-banner d-flex justify-end pa-0"
  >

    <div
      class="align-center container d-flex justify-end ma-0 pa-0"
    >
      <div
        class="background ma-0 pa-0 primary"
      />
      <!-- Home title -->
      <div
        v-if="!bannerTitle"
        class="d-flex flex-column text-center title my-0 pa-0"
      >
        <div class="d-flex align-end flex-column mr-9">
          <span
              :class="[$device.mobile ? 'text-h6' : 'text-h3']"
              class="white--text font-weight-light"
          >
            {{ $t('general.banner_online_attendance') }}
            <b>
              {{ $t('general.online') }}
            </b>
          </span>
          <span
                :class="[$device.mobile ? 'text-h7' : 'text-h4']"
                class="white--text font-weight-light"
          >
            {{ $t('general.when_you_need_it_most') }}
          </span>
        </div>

      </div>

      <!-- Other pages title -->
      <div
        v-else
        class="d-flex text-center title custom justify-center my-0 pa-0"
      >
        <span
          :class="[$device.mobile ? 'text-h6' : 'text-h4']"
          class="white--text font-weight-regular"
        >
          <v-icon
            v-if="bannerIcon"
            :size="$device.mobile ? '16' : '30'"
            class="mr-3 mb-2"
            color="white"
          >
            {{ bannerIcon }}
          </v-icon>

          {{ bannerTitle }}
        </span>
      </div>
    </div>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'CentralBanner',

    data: () => ({
      //
    }),

    computed: {
      ...mapGetters('data/general', {
        bannerIcon: 'getBannerIcon', // -> this.bannerIcon
        bannerTitle: 'getBannerTitle', // -> this.bannerTitle
      }),
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/styles/colors.scss';

  .central-banner {
    background-image: url("@/assets/images/header_bg_desktop.png");
    height: 246px;
    max-width: 100%;
    width: 100%;

    .container {
      height: 100%;
      max-width: calc(100% - 604px);
      min-width: 428px;
      position: relative;
      width: calc(100% - 604px);

      .background {
        height: 100%;
        position: absolute;
        right: -90px;
        transform: skew(-35deg); /* Padrão */
        -ms-transform: skew(-35deg); /* IE */
        -webkit-transform: skew(-35deg); /* Chrome, Opera */
        width: 100%;
        z-index: 1;
      }

      .title {
        margin-left: 118px;
        position: absolute;
        z-index: 2;

        &.custom {
          margin-left: 100px;
          margin-right: 10px;
          max-width: 1248px;
          width: calc(100% - 108px);

          span {
            white-space: initial;
          }
        }

        span {
          text-shadow: 2px 0 $primary, -2px 0 $primary, 0 2px $primary, 0 -2px $primary,
            1px 1px $primary, -1px -1px $primary, 1px -1px $primary, -1px 1px $primary;
          white-space: nowrap;
        }
      }
    }
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    .central-banner {
      background-image: url("@/assets/images/header_bg_tablet.png");
      height: 216px;

      .container {
        min-width: 400px;
        max-width: calc(100% - 290px);
        width: calc(100% - 290px);

        .background {
          right: -70px;
          transform: skew(-30deg); /* Padrão */
          -ms-transform: skew(-30deg); /* IE */
          -webkit-transform: skew(-30deg); /* Chrome, Opera */
        }

        .title {
          margin-left: 93px;

          &.custom {
            margin-left: 100px;
          }
        }
      }
    }
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .central-banner {
      background-image: url("@/assets/images/header_bg_half_mobile.png");
      height: 129px;

      &.full {
        background-image: url("@/assets/images/header_bg_full_mobile.png");
        height: 194px;

        .container {
          min-width: 100%;
          max-width: 100%;
          width: 100%;

          .background {
            bottom: 0;
            right: 0;
            border-radius: 0 0 25px 25px;
            height: 69px;
            transform: skew(0); /* Padrão */
            -ms-transform: skew(0); /* IE */
            -webkit-transform: skew(0); /* Chrome, Opera */
          }

          .title {
            margin-left: 0;

            &.custom {
              bottom: 18px;
              margin-left: 0;
              width: 100%;
            }
          }
        }
      }

      .container {
        min-width: 240px;
        max-width: calc(100% - 200px);
        width: calc(100% - 200px);

        .background {
          right: -40px;
          transform: skew(-30deg); /* Padrão */
          -ms-transform: skew(-30deg); /* IE */
          -webkit-transform: skew(-30deg); /* Chrome, Opera */
        }

        .title {
          margin-left: 54px;

          &.custom {
            margin-left: 0;
          }
        }
      }
    }
  }

  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .central-banner {
      background-image: url("@/assets/images/header_bg_half_mobile.png");
      height: 129px;

      &.full {
        background-image: url("@/assets/images/header_bg_full_mobile.png");
        height: 194px;

        .container {
          min-width: 100%;
          max-width: 100%;
          width: 100%;

          .background {
            bottom: 0;
            right: 0;
            border-radius: 0 0 25px 25px;
            height: 69px;
            transform: skew(0); /* Padrão */
            -ms-transform: skew(0); /* IE */
            -webkit-transform: skew(0); /* Chrome, Opera */
          }

          .title {
            margin-left: 0;

            &.custom {
              bottom: 18px;
              margin-left: 0;
              width: 100%;
            }
          }
        }
      }

      .container {
        min-width: 240px;
        max-width: calc(100% - 200px);
        width: calc(100% - 200px);

        .background {
          right: -40px;
          transform: skew(-30deg); /* Padrão */
          -ms-transform: skew(-30deg); /* IE */
          -webkit-transform: skew(-30deg); /* Chrome, Opera */
        }

        .title {
          margin-left: 54px;

          &.custom {
            margin-left: 0;
          }
        }
      }
    }
  }

  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .central-banner {
      background-image: url("@/assets/images/header_bg_half_mobile.png");
      height: 129px;

      &.full {
        background-image: url("@/assets/images/header_bg_full_mobile.png");
        height: 194px;

        .container {
          min-width: 100%;
          max-width: 100%;
          width: 100%;

          .background {
            bottom: 0;
            right: 0;
            border-radius: 0 0 25px 25px;
            height: 69px;
            transform: skew(0); /* Padrão */
            -ms-transform: skew(0); /* IE */
            -webkit-transform: skew(0); /* Chrome, Opera */
          }

          .title {
            margin-left: 0;

            &.custom {
              bottom: 18px;
              margin-left: 0;
              width: 100%;
            }
          }
        }
      }

      .container {
        min-width: 240px;
        max-width: calc(100% - 200px);
        width: calc(100% - 200px);

        .background {
          right: -40px;
          transform: skew(-30deg); /* Padrão */
          -ms-transform: skew(-30deg); /* IE */
          -webkit-transform: skew(-30deg); /* Chrome, Opera */
        }

        .title {
          margin-left: 54px;

          &.custom {
            margin-left: 0;
          }
        }
      }
    }
  }
</style>
