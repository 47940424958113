<template>
  <v-container
    class="cy-personal-info-form personal-info-form ma-o pa-0"
  >
    <v-form
      :class="{ 'px-4': $device.mobile }"
      :loading="loading"
      autocomplete="off"
    >
      <!-- Header -->
      <v-row
        class="align-center d-flex flex-column ma-0"
      >
        <v-img
          :src="require('@/assets/general/register_user.svg')"
          class="mb-4 pa-0"
          contain
          width="140"
          height="100"
        />

        <h1
          :class="[ $device.mobile ? 'text-h6' : 'text-h5' ]"
          class="outerSpace--text"
        >
          {{ $t('register.register') }}
        </h1>
      </v-row>

      <!-- Form -->
      <v-row
        class="ma-0 pt-6"
      >
        <v-col
          :class="{ 'py-0': $device.mobile }"
          class="px-0 pt-0"
          cols="12"
          md="12"
        >
          <v-text-field
            v-model.trim="form.name"
            :error-messages="nameErrors"
            :label="$t('register.name')"
            @blur="checkName"
            class="cy-input-name input-name pa-0"
            height="37"
            required
          />
        </v-col>

        <v-col
          :class="{ 'py-0': $device.mobile }"
          class="px-0 pt-0"
          cols="12"
          md="12"
        >
          <v-text-field
            v-model="form.cpf"
            v-mask="['###.###.###-##']"
            :error-messages="cpfErrors"
            :label="$t('register.cpf')"
            @blur="checkCpf"
            class="cy-input-cpf input-cpf pa-0"
            height="37"
            required
          />
        </v-col>

        <v-col
          :class="{ 'py-0': $device.mobile }"
          class="px-0 pt-0"
          cols="12"
          md="12"
        >
          <v-menu
            v-model="birthdatePicker"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="290"
            offset-y
          >
            <template v-slot:activator="{ off, attrs }">
              <v-text-field
                v-on="off"
                v-bind="attrs"
                v-model="formattedDate"
                v-mask="'##/##/####'"
                :error-messages="birthdateErrors"
                :label="$t('register.birthdate')"
                @blur="$v.form.birthdate.$touch()"
                @input="dateTyped"
                class="cy-input-birthdate input-birthdate pa-0"
                height="37"
                required
              >
                <v-icon
                    @click="birthdatePicker = true"
                    class="cy-icon-date-picker"
                    color="secondary"
                    slot="append"
                    small
                >
                  {{ 'fa-calendar' }}
                </v-icon>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="form.birthdate"
              no-title
              @input="datePicked"
              class="cy-date-picker date-picker"
              locale="pt-br"
            />
          </v-menu>
        </v-col>

        <v-col
          :class="{ 'py-0': $device.mobile }"
          class="px-0 pt-0"
          cols="12"
          md="12"
        >
          <v-text-field
            v-model="form.phone"
            v-mask="['(##) #####-####']"
            :error-messages="phoneErrors"
            :label="$t('register.phone')"
            @blur="$v.form.phone.$touch()"
            class="cy-input-phone input-phone pa-0"
            height="37"
            required
          />
        </v-col>

        <v-col
          :class="{ 'py-0': $device.mobile }"
          class="px-0 pt-0"
          cols="12"
          md="12"
        >
          <v-select
            v-model="form.gender"
            :error-messages="genderErrors"
            :items="genderOptions"
            :label="$t('register.gender')"
            @blur="$v.form.gender.$touch()"
            class="cy-input-gender input-gender pa-0"
            height="37"
            item-value="value"
            item-text="text"
            required
          />
        </v-col>
      </v-row>

      <!-- Action -->
      <v-row
        class="ma-0"
      >
        <v-col
          class="d-flex justify-center px-0"
          cols="12"
          md="12"
        >
          <v-btn
            :loading="loading"
            :min-width="$device.mobile ? 165 : 195"
            :class="{ 'disabled-button-dark': isBtnDisabled }"
            @click="save"
            class="cy-confirm my-0 px-8"
            color="outerSpace"
            dark
            depressed
            height="46"
            rounded
            width="230"
          >
            {{ $t('general.next') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <GeneralModal
      :confirm-text="$t('general.ok_got_it')"
      :dialog="warningDialog"
      @handleConfirm="closeWarning"
      @handleClose="closeWarning"
      alert
      has-confirm
      width="478"
    >
      <div
        class="cy-warning"
      >
        <h3
          class="text-center mb-3"
        >
          {{ $t('general.warning') }}
        </h3>

        <p
          class="body-1 mb-0"
        >
          {{ warningMessage }}
        </p>
      </div>
    </GeneralModal>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import { minLength, maxLength, required } from 'vuelidate/lib/validators';
  import helpers from '@/mixins/helpers';
  import GeneralModal from '@/components/general/GeneralModal';

  export default {
    name: 'PersonalInfoForm',

    components: {
      GeneralModal,
    },

    props: {
      // Function to handle confirm action
      handleConfirm: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },

      // Function to handle user previosly existence
      handleWarning: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },
    },

    mixins: [helpers],

    data: () => ({
      birthdatePicker: false,
      form: {
        name: null,
        cpf: null,
        birthdate: null,
        phone: null,
        gender: null,
      },
      genderOptions: null,
      invalidCpf: false,
      loading: false,
      invalidBaseLives: false,
      warningDialog: false,
    }),

    created() {
      this.genderOptions = [
        {
          text: this.$t('general.female'),
          value: 'Feminino'
        },
        {
          text: this.$t('general.male'),
          value: 'Masculino'
        }
      ];
    },

    methods: {
      ...mapActions('data/register', [
        'userCheckExistence', // -> map `this.userCheckExistence()` to `this.$store.dispatch('userCheckExistence')`
        'memberDataCheck', // -> map `this.memberDataCheck()` to `this.$store.dispatch('memberDataCheck')`
      ]),

      ...mapMutations('data/register', [
        'setPersonalInfo', // this.setPersonalInfo
      ]),

      closeWarning() {
        this.$emit('handleWarning');

        this.warningDialog = false;
      },

      checkCpf() {
        this.invalidCpf = !this.validateCpf(this.form.cpf);

        this.$v.form.cpf.$touch();
      },

      checkName() {
        if (this.form.name) {
          this.form.name = this.form.name.replace(/([\u0300-\u036f]|[^ a-zA-Z])/g, '').toUpperCase();
        }

        this.$v.form.name.$touch();
      },

      formattedDate(date) {
        return date ? this.$moment(date).format('DD/MM/YYYY') : '';
      },

      save() {
        this.$v.$touch();

        this.invalidRF = false;
        this.invalidBaseLives = false;

        if (this.$v.$invalid || this.invalidCpf) return;

        this.invalidCpf = false;

        // User under 16 years cant register
        if (this.$moment().diff(this.form.birthdate, 'years') < 16) {
          this.warningDialog = this.warningTypes.USER_UNDER_AGE;
          return;
        }

        this.loading = true;

        let payload = {
          birthdate: this.$moment(this.form.birthdate).format('DD/MM/YYYY'),
          cpf: `${this.form.cpf}`.replace(/[-.\s]/g, ''),
          name: this.form.name,
        }

        this.userCheckExistence(payload).then((response) => {
          if (!response.member) {
            this.warningDialog = this.warningTypes.USER_NOT_REGISTERED;

            this.loading = false;
          } else if (response.patient) {
            this.warningDialog = this.warningTypes.USER_ALREADY_REGISTERED;

            this.loading = false;
          } else {
            // Patient can register
            this.continueRegister();
          }
        }).catch((error) => {
          console.error(error);
          this.loading = false;
        });
      },

      continueRegister() {
        this.form.cpf = `${this.form.cpf}`.replace(/[-.\s]/g, '');

        this.setPersonalInfo(this.form);

        this.$emit('handleConfirm');
      },

      datePicked(date) {
        if (!date) return;
        this.formattedDate = this.formatDateForInput(date);
        this.birthdatePicker = false;
      },
      formatDateForInput(date) {
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
      },
      dateTyped(date) {
        if (!date || date.length !== 10) return;

        this.form.birthdate = this.formatDateForPicker(date);
      },
      formatDateForPicker(date) {
        const [day, month, year] = date.split("/");
        return `${year}-${month}-${day}`;
      },

    },

    computed: {
      ...mapGetters('data/register', {
        warningTypes: 'getWarningTypes', // -> this.getWarningTypes
      }),

      birthdateFormated() {
        return this.form.birthdate
          ? this.$moment(this.form.birthdate).format('DD/MM/YYYY')
          : '';
      },

      nameErrors() {
        const errors = [];
        if (!this.$v.form.name.$dirty) return errors;
        !this.$v.form.name.minLength && errors.push(this.$t('register.name_min_invalid'));
        !this.$v.form.name.maxLength && errors.push(this.$t('register.name_max_invalid'));
        !this.$v.form.name.required && errors.push(this.$t('general.required'));
        return errors;
      },

      cpfErrors() {
        const errors = [];
        if (!this.$v.form.cpf.$dirty) return errors;
        !this.$v.form.cpf.required && errors.push(this.$t('general.required'));
        (!this.$v.form.cpf.minLength || this.invalidCpf) && errors.push(this.$t('register.cpf_invalid'));
        this.invalidRF && errors.push(this.$t('register.user_invalid_RF'));
        this.invalidBaseLives && errors.push(this.$t('register.user_not_in_base_lives'));
        return errors;
      },

      birthdateErrors() {
        const errors = [];
        if (!this.$v.form.birthdate.$dirty) return errors;
        !this.$v.form.birthdate.required && errors.push(this.$t('general.required'));
        return errors;
      },

      phoneErrors() {
        const errors = [];
        if (!this.$v.form.phone.$dirty) return errors;
        !this.$v.form.phone.required && errors.push(this.$t('general.required'));
        (!this.$v.form.phone.minLength) && errors.push(this.$t('register.phone_invalid'));
        return errors;
      },

      genderErrors() {
        const errors = [];
        if (!this.$v.form.gender.$dirty) return errors;
        !this.$v.form.gender.required && errors.push(this.$t('general.required'));
        return errors;
      },

      warningMessage() {
        switch(this.warningDialog) {
          case this.warningTypes.USER_ALREADY_REGISTERED:
            return this.$t('register.user_already_registered');
          case this.warningTypes.USER_UNDER_AGE:
            return this.$t('register.user_under_age');
          case this.warningTypes.USER_NOT_REGISTERED:
            return this.$t('register.user_not_registered');
          default:
            return '';
        }
      },
      isBtnDisabled(){
        return false;
      }
    },
    validations: {
      form: {
        name: {
          minLength: minLength(3),
          maxLength: maxLength(100),
          required
        },
        cpf: {
          minLength: minLength(14),
          required
        },
        birthdate: {
          required
        },
        phone: {
          minLength: minLength(15),
          required
        },
        gender: {
          required
        },
      }
    }
  };
</script>

<style lang="scss" scoped>
  .personal-info-form {
    // 
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    .personal-info-form {
      // 
    }
  }
   
  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .personal-info-form {
      // 
    }
  }
   
  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .personal-info-form {
      // 
    }
  }
   
  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .personal-info-form {
      // 
    }
  }
</style>
