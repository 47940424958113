<template>
  <div
    class="attendances-history ma-0 pa-0"
  >
    <!-- Patient -->
    <v-card
      v-if="patient"
      @click="patientSelectionDialog = true"
      :class="[!(this.dependents && this.dependents.length > 0) ? 'no-click' : '' ]"
      class="patient-info d-flex align-center pa-3 mb-4 mr-4"
      outlined
      width="350px"
    >
      <v-avatar
        class="avatar mr-4"
        color="white"
        rounded
        size="60"
        tile
      >
        <v-img
          :src="patient.image || patient.image_path
            ? patient.image || patient.image_path
            : require('@/assets/images/avatar.png')"
          alt="avatar"
        />
      </v-avatar>

      <div
        class="d-flex flex-column pr-2"
      >
        <h4
          class="name font-weight-bold outerSpace--text text-capitalize"
        >
          {{ getPatientName(patient) }}
        </h4>

        <p
          v-if="patient.age || patient.birthdate"
          class="ma-0 text-caption outerSpace--text"
        >
          {{ getPatientAge(patient) }} {{ $t('general.years') }}
        </p>
      </div>

      <v-icon
        color="secondary"
        x-small
        v-if="(this.dependents && this.dependents.length > 0)"
      >
        fa-chevron-right
      </v-icon>
    </v-card>

    <div
      :class="{ 'flex-column-reverse': $device.mobile }"
      class="d-flex justify-space-between mt-10"
    >
      <!-- Subtitle -->
      <div
        :class="{ 'mt-8': $device.mobile }"
        class="subtitle d-flex align-center"
      >
        <span
          :class="{ 'text-caption mb-8': $device.mobile }"
          class="outerSpace--text font-weight-bold"
        >
          {{ $t('attendances.history.subtitle') }}:
        </span>

        <!-- Phone -->
        <v-card
          class="subtitle-icon-circle d-flex justify-center align-center mx-2"
          color="deepPurple"
        >
          <v-icon
            color="white"
            x-small
          >
            fa-phone
          </v-icon>
        </v-card>

        <span
          :class="{ 'text-caption': $device.mobile }"
          class="outerSpace--text"
        >
          {{ $t('attendances.history.phone') }}
        </span>

        <!-- Video -->
        <v-card
          class="subtitle-icon-circle d-flex justify-center align-center ml-4 mr-2"
          color="primary"
        >
          <v-icon
            color="white"
            x-small
          >
            fa-video
          </v-icon>
        </v-card>

        <span
          :class="{ 'text-caption': $device.mobile }"
          class="outerSpace--text"
        >
          {{ $t('attendances.history.video') }}
        </span>

        <!-- Canceled -->
        <v-card
          class="subtitle-icon-circle d-flex justify-center align-center ml-4 mr-2"
          color="electricRed"
        >
          <v-icon
            color="white"
            x-small
          >
            fa-ban
          </v-icon>
        </v-card>

        <span
          :class="{ 'text-caption': $device.mobile }"
          class="outerSpace--text"
        >
          {{ $t('attendances.history.canceled') }}
        </span>
      </div>

      <!-- Filter -->
<!--      <div-->
<!--        :class="[ $device.mobile ? 'flex-column align-left mt-8' : 'align-center' ]"-->
<!--        class="filter d-flex"-->
<!--      >-->
<!--        <span-->
<!--          :class="{ 'text-caption mb-4': $device.mobile }"-->
<!--          class="outerSpace&#45;&#45;text font-weight-bold mr-2"-->
<!--        >-->
<!--          {{ $t('attendances.history.order_by').toUpperCase() }}:-->
<!--        </span>-->

<!--        <v-btn-toggle-->
<!--          v-model="filter"-->
<!--          class="button-group d-flex"-->
<!--          color="antiFlash"-->
<!--          borderless-->
<!--          height="42"-->
<!--          mandatory-->
<!--          rounded-->
<!--          width="343px"-->
<!--        >-->
<!--          <v-btn-->
<!--            :color="filter == 'date' ? 'primary' : 'antiFlash'"-->
<!--            :class="{ 'white&#45;&#45;text': filter == 'date' }"-->
<!--            class="button flex-grow-1"-->
<!--            height="33"-->
<!--            value="date"-->
<!--            rounded-->
<!--          >-->
<!--            {{ $t('attendances.history.date') }}-->
<!--          </v-btn>-->

<!--          <v-btn-->
<!--            :color="filter == 'type' ? 'primary' : 'antiFlash'"-->
<!--            :class="{ 'white&#45;&#45;text': filter == 'type' }"-->
<!--            class="button flex-grow-1"-->
<!--            height="33"-->
<!--            value="type"-->
<!--            rounded-->
<!--          >-->
<!--            {{ $t('attendances.history.type') }}-->
<!--          </v-btn>-->
<!--        </v-btn-toggle>-->
<!--      </div>-->
    </div>

    <div
      v-if="loading"
      class="d-flex justify-center"
    >
      <v-progress-circular
        :active="loading"
        indeterminate
        color="primary"
        size="42"
        width="4"
      />
    </div>

    <div v-else>
      <v-data-table
        :headers="headers"
        :items="historyAttendances"
        class="table spaced"
        hide-default-header
        hide-default-footer
      >
        <template v-slot:header="{ props }">
          <tr
            v-if="!$device.mobile"
            class="table-header"
          >
            <th
              v-for="(itemHeader, indexHeader) in props.headers"
              :key="indexHeader"
            >
              <div class="px-2">
                {{ itemHeader.text }}
              </div>
            </th>
          </tr>
        </template>

        <template v-slot:item="props">
          <tr
            :class="{ 'd-flex flex-column py-4 pr-4': $device.mobile }"
            @click="handleSelectHistoryAttendance(props.item)"
            class="table-item"
          >
            <!-- Date -->
            <td
              :class="[ $device.mobile ? 'pa-0' : 'py-2' ]"
              class="td-item"
            >
              <v-avatar
                v-if="!$device.mobile"
                :color="getAttendanceColor(props.item)"
                class="d-flex flex-column"
                size="63"
              >
                <!-- Day -->
                <h5
                  class="day text-h5 pa-0 mb-0 mt-1 mx-0 font-weight-bold white--text"
                >
                  {{ convertUtcOffset(`${props.item.due_date.split(' ')[0]} ${props.item.due_time}`).date() }}
                </h5>

                <!-- Month -->
                <p
                  class="month text-caption pa-0 ma-0 white--text"
                >
                  {{ convertUtcOffset(`${props.item.due_date.split(' ')[0]} ${props.item.due_time}`).format('MMM') }}
                </p>
              </v-avatar>
            </td>

            <!-- Type -->
            <td
              :class="[ $device.mobile ? `pt-0 pb-2` : 'py-2' ]"
              class="td-item text-center"
            >
              <span
                :class="[ $device.mobile ? `${getAttendanceColor(props.item)}--text font-weight-bold` : 'outerSpace--text' ]"
              >
                {{ getProgramName(props.item.program_id) }}
              </span>
            </td>

            <!-- Professional -->
            <td
              :class="[ $device.mobile ? `py-0` : 'py-2' ]"
              class="td-item text-center"
            >
              <span
                class="outerSpace--text"
              >
                {{ getDoctorPrefix(props.item) }} {{ getDoctorName(props.item.doctor_id || props.item.attendant_id) }}
              </span>
            </td>

            <!-- Patient (Time for Mobile) -->
            <td
              :class="[ $device.mobile ? `py-0` : 'py-2' ]"
              class="td-item text-center"
            >
              <span
                class="outerSpace--text text-capitalize"
              >
                {{
                  $device.mobile
                    ? convertUtcOffset(`${props.item.due_date.split(' ')[0]} ${props.item.due_time}`).format('DD/MM/YYYY - HH:mm')
                    : getPatientName(patient).toLowerCase()
                }}
              </span>
            </td>

            <!-- Time (Patient for Mobile) -->
            <td
              :class="[ $device.mobile ? `pt-2 pb-0` : 'py-2' ]"
              class="td-item text-center"
            >
              <span
                :class="{ 'text-capitalize': $device.mobile }"
                class="outerSpace--text"
              >
                {{
                  $device.mobile
                    ? `${$t('attendances.headers.patient')} - ${(patient.name || patient.nome).toLowerCase()}`
                    : `${convertUtcOffset(`${props.item.due_date.split(' ')[0]} ${props.item.due_time}`).format('HH:mm')}h`
                }}
              </span>
            </td>

            <!-- Action -->
            <td
              :class="[ $device.mobile ? `py-0` : 'py-2' ]"
              class="td-item text-center"
            >
              <v-icon
                color="outerSpace"
                x-small
              >
                fa-chevron-right
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>

      <!-- Pagination -->
      <v-pagination
        v-model="pagination.currentPage"
        v-if="pagination.pages > 1"
        :length="pagination.pages"
        :total-visible="10"
        @input="changePage"
        circle
        class="mt-4"
        color="lightSilver"
      />
    </div>

    <!-- PATIENT SELECTION -->
    <GeneralModal
      :dialog="patientSelectionDialog"
      :header-title="$t('general.patient_selection_title')"
      @handleClose="patientSelectionDialog = false"
      @handleHeaderInfo="warningDialog = true"
      has-close
      has-header-info
      width="549"
    >
      <PatientSelection
        @handleCancel="patientSelectionDialog = false"
        @handleSelect="handleSelectPatient"
      />
    </GeneralModal>

    <!-- Warning info for patient selection -->
    <GeneralModal
      :confirm-text="$t('general.ok_got_it')"
      :dialog="warningDialog"
      @handleClose="warningDialog = false"
      @handleConfirm="warningDialog = false"
      alert
      has-confirm
      width="440"
    >
      <h3
        class="text-center pb-2 outerSpace--text"
      >
        {{ $t('general.warning') }}
      </h3>

      <span>
        {{ $t('general.warning_patient_selection_1') }}
      </span>

      <span
        class="font-weight-bold"
      >
        {{ $t('general.warning_patient_selection_2') }}
      </span>
    </GeneralModal>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import helpers from '@/mixins/helpers';
  import GeneralModal from '@/components/general/GeneralModal';
  import PatientSelection from '@/components/general/PatientSelection';

  export default {
    name: 'AttendancesHistory',
    
    components: {
      GeneralModal,
      PatientSelection,
    },

    mixins: [helpers],
    
    data: () => ({
      cancelledIds: [
        14,
        15,
      ],
      filter: 'date',
      headers: [],
      historyAttendances: [],
      loading: false,
      pagination: {
        pages: 0,
        currentPage: 1,
      },
      patient: null,
      patientSelectionDialog: false,
      videoIds: [
        4, // Esportivo por Vídeo
        5, // Orientação Nutricional por Vídeo
        7, // OMV
        52, // Porto Seguro OMV
        57, // Psicologia por vídeo
        58, // Cardiologia OMV
        59, // Geriatria OMV
        60, // Ginecologia OMV
        61, // Endocrinologia OMV
        62, // Dermatologia OMV
        63, // Psiquiatria OMV
        64, // Otorrinolaringologia OMV
        65, // Neurologia OMV
        66, // Gastroenterologista OMV
        13939, // Geriatria OMV
        13940, // Ginecologia OMV
        13941, // Endocrinologia OMV
        13942, // Dermatologia OMV
        13943, // Psiquiatria OMV
        13944, // Otorrinolaringologia OMV
      ],
      limit: 0,
      offset: 0,
      warningDialog: false,
    }),

    created() {
      this.headers = [
        {
          text: this.$t('attendances.headers.date'),
          align: 'start',
          value: 'date',
          sortable: false,
        },
        {
          text: this.$t('attendances.headers.type'),
          align: 'center',
          value: 'type',
          sortable: false,
        },
        {
          text: this.$t('attendances.headers.professional'),
          align: 'center',
          value: 'professional',
          sortable: false,
        },
        {
          text: this.$t('attendances.headers.patient'),
          align: 'center',
          value: 'patient',
          sortable: false,
        },
        {
          text:  this.$t('attendances.headers.scheduled_hour'),
          align: 'center',
          value: 'time',
          sortable: false,
        },
        {
          text: '',
          align: 'center',
          value: 'actions',
          sortable: false,
        },
      ];

      this.limit = this.$device.mobile ? 5 : 8;

      this.patient = { ...this.profile };

      this.getHistoryAttendances();
    },

    mounted() {
      this.setBannerTitle(this.$t('general.banner_my_attendances'));
      this.setBannerIcon('fa-calendar-days');
    },

    methods: {
      ...mapActions('data/attendance', [
        'caseAttendancesHistory', // -> map `this.caseAttendancesHistory()` to `this.$store.dispatch('caseAttendancesHistory')`
      ]),

      ...mapMutations('data/general', [
        'setBannerTitle', // -> this.setBannerTitle
        'setBannerIcon', // -> this.setBannerIcon
        'setDoctorsInfo', // -> this.setDoctorsInfo
        'setGeneralError', // -> this.setGeneralError
        'setGeneralErrorRedirectRoute', // -> this.setGeneralErrorRedirectRoute
        'setHistoryAttendance', // -> this.setHistoryAttendance
        'setProgramsInfo', // -> this.setProgramsInfo
      ]),

      getHistoryAttendances(page = 1) {
        this.loading = true;

        this.historyAttendances = [];

        this.offset = page == 1 ? 0 : (page - 1) * this.limit;

        let payload = {
          company_id: null,
          limit: this.limit,
          member_id: this.patient.id,
          offset: this.offset,
          order_by: this.filter
        };

        this.caseAttendancesHistory(payload).then((response) => {
          this.setDoctorsInfo(response.user_info);

          this.setProgramsInfo(response.program_info);

          this.historyAttendances = response.case_attendances_info;

          this.pagination.currentPage = page;

          this.pagination.pages = response.pagination.total > 1
            ? Math.ceil(response.pagination.total / this.limit)
            : response.pagination.total;
        }).catch((error) => {
          console.error(error);
          this.setGeneralError(true);
          this.setGeneralErrorRedirectRoute('home');
        }).finally(() => {
          this.loading = false;
        });
      },

      changePage() {
        this.getHistoryAttendances(this.pagination.currentPage);
      },

      getPatientAge(patient) {
        if (!patient) {
          patient = this.patient;
        }

        return patient.age || this.$moment().diff(patient.birthdate, 'years');
      },

      getPatientName(patient) {
        if (!patient) {
          patient = this.patient;
        }

        return patient.social_name
          ? patient.social_name.toLowerCase()
          : (patient.name || patient.nome).toLowerCase();
      },

      getAttendanceColor(attendance) {
        if (this.cancelledIds.includes(attendance.status)) {
          return 'electricRed';
        } else if (this.videoIds.includes(attendance.program_id)) {
          return 'primary';
        } else {
          return 'deepPurple';
        }
      },

      getProgramName(programId) {
        let program = this.programsInfo.find(o => o.id === programId);
        
        if (!program) return '-';

        return program.translate.length ? program.translate[0].translate : program.name;
      },

      getDoctorPrefix(item) {
        let doctorId = item.doctor_id || item.attendant_id;
        if (!doctorId) return '';

        let programList = [48, 57]; // "Psicologia" and "Psicologia por Vídeo"
        if (programList.includes(item.program_id)) {
          return '';
        }

        let doctor = this.doctorsInfo.find(o => o.id === doctorId);

        return doctor.crm ? this.$t('general.dr_a') : '';
      },

      getDoctorName(doctorId) {
        if (!doctorId) return '-';

        let doctor = this.doctorsInfo.find(o => o.id === doctorId);
        
        return doctor ? doctor.name : '-';
      },

      handleSelectPatient(selectedPatient) {
        this.patient = selectedPatient;

        this.getHistoryAttendances();

        this.patientSelectionDialog = false;
      },

      handleSelectHistoryAttendance(selectedHistoryAttendance) {
        selectedHistoryAttendance.patient = this.patient;
        this.setHistoryAttendance(selectedHistoryAttendance);

        this.$router.push('/attendances/historydetails');
      },
    },

    computed: {
      ...mapGetters('data/general', {
        doctorsInfo: 'getDoctorsInfo', // -> this.doctorsInfo
        programsInfo: 'getProgramsInfo', // -> this.programsInfo
      }),

      ...mapGetters('data/login', {
        companyId: 'getCompanyId',  // -> this.companyId
        memberId: 'getMemberId', // -> this.memberId
      }),

      ...mapGetters('data/patient', {
        profile: 'getProfile', // -> this.profile
        dependents: 'getDependents',  // -> this.dependents
      }),
    },

    watch:{
      filter() {
        this.getHistoryAttendances();
      }
    } 
  }
</script>

<style lang="scss" scoped>
  @import '@/styles/colors.scss';

  .attendances-history {
    max-width: 1233px;
    width: auto;

    .patient-info {
      border-radius: 50px !important;
      min-width: 300px;
      overflow: hidden;

      .avatar {
        border: $antiFlash solid 1px !important;
        border-radius: 50px !important;
      }

      .name {
        height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 230px;
      }
    }

    .subtitle {
      .subtitle-icon-circle {
        border-radius: 50px;
        height: 23px;
        width: 23px;
      } 
    }

    .filter {
      .button-group {
        background-color: $antiFlash;
        border: 6px solid #F3F3F3;
        width: 330px;

        .button {
          border-radius: 50px;
        }
      }
    }

    .table-header {
      background-color: $outerSpace !important;
      color: white !important;
    }

    .table-item {
      .td-item {
        border-top: 1px solid $lightSilver !important;
        border-bottom: 1px solid $lightSilver !important;

        &:first-child {
          border-left: 1px solid $lightSilver !important;
        }

        &:last-child {
          border-right: 1px solid $lightSilver !important;
        }

        .day {
          line-height: 1rem;
        }
      }
    }
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    .attendances-history {
      //
    }
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .attendances-history {
      max-width: auto;

      .table {
        border: 1px solid $lightSilver !important;

        .table-item {
          position: relative;
          border-bottom: 1px solid $lightSilver !important;

          &:last-child {
            border-bottom: none !important;
          }

          .td-item {
            border: none !important;
            height: auto;
            text-align: left !important;
            border-top: none !important;
            border-bottom: none !important;

            &:first-child {
              border-left: none !important;
            }

            &:last-child {
              border-right: none !important;
              display: flex;
              height: 100%;
              position: absolute;
              right: 0;
              top: 0;
            }
          }
        }
      }
    }
  }

  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .attendances-view {
      .content {
        min-height: calc(100vh - 129px - 76px);
      }
    }
  }

  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .attendances-view {
      .content {
        min-height: calc(100vh - 129px - 76px);
      }
    }
  }
</style>