import axios from 'axios';
import consts from '@/static/js/consts';

export default {
  getMemberAttendances({commit}, payload = {}) {
    return new Promise((resolve, reject) => {
      let newEndpointUrl = consts.ENDPOINTS.GET_MEMBER_ATTENDANCES;
      if (payload && Object.values(payload).length > 0) {
        newEndpointUrl = `${newEndpointUrl}?${new URLSearchParams(payload).toString()}`;
      }
      axios.get(newEndpointUrl, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token'),
        }
      }).then((response) => {
        commit(resolve(response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    })
  },

  /*
  * POST
  * @param program_id: int
  */
  programGetInfo({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.PROGRAM_GET_INFO, payload, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        resolve(commit('setAttendance', response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    });
  },
};