<template>
  <v-container
    class="register-success ma-0 px-0 py-6 text-center"
  >
    <h2
      :class="[ $device.mobile ? 'text-h5' : 'text-h4' ]"
      class="primary--text font-weight-bold title"
    >
      {{ $t('register.register_success') }}
    </h2>

    <v-img
      :src="require('@/assets/general/phone_code_success.svg')"
      contain
    />
       
    <v-btn
      :min-width="$device.mobile ? 165 : 195"
      @click="$emit('handleConfirm')"
      class="cy-finish-registration finish-registration text-capitalize"
      color="secondary"
      dark
      depressed
      height="46"
      max-width="208"
      rounded
    >
      {{ $t('general.back_to_start') }}
    </v-btn>
  </v-container>
</template>

<script>
  export default {
    name: 'RegisterSuccess',

    props: {
      // Function to handle confirm action
      handleConfirm: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },
    },

    data: () => ({
      // 
    }),
  };
</script>

<style lang="scss" scoped>
  .register-success {
    .title {
      white-space: pre-line;
    }
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    .register-success {
      //
    }
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .register-success {
      //
    }
  }

  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .register-success {
      //
    }
  }

  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .register-success {
      //
    }
  }
</style>

