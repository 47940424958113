<template>
  <GeneralModal
    :cancel-text="$t('schedule.upload_file_modal_action_2')"
    :confirm-text="$t('schedule.upload_file_modal_action_1')"
    :dialog="dialog"
    :has-confirm="!loading"
    :has-cancel="!loading"
    :has-close="!loading"
    :header-title="$t('schedule.upload_file_modal_title')"
    :loading="loading"
    @handleCancel="handleSkipUploadFile"
    @handleClose="handleClose"
    @handleConfirm="handleConfirmUploadFile"
    invert-actions
    persistent
    width="549"
  >
    <v-card
      v-if="loading"
      class="content text-center ma-0 px-0 py-16"
      width="100%"
      flat
    >
      <v-progress-circular
        :size="$device.mobile ? 40 : 70"
        :width="$device.mobile ? 4 : 7"
        color="primary"
        indeterminate
      />
    </v-card>

    <v-card
      v-else
      flat
    >
      <h3 class="outerSpace--text">
        {{ $t('schedule.upload_file_modal_body') }}
      </h3>

      <v-alert
        ref="error"
        v-if="errors.length > 0"
        class="mb-0 mt-2 w-auto"
        border="left"
        type="warning"
        close-text="Fechar alerta"
        color="#FCAA42"
        dismissible
      >
        <span v-for="(error, i) in errors" :key="i">{{ error }}</span>
      </v-alert>

      <v-file-input
        class="d-none"
        accept="image/jpeg,image/jpg,image/png,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ref="uploader"
        v-model="fileInputs"
        chip
        multiple
        clearable
      />
    </v-card>
  </GeneralModal>
</template>

<script>
  import {mapGetters, mapMutations} from 'vuex';
  import GeneralModal from '@/components/general/GeneralModal.vue';

  export default {
    name: 'ScheduleUploadFileModal',
    components: {GeneralModal},

    props: {
      // Controls if modal is active or not
      dialog: {
        type: Boolean,
        required: true,
        default: false
      },
    },

    data: () => ({
      loading: false,
      fileInputs: [],
      errors: [],
      typeFilesAccept: ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    }),

    methods: {
      ...mapMutations('data/general', [
        'setGeneralError',
      ]),

      ...mapGetters('data/schedule', {
        getFiles: 'getFiles',
      }),

      ...mapMutations('data/schedule', {
        setFile: 'setFile',
      }),

      goScheduleDetails() {
        this.$nextTick(() => {
          this.$router.push('/schedule/details');
          this.loading = false;
        });
      },

      handleConfirmUploadFile() {
        this.$refs.uploader.$refs.input.click();
      },

      handleSkipUploadFile() {
        this.goScheduleDetails();
      },

      setFilesInStorage() {
        this.errors = [];

        for (let j = 0; j < this.fileInputs.length; j++) {
          if (!this.typeFilesAccept.includes(this.fileInputs[j].type)) {
            this.errors.push(this.$t('general.invalid_file_type'));
            break;
          }
        }

        if (this.fileInputs.some(file => file.size > 15000000)) {
          this.errors.push(this.$t('general.invalid_file_size'));
        }

        if (this.fileInputs.length > 5) {
          this.errors.push(this.$t('general.invalid_file_count'));
        }

        if (this.errors.length > 0) {
          return false;
        } else {
          this.fileInputs.forEach(fileInput => {
            this.setFile(fileInput);
          });

          this.loading = true;
          setTimeout(() => {
            this.goScheduleDetails();
          }, 2000);
        }
      },

      handleClose() {
        this.$emit('handleClose');
      },
    },

    watch: {
      fileInputs() {
        this.setFilesInStorage()
      },
    }
  }
</script>

<style lang="scss" scoped>
  .w-auto {
    width: auto;
  }
</style>
