export default {
  getCompanyId: state => {
    return state.company_id;
  },

  getCompanyStatusId: state => {
    return state.company_status_id;
  },

  getCompanyStatusName: state => {
    return state.company_status_name;
  },

  getFlavourId: state => {
    return state.flavour_id;
  },

  getGroupId: state => {
    return state.group_id;
  },

  getMemberId: state => {
    return state.member_id;
  },

  getPatientGroups: state => {
    return state.groups;
  },

  getToken: state => {
    return state.token;
  },
};
