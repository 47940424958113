import { render, staticRenderFns } from "./AttendancesDetailsView.vue?vue&type=template&id=3900c676&scoped=true"
import script from "./AttendancesDetailsView.vue?vue&type=script&lang=js"
export * from "./AttendancesDetailsView.vue?vue&type=script&lang=js"
import style0 from "./AttendancesDetailsView.vue?vue&type=style&index=0&id=3900c676&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3900c676",
  null
  
)

export default component.exports