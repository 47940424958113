export default {
  primary: '#5949A7',
  secondary: '#00B7E6',
  silverSand: '#C4C4C4',
  outerSpace: '#444444',
  spanishGray: '#9C9C9C',
  antiFlash: '#F3F3F3',
  antiFlashWhite: '#F0F0F0',
  cultured: '#F7F7F7',
  error: '#FF0000',
  folly: '#FC015B',
  deepPurple: '#350165',
  electricRed: '#E60000',
  lightSilver: '#D8D8D8',
};