<template>
    <span>
        <WaitingAttendance v-show="!isDoctorConnected"/>

        <VideoConsultation v-show="isDoctorConnected" @handleDoctorStartedStream="handleDoctorStartedStream"/>
    </span>
</template>

<script>
import WaitingAttendance from '@/components/attendance/WaitingAttendance.vue';
import VideoConsultation from '@/components/attendance/VideoConsultation.vue';

export default {
  name: 'AttendanceView',

  components: {
    VideoConsultation,
    WaitingAttendance
  },

  data: () => ({
    isDoctorConnected: false,
  }),

  methods: {
    handleDoctorStartedStream() {
      this.isDoctorConnected = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';


</style>