import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = () => ({
  company_id: null,
  company_status_id: null,
  company_status_name: null,
  flavour_id: null,
  group_id: null,
  groups: [],
  member_id: null,
  token: null,
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};