import attendance from '@/lang/translations/br/attendance';
import attendances from '@/lang/translations/br/attendances';
import attendances_details from '@/lang/translations/br/attendances_details';
import general from './br/general';
import home from './br/home';
import login from './br/login';
import register from './br/register';
import validate_phone_number from './br/validate_phone_number';
import hardware_check from "@/lang/translations/br/hardware_check";
import feedback from "@/lang/translations/br/feedback";
import profile from './br/profile';
import reschedule from "@/lang/translations/br/reschedule";
import schedule from '@/lang/translations/br/schedule';
import faq from '@/lang/translations/br/faq';
import feedbackSuccess from "@/lang/translations/br/feedbackSuccess";

const br_lang = {
  ...attendance,
  ...attendances,
  ...attendances_details,
  ...general,
  ...home,
  ...login,
  ...register,
  ...validate_phone_number,
  ...hardware_check,
  ...feedback,
  ...profile,
  ...reschedule,
  ...schedule,
  ...faq,
  ...feedbackSuccess
};

export var br = br_lang;