<template>
  <v-container
    v-if="attendancesList.length"
    class="attendances-carousel d-flex flex-column py-9"
    :class="{
      'px-10': $device.mobile,
      'px-16': !$device.mobile,
      'mobile-start-attendance': mobileStartAttendance,
    }"
  >
    <p
      :class="{
        'pl-0': $device.mobile || (attendancesList.length <= 1),
        'pl-14': !$device.mobile && (attendancesList.length > 1)
      }"
      class="text-h6 white--text mb-2"
    >
      {{ $t(`general.${!attendancesList.length ? 'next_attendance' : 'next_attendances'}`) }}
    </p>

    <!-- Loader -->
    <v-card
      v-if="loading"
      class="text-center ma-0 pa-0"
      width="100%"
      color="transparent"
      flat
    >
      <v-progress-circular
        :size="$device.mobile ? 20 : 40"
        :width="$device.mobile ? 2 : 4"
        color="primary"
        indeterminate
      />
    </v-card>

    <v-slide-group
      v-else
      :class="{ 'mobile-start-attendance': mobileStartAttendance }"
      class="carousel"
      :show-arrows="!$device.mobile"
      prev-icon="fa-circle-chevron-left"
      next-icon="fa-circle-chevron-right"
    >
      <v-slide-item
        v-for="(a, i) in attendancesList"
        :key="i"
      >
        <div
            class="card-container"
        >
          <!-- Attendance Card -->
          <v-card
            :class="{
              'pa-3': $device.mobile,
              'pa-5': !$device.mobile,
              'mr-5': i+1 !== attendancesList.length && $device.mobile,
              'mr-8': i+1 !== attendancesList.length && !$device.mobile,
            }"
            :min-width="$device.mobile ? '100' : '423'"
            @click="attendanceDetails(a)"
            class="d-flex flex-row align-center mt-3"
            height="99"
            width="310"
          >
            <!-- Date -->
            <v-avatar
              class="d-flex flex-column"
              color="primary"
              size="63"
            >
              <!-- Day -->
              <h5
                class="day text-h5 pa-0 mb-0 mt-1 mx-0 font-weight-bold white--text"
              >
                {{ convertUtcOffset(a.sla).date() }}
              </h5>

              <!-- Month -->
              <p
                class="month text-caption pa-0 ma-0 white--text"
              >
                {{ convertUtcOffset(a.sla).format('MMM') }}
              </p>
            </v-avatar>

            <!-- Info -->
            <div
              :class="[ $device.mobile ? 'pl-2' : 'pl-5' ]"
              class="d-flex flex-column"
            >
              <!-- Program name -->
              <h5
                class="text-h6 program pa-0 mb-1 mt-0 mx-0 font-weight-bold outherSpace--text"
              >
                {{ a.program_translate || a.program_name }}
              </h5>

              <!-- Info: Less than one hour -->
              <div
                v-if="isLessThanOneHour(a)"
              >
                <!-- Status: Initial, waiting for updates from pusher -->
                <div
                  v-if="status == status_types.INITIAL"
                >
                  <!-- Text -->
                  <p
                    class="name text-caption pa-0 ma-0 outherSpace--text"
                  >
                    {{ $t('home.you_will_be_attended_soon') }}
                  </p>
                </div>

                <!-- Status: Reserved, show doctors info and waiting text -->
                <div
                  v-else-if="status == status_types.RESERVED"
                >
                  <!-- Doctor info -->
                  <p
                    class="name text-caption pa-0 ma-0 spanishGray--text text-capitalize"
                  >
                    {{ doctor.name || a.doctor_name }}
                    <span
                      v-if="doctor.crm"
                    >
                      - {{ $t('general.crm').toUpperCase() }} {{ doctor.crm }}
                    </span>
                  </p>

                  <!-- Text -->
                  <p
                    class="time text-caption pa-0 ma-0 outherSpace--text"
                  >
                    {{ $t('home.waiting_professional_enter_attendance') }}
                  </p>
                </div>

                <!-- Status: Attending, Shows text and start button -->
                <div
                  v-else-if="status == status_types.ATTENDING"
                >
                  <!-- Text -->
                  <p
                    class="time text-caption pa-0 ma-0 outherSpace--text"
                  >
                    <span>
                      {{ $t('home.click_in') }}
                    </span>

                    <span
                      class="font-weight-bold"
                    >
                      {{ $t('home.start') }}
                    </span>

                    <span>
                      {{ $t('home.to_enter_attendance_room') }}
                    </span>
                  </p>

                  <!-- Start button with transition animation -->
                  <div
                    class="start-button-container"
                  >
                    <v-btn
                      @click="startAttendance(a)"
                      class="start-button"
                      color="outerSpace"
                      dark
                      height="100%"
                      width="100%"
                    >
                      <span
                        class="text-h6 font-weight-bold"
                      >
                        {{ $t('general.start').toUpperCase() }}
                      </span>
                    </v-btn>
                  </div>
                </div>
              </div>

              <!-- Info: Default -->
              <div
                v-else
              >
                <!-- Patient name -->
                <p
                  class="name text-caption pa-0 ma-0 spanishGray--text text-capitalize"
                >
                  {{ $t('general.patient') }}: {{ getPatientName(a.member_id) }}
                </p>

                <!-- Time -->
                <p
                  class="time text-caption pa-0 ma-0 spanishGray--text"
                >
                  {{ $t('general.time') }}: {{ convertUtcOffset(a.sla).format('HH:mm') }}H
                </p>
              </div>
            </div>
          </v-card>

          <!-- Chat -->
          <AttendanceChatButton
            v-if="i == 0 && a.program_id == 7"
          />
        </div>
      </v-slide-item>
    </v-slide-group>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import AttendanceChatButton from '@/components/home/AttendanceChatButton';
import helpers from '@/mixins/helpers';
import Pusher from 'pusher-js';

export default {
  name: 'AttendancesCarousel',

  components: {
    AttendanceChatButton,
  },

  mixins: [helpers],

  data: () => ({
    doctor: {
      id: null,
      name: null,
      crm: null,
    },
    loading: false,
    loadingDoctor: false,
    mobileStartAttendance: false,
    status: 0,
    status_types: {
      INITIAL: 0,
      RESERVED: 1,
      ATTENDING: 2,
      FINISH: 3,
    },
    socketChannel: null,
  }),

  mounted() {
    this.getPatientAttendances();
  },

  methods: {
    ...mapActions('data/general', [
      'userGet', // -> map `this.userGet()` to `this.$store.dispatch('userGet')`
      'socketGetInfo' // -> map `this.socketGetInfo()` to `this.$store.dispatch('socketGetInfo')`
    ]),

    ...mapActions('data/patient', [
      'patientAttendances' // -> map `this.patientAttendances()` to `this.$store.dispatch('patientAttendances')`
    ]),

    ...mapMutations('data/schedule', [
      'setPatient' // -> this.setPatient()
    ]),

    ...mapMutations('data/attendance', [
      'setCaseAttendanceId', // -> this.setCaseAttendanceId()
    ]),

    ...mapMutations('data/general', [
      'setAttendance', // -> this.setAttendance()
      'setGeneralError', // -> this.setGeneralError()
    ]),

    getPatientAttendances() {
      this.loading = true;

      this.mobileStartAttendance = false;

      this.status = this.status_types.INITIAL;

      this.doctor = {
        id: null,
        name: null,
        crm: null,
      };

      let payload = {
        limit: 20,
        offset: 0
      };

      this.patientAttendances(payload).catch((error) => {
        console.error(error);
        // User already in Home Screen, this error should log out. Do NOT send route to 'setGeneralError'
        this.setGeneralError(true);
      }).finally(() => {
        this.loading = false;
      });
    },

    getPatientName(memberId) {
      let dependent = this.dependents.find(o => memberId == o.id);

      if (dependent) {
        return dependent.social_name
          ? dependent.social_name.toLowerCase()
          : (dependent.name || dependent.nome).toLowerCase();
      } else {
        return (this.patient.social_name || this.patient.name).toLowerCase();
      }
    },

    attendanceDetails(attendance) {
      this.setAttendance(attendance);
      this.setPatient({
        id: attendance.member_id,
        name: attendance.name
      });

      this.$router.push('/attendances/details');
    },

    isLessThanOneHour(attendance) {
      if (!attendance) return false;

      return this.convertUtcOffset(attendance.sla).diff(this.$moment(), 'hours') <= 1;
    },

    getSocketInfo(attendance) {
      // If doctor already in attendance object, should show attendance card with doctor's info
      if (
        attendance.doctor_id &&
        attendance.doctor_name &&
        attendance.doctor_crm
      ) {
        this.doctor = {
          id: attendance.doctor_id,
          name: attendance.doctor_name,
          crm: attendance.doctor_crm,
        };

        // If attendance status is already in 'ATTENDING' should show start attendance button
        this.status = attendance.status_id == 12 || attendance.status == 'ATTENDING'
          ? this.status_types.ATTENDING
          : this.status_types.RESERVED;
      }


      this.mobileStartAttendance = (this.status == this.status_types.ATTENDING
        && this.$device.mobile)

      if (this.socketChannel) return;

      this.socketChannel = attendance.socket_channel;

      let payload = {
        case_attendance_id: attendance.case_attendance_id,
        member_id: attendance.member_id,
      };

      this.socketGetInfo(payload).then(() => {
        this.connetToPusher();
      }).catch((error) => {
        console.error(error);
        // User already in Home Screen, this error should log out. Do NOT send route to 'setGeneralError'
        this.setGeneralError(true);
      });
    },

    connetToPusher() {
      if (!this.socketInfo) {
        // User already in Home Screen, this error should log out. Do NOT send route to 'setGeneralError'
        return this.setGeneralError(true);
      }

      this.pusher = new Pusher(this.socketInfo.key, {
        cluster: this.socketInfo.options.cluster
      });

      this.pusher.subscribe(this.socketChannel);

      // Subscribe to event 'reserved_attendance': Médico encontrado.
      this.pusher.bind('reserved_attendance', data => {
        if (this.status !== this.status_types[data.status]) {
          this.status = this.status_types[data.status];

          this.mobileStartAttendance = false;

          if (!this.doctor.id && !this.loadingDoctor) {
            this.doctor.id = data.doctor_id;

            this.getDoctorInfo();
          }
        }
      });
      // Subscribe to event 'start_stream': Médico iniciou a chamada.
      this.pusher.bind('start_stream', (data) => {
        if (this.status !== this.status_types[data.status]) {
          this.status = this.status_types[data.status];

          this.mobileStartAttendance = this.$device.mobile;

          if (!this.doctor.id && !this.loadingDoctor) {
            this.doctor.id = data.doctor_id;

            this.getDoctorInfo();
          }
        }
      });
      // Subscribe to event 'finish_stream': Médico finalizou a chamada.
      this.pusher.bind('finish_stream', (data) => {
        if (this.status !== this.status_types[data.status]) {
          this.status = this.status_types[data.status];

          // Reset attendances list
          this.getPatientAttendances();
        }
      });
    },

    getDoctorInfo() {
      this.loadingDoctor = true;

      let payload = {
        user_id: this.doctor.id,
      };

      this.userGet(payload).then((response) => {
        this.doctor = {
          ...this.doctor,
          name: response.user_info.name,
          crm: response.user_info.crm,
        }
      }).catch((error) => {
        console.error(error);
        // User already in Home Screen, this error should log out. Do NOT send route to 'setGeneralError'
        this.setGeneralError(true);
      }).finally(() => {
        this.loadingDoctor = false;
      });
    },

    startAttendance(attendance) {
      localStorage.setItem('case_attendance_id', attendance.case_attendance_id);

      this.setCaseAttendanceId(attendance.case_attendance_id);

      this.$router.push('/hardwarecheck');
    },
  },

  computed: {
    ...mapGetters('data/general', {
      socketInfo: 'getSocketInfo', // -> this.socketInfo
    }),

    ...mapGetters('data/patient', {
      attendances: 'getAttendances', // -> this.attendances
      dependents: 'getDependents', // this.dependents
      patient: 'getProfile',  // -> this.patient
    }),

    attendancesList() {
      if (!this.attendances) return [];

      // If next attendance is less than 1 hour to star it should be in socket/pushers watch
      if (this.attendances[0]) {
        if (this.isLessThanOneHour(this.attendances[0])) {
          this.getSocketInfo(this.attendances[0]);

          return [this.attendances[0]];
        }
      }

      return this.attendances;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.attendances-carousel {
  background-image: linear-gradient(to right, $secondary, transparent);
  height: auto;
  max-width: 100%;
  min-height: 215px;
  width: 100%;

  .carousel {
    width: 100%;

    .card-container {
      position: relative;

      .day {
        line-height: 1rem;
      }

      .name {
        height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .start-button-container {
      animation: slideRightStartButton 1s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      background-color: white;
      border-radius: 0 5px 5px 0;
      bottom: 0;
      overflow: hidden;
      padding: 5px;
      position: absolute;
      right: -110px;
      top: 0;
      width: 115px;
    }
  }
}

@keyframes slideRightStartButton {
  from {
    right: 0;
    width: 0;
  }
  to {
    right: -110px;
    width: 115px;
  }
}

@keyframes slideDownStartButton {
  from {
    bottom: 0;
    height: 0;
  }
  to {
    bottom: -60px;
    height: 65px;
  }
}

/* For Tablet View */
@media screen and (min-device-width: 641px) and (max-device-width: 1024px) {
  .attendances-carousel {
    //
  }
}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
  .attendances-carousel {
    &.mobile-start-attendance {
      height: 284px;
    }

    .carousel {
      &.mobile-start-attendance {
        height: 172px !important;
        min-height: 172px !important;
      }

      .start-button-container {
        animation: slideDownStartButton 1s;
        border-radius: 0 0 5px 5px;
        bottom: -60px;
        height: 65px;
        left: 0;
        right: 0;
        top: unset;
        width: 100%;
      }
    }
  }
}

/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  .attendances-carousel {
    &.mobile-start-attendance {
      height: 284px;
    }

    .carousel {
      &.mobile-start-attendance {
        height: 172px !important;
        min-height: 172px !important;
      }

      .start-button-container {
        animation: slideDownStartButton 1s;
        border-radius: 0 0 5px 5px;
        bottom: -60px;
        height: 65px;
        left: 0;
        right: 0;
        top: unset;
        width: 100%;
      }
    }
  }
}

/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {
  .attendances-carousel {
    &.mobile-start-attendance {
      height: 284px;
    }

    .carousel {
      &.mobile-start-attendance {
        height: 172px !important;
        min-height: 172px !important;
      }

      .start-button-container {
        animation: slideDownStartButton 1s;
        border-radius: 0 0 5px 5px;
        bottom: -60px;
        height: 65px;
        left: 0;
        right: 0;
        top: unset;
        width: 100%;
      }
    }
  }
}
</style>