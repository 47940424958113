import axios from 'axios';
import consts from '@/static/js/consts';

export default {
  /*
   * POST
   */
  streamPatientGet({commit}, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.STREAM_PATIENT_GET, payload, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token'),
        }
      }).then((response) => {
        commit(resolve(response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    });
  },

  /*
   * POST
   */
  caseAttendanceCancelReasons({commit}, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.CASE_ATTENDANCE_CANCEL_REASONS, payload, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token'),
        }
      }).then((response) => {
        let list = response.data.data;

        // Reorder "outros" to last position
        const fromIndex = list.findIndex(o => o.name.toLowerCase() == 'outros');
        const toIndex = list.length-1;
        list.splice(toIndex, 0, list.splice(fromIndex, 1)[0]);

        resolve(commit('setCancelReasons', list));
      }).catch(error => {
        reject(error.response);
      });
    });
  },

  /*
   * POST
   * @param case_attendance_id: int
   * @param cancel_reason_id: int
   * @param cancel_reason_text: string
   */
  partnerAttendanceCancel({commit}, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.PARTNER_ATTENDANCE_CANCEL, payload, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token'),
        }
      }).then((response) => {
        commit(resolve(response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    });
  },

  /*
  * POST
  * @param company_id: int
  * @param group_id: int
  */
  getPatientLastAttendance({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios.get(consts.ENDPOINTS.CHECK_LAST_ATTENDANCE, {
        params: params,
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        resolve(commit('setPatientLastAttendance', response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    });
  },

  /*
   * POST
   * @param company_id: int,
   * @param limit: int,
   * @param member_id: int,
   * @param offset: int,
   * @param order_by: string
   */
  caseAttendancesHistory({commit}, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.CASE_ATTENDANCES_HISTORY, payload, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token'),
        }
      }).then((response) => {
        commit(resolve(response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    });
  },

  /*
  * GET
  * @param member_id: int
  * @param limit: int
  * @param offset: int
  */
  getMemberAttendances({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const params = `?member_id=${payload.member_id}&limit=${payload.limit || 20}&offset=${payload.offset || 0}`

      axios.get(`${consts.ENDPOINTS.MEMBER_ATTENDANCES}${params}`, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        commit(resolve(response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    });
  },

  caseAttendanceReschedule({commit}, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.CASE_ATTENDANCE_RESCHEDULE, payload, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token'),
        }
      }).then((response) => {
        commit(resolve(response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    });
  },
};