export default {
  attendances_details: {
    my_attendance: 'Minha consulta',
    attendance_summary: 'Resumo da consulta',
    specialty: 'Especialidade',
    patient: 'Paciente',
    date_and_time: 'Data e horário',
    orders_exams_photos: 'Pedidos, exames e fotos',
    attach_up_to: 'Você pode anexar até',
    five_files_types: '5 arquivos (.jpg, .pdf ou .doc)',
    click_to_view: 'Clique para visualizar',
    professional: 'Profissional',
    my_informations: 'Os dados estão corretos?',
    cancel_attendance: 'Cancelar consulta',
    select_cancel_reason: 'Selecione o motivo do cancelamento',
    write_reason_here: 'Escreva o motivo aqui',
    cancel_sheduled_attendance: 'Cancelar consulta agendada',
    title_attendance_success_cancel_alert:'Sua consulta foi cancelada.',
    subtitle_attendance_success_cancel_alert: 'Obrigado por utilizar os serviços da Teladoc.',
    are_you_sure_you_want_delete_file: 'Tem certeza que deseja excluir esse arquivo?',
    wish_to_attach_files: 'Deseja incluir pedidos médicos, exames ou fotos na consulta?',
    number_attendance: 'ª consulta',
    schedule: 'Agendar',
    schedule_only: 'Agendar apenas',
    appointment: 'consulta',
    appointments: 'consultas',
    confirm_your_appointment_of: 'Deseja confirmar sua consulta de',
    and_schedule_next: 'e agendar a próxima?',
    schedule_confirmed_success: 'Consulta confirmada com sucesso',
  }
};