export default {
  reset(state) {
    state.case_attendance_id = null;
  },

  setCaseAttendanceId(state, payload) {
    state.case_attendance_id = payload;
  },

  setAttendance(state, payload) {
    state.attendance = payload;
  },

  setFeedback(state, payload) {
    state.feedback = payload;
  },
};