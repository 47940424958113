<template>
  <v-container
    class="login-view ma-o pa-0 d-flex justify-center"
  >
    <!-- LOGIN -->
    <LoginContainer
      v-if="registerStep == 0 && !benefitsDialog && !showPreloaderSplash"
    >
      <div>
        <v-img
          :src="require('@/assets/logos/teladoc_health.svg')"
          class="logo mx-auto"
          contain
          height="86px"
          width="265px"
        />
      </div>

      <LoginForm
        @handleLogin="handleLogin"
        @handleRegister="handleRegister"
        @handleForgotPassword="handleForgotPassword"
      />

      <LoginFooter
        @handlePrivacyPolicy="handlePrivacyPolicy"
        @handleConsentTerm="handleConsentTerm"
      />
    </LoginContainer>

    <!-- BENEFITS SELECTION -->
    <GeneralModal
      :dialog="benefitsDialog"
      :header-title="$t('general.benefits')"
      overlay-color="transparent"
      persistent
      width="549"
    >
      <BenefitsSelection
        @handleSelect="handleSelectBenefit"
      />
    </GeneralModal>

    <PreloaderSplash
      v-if="showPreloaderSplash"
    />

    <!-- REGISTER -->
    <GeneralModal
      :dialog="registerStep >= 1"
      :content-icon="registerStep == 1 ? 'fa-file-lines' : null"
      :content-title="registerStep == 1 ? $t('general.consent_term') : null"
      :fullscreen="$device.mobile && registerStep == 1"
      :hide-header="registerStep == 1"
      :logo="registerStep > 1"
      :width="registerStep == 1 ? '1000' : '549'"
      @handleCancel="resetRegister"
      @handleClose="resetRegister"
      overlay-color="transparent"
      has-close
      persistent
    >
      <!-- Step 1: Term Acceptance -->
      <ConsentTerm
        v-if="registerStep == 1"
        @handleAcceptTerm="handleAcceptTerm"
        has-accept-term
      />

      <!-- Step 2: Personal Info -->
      <PersonalInfoForm
        v-else-if="registerStep == 2"
        @handleConfirm="incressRegisterStep"
        @handleWarning="resetRegister"
      />

      <!-- Step 3: Login Info -->
      <LoginInfoForm
        v-else-if="registerStep == 3"
        @handleConfirm="incressRegisterStep"
      />

      <!-- Step 4: Phone Code Confirmation -->
      <PhoneCodeVerification
        v-else-if="registerStep == 4"
        @handleConfirm="incressRegisterStep"
      />
      
      <!-- Step 5: Register Success -->
      <RegisterSuccess
        v-else-if="registerStep == 5"
        @handleConfirm="resetRegister"
      />
    </GeneralModal>

    <!-- CONSENT TERM -->
    <GeneralModal
      :cancel-text="$t('general.close')"
      :content-title="$t('general.consent_term')"
      :dialog="consentTermDialog"
      :fullscreen="$device.mobile"
      @handleCancel="closeConsentTermDialog"
      @handleClose="closeConsentTermDialog"
      background-color="antiFlash"
      content-icon="fa-file-lines"
      has-cancel
      hide-header
      width="1000"
    >
      <ConsentTerm />
    </GeneralModal>


    <!-- Privacy Policy -->
    <GeneralModal
      :cancel-text="$t('general.close')"
      :contentTitle="$t('general.privacy_policy')"
      :dialog="privacyPolicyDialog"
      :fullscreen="$device.mobile"
      @handleCancel="closePrivacyPolicyDialog"
      @handleClose="closePrivacyPolicyDialog"
      background-color="antiFlash"
      content-icon="fa-file-lines"
      has-cancel
      hide-header
      max-width="1000"
    >
      <PrivacyPolicy />
    </GeneralModal>

    <!-- Forgot Password -->
    <GeneralModal
      :cancel-text="$t('general.cancel')"
      :confirm-text="$t('general.send')"
      :dialog="forgotPasswordDialog"
      :width="$device.mobile ? '346' : '491'"
      @handleClose="closeForgotPasswordDialog"
      background-color="antiFlash"
      header-icon="fa-lock"
      header-title="Esqueci minha senha"
      has-close
    >
      <ForgotPassword
        @handleCancel="closeForgotPasswordDialog"
      />
    </GeneralModal>
  </v-container>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';
  import LoginContainer from '@/components/login/LoginContainer';
  import LoginForm from '@/components/login/LoginForm';
  import LoginFooter from '@/components/login/LoginFooter';
  import GeneralModal from '@/components/general/GeneralModal';
  import ConsentTerm from '@/components/general/ConsentTerm';
  import PrivacyPolicy from '@/components/general/PrivacyPolicy';
  import ForgotPassword from '@/components/login/ForgotPassword';
  import PersonalInfoForm from '@/components/login/PersonalInfoForm';
  import LoginInfoForm from '@/components/login/LoginInfoForm';
  import PhoneCodeVerification from '@/components/general/PhoneCodeVerification';
  import RegisterSuccess from '@/components/login/RegisterSuccess';
  import BenefitsSelection from '@/components/general/BenefitsSelection';
  import PreloaderSplash from '@/components/general/PreloaderSplash';

  export default {
    name: 'LoginView',

    components: {
      LoginContainer,
      LoginForm,
      LoginFooter,
      GeneralModal,
      ConsentTerm,
      PrivacyPolicy,
      ForgotPassword,
      PersonalInfoForm,
      LoginInfoForm,
      PhoneCodeVerification,
      RegisterSuccess,
      BenefitsSelection,
      PreloaderSplash,
    },
    data: () => ({
      benefitsDialog: false,
      consentTermDialog: false,
      privacyPolicyDialog: false,
      forgotPasswordDialog: false,
      forgotPasswordEmail: true,
      showPreloaderSplash: false,
    }),


    methods: {
      ...mapMutations('data/general', [
        'setConsentTermDialog', // -> this.setConsentTermDialog
        'setPrivacyPolicyDialog', // -> this.setPrivacyPolicyDialog
      ]),

      ...mapMutations('data/register', {
        resetRegister: 'reset', // -> this.resetRegister
        incressRegisterStep: 'incressCurrentStep', // -> this.incressRegisterStep
        acceptTerm: 'acceptTerm', // -> this.acceptTerm
      }),

      handleLogin() {
        if (this.groupId) {
          this.showPreloaderSplash = true;
        } else {
          this.benefitsDialog = true;
        }
      },

      handleSelectBenefit() {
        this.benefitsDialog = false;
        this.showPreloaderSplash = true;
      },

      handleRegister() {
        this.incressRegisterStep();
      },

      handleForgotPassword() {
        this.forgotPasswordDialog = true;
      },

      closeForgotPasswordDialog() {
        this.forgotPasswordDialog = false;
      },

      handlePrivacyPolicy() {
        this.setPrivacyPolicyDialog(true);
      },

      handleConsentTerm() {
        this.setConsentTermDialog(true);
      },

      handleAcceptTerm() {
        this.acceptTerm();
        this.incressRegisterStep();
      },

      closePrivacyPolicyDialog() {
        this.privacyPolicyDialog = false;
      },

      closeConsentTermDialog() {
        this.consentTermDialog = false;
      },
    },

    computed: {
      ...mapGetters('data/register', {
        registerStep: 'getCurrentStep', // -> this.registerStep
      }),

      ...mapGetters('data/login', {
        groupId: 'getGroupId', // -> this.groupId
      }),
    },
  };
</script>

<style lang="scss" scoped>
  .login-view {
    background-image: url("@/assets/images/login_bg_desktop.png");
    background-size: cover;
    box-shadow: inset 0 0 0 1024px rgba(89, 73, 167, 0.6);
    height: 100vh;
    max-height: 100vh;
    max-width: 100vw;
    width: 100vw;
  }

  /* For Oversized Desktop View */
  @media screen and (min-width: 3072px) {
    .login-view {
      box-shadow: inset 0 0 0 3072px rgba(89, 73, 167, 0.6);
    }
  }
   
  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    .login-view {
      background-image: url("@/assets/images/login_bg_tablet.png");
    }
  }
   
  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .login-view {
      background-image: url("@/assets/images/login_bg_mobile.png");
    }
  }
   
  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .login-view {
      background-image: url("@/assets/images/login_bg_mobile.png");
    }
  }
   
  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .login-view {
      background-image: url("@/assets/images/login_bg_mobile.png");
    }
  }
</style>
