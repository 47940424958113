export default {
  getCurrentStep: state => {
    return state.currentStep;
  },

  getWarningTypes: state => {
    return state.warning_types;
  },

  getRegisterData: state => {
    return state.form;
  },

  getMemberId: state => {
    return state.member_id;
  },
};