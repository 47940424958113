<template>
  <v-container
    class="ma-0 pa-0" 
  >
    <!-- Splash screen -->
    <div
      v-if="!showPreloaderSplash"
      class="splash-screen d-flex align-center justify-center"
    >
      <div
        :class="{ 'fade-out': fadeOut }"
        class="d-flex align-center"
      >
        <div
          class="balls d-flex align-center"
        >
          <div
            class="balls-wrapper"
          >
            <div class="white-ball" />
            <div class="blue-ball" />
          </div>
        </div>

        <div
          class="pipes d-flex align-center"
        >
          <div
            class="pipes d-flex align-center"
          >
            <div
              class="white-pipe-wrapper"
            >
              <div
                class="white-pipe"
              >
                <div class="inner-blue" />
              </div>
            </div>

            <div
              class="blue-pipe-wrapper"
            >
              <div
                class="blue-pipe"
              >
                <div class="inner-white" />
              </div>
            </div>
          </div>
        </div>

        <v-img class="logo-white logoAnimation"
          :src="require('@/assets/logos/teladoc_health_splash.svg')"
          contain
        />
      </div>

      <!-- Benefits Selection -->
      <GeneralModal
        :dialog="benefitsDialog"
        :header-title="$t('general.benefits')"
        overlay-color="transparent"
        persistent
        width="549"
      >
        <BenefitsSelection
          @handleSelect="handleSelectBenefit"
        />
      </GeneralModal>
    </div>

    <!-- Preloader Splash -->
    <PreloaderSplash
      v-if="showPreloaderSplash"
    />
  </v-container>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import GeneralModal from '@/components/general/GeneralModal';
  import BenefitsSelection from '@/components/general/BenefitsSelection';
  import PreloaderSplash from '@/components/general/PreloaderSplash';

  export default {
    name: 'SplashScreen',

    components: {
      GeneralModal,
      BenefitsSelection,
      PreloaderSplash,
    },

    data: () => ({
      loading: false,
      token: null,
      member_id: null,
      fadeOut: false,
      showPreloaderSplash: false,
      benefitsDialog: false,
    }),

    created() {
      this.token = localStorage.getItem('token');
      this.member_id = localStorage.getItem('member_id');

      setTimeout(() => {
        if (!this.token || !this.member_id) {
          this.endSplashScreen('login');
        } else {
          this.setMemberId(this.member_id);
          this.validateToken();
        }
      }, 6000);
    },

    methods: {
      ...mapActions('data/login', [
        'getPatientGroups', // -> map `this.getPatientGroups()` to `this.$store.dispatch('getPatientGroups')`
      ]),

      ...mapMutations('data/general', [
        'setShowSplashScreen', // -> this.setShowSplashScreen()
      ]),

      ...mapMutations('data/login', [
        'setToken', // -> this.setToken()
        'setMemberId', // -> this.setMemberId()
      ]),

      handleSelectBenefit() {
        this.benefitsDialog = false;
        this.showPreloaderSplash = true;
      },

      endSplashScreen(path) {
        this.fadeOut = true;

        setTimeout(() => {
          if (!path) {
            if (this.groupId) {
              this.showPreloaderSplash = true;
            } else {
              this.benefitsDialog = true;
            }
          } else if (this.$route.name !== path) {
            this.setShowSplashScreen();

            this.$router.push(`/${path}`);
          } else {
            this.setShowSplashScreen();
          }
        }, 600);
      },

      validateToken() {
        this.loading = true;

        this.getPatientGroups().then(() => {
          this.setToken(this.token);
          this.endSplashScreen();
        }).catch((error) => {
          console.error(error);
          this.endSplashScreen('login');
        }).finally(() => {
          this.loading = false;
        });
      },
    },

    computed: {
      ...mapGetters('data/login', {
        groupId: 'getGroupId', // -> this.groupId
      }),
    },
  };
</script>

<style lang="scss" scoped>
  .splash-screen {
    background: linear-gradient(0.76deg, #5949A7 0.66%, #350165 99.38%);
    height: 100vh;
    overflow: hidden;
    width: 100vw;
  }

  .fade-out {
    opacity: 1;
    animation: fadeOut .6s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .pipes {
    width: 115px;
    position: relative;
    left: 100px;
    animation: MoveLeftPipes .3s;
    animation-delay: 3.70s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .balls {
    left: 100px;
    position: relative;
  }

  .logo-white {
    width: 686.52px;
    height: 222.65px;
    opacity: 0;
    animation: fadeIn 1.5s;
    animation-delay: 3.8s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .white-pipe-wrapper {
    position: absolute;
    overflow: hidden;
    transform: rotate(180deg);
    right: -519px;
    animation: twistPipe .4s, shrinkPipeWrapper;
    animation-delay: 2.1s, 3.75s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .blue-pipe-wrapper {
    position: absolute;
    overflow: hidden;
    transform: rotate(180deg);
    left: -240px;
    animation: shrinkPipeWrapperR;
    animation-delay: 3.75s, 4.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

  }

  .white-ball {
    position: absolute;
    left: 490px;
    width: 91px;
    height: 91px;
    border-radius: 100%;
    background: #00B5E2;
    animation: fadeOutBalls .4s;
    animation-delay: 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .blue-ball {
    position: relative;
    width: 91px;
    height: 91px;
    border-radius: 100%;
    background: #fff;
    left: 99px;
    animation: fadeOutBalls .4s;
    animation-delay: 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .white-pipe {
    position: relative;
    border: 91px solid #00B5E2;
    border-radius: 100%;
    right: 242px;
    width: 484px;
    height: 484px;
    opacity: 0;
    animation: fadeInPipe .5s, shrinkWhitePipe ease 150ms;
    animation-delay: 2s, 3.75s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .blue-pipe {
    position: relative;
    border: 91px solid #fff;
    border-radius: 100%;
    right: 242px;
    width: 484px;
    height: 484px;
    opacity: 0;
    animation: fadeInPipe .5s, shrinkBluePipe ease 150ms;
    animation-delay: 2s, 3.75s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .balls-wrapper {
    width: 665px;
    position: absolute;
  }

  .inner-white {
    position: absolute;
    border-radius: 100%;
    background: #00B5E2;
    opacity: 0;
    top: -91px;
    left: 105px;
    height: 91px;
    width: 91px;
    animation: fadeInInnerBlue .1s, shrinkBalls ease 150ms;
    animation-delay: 2.3s, 3.75s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .inner-blue {
    position: relative;
    border-radius: 100%;
    background: #fff;
    opacity: 0;
    left: 105px;
    top: -91px;
    height: 91px;
    width: 91px;
    animation: fadeInInnerBlue .1s, shrinkBalls ease 150ms;
    animation-delay: 2.3s, 3.75s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  @keyframes fadeOutBalls {
    to {
      opacity: 0;
      width: 101px;
      height: 141px;
    }
  }

  @keyframes twistPipe {
    0% {
      transform: rotate(180deg);
      right: 0;
    }
    5% {
      opacity: 0;
      transform: rotate(180deg);
      top: -141px;
      right: 0;
    }
    20% {
      transform: rotate(180deg);
      top: -242px;
      right: -300px;
    }
    60% {
      opacity: 1;
      transform: rotate(0deg);
      top: -242px;
      right: -612px;
      left: 240px;
    }
    100% {
      transform: rotate(0deg);
      top: -242px;
      right: -612px;
      left: 240px;
    }
  }

  @keyframes fadeInPipe {
    from {
      opacity: 0;
      width: 61px;
      height: 141px;
    }
    to {
      opacity: 1;
      height: 484px;
      width: 484px;
    }
  }

  @keyframes fadeInInnerBlue {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes shrinkBalls {
    to {
      top: -18px;
      left: 19px;
      height: 18px;
      width: 18px;
    }
  }

  @keyframes shrinkWhitePipe {
    to {
      left: -47px;
      width: 94px;
      height: 94px;
      border: 18px solid #00B5E2;
    }
  }

  @keyframes shrinkPipeWrapper {
    to {
      top: -77px;
    }
  }

  @keyframes shrinkPipeWrapperR {
    to {
      top: -77px;
      right: -125px;
    }
  }

  @keyframes shrinkBluePipe {
    to {
      right: 47px;
      width: 94px;
      height: 94px;
      border: 18px solid #fff;
    }
  }

  @keyframes MoveLeftPipes {
    to {
      left: 188px;
    }
  }

  @keyframes fadeOut {
    to {
      opacity: 0;
    }
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    .splash-screen {
      // 
    }
    .logo-white {
      left: -53px;
    }

    .balls {
      left: 60px;
      position: relative;
    }

    .pipes {
      left: 85px;
    }

    @keyframes MoveLeftPipes {
      to {
        left: 164px;
      }
    }
  }
   
  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .splash-screen {
      // 
    }

    .logo-white {
      left: -50px;
      width: 393.00px !important;
    }

    .balls {
      left: -100px;
      position: relative;
    }

    .white-ball {
      left: 360px;
      width: 57px;
      height: 57px;
    }

    .blue-ball {
      left: 290px;
      width: 57px;
      height: 57px;
    }

    .pipes {
      left: 10px;
    }

    @keyframes MoveLeftPipes {
      to {
        left: 57px;
      }
    }


    @keyframes shrinkBluePipe {
      to {
        right: 29px;
        width: 56px;
        height: 56px;
        border: 12px solid #fff;
      }
    }

    @keyframes shrinkWhitePipe {
      to {
        left: -29px;
        width: 56px;
        height: 56px;
        border: 12px solid #00B5E2;
      }
    }

    @keyframes shrinkPipeWrapper {
      to {
        top: -44px;
      }
    }

    @keyframes shrinkPipeWrapperR {
      to {
        top: -44px;
        right: -125px;
      }
    }

    @keyframes shrinkBalls {
      to {
        top: -12px;
        left: 10px;
        height: 12px;
        width: 12px;
      }
    }

    @keyframes logoAnimation {
      0% {
        opacity: 0;
        height: 100vh;
        width: 100vw;
      }

      20% {
        opacity: 0;
        height: 50vh;
        width: 80vw;
      }

      80% {
        opacity: 0;
        height: 50vh;
        width: 80vw;
      }

      100% {
        opacity: 1;
        height: 50vh;
        width: 80vw;
      }
    }
  }
   
  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .splash-screen {
      // 
    }

    .logo-white {
      width: 393.00px !important;
      height: 222.65px;
    }

    @keyframes logoAnimation {
      0% {
        opacity: 0;
        height: 100vh;
        width: 100vw;
      }

      20% {
        opacity: 0;
        height: 50vh;
        width: 80vw;
      }

      80% {
        opacity: 0;
        height: 50vh;
        width: 80vw;
      }

      100% {
        opacity: 1;
        height: 50vh;
        width: 80vw;
      }
    }
  }
   
  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .splash-screen {
      // 
    }


    .logo-white {
      width: 393.00px !important;
      height: 222.65px;
    }


    @keyframes logoAnimation {
      0% {
        opacity: 0;
        height: 100vh;
        width: 100vw;
      }

      20% {
        opacity: 0;
        height: 50vh;
        width: 80vw;
      }

      80% {
        opacity: 0;
        height: 50vh;
        width: 80vw;
      }

      100% {
        opacity: 1;
        height: 50vh;
        width: 80vw;
      }
    }
  }
</style>

