export default {
  home: {
    need_attendance_title: 'Preciso de um atendimento',
    need_attendance_text: 'Agende sua consulta ou obtenha atendimento imediato com nossos profissionais.',
    my_attendances_title: 'Minhas consultas',
    my_attendances_text: 'Acesse suas consultas agendadas e seu histórico de atendimento.',
    my_profile_title: 'Meu Perfil',
    my_profile_text: 'Visualize ou altere suas informações de cadastro e dependentes.',
    support_title: 'Suporte 0800',
    support_text: 'Precisa de ajuda com algum serviço Teladoc ou com o portal? Entre em contato com a gente.',
    virtual_medical_care: 'Cuidado médico virtual para todos',
    virtual_medical_care_mobile: 'Cuidado médico virtual\npara todos',
    virtual_medical_care_body: 'Mais uma forma de cuidar da sua saúde e da saúde da sua família. Agende já sua consulta online e receba cuidados médicos sem sair de casa!',
    you_will_be_attended_soon: 'Você será atendido(a) em breve.',
    waiting_professional_enter_attendance: 'Aguardando o profissional entrar na sala',
    click_in: 'Clique em',
    start: 'iniciar',
    to_enter_attendance_room: 'para entrar na sala de vídeo',
  }
};