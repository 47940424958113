<template>
  <v-container
    class="cancel-reasons-form"
  >
    <p
      class="text-subtitle-1 mb-5 outerSpace--text"
    >
      {{ $t('attendances_details.select_cancel_reason') }}:
    </p>

    <div
      v-if="loadingReasons"
      class="text-center"
    >
      <v-progress-circular
        :width="3"
        color="primary"
        indeterminate
      />
    </div>

    <div
      v-else
    >
      <v-radio-group
        v-model="selectedReason"
        :disabled="loading"
        hide-details
      >
        <div
          v-for="(reason, index) in reasons"
          :key="index"
          class="mb-4"
        >
          <div
            class="d-flex justify-space-between mb-4"
          >
            <label
              class="option-label"
            >
              {{ reason.name.toLowerCase() }}
            </label>

            <v-radio
              :value="reason"
              color="secondary"
            />
          </div>

          <v-textarea
            v-if="index === reasons.length-1"
            v-model="reasonText"
            :placeholder="$t('attendances_details.write_reason_here')"
            :disabled="!selectedReason || !isOthersSelected(selectedReason.name)"
            class="mt-2"
            height="178"
            hide-details
            maxlength="200"
            minlength="7"
            no-resize
            outlined
          />

          <v-divider
            v-if="index < reasons.length-1"
          />
        </div>
      </v-radio-group>

      <v-btn
        :class="{'disabled-button-dark': disabledButton }"
        :loading="loading"
        @click="submitCancel"
        class="mt-2"
        color="outerSpace"
        dark
        height="46"
        rounded
        width="100%"
      >
        {{ $t('attendances_details.cancel_sheduled_attendance') }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';

  export default {
    name: 'cancelReasonsForm',

    props: {
      // loading prop
      loading: {
        type: Boolean,
        default: false
      },

      // Function to handle confirm button and action
      handleConfirm: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },
    },
    
    data: () => ({
      disabledButton: true,
      loadingReasons: false,
      selectedReason: null,
      reasonText: null,
    }),

    created() {
      if (!this.reasons || !this.reasons.length) {
        this.listCancelReasons();
      }
    },

    methods: {
      ...mapActions('data/attendance', [
        'caseAttendanceCancelReasons', // -> map `this.caseAttendanceCancelReasons()` to `this.$store.dispatch('caseAttendanceCancelReasons')`
      ]),

      ...mapMutations('data/general', [
        'setGeneralError', // -> this.setGeneralError
        'setGeneralErrorRedirectRoute', // -> this.setGeneralErrorRedirectRoute
      ]),

      listCancelReasons() {
        this.loadingReasons = true;

        this.caseAttendanceCancelReasons().catch(error => {
          console.error(error);
          this.setGeneralError(true);
          this.setGeneralErrorRedirectRoute('home');
        }).finally(() => {
          this.loadingReasons = false;
        })
      },

      changeButtonState(reasonText) {
        if (!this.selectedReason || (!reasonText && this.isOthersSelected(this.selectedReason.name))) {
          this.disabledButton = true;
        } else if (reasonText && this.isOthersSelected(this.selectedReason.name)) {
          let reasonSlit = reasonText.split(' ');
          if (reasonSlit[0] && reasonSlit[0].length >= 1) {
            this.disabledButton = false;
          } else {
            this.disabledButton = true;
          }
        } else {
          this.disabledButton = false;
        }
      },

      submitCancel() {
        if (!this.selectedReason) return;

        let payload = {
          cancel_reason_id: this.selectedReason.id,
          cancel_reason_text: this.isOthersSelected(this.selectedReason.name)
            ? this.reasonText
            : this.selectedReason.name
        };

        this.$emit('handleConfirm', payload);
      },
    },

    computed: {
      ...mapGetters('data/attendance', {
        reasons: 'getCancelReasons', // -> this.reasons
      }),

      isOthersSelected() {
        return reasonName => reasonName.toLowerCase() == 'outros';
      },
    },

    watch: {
      selectedReason(newReason) {
        if (!this.isOthersSelected(newReason.name)) {
          this.reasonText = null;
        }

        this.changeButtonState(this.reasonText);
      },

      reasonText(newReasonText) {
        this.changeButtonState(newReasonText);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .cancel-reasons-form {
    .option-label {
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
</style>