<template>
  <v-container
      class="home-view d-flex flex-column pa-0 justify-center"
      max-width="100%"
  >
    <div class="content">
      <div class="d-flex justify-center pa-12">
        <v-img
            :src="require('@/assets/general/success_doctors.png')"
            class="img-success"
            contain
        />
      </div>
      <div class="text-center justify-center pa-10 title">
        <p>{{ $t('attendances_details.title_attendance_success_cancel_alert') }}</p>
        <p>{{ $t('attendances_details.subtitle_attendance_success_cancel_alert') }}</p>
      </div>
      <div class="d-flex justify-center pb-4">
        <v-btn
            @click="handleBack"
            class="font-weight-bold mx-3"
            color="blue"
            dark
            height="46"
            outline-border
            rounded
            width="244"
        >
          {{ $t('general.back_to_start') }}
        </v-btn>
      </div>
    </div>
    <!-- Footer -->
    <HomeFooter/>
  </v-container>
</template>

<script>
import HomeFooter from '@/components/home/HomeFooter';
import {mapMutations} from "vuex";

export default {
  name: 'AttendanceCancelSuccess',


  components: {
    HomeFooter,
  },

  data: () => ({
    //
  }),

  mounted() {
    this.setBannerTitle(this.$t('general.banner_cancel_attendance'));
    this.setBannerIcon('fa-calendar-days');
  },

  methods: {
    ...mapMutations('data/general', [
      'setBannerTitle', // -> this.setBannerTitle
      'setBannerIcon', // -> this.setBannerIcon
    ]),

    handleBack() {
      this.$router.push('/');
    }
  },

  computed: {}
};
</script>

<style lang="scss" scoped>
.home-view {
  max-width: 100%;

  .content {
    min-height: calc(100vh - 246px - 97px);
    max-width: 1500px;
    padding-bottom: 107px !important;
    padding-top: 74px !important;

    .image {
      margin-top: 80px !important;
      z-index: 2;
    }

    .logo {
      z-index: 2;
    }

    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 268px;
    }

    .title {
      font-style: normal;
      font-weight: 400;
      font-size: 20px !important;
      line-height: 12px;
    }
  }
}

/* For Tablet View */
@media screen and (min-device-width: 641px) and (max-device-width: 1024px) {
  .home-view {
    .content {
      min-height: calc(100vh - 216px - 97px);
      padding-bottom: 85px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      padding-top: 74px !important;

      .image {
        margin: 0 auto 56px auto !important;
      }

      .components {
        flex-direction: column-reverse !important;
      }

      .title {
        font-size: 30px !important;
      }
    }
  }
}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
  .home-view {
    .content {
      min-height: calc(100vh - 129px - 76px);
      padding-bottom: 38px !important;
      padding-top: 0 !important;

      .image {
        margin-top: 1px !important;
      }

      .title {
        font-size: 16px !important;
        white-space: pre-line;
      }
    }
  }
}

/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  .home-view {
    .content {
      min-height: calc(100vh - 129px - 76px);
      padding-bottom: 38px !important;
      padding-top: 0 !important;

      .image {
        margin-top: 1px !important;
      }

      .title {
        font-size: 16px !important;
        white-space: pre-line;
      }
    }
  }
}

/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {
  .home-view {
    .content {
      min-height: calc(100vh - 129px - 76px);
      padding-bottom: 38px !important;
      padding-top: 0 !important;

      .image {
        margin-top: 1px !important;
      }

      .title {
        font-size: 16px !important;
        white-space: pre-line;
      }
    }
  }
}

.img-success {
  max-width: 436px;
}
</style>