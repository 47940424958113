<template>
  <v-container fluid class="hardwarecheck-view">
    <v-row class="mt-5 text-center"
         justify="center"
         md="10"
    >
      <v-col md="8">
        <div class="title-hardwarecheck white--text">
          {{ $t('hardware_check.title') }}
        </div>

        <div class="subtitle-hardwarecheck  white--text">
          {{ $t('hardware_check.subtitle_warning') }}
        </div>
      </v-col>
    </v-row>

    <!--desktop-->
    <div v-if="!$device.mobile && checkHardware">
      <v-row>
        <v-col>
          <VideoCall @handleHardwareAvailable="handleHardwareAvailable"/>
        </v-col>

        <v-col>
          <VideoCallTips/>
        </v-col>
      </v-row>

      <v-row class="mt-5 text-center">
        <v-col>
          <v-btn
              class="px-12 primary--text outline-border "
              height="60"
              dark
              rounded
              @click="goWaitingAttendance"
              :disabled="isEnableStartAttendanceBtn"
          >
            <b class=""> {{ $t('hardware_check.btn_start_video_call') }}</b>
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <!--mobile-->
    <div v-if="$device.mobile && checkHardware">
      <v-row>
        <v-col cols="12">
          <VideoCall @handleHardwareAvailable="handleHardwareAvailable"/>
        </v-col>

        <v-col cols="12" class="text-center">
          <v-btn
            class="px-12 primary--text outline-border"
            height="60"
            rounded
            :disabled="isEnableStartAttendanceBtn"
            @click="goWaitingAttendance"
          >
            <b> {{ $t('hardware_check.btn_start_video_call') }}</b>
          </v-btn>
        </v-col>

        <v-col cols="12" class="px-16">
          <VideoCallTips/>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
  import VideoCallTips from '@/components/hardwareCheck/VideoCallTips.vue';
  import VideoCall from '@/components/hardwareCheck/VideoCall.vue';
  import {mapGetters, mapMutations} from 'vuex';

  export default {
    name: 'HardwareCheck',

    components: {VideoCall, VideoCallTips},

    data: () => ({
      isEnableStartAttendanceBtn: false,
      checkHardware: false
    }),

    created() {
      this.checkHasCaseAttendanceId()
    },

    methods: {
      ...mapMutations('data/attendance', [
        'setCaseAttendanceId',
      ]),

      ...mapMutations('data/general', [
        'setGeneralError',
        'setGeneralErrorRedirectRoute',
      ]),

      handleHardwareAvailable(isHardwareAvailable) {
        this.isEnableStartAttendanceBtn = isHardwareAvailable
      },

      checkHasCaseAttendanceId() {
        let caseAttendanceId = this.getCaseAttendanceId;

        if (!caseAttendanceId) {
          caseAttendanceId = localStorage.getItem('case_attendance_id');

          if (caseAttendanceId) {
            caseAttendanceId = this.setCaseAttendanceId(caseAttendanceId);
          }

          this.setGeneralError(true);
          this.setGeneralErrorRedirectRoute('home');
        }

        if (caseAttendanceId) {
          this.checkHardware = true;
        }
      },

      goWaitingAttendance() {
        this.$router.push('/attendance');
      },
    },

    computed: {
      ...mapGetters('data/attendance', {
        getCaseAttendanceId: 'getCaseAttendanceId',
      }),
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/styles/colors.scss';

  .hardwarecheck-view {
    background-color: $outerSpace;
    height: 100vh;
    max-height: 100vh;
    max-width: 100vw;
    width: 100vw;
    overflow: auto;
  }

  .title-hardwarecheck {
    font-size: 35px;
    font-weight: 700;
  }

  .subtitle-hardwarecheck {
    font-size: 25px;
    font-weight: 400;
  }

  @media(max-width: 959px) {
    .title-hardwarecheck {
    font-size: 20px;
    font-weight: 700;
    }
    .subtitle-hardwarecheck {
    font-size: 13px;
    font-weight: 400;
    }
  }
</style>