import axios from 'axios';
import consts from '@/static/js/consts';

export default {
  themesGet({commit}, params) {
    return new Promise((resolve, reject) => {
      axios.get(consts.ENDPOINTS.THEMES_GET, {
        params: params,
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        resolve(commit('setThemes', response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    });
  },
};
