<template>
  <v-container
    class="ma-0 pa-0"
  >
    <div
      v-if="$device.mobile"
      :class="[ isMobileBottomBar ? 'bottom-bar text-center py-3' : 'ma-5' ]"
      class="app-bar-nav"
    >
      <v-app-bar-nav-icon
        @click="openSideMenu"
        class="icon"
        variant="text"
      />
    </div>

    <v-navigation-drawer
      v-model="drawer"
      :hide-overlay="!$device.mobile"
      :permanent="!$device.mobile"
      class="side-menu px-11 py-14"
      fixed
      floating
    >
      <template
        v-slot:prepend
      >
        <div>
          <v-img
            :src="companyLogo || require('@/assets/logos/teladoc_health.svg')"
            contain
            class="logo"
            max-width="100%"
          />

          <v-btn
            v-if="$device.mobile"
            @click="closeSideMenu"
            class="close pa-0"
            icon
            text
          >
            <v-icon
              color="outerSpace"
            >
              fa-xmark
            </v-icon>
          </v-btn>
        </div>

        <v-list-item
          class="d-flex justify-center mt-11 mb-6"
        >
          <v-avatar
            height="117"
            width="117"
          >
            <v-img
              :src="
                profile && profile.image
                  ? profile.image
                  : require('@/assets/images/avatar.png')
              "
              class="rounded-circle"
            />
          </v-avatar>
        </v-list-item>

        <v-list-item
          two-line
        >
          <v-list-item-content
            class="text-center pa-0"
          >
            <v-list-item-title
              class="mb-2 text-capitalize"
            >
              {{ profile ? (profile.social_name || profile.name).toLowerCase() : '' }}
            </v-list-item-title>

            <v-list-item-subtitle
              class="d-flex justify-end"
            >
              <v-btn
                @click="$emit('handleLogout')"
                class="pa-0"
                text
              >
                <v-icon
                  color="secondary"
                  class="mr-2"
                  small
                >
                  fa-right-from-bracket
                </v-icon>

                {{ $t('general.exit') }}
              </v-btn>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-list
        class="mt-16"
        dense
      >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          @click="item.action"
          link
        >
          <v-list-item-icon
            class="mr-2"
          >
            <v-icon
              color="secondary"
              small
            >
             {{ item.icon }}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              :class="[ $route.name == item.name ? 'font-weight-bold' : 'font-weight-regular' ]"
            >
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div
        class="ma-5"
      >
       {{ portalWebVersion }}
      </div>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';

  export default {
    name: 'SideMenu',

    props: {
      // Function to handle logout button and action
      handleLogout: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },
    },

    data () {
      return {
        items: [],
        drawer: false,
      }
    },

    created() {
      this.drawer = !this.$device.mobile;

      this.items = [
        {
          action: () => {
            this.clearStore();
            if (this.$route.name !== 'home') {
              this.$router.push({path: '/'});
            }
            this.closeSideMenu();
          },
          icon: 'fa-house',
          name: 'home',
          title: this.$t('general.home'),
        },
        {
          action: () => {
            this.clearStore();
            if (this.$route.name !== 'attendances') {
              this.$router.push({path: '/attendances'});
            }
            this.closeSideMenu();
          },
          icon: 'fa-regular fa-calendar-days',
          name: 'attendances',
          title: this.$t('general.my_attendances'),
        },
        {
          action: () => {
            this.setPrivacyPolicyDialog(true);
          },
          icon: 'fa-regular fa-file-lines',
          name: 'privacy_policy',
          title: this.$t('general.privacy_policy'),
        },
        {
          action: () => {
            this.setConsentTermDialog(true);
          },
          icon: 'fa-regular fa-file-lines',
          name: 'consent_term',
          title: this.$t('general.consent_term'),
        },
        {
          action: () => {
            this.clearStore();
            if (this.$route.name !== 'faq') {
              this.$router.push({path: '/faq'});
            }
            this.closeSideMenu();
          },
          icon: 'fa-question',
          name: 'faq',
          title: this.$t('general.frequently_asked_questions'),
        },
        {
          action: () => {
            this.clearStore();
            if (this.$route.name !== 'profile') {
              this.$router.push({path: '/profile'});
            }
            this.closeSideMenu();
          },
          icon: 'fa-regular fa-user',
          name: 'profile',
          title: this.$t('general.my_profile'),
        },
      ];
    },

    methods: {
      ...mapMutations('data/general', [
        'setConsentTermDialog', // -> this.setConsentTermDialog
        'setPrivacyPolicyDialog', // -> this.setPrivacyPolicyDialog
      ]),

      ...mapMutations('data/schedule', {
        scheduleReset: 'reset', // -> this.scheduleReset
      }),

      openSideMenu() {
        this.drawer = true;
      },

      closeSideMenu() {
        if (!this.$device.mobile) return;

        this.drawer = false;
      },

      clearStore() {
        this.scheduleReset();
      },
    },

    computed: {
      ...mapGetters('data/patient', {
        profile: 'getProfile',  // -> this.profile
      }),

      ...mapGetters('data/general', {
        companyLogo: 'getCompanyLogo', // -> this.companyLogo
      }),

      isMobileBottomBar() {
        return this.$device.mobile && this.$route.name !== 'home';
      },

      portalWebVersion() {
        return `v${process.env.VUE_APP_VERSION}`;
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/styles/colors.scss';

  .side-menu {
    background-color: $antiFlash !important;
    max-width: 369px !important;
    width: 100% !important;
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px) and (max-device-width: 1024px) {
    .side-menu {
      max-width: 293px !important;
      padding: 40px 30px !important;
    }
  }
  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .app-bar-nav {
      display: block;
      position: absolute;
      left: 0;
      z-index: 3;

      &.bottom-bar {
        background-color: white;
        position: fixed;
        bottom: 0;
        box-shadow: 0 -5px 5px -5px $outerSpace;
        right: 0;
        width: 100%;
      }

      .icon {
        color: $primary !important;
        height: 30px;
        width: 30px;
      }
    }

    .side-menu {
      max-width: 355px !important;

      .close {
        position: fixed;
        right: 16px;
        top: 16px;
      }
    }
  }

  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
    .app-bar-nav {
      display: block;
      position: absolute;
      left: 0;
      z-index: 3;

      .icon {
        color: $primary !important;
        height: 30px;
        width: 30px;
      }
    }

    .side-menu {
      max-width: 355px !important;
    }
  }

  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {
    .app-bar-nav {
      display: block;
      position: absolute;
      left: 0;
      z-index: 3;

      .icon {
        color: $primary !important;
        height: 30px;
        width: 30px;
      }
    }

    .side-menu {
      max-width: 355px !important;
    }
  }
</style>