<template>
  <v-card
    class="login-container ma-o d-flex flex-column align-self-center justify-space-between"
    flat
    rounded="0"
  >
    <slot />
  </v-card>
</template>

<script>
  export default {
    name: 'LoginContainer',

    data: () => ({
      // 
    }),
  };
</script>

<style lang="scss" scoped>
  .login-container {
    background-color: rgba(255, 255, 255, 0.8);
    border: 3px solid #C4C4C4;
    height: 100%;
    max-height: 743px;
    max-width: 551px;
    padding: 62px 59px 34px 59px;
    width: 100%;
  }
   
  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    .login-container {
      height: 100%;
      max-height: 743px;
      max-width: 551px;
      padding: 62px 59px 34px 59px;
      width: 100%;
    }
  }
   
  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .login-container {
      height: calc(100vh - 36px);
      max-height: 722px;
      max-width: calc(100vw - 36px);
      padding: 45px 25px 30px 25px;
    }
  }
   
  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .login-container {
      height: calc(100vh - 36px);
      max-height: 722px;
      max-width: calc(100vw - 36px);
      padding: 45px 25px 30px 25px;
    }
  }
   
  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .login-container {
      height: calc(100vh - 36px);
      max-height: 722px;
      max-width: calc(100vw - 36px);
      padding: 45px 25px 30px 25px;
    }
  }
</style>
