export default {
  login: {
    consent_term: 'Termo de\n Consentimento',
    email: 'EMAIL',
    email_invalid: 'E-mail inválido.',
    email_placeholder: 'Digite seu endereço de email',
    forgot_password: 'Esqueci minha senha',
    login: 'Entrar',
    login_error: 'E-mail ou senha incorretos.',
    password: 'SENHA',
    password_placeholder: 'Digite sua senha',
    privacy_policy: 'Política de\n Privacidade',
    register: 'Cadastrar',
    enter_your_email: 'Digite seu e-mail cadastrado:',
    email_example: 'email@email.com.br',
    response_send_email_success: 'Você receberá um e-mail contendo um link para redefinição de senha.',
  }
};