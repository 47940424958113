export default {
  getAttendance: state => {
    return state.attendance;
  },

  getAttendanceAttachments: state => {
    return state.attendance_attachments;
  },

  getCompany: state => {
    return state.company;
  },

  getCompanyLogo: state => {
    return state.companyLogo;
  },

  getConsentTerm: state => {
    return state.consentTerm;
  },

  getFlavour: state => {
    return state.flavour;
  },

  getPrivacyPolicy: state => {
    return state.privacyPolicy;
  },

  getBannerIcon: state => {
    return state.bannerIcon;
  },

  getBannerTitle: state => {
    return state.bannerTitle;
  },

  getGeneralError: state => {
    return state.generalError;
  },

  getGeneralErrorRedirectRoute: state => {
    return state.generalErrorRedirectRoute;
  },

  getConsentTermDialog: state => {
    return state.consentTermDialog;
  },

  getPrivacyPolicyDialog: state => {
    return state.privacyPolicyDialog;
  },

  getShowSplashScreen: state => {
    return state.showSplashScreen;
  },

  getSocketInfo: state => {
    return state.socketInfo;
  },

  getDoctorsInfo: state => {
    return state.doctors_info;
  },

  getProgramsInfo: state => {
    return state.programs_info;
  },

  getHistoryAttendance: state => {
    return state.history_attendance;
  },
};