<template>
  <div
    class="attendances-view"
  >
    <div
      :class="[ $device.mobile ? 'px-6 py-8' : 'px-16 py-12' ]"
      class="content"
    >
      <!-- Header -->
      <div
        class="d-flex flex-column mb-8"
      >
        <h2
          :class="[ $device.mobile ? 'text-h6' : 'text-h5' ]"
          class="mb-2"
        >
          {{ $t('attendances.title') }}
        </h2>

        <span
          class="mb-2"
        >
          {{ $t('attendances.subtitle') }}
        </span>

        <v-btn-toggle
          v-model="toggle"
          class="button-group d-flex"
          color="antiFlash"
          borderless
          height="42"
          mandatory
          rounded
          width="343px"
        >
          <v-btn
            :color="toggle == 0 ? 'primary' : 'antiFlash'"
            :class="{ 'white--text': toggle == 0 }"
            class="button flex-grow-1"
            height="33"
            value="0"
            rounded
          >
            {{ $t('attendances.selectables.scheduled') }}
          </v-btn>

          <v-btn
            :color="toggle == 1 ? 'primary' : 'antiFlash'"
            :class="{ 'white--text': toggle == 1 }"
            class="button flex-grow-1"
            height="33"
            value="1"
            rounded
          >
            {{ $t('attendances.selectables.history') }}
          </v-btn>
        </v-btn-toggle>
      </div>

      <!-- Next Attendances -->
      <ListAttendances
        v-if="toggle == 0"
      />

      <!-- History -->
      <AttendancesHistory
        v-if="toggle == 1"
      />
    </div>

    <!-- Footer -->
    <HomeFooter/>
  </div>
</template>

<script>
import HomeFooter from '@/components/home/HomeFooter';
import ListAttendances from '@/components/attendances/ListAttendances';
import AttendancesHistory from '@/components/attendances/AttendancesHistory';
import {mapMutations} from "vuex";

export default {
  name: 'AttendancesView',

  components: {
    HomeFooter,
    ListAttendances,
    AttendancesHistory,
  },

  data: () => ({
    toggle: 0
  }),

  mounted() {
    this.setBannerTitle(this.$t('general.banner_my_attendances'));
    this.setBannerIcon('fa-calendar-days');
  },

  methods: {
    ...mapMutations('data/general', [
      'setBannerTitle', // -> this.setBannerTitle
      'setBannerIcon', // -> this.setBannerIcon
    ]),
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.attendances-view {
  .content {
    min-height: calc(100vh - 246px - 97px);

    .button-group {
      background-color: $antiFlash;
      border: 6px solid #F3F3F3;
      width: 330px;

      .button {
        border-radius: 50px;
      }
    }
  }
}

/* For Tablet View */
@media screen and (min-device-width: 641px) and (max-device-width: 1024px) {
  .attendances-view {
    .content {
      min-height: calc(100vh - 216px - 97px);
    }
  }
}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
  .attendances-view {
    padding-bottom: 54px;

    .content {
      min-height: calc(100vh - 129px - 76px);
    }
  }
}

/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  .attendances-view {
    padding-bottom: 54px;

    .content {
      min-height: calc(100vh - 129px - 76px);
    }
  }
}

/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {
  .attendances-view {
    padding-bottom: 54px;

    .content {
      min-height: calc(100vh - 129px - 76px);
    }
  }
}
</style>