<template>
  <v-container
    class="faq-view d-flex flex-column pa-0"
    max-width="100%"
  >
    <!-- Loader -->
    <v-card
      v-if="loading"
      class="content text-center ma-0 px-0 py-16"
      width="100%"
      flat
    >
      <v-progress-circular
        :size="$device.mobile ? 40 : 70"
        :width="$device.mobile ? 4 : 7"
        color="primary"
        indeterminate
      />
    </v-card>

    <v-card v-else>
      <div :class="[ $device.mobile ? 'pa-0' : 'pa-8' ]" class="content mb-10">
        <v-row v-if="!selected_option" :class="[ $device.mobile ? 'mr-1 ml-1' : 'mr-9 ml-9' ]">
          <v-col cols="12">
            <v-list :rounded="!$device.mobile" :class="!$device.mobile? '' : 'list'">
              <v-list-item-group
                v-model="selected_option"
              >
                <v-list-item
                  v-for="(faq, i) in getFaqs"
                  :key="i"
                  :value="faq"
                  class="list-item"
                  :class="!$device.mobile? 'mt-4' : ''"
                  dense>

                  <v-list-item-content>
                    <v-list-item-title style="white-space: pre-line">{{ faq.question }}</v-list-item-title>
                  </v-list-item-content>

                  <v-icon
                    size="15"
                    color="secondary"
                  >
                    fa-solid fa-chevron-right
                  </v-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <div v-if="!$device.mobile" class="mt-6 pl-8 did_not_find_answer">
              <p>{{ this.$t('faq.did_not_find_answer.part_1') }}
                <br>{{ this.$t('faq.did_not_find_answer.part_2') }}
                <span>{{ this.$t('faq.did_not_find_answer.part_3') }}</span>
              </p>
            </div>

            <div v-if="$device.mobile" class="mt-6 did_not_find_answer d-flex flex-column">
              <p>{{ this.$t('faq.did_not_find_answer.part_1') }}
                {{ this.$t('faq.did_not_find_answer.part_4') }}
              </p>

              <v-btn class="send-email-btn" rounded block prepend-icon="fa-envelope">
                {{ this.$t('faq.email') }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <FaqDetail
            v-if="selected_option"
            :faq="selected_option"
            @handleBack="returnListQuestion"
            @showModalHelp="show_modal_help=true"
        />
      </div>
    </v-card>
    <GeneralModal
      :dialog="show_modal_help"
      persistent
      width="500"
      has-cancel
      :header-title="$t('faq.modal_support.title')"
      :cancel-text="$t('faq.back')"
      @handleCancel="returnListQuestion"
    >
     <FaqHelp/>
    </GeneralModal>
    <!-- Footer -->
    <HomeFooter/>
  </v-container>
</template>

<script>
  import HomeFooter from '@/components/home/HomeFooter';
  import {mapActions, mapGetters, mapMutations} from 'vuex';
  import FaqDetail from '@/components/faq/FaqDetail';
  import GeneralModal from '@/components/general/GeneralModal';
  import FaqHelp from '@/components/faq/FaqHelp';

  export default {
    name: 'FaqView',
    components: {FaqHelp, GeneralModal, FaqDetail, HomeFooter},

    data: () => ({
      loading: false,
      selected_option: null,
      show_modal_help: false,
    }),

    created() {
      this.loading = true;
      this.init();
    },

    mounted() {
      this.setBannerTitle(this.$t('general.banner_faq'));
      this.setBannerIcon('fa-question');
    },

    methods: {
      ...mapActions('data/faq', {
        faqsGet: 'faqsGet',
      }),

      ...mapMutations('data/general', [
        'setBannerTitle',
        'setBannerIcon',
        'setGeneralError',
        'setGeneralErrorRedirectRoute',
      ]),

      init() {
        this.faqsGet({
          company_id: this.getCompanyId,
          group_id: this.getGroupId
        }).catch(error => {
          console.error(error);
          this.setGeneralError(true);
          this.setGeneralErrorRedirectRoute('home');
        }).finally(() => {
          this.loading = false;
        });
      },

      returnListQuestion() {
        this.selected_option = null;
        this.show_modal_help = false;
      },
    },

    computed: {
      ...mapGetters('data/login', {
        getCompanyId: 'getCompanyId',
        getGroupId: 'getGroupId'
      }),

      ...mapGetters('data/faq', {
        getFaqs: 'getFaqs'
      }),
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/styles/colors.scss';

  .faq-view {
    max-width: 100%;

    .content {
      font-size: 16px;
      padding: 1em !important;
      min-height: calc(100vh - 246px - 97px);
      margin-top: 2em;
    }

    /* For Tablet View */
    @media screen and (min-device-width: 641px) and (max-device-width: 1024px) {
      margin-top: 0;

      min-height: calc(100vh - 216px - 97px);
      flex-direction: column-reverse;
    }

    /* For Mobile Phones Portrait or Landscape View */
    @media screen and (max-device-width: 640px) {
      .list-item {
        font-size: 12px;
      }

      margin-top: 0;

      min-height: calc(100vh - 129px - 76px);

      /* For iPhone 5 Portrait or Landscape View */
      @media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
        min-height: calc(100vh - 129px - 76px);
      }

      /* For iPhone 6 and 6 plus Portrait or Landscape View */
      @media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {
        min-height: calc(100vh - 129px - 76px);
      }
    }
  }

  .did_not_find_answer {
    font-size: 15px;
    color: $outerSpace;

    span {
      font-weight: 700;
    }
  }

  .list-item {
    border: 1px solid;
    border-color: #D8D8D8;
  }

  .send-email-btn {
    background-color: $primary !important;
    color: $antiFlashWhite !important;
  }
   
  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    .faq-view {
      // 
    }
  }
   
  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .faq-view {
      padding-bottom: 54px !important;
    }
  }
   
  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .faq-view {
      padding-bottom: 54px !important;
    }
  }
   
  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .faq-view {
      padding-bottom: 54px !important;
    }
  }
</style>