<template>
    <v-container
            class="home-view d-flex flex-column pa-0"
            max-width="100%"
    >
        <div class="central-text mt-5" >
            <v-img
                height="170px"
                width="400px"
                :src="require('@/assets/general/success_doctors.png')"
                contain
            />
        </div>

        <div class="central-text mt-5">
            <h2 class="title"> {{ $t('general.success')}}! </h2>
        </div>

        <div class="central-text mb-10">
            <b>
                {{ $t('reschedule.success_message') }}
            </b>
        </div>

        <v-list
                :width="$device.mobile ? '100%' : '80%'"
                class="tips"
                flat
                dense
                disabled
                three-line
        >
            <v-list-item-group>
                <v-list-item
                    v-for="(item, i) in $t('reschedule.tips')"
                    :key="i"
                >
                    <v-list-item-icon>
                        <v-icon class="icon-color" v-text="'fa fa-circle'"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        {{item}}
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>

        <div class="central-text mt-10">
            <v-btn
                :min-width="$device.mobile ? 165 : 195"
                @click="backToStart"
                color="secondary"
                dark
                depressed
                height="46"
                max-width="208"
                rounded
            >
                {{ $t('general.back_to_start') }}
            </v-btn>
        </div>

        <HomeFooter />
    </v-container>
</template>

<script>
import HomeFooter from "@/components/home/HomeFooter.vue";
import {mapMutations} from "vuex";

export default {
    name: "AttendanceRescheduleSuccessView",
    components: {HomeFooter},
    mounted() {
        this.setBannerTitle(this.$t('reschedule.banner_title'))
        this.setBannerIcon(this.$t('reschedule.banner_icon'))
    },
    methods:{
        ...mapMutations('data/general', {
            setBannerTitle: 'setBannerTitle',
            setBannerIcon: 'setBannerIcon',
        }),
        backToStart() {
            this.$router.push({path: '/'});
        }
    }

}
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.tips {
  background-color: $antiFlashWhite;
  margin: 0 auto;
  padding: 0.5em 2em;
}

.icon-color{
  color: $secondary;
  font-size: 0.7em;
  margin-top: 2em;
}

.tips-icon{
  background-color: $secondary;
  width: 30px;
}
.central-text{
    padding: 12px;
    justify-content: center;
    display: flex;
    text-align: center;
}

.title{
    font-weight: 700;
    color: $primary;
    text-transform: uppercase;
}
</style>
