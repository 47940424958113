<template>
    <div class="general-error">
        <h3 class="text-center outerSpace--text mb-3">
            {{ $t('general.warning') }}
        </h3>
        <p class="text-center  outerSpace--text ma-0">
            {{ $t('attendance.end_attendance_warning') }}
        </p>
    </div>
</template>

<script>
export default {
    name: "EndAttendanceModal"
}
</script>

<style scoped>

</style>