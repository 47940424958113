export default {
  faq: {
    did_not_find_answer: {
      part_1: 'Não encontrou sua resposta?',
      part_2: 'Fale com a gente através do email:',
      part_3: 'suporte@teladochealth.com',
      part_4: 'Fale com a gente:'
    },
    email: 'E-mail',
    your_problem_is_solved: 'Seu problema doi resolvido?',
    yes: 'Sim',
    no: 'Não',
    we_are_happy_to_help: 'Ficamos felizes em ajudar :)',
    back: 'Voltar',
    modal_support: {
      title: 'Suporte 0800',
      phone_number: '0800 835 2362',
      sub_title: 'Ligue gratuitamente e tire suas dúvidas',
      sub_title_2: 'Segunda a Sexta - 09h:00 as 17h:00'
    },
  }
};