<template>
  <div
    class="list-attendances ma-0 pa-0"
  >
    <!-- Loader -->
    <div
      v-if="loading"
      class="d-flex justify-center"
    >
      <v-progress-circular
        :active="loading"
        indeterminate
        color="primary"
        size="28"
        width="2"
      />
    </div>

    <!-- Empty Calendar Image -->
    <div
      v-else-if="!attendancesList || !attendancesList.length "
      class="d-flex align-center flex-column"
    >
      <v-img
        :class="[ $device.mobile ? 'mt-0 mb-7 mx-auto' : 'ml-6 mr-0 my-0' ]"
        :height="117.86"
        :max-width="157.2"
        :width="157.2"
        :src="require('@/assets/general/calendar_not_found.svg')"
        class="image pa-0 my-10"
        contain
      />
      <span> {{ $t('attendances.no_itens_found') }} </span>
    </div>

    <!-- List table -->
    <div v-else>
      <v-data-table
        :headers="headers"
        :items="attendancesList"
        class="table spaced"
        hide-default-header
        hide-default-footer
      >
        <template v-slot:header="{ props }">
          <tr
            v-if="!$device.mobile"
            class="table-header"
          >
            <th
              v-for="(itemHeader, indexHeader) in props.headers"
              :key="indexHeader"
            >
              <div class="px-4 py-1">
                {{ itemHeader.title }}
              </div>
            </th>
          </tr>
        </template>

        <template v-slot:item="props">
          <tr
            :class="{ 'd-flex flex-column py-4': $device.mobile }"
            class="table-item"
          >
            <!-- Date -->
            <td
              :class="[ $device.mobile ? 'd-flex' : 'py-2']"
              class="td-item text-center"
            >
              <v-avatar
                :size="$device.mobile ? '42' : '58'"
                color="secondary"
                class="avatar d-flex flex-column"
              >
                <!-- Day -->
                <h5
                  class="day text-h5 pa-0 mb-0 mt-1 mx-0 font-weight-bold white--text"
                >
                  {{ convertUtcOffset(`${props.item.due_date.split(' ')[0]} ${props.item.due_time}`).date() }}
                </h5>

                <!-- Month -->
                <p
                  class="month pa-0 ma-0 white--text"
                >
                  {{ convertUtcOffset(`${props.item.due_date.split(' ')[0]} ${props.item.due_time}`).format('MMM') }}
                </p>
              </v-avatar>

              <div
                v-if="$device.mobile"
                class="d-flex flex-column pl-5"
              >
                <!-- Program -->
                <span
                  :class="{ 'font-weight-bold': $device.mobile }"
                  class="outerSpace--text"
                >
                  {{ props.item.program_translate }}
                </span>

                <!-- Professional -->
                <span
                  class="outerSpace--text"
                >
                  {{ getProfessionalName(props.item) }}
                </span>

                <!-- Patient -->
                <span
                  :class="{ 'text-capitalize': $device.mobile }"
                  class="outerSpace--text"
                >
                  {{ `${$t('attendances.headers.patient')}: ${getPatientName(props.item).toLowerCase()}` }}
                </span>

                <!-- Time -->
                <span
                  class="outerSpace--text text-capitalize"
                >
                  {{ `${$t('attendances.headers.scheduled_hour')}: ${convertUtcOffset(`${props.item.due_date.split(' ')[0]} ${props.item.due_time}`).format('HH:mm')}h` }}
                </span>
              </div>
            </td>

            <!-- Program -->
            <td
              v-show="!$device.mobile"
              class="td-item text-center py-2"
            >
              <span
                :class="{ 'font-weight-bold': $device.mobile }"
                class="outerSpace--text"
              >
                {{ props.item.program_translate }}
              </span>
            </td>

            <!-- Professional -->
            <td
              v-show="!$device.mobile"
              class="td-item text-center py-2"
            >
              <span
                class="outerSpace--text"
              >
                {{ getProfessionalName(props.item) }}
              </span>
            </td>

            <!-- Patient -->
            <td
              v-show="!$device.mobile"
              class="td-item text-center py-2"
            >
              <span
                class="outerSpace--text text-capitalize"
              >
                {{ getPatientName(props.item).toLowerCase() }}
              </span>
            </td>

            <!-- Time (Patient for Mobile) -->
            <td
              v-show="!$device.mobile"
              class="td-item text-center py-2"
            >
              <span
                :class="{ 'text-capitalize': $device.mobile }"
                class="outerSpace--text"
              >
                {{ `${convertUtcOffset(`${props.item.due_date.split(' ')[0]} ${props.item.due_time}`).format('HH:mm')}h` }}
              </span>
            </td>

            <!-- Action -->
            <td
              :class="[ $device.mobile ? `py-0` : 'py-2' ]"
              class="td-item text-center"
            >
              <v-divider
                v-if="$device.mobile"
                class="my-5"
              />

              <v-btn
                :width="$device.mobile ? '100%' : '150'"
                @click="attendanceDetails(props.item)"
                class="white--text font-btn"
                color="outerSpace"
                dark
                depressed
                height="39px"
                rounded
              >
                {{ $t("attendances.see") }}
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>

      <!-- Pagination -->
      <v-pagination
        v-if="pagination.pages > 1"
        v-model="pagination.currentPage"
        :length="pagination.pages"
        :total-visible="10"
        @input="changePage"
        circle
        class="mt-4"
        color="lightSilver"
      />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import helpers from '@/mixins/helpers';

  export default {
    name: 'ListAttendances',

    mixins: [helpers],

    data: () => ({
      attendancesList: [],
      headers: [],
      loading: false,
      pagination: {
        pages: 0,
        currentPage: 1,
      },
      patient: null,
      limit: 0,
      offset: 0,
    }),

    created() {
      this.headers = [
        {
          title: this.$t('attendances.headers.date'),
          align: 'center',
          key: 'date'
        },
        {
          title: this.$t('attendances.headers.specialty'),
          align: 'center',
          key: 'specialty'
        },
        {
          title: this.$t('attendances.headers.professional'),
          align: 'center',
          key: 'professional'
        },
        {
          title: this.$t('attendances.headers.patient'),
          align: 'center',
          key: 'patient'
        },
        {
          title: this.$t('attendances.headers.scheduled_hour'),
          align: 'center',
          key: 'scheduled_hour'
        },
        {
          title: this.$t('attendances.headers.action'),
          align: 'center',
          key: 'action'
        }
      ];

      this.limit = this.$device.mobile ? 2 : 8;

      this.patient = { ...this.profile };

      this.getAttendances();
    },

    methods:{
      ...mapActions('data/attendances', [
        'getMemberAttendances'
      ]),

      ...mapMutations('data/attendance', [
        'setCaseAttendanceId', // -> this.setCaseAttendanceId()
      ]),

      ...mapMutations('data/general', [
        'setAttendance', // -> this.setAttendance()
        'setAttendanceAttachments', // -> this.setAttendanceAttachments()
        'setGeneralError', // -> this.setGeneralError
        'setGeneralErrorRedirectRoute', // -> this.setGeneralErrorRedirectRoute
        'setProgramsInfo', // -> this.setProgramsInfo()
      ]),

      getAttendances(page = 1) {
        this.loading = true;

        this.offset = page == 1 ? 0 : (page - 1) * this.limit;

        let payload = {
          member_id: this.memberId,
          limit: this.limit,
          offset: this.offset,
        };

        this.getMemberAttendances(payload).then((response) => {
          this.attendancesList = response.attendances;

          this.pagination.currentPage = page;

          this.pagination.pages = response.pagination.total > 1
            ? Math.ceil(response.pagination.total / this.limit)
            : response.pagination.total;
        }).catch((error) => {
          console.error(error);
          this.setGeneralError(true);
          this.setGeneralErrorRedirectRoute('home');
        }).finally(() => {
          this.loading = false
        })
      },

      changePage() {
        this.getAttendances(this.pagination.currentPage);
      },

      attendanceDetails(attendance) {
        if (attendance.doctor_info) {
          attendance.doctor_crm = attendance.doctor_info.user_info.crm; // Code Smell: Missing information
          attendance.doctor_id = attendance.doctor_info.user_info.id;
          attendance.doctor_name = attendance.doctor_info.user_info.name;
        }

        attendance.member_id = attendance.attendance_patient_info.member_info.id;
        attendance.sla = `${attendance.due_date} ${attendance.due_time}`;
        attendance.program_name = attendance.program_info.name || attendance.program_info.product_name;

        this.setAttendanceAttachments(attendance.attachments);

        this.setProgramsInfo(attendance.program_info);

        this.setAttendance(attendance);

        this.$router.push('/attendances/details');
      },

      getPatientName(attendance) {
        let memberInfo = attendance?.attendance_patient_info?.member_info;

        if (memberInfo) {
          return memberInfo.social_name
            ? memberInfo.social_name.toLowerCase()
            : (memberInfo.name || memberInfo.nome).toLowerCase();
        } else {
          return (this.patient.social_name || this.patient.name).toLowerCase();
        }
      },

      getProfessionalName(item) {
        if (!item || !item.doctor_info || !item.doctor_info.user_info) return '';

        let programList = [48, 57]; // "Psicologia" and "Psicologia por Vídeo"
        if (programList.includes(item.program_id)) {
          return item?.doctor_info?.user_info?.name??'';
        }

        return item.doctor_info.user_info.crm
          ? `${this.$t('general.dr_a')} ${item?.doctor_info?.user_info?.name??''}`
          : item?.doctor_info?.user_info?.name??'';
      },
    },

    computed: {
      ...mapGetters('data/general', {
        programsInfo: 'getProgramsInfo', // -> this.programsInfo
      }),

      ...mapGetters('data/login', {
        memberId: 'getMemberId', // -> this.memberId
      }),

      ...mapGetters('data/patient', {
        dependents: 'getDependents', // this.dependents
        profile: 'getProfile', // -> this.profile
      }),
    },
  }
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

  .list-attendances {
    max-width: 1233px;
    width: auto;

    .avatar {
      border: $antiFlash solid 1px !important;
      border-radius: 50px !important;
    }

    .font-btn {
      font-weight: 400;
      font-size: 17px;
      line-height: 20px;
    }

    .table-header {
      font-weight: 700;
      font-size: 10px;
      line-height: 12px;
      background-color: $outerSpace !important;
      text-transform: uppercase !important;
      color: white !important;
    }

    .table-item {
      &:first-child {
        padding-top: 0 !important;
      }

      &:last-child {
        padding-bottom: 0 !important;
      }

      .td-item {
        border-top: 1px solid $lightSilver !important;
        border-bottom: 1px solid $lightSilver !important;

        &:first-child {
          border-left: 1px solid $lightSilver !important;
        }

        &:last-child {
          border-right: 1px solid $lightSilver !important;
        }
      }
    }

    .day {
      font-weight: 700;
      font-size: 22px;
      line-height: 26px;
    }

    .month {
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
    }
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    .list-attendances {
      // 
    }
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .list-attendances {
      max-width: auto;

      .table {
        border: 1px solid $lightSilver !important;

        .table-item {
          position: relative;
          border-bottom: 1px solid $lightSilver !important;

          .avatar {
            border-radius: 0 !important;
            height: inherit !important;
          }

          &:last-child {
            border-bottom: none !important;
          }

          .td-item {
            border: none !important;
            height: auto;
            text-align: left !important;
            border-top: none !important;
            border-bottom: none !important;

            &:first-child {
              border-left: none !important;
            }

            &:last-child {
              border-right: none !important;
            }
          }
        }
      }
    }
  }

  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .list-attendances {
      // 
    }
  }

  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .list-attendances {
      // 
    }
  }

</style>