const consts = {
  ENDPOINTS: {
    GET_TERMS: `${process.env.VUE_APP_API_URL}/terms/get`,
    GET_PRIVACY_POLICY: `${process.env.VUE_APP_API_URL}/privacyPolicy/get`,
    GET_MEMBER_ATTENDANCES: `${process.env.VUE_APP_VANELLOPE_URL}/member/attendances`,
    USER_CHECK_EXISTENCE: `${process.env.VUE_APP_API_URL}/user/check/existence`,
    USER_DATA_CHECK: `${process.env.VUE_APP_API_URL}/User/data/check`,
    MEMBER_DATA_CHECK: `${process.env.VUE_APP_API_URL}/member/data/check`,
    PATIENT_FORGOT_PASSWORD: `${process.env.VUE_APP_API_URL}/patient/forgot/password`,
    PATIENT_AUTHORIZE: `${process.env.VUE_APP_API_URL}/patient/authorize`,
    PATIENT_CONFIRM_SMS: `${process.env.VUE_APP_API_URL}/patient/confirm/sms`,
    PATIENT_RESEND_SMS: `${process.env.VUE_APP_API_URL}/patient/resend/sms`,
    PATIENT_PROFILE: `${process.env.VUE_APP_API_URL}/patient/profile`,
    ONBOARDING_PATIENT_SIGNUP: `${process.env.VUE_APP_API_URL}/onboarding/patient/signup`,
    PATIENT_GROUP: `${process.env.VUE_APP_API_URL}/patient/group`,
    COMPANY_GET: `${process.env.VUE_APP_API_URL}/Company/get`,
    FLAVOUR_SETTINGS_GET: `${process.env.VUE_APP_API_URL}/flavour/settings/get`,
    PATIENT_COMPANY_DEPENDENTS: `${process.env.VUE_APP_API_URL}/patient/company/dependents`,
    PROGRAM_GET_DETAILS: `${process.env.VUE_APP_API_URL}/program/get/details`,
    RATING_QUESTIONS: `${process.env.VUE_APP_API_URL}/rating/questions`,
    SEND_RATING_QUESTIONS: `${process.env.VUE_APP_API_URL}/case/attendace/feedback`,
    PATIENT_EMAIL_VERIFY: `${process.env.VUE_APP_API_URL}/patient/email/verify`,
    MEMBER_UPDATE: `${process.env.VUE_APP_API_URL}/member/update`,
    PATIENT_RESEND_EMAIL: `${process.env.VUE_APP_API_URL}/patient/resend/email`,
    PATIENT_RESET_PASSWORD: `${process.env.VUE_APP_API_URL}/patient/reset/password`,
    STREAM_PATIENT_GET: `${process.env.VUE_APP_API_URL}/stream/patient/get`,
    PATIENT_ATTENDANCES: `${process.env.VUE_APP_API_URL}/patient/attendances`,
    USER_GET: `${process.env.VUE_APP_API_URL}/User/get`,
    SOCKET_GET_INFO: `${process.env.VUE_APP_API_URL}/socket/get/info`,
    ATTENDANCE_SAVE_ATTACHMENT: `${process.env.VUE_APP_API_URL}/case-attendance-attachment-member/saveAttachment`,
    ATTENDANCE_DELETE_ATTACHMENT: `${process.env.VUE_APP_API_URL}/case-attendance-attachment-member/deleteAttachment`,
    ATTENDANCE_LIST_ATTACHMENT: `${process.env.VUE_APP_API_URL}/case-attendance-attachment-member/listAttachment`,
    CASE_ATTENDANCE_ADD: `${process.env.VUE_APP_API_URL}/case/attendance/add`,
    PROGRAM_ATTENDANCE_ADD: `${process.env.VUE_APP_API_URL}/program/attendance/add`,
    CASE_CREATE: `${process.env.VUE_APP_API_URL}/case/create`,
    ASSET_CREATE: `${process.env.VUE_APP_API_URL}/asset/create`,
    CASE_ATTENDANCE_CANCEL_REASONS: `${process.env.VUE_APP_API_URL}/user/schedule/cancel/reason`,
    PARTNER_ATTENDANCE_CANCEL: `${process.env.VUE_APP_API_URL}/partner/attendance/cancel`,
    PROGRAM_GET_INFO: `${process.env.VUE_APP_API_URL}/Program/get/info`,
    CHECK_LAST_ATTENDANCE: `${process.env.VUE_APP_VANELLOPE_URL}/member/last/attendance`,
    CHECK_DOUBLE_BOOKING: `${process.env.VUE_APP_API_URL}/check/doublebooking`,
    SCHEDULE_SLOTS: `${process.env.VUE_APP_API_URL}/schedule/slots`,
    UPLOAD_FILE: `${process.env.VUE_APP_API_URL}/asset/create`,
    CASE_ATTENDANCES_HISTORY: `${process.env.VUE_APP_API_URL}/case/attendances/history`,
    FAQS_GET: `${process.env.VUE_APP_API_URL}/faqs/get`,
    MEMBER_ATTENDANCES: `${process.env.VUE_APP_VANELLOPE_URL}/member/attendances`,
    CASE_ATTENDANCE_RESCHEDULE: `${process.env.VUE_APP_API_URL}/case/attendance/reSchedule`,
    VERIFY_SCHEDULE:`${process.env.VUE_APP_API_URL}/schedules/verify/scheduler`,
    QUEUE_STATUS_CHANGE: `${process.env.VUE_APP_API_URL}/queue/status/change`,
    THEMES_GET: `${process.env.VUE_APP_API_URL}/themes`,
  },

  QUEUE_ATTENDANCE_STATUS: {
    OPENED: 1,
    TRANSFERRED_CASE: 2,
    RECEIVED_TRANSFERRED_CASE: 3,
    ATTENDED: 4,
    ENDED: 5,
    REOPENED: 6,
    CREATED_NEW_CASE: 7,
    EMERGENCY: 8,
    ASO: 9,
    B2C_OMV: 10,
    B2C_OMA: 11,
    ATTENDING: 12,
    RESERVED: 13,
    PATIENT_CANCELED: 14,
    ATTENDANT_CANCELED: 15,
    DOCTOR_PENDING: 22,
    SCHEDULE_PENDING: 24,
    PROCESSING_PAYMENT: 25,
  },

  DEFAULT_COLORS: {
    PRIMARY: '#5949A7',
    SECONDARY: '#00B7E6',
  },

  DEFAULT_STATUS: [
    'OPENED',
    'ATTENDANT_CANCELED',
    'PATIENT_CANCELED',
    'CLEARED',
    'ATTENDED',
    'DOCTOR_PENDING',
    'SCHEDULED_PENDING',
    'ATTENDING',
    'ENDED'
  ],

  PSYCHOLOGY_PROGRAMS_IDS: [
    57, // Psicologia
    67, // OPT
  ],

  PROGRAM_WELL_BEING_IDS: [
    4, // Esportivo
    5, // Nutrição
    57, // Psicologia
    10, // Orientação por telefone
    53, // Orientação por telefone
    54  // Orientação por telefone
  ],
};

for (const x in consts) {
  consts[x] = Object.freeze(consts[x]);
}

export default consts;
export const namespaced = true;