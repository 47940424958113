<template>
  <v-container
    class="home-view d-flex flex-column pa-0"
    max-width="100%"
  >
    <!-- Loader -->
    <v-card
      v-if="loading"
      class="content text-center ma-0 px-0 py-16"
      width="100%"
      flat
    >
      <v-progress-circular
        :size="$device.mobile ? 40 : 70"
        :width="$device.mobile ? 4 : 7"
        color="primary"
        indeterminate
      />
    </v-card>

    <!-- Attendances Carousel -->
    <AttendancesCarousel
      v-if="!loading && !$device.mobile"
    />

    <!-- Home Components -->
    <v-card
      v-if="!loading"
      :class="[ $device.mobile ? 'pa-0' : 'pa-8' ]"
      class="content ma-0"
      width="100%"
      flat
    >
      <div
        :class="[ $device.mobile ? 'flex-column-reverse' : 'flex-row' ]"
        class="components d-flex"
      >
        <!-- Primary buttons -->
        <HomeButtons
          :class="{ 'px-8': $device.mobile }"
        />

        <!-- Attendances Carousel -->
        <AttendancesCarousel
          v-if="!loading && $device.mobile"
          class="mb-12"
        />

        <!-- Doctors Image -->
        <v-img
          :class="[ $device.mobile ? 'mt-0 mb-7 mx-auto' : 'ml-6 mr-0 my-0' ]"
          :height="$device.mobile ? 216 : 378"
          :max-width="$device.mobile ? 282 : 492"
          :width="$device.mobile ? 282 : '100%'"
          :src="require('@/assets/general/home_doctors.svg')"
          class="image pa-0"
          contain
        />

        <!-- Logo Image -->
        <v-img
          v-if="$device.mobile"
          :src="companyLogo || require('@/assets/logos/teladoc_health.svg')"
          class="logo pa-0 mx-auto my-9"
          contain
          height="65"
        />

        <!-- Mobile logo background -->
        <div
          v-if="$device.mobile"
          class="background ma-0 pa-0 antiFlashWhite"
        />
      </div>

      <!-- Footer -->
      <div
        class="text-center"
      >
        <h5
          class="title text-h5 outerSpace--text"
        >
          {{ $t(`home.virtual_medical_care${$device.mobile ? '_mobile' : ''}`) }}
        </h5>

        <h5
          class="subtitle text-body-2 my-2 px-5 outerSpace--text"
        >
          {{ $t('home.virtual_medical_care_body') }}
        </h5>

        <!-- Store buttons -->
        <StoreButtons/>
      </div>

    </v-card>

    <!-- Footer -->
    <HomeFooter/>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import AttendancesCarousel from '@/components/home/AttendancesCarousel';
import HomeButtons from '@/components/home/HomeButtons';
import HomeFooter from '@/components/home/HomeFooter';
import StoreButtons from '@/components/home/StoreButtons';

export default {
  name: 'HomeView',

  components: {
    AttendancesCarousel,
    HomeButtons,
    HomeFooter,
    StoreButtons
  },

  data: () => ({
    loading: false,
  }),

  created() {
    if (!this.memberId) {
      this.setMemberId(localStorage.getItem('member_id'));
    }

    // Get patient profile if needed
    if (!this.profile) {
      this.getPatientProfile();
    }
    this.getPrograms();
  },

  mounted() {
    this.scheduleReset();
    this.setBannerTitle(null);
    this.setBannerIcon(null);
  },

  methods: {
    ...mapActions('data/patient', [
      'patientProfile',
    ]),

    ...mapActions('data/schedule', [
      'programGetDetails',
    ]),

    ...mapMutations('data/general', [
      'setGeneralError',
      'setBannerTitle',
      'setBannerIcon',
    ]),

    ...mapMutations('data/login', [
      'setMemberId',
    ]),

    ...mapMutations('data/schedule', {
      scheduleReset: 'reset',
    }),

    getPatientProfile() {
      this.loading = true;

      this.patientProfile(this.memberId).catch((error) => {
        console.error(error);
        // User already in Home Screen, this error should log out. Do NOT send route to 'setGeneralError'
        this.setGeneralError(true);
      }).finally(() => {
        this.loading = false;
      });
    },

    getPrograms() {
      if (this.company_id && this.group_id) {
        let payload = {
          company_id: this.company_id,
          group_id: this.group_id
        };
        if (!payload) {
          return;
        }
        this.loading = true;
        this.programGetDetails(payload)
        .catch((error) => {
          console.error(error);
          // User already in Home Screen, this error should log out. Do NOT send route to 'setGeneralError'
          this.setGeneralError(true);
        }).finally(() => {
          this.loading = false;
        });
      }
    }
  },

  computed: {
    ...mapGetters('data/login', {
      memberId: 'getMemberId',
      company_id: 'getCompanyId',
      group_id: 'getGroupId',
    }),

    ...mapGetters('data/patient', {
      profile: 'getProfile',
    }),

    ...mapGetters('data/schedule', {
      programInfo: 'getProgram',
    }),

    ...mapGetters('data/general', {
      companyLogo: 'getCompanyLogo',
    }),
  },
};
</script>

<style lang="scss" scoped>
.home-view {
  max-width: 100%;

  .content {
    min-height: calc(100vh - 246px - 97px);
    max-width: 1500px;
    padding-bottom: 107px !important;
    padding-top: 74px !important;

    .image {
      margin-top: 80px !important;
      z-index: 2;
    }

    .logo {
      z-index: 2;
    }

    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 268px;
    }

    .title {
      font-size: 40px !important;
    }

    .subtitle {
      font-size: 20px !important;
      margin: 40px auto 62px auto !important;
    }
  }
}

/* For Tablet View */
@media screen and (min-device-width: 641px) and (max-device-width: 1024px) {
  .home-view {
    .content {
      min-height: calc(100vh - 216px - 97px);
      padding-bottom: 85px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      padding-top: 74px !important;

      .image {
        margin: 0 auto 56px auto !important;
      }

      .components {
        flex-direction: column-reverse !important;
      }

      .title {
        font-size: 30px !important;
      }

      .subtitle {
        font-size: 17px !important;
        margin: 30px auto 40px auto !important;
      }
    }
  }
}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
  .home-view {
    .content {
      min-height: calc(100vh - 129px - 76px);
      padding-bottom: 38px !important;
      padding-top: 0 !important;

      .image {
        margin-top: 1px !important;
      }

      .title {
        font-size: 23px !important;
        white-space: pre-line;
      }

      .subtitle {
        font-size: 15px !important;
        margin: 27px auto 33px auto !important;
      }
    }
  }
}

/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  .home-view {
    .content {
      min-height: calc(100vh - 129px - 76px);
      padding-bottom: 38px !important;
      padding-top: 0 !important;

      .image {
        margin-top: 1px !important;
      }

      .title {
        font-size: 23px !important;
        white-space: pre-line;
      }

      .subtitle {
        font-size: 15px !important;
        margin: 27px auto 33px auto !important;
      }
    }
  }
}

/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {
  .home-view {
    .content {
      min-height: calc(100vh - 129px - 76px);
      padding-bottom: 38px !important;
      padding-top: 0 !important;

      .image {
        margin-top: 1px !important;
      }

      .title {
        font-size: 23px !important;
        white-space: pre-line;
      }

      .subtitle {
        font-size: 15px !important;
        margin: 27px auto 33px auto !important;
      }
    }
  }
}
</style>
