<template>
  <div class="schedule-form-immediate">
    <p class="text-left pt-4 spanishGray--text">
      {{ $t('schedule.title_immediate_care.part_1') }}

      <span class="secondary--text"> {{ $t('schedule.title_immediate_care.part_2') }}</span>

      {{ $t('schedule.title_immediate_care.part_3') }}
    </p>

    <div class="d-flex" :class="[ $device.mobile ? 'justify-center' : 'justify-start']">
      <v-btn
        color="outerSpace"
        dark
        depressed
        rounded
        large
        shaped
        :class="[ $device.mobile ? 'col-6' : 'col-3']"
        class="mt-5"
        @click="checkExistAttendance"
      >
        {{ $t('general.next') }}
      </v-btn>
    </div>

    <GeneralModal
      :dialog="showInfoExistAttendance"
      persistent
      width="540"
      :alert="showInfoExistAttendance"
      :has-confirm="showInfoExistAttendance"
      :confirm-text="$t('general.ok_got_it')"
      @handleConfirm="handleConfirmDialogExistAttendance"
    >
      <h2 class="text-center">
        {{ $t('general.warning') }}
      </h2>

      <p class="p-exist-attendance text-left pt-4">
        {{ $t('schedule.warning_exist_immediate_attendance.part_1') }}
      </p>

      <p class="p-exist-attendance text-left pt-4">
        {{ $t('schedule.warning_exist_immediate_attendance.part_2') }}
        <strong> {{ $t('schedule.warning_exist_immediate_attendance.part_3') }}</strong>
      </p>
    </GeneralModal>

    <ScheduleUploadFileModal
      :dialog="showUploadFile"
      @handleClose="showUploadFile=false"
    />
  </div>
</template>
<script>
  import {mapGetters, mapMutations} from "vuex";
  import GeneralModal from "@/components/general/GeneralModal.vue";
  import ScheduleUploadFileModal from "@/components/schedule/ScheduleUploadFileModal.vue";

  export default {
    name: "ScheduleFormImmediate",
    components: {ScheduleUploadFileModal, GeneralModal},
    data: () => ({
      showInfoExistAttendance: false,
      showUploadFile: false
    }),

    created() {
      //
    },

    methods: {
      ...mapMutations('data/schedule', {
        setDatetime: 'setDatetime'
      }),

      ...mapGetters('data/attendance', {
        getPatientLastAttendance: 'getPatientLastAttendance',
      }),

      checkExistAttendance() {
        const lastAttendance = this.getPatientLastAttendance();
        if (lastAttendance) {
          const programsImmediate = lastAttendance.attendances.programs_immediate;
          if (programsImmediate.length) {
            this.openInfoExistAttendance();
          } else {
            this.resetSelectScheduleDateAndOpenUploadFile();
          }
        } else {
          this.resetSelectScheduleDateAndOpenUploadFile();
        }
      },

      resetSelectScheduleDateAndOpenUploadFile() {
        this.setDatetime(null);
        if (this.files?.length < 5) {
          this.openUploadFile();
        } else {
          this.$router.push('/schedule/details');
        }
      },

      openInfoExistAttendance() {
        this.showInfoExistAttendance = true;
      },

      handleConfirmDialogExistAttendance() {
        this.showInfoExistAttendance = false;

        const {program_info = [], program_well_being = []} = this.programsInfo.data;

        if (program_info.length + program_well_being.length === 1) {
          this.$router.push('/');
        } else {
          this.$router.push('/programs');
        }
      },

      openUploadFile() {
        this.showUploadFile = true;
      }
    },

    computed: {
      ...mapGetters('data/schedule', {
        programsInfo: 'getPrograms', // -> this.programInfo
        files: 'getFiles',
      }),
    }
  }
</script>

<style lang="scss" scoped>
  .schedule-form-immediate {
    // 
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px) and (max-device-width: 1024px) {
    .schedule-form-immediate {
      // 
    }
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .schedule-form-immediate {
      // 
    }
  }

  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
    .schedule-form-immediate {
      // 
    }
  }

  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {
    .schedule-form-immediate {
      // 
    }
  }
</style>
