<template>
    <v-row
      :class="[ $device.mobile ? 'mr-1 ml-1' : 'mr-9 ml-9' ]"
      class="text-center"
    >
      <v-col
        cols="12"
      >
        <v-img
          :src="require('@/assets/general/phone_code_success.svg')"
          contain
          width="500"
        />
      </v-col>

      <v-col
        cols="12"
      >
        <h1
          class="text-color"
        >
          <v-icon
            color="secondary"
          >
            mdi-phone
          </v-icon>

          {{ $t('schedule.modal_support_sac.phone_number') }}
        </h1>
      </v-col>

      <v-col
        cols="12"
      >
        <h4
          class="text-color"
        >
          {{ $t('schedule.modal_support_sac.sub_title') }}
        </h4>

        <h5 class="text-color">
          {{ $t('schedule.modal_support_sac.sub_title_2') }}
        </h5>
      </v-col>
    </v-row>
</template>

<script>

export default {
  props: {
    faq: {
      type: Object,
      default: null
    },
  },
}
</script>

<style lang="scss" scoped>
  .text-color {
    color: #444444;
    font-family: 'Roboto';
    font-style: normal;
    line-height: 23px;
  }
</style>