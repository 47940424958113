<template>
  <v-container
    class="psychology-program-selection ma-auto my-0 pa-0"
  >
    <!-- Psychology Programs Selection -->
    <div
      v-if="
        (!selectedProgram && (filteredPrograms && filteredPrograms.length > 1))
        || selectedProgram && selectedProgram.program_id !== optProgramId
      "
    >
      <h2 class="text-center mb-4 outerSpace--text">
        {{ $t('schedule.title_when_do_you_want_to_be_served') }}
      </h2>

      <!-- Psychology programs list -->
      <v-list
        class="d-flex flex-wrap gap pa-0"
        rounded
      >
        <v-list-item
          v-for="(item, index) in filteredPrograms"
          v-show="!selectedProgram || (selectedProgram && item.program_id == selectedProgram.program_id)"
          :key="index"
          :value="item.id"
          :class="{
            'v-item-inactive-dark': getProgramSchedule.includes(item.program_id)
          }"
          @click="scheduleAttendance(item)"
          class="v-list-item my-2"
        >
          <v-list-item-avatar>
            <v-icon
              color="secondary"
            >
              {{ item.program_id == optProgramId ? 'fa-phone-flip' : 'fa-calendar-days' }}
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              {{
                item.program_id == optProgramId
                  ? $t('schedule.psychology_emergency')
                  : $t('schedule.schedule_appointment')
              }}
            </v-list-item-title>
          </v-list-item-content>

          <v-icon size="15" color="secondary">
            fa-solid fa-chevron-right
          </v-icon>
        </v-list-item>
      </v-list>
    </div>

    <!-- OPT Only -->
    <div
      v-if="
        (filteredPrograms && filteredPrograms.length == 1)
        && filteredPrograms.find(o => o.program_id == optProgramId)
      "
    >
      <h2
        class="text-center outerSpace--text"
      >
        {{ $t('schedule.psychology_emergency') }}
      </h2>

      <div
        :class="[ $device.mobile ? 'my-12' : 'mt-8 mb-2' ]"
        class="d-flex align-center"
      >
        <v-img
          :max-height="$device.mobile ? 105 : 127"
          :max-width="$device.mobile ? 67 : 82"
          :src="require('@/assets/icons/success_attendance.svg')"
          contain
        />

        <h3
          :class="[ $device.mobile || filteredPrograms[0].company_program_phone.length > 13 ? 'text-h4' : 'text-h3' ]"
          class="font-weight-bold text-end text-h3 outerSpace--text flex-grow-1"
        >
          {{ filteredPrograms[0].company_program_phone }}

          <p
            class="text-subtitle-1 font-weight-normal ma-0"
          >
            {{ $t('schedule.call_freely') }}
          </p>
        </h3>
      </div>
    </div>

    <!-- Program selected -->
    <div
      v-if="selectedProgram"
    >
      <!-- OPT selected -->
      <div
        v-if="selectedProgram.program_id == optProgramId"
        class="align-center d-flex flex-column"
      >
        <v-img
          :src="require('@/assets/general/phone_code_success.svg')"
          contain
        />
        
        <h3
          :class="[ $device.mobile ? 'text-h4 font-weight-bold' : 'text-h3' ]"
          class="d-flex mb-4 outerSpace--text"
        >
          <v-icon
            :large="!$device.mobile"
            class="mr-2"
            color="secondary"
          >
            fa-phone
          </v-icon>

          {{ selectedProgram.company_program_phone }}
        </h3>

        <span
          :class="[ $device.mobile ? 'mt-4 mb-2' : 'my-4' ]"
          class="text-h5"
        >
          {{ $t('schedule.call_freely') }}
        </span>

        <span
          class="text-subtitle-1"
        >
          {{ $t('schedule.everyday_time') }}
        </span>

        <v-btn
          @click="resetSelectedProgram"
          :height="$device.mobile ? 40 : 46"
          :width="$device.mobile ? 180 : 208"
          class="mt-8"
          color="outerSpace"
          outlined
          rounded
        >
          {{ $t('general.back') }}
        </v-btn>
      </div>

      <!-- Other Psychology selected -->
      <div
        v-else
        class="align-center d-flex flex-column"
      >
        <h3
          :class="{ 'mt-5': $device.mobile }"
          class="mb-3 error--text"
        >
          {{ $t('general.warning') }}
        </h3>

        <span
          :class="[ $device.mobile ? 'text-caption' : 'text-subtitle-1' ]"
        >
          {{ message || $t('general.psychology_schedule_full') }}
        </span>

        <v-btn
          @click="resetSelectedProgram"
          :height="$device.mobile ? 40 : 46"
          :width="$device.mobile ? 180 : 208"
          class="mt-8"
          color="outerSpace"
          dark
          rounded
        >
          {{ $t('general.ok_got_it') }}
        </v-btn>
      </div>
    </div>

    <!-- Warnings list -->
    <v-alert
      v-if="!selectedProgram"
      :class="[ $device.mobile ? 'pl-4 pr-2 py-2' : 'pa-4' ]"
      outlined
      color="outerSpace"
      class="d-flex flex-column mt-8"
    >
      <div
        class="d-flex flex-row"
      >
        <v-icon
          color="primary"
          class="mr-4"
        >
          fa-arrow-right
        </v-icon>

        <span
          :class="{ 'text-caption': $device.mobile }"
        >
          {{ $t('schedule.attendance_time') }}:

          <span
            class="font-weight-bold"
          >
            {{ $t('schedule.warning_psychology.part_1') }}
          </span>
        </span>
      </div>

      <v-divider
        :class="[ $device.mobile ? 'my-2' : 'my-4' ]"
      />

      <div
        class="d-flex flex-row"
      >
        <v-icon
          color="primary"
          class="mr-4"
        >
          fa-arrow-right
        </v-icon>

        <span
          :class="{ 'text-caption': $device.mobile }"
        >
          {{ $t('schedule.warning_psychology.part_2') }}
        </span>
      </div>

      <v-divider
        :class="[ $device.mobile ? 'my-2' : 'my-4' ]"
      />

      <div
        class="d-flex flex-row"
      >
        <v-icon
          color="primary"
          class="mr-4"
        >
          fa-arrow-right
        </v-icon>

        <span
          :class="{ 'text-caption': $device.mobile }"
        >
          {{ $t('schedule.warning_psychology.part_3') }}
        </span>
      </div>
    </v-alert>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import consts from '@/static/js/consts';

  export default {
    name: 'PsychologyProgramSelection',

    props: {
      dialog: {
        type: Boolean,
        required: true,
        default: false
      },
    },

    data: () => ({
      selectedProgram: null,
      message: null,
      optProgramId: 67,
      programId: null,
      psychologyProgramIds: [],
      notAvailableWarning: false,
      warningList: [],
    }),

    created() {
      // Get patient profile if needed
      if (!this.profile) {
        this.getPatientProfile();
      }

      this.psychologyProgramIds = [...consts.PSYCHOLOGY_PROGRAMS_IDS];
      const {program_well_being = []} = this.programsInfo.data;

      this.programSchedule = this.getProgramsScheduled
        .filter(({member_id}) => member_id == this.profile.id)
        .filter(({program_id}) => this.psychologyProgramIds.includes(program_id))
        .filter(item => {
          let program = program_well_being.find(o => o.program_id == item.program_id);

          return (program)
            ? item.count_scheduled >= +program.attendances_qty
            : false;
        })
        .map(({program_id}) => program_id);
    },

    methods: {
      ...mapActions('data/patient', [
        'patientProfile'
      ]),

      ...mapMutations('data/schedule', [
        'setProgram',
        'resetFiles',
      ]),

      getPatientProfile() {
        this.loading = true;

        this.patientProfile(this.member_id).catch((error) => {
          console.error(error);
          this.setGeneralError(true);
          this.setGeneralErrorRedirectRoute('home');
        }).finally(() => {
          this.loading = false;
        });
      },

      resetSelectedProgram() {
        this.message = null;
        this.selectedProgram = null;
      },

      scheduleAttendance(data) {
        this.programId = data.program_id;

        // OPT selected or other psychology program is fully scheduled
        if (this.programId == this.optProgramId || this.getProgramSchedule.includes(this.programId)) {
          return this.selectedProgram = data;
        }

        let hasWarningType = Object.keys(this.warningTypes).indexOf(this.programId.toString());

        // Has Warnings
        if (hasWarningType !== -1) {
          if (this.restrictedProgramsAge.includes(this.programId) && this.profile.age < 14) {
            this.message = this.$t(this.warningTypes[this.programId]);
            return this.selectedProgram = data;
          }

          if (this.warningPrograms.includes(this.programId)) {
            this.message = this.$t(this.warningTypes[this.programId]);
            return this.selectedProgram = data;
          }
        }

        // Can Schedule
        this.setProgram({
          name: data.translate,
          id: data.program_id,
          occupation_id: data.occupation_id,
          speciality_id: data.speciality_id,
          attendances_qty: data.attendances_qty,
          attendance_interval: data.attendance_interval,
          days_ahead: data.days_ahead,
          is_program_well_being: Boolean(this.programsInfo.data.program_well_being.find(i => i.program_id == data.program_id))
        });

        this.resetFiles();
        this.$router.push('/schedule');
      },
    },

    computed: {
      ...mapGetters('data/login', {
        member_id: 'getMemberId',
      }),

      ...mapGetters('data/patient', [
        'getProgramsScheduled',
      ]),

      ...mapGetters('data/schedule', {
        profile: 'getPatient',
        programsInfo: 'getPrograms',
        restrictedProgramsAge: 'getRestrictedPrograms',
        warningPrograms: 'getWarningPrograms',
        warningTypes: 'getWarningTypes',
      }),

      filteredPrograms() {
        return [...this.programsInfo.data.program_well_being]
          .filter(({program_id}) => this.psychologyProgramIds.includes(program_id));
      },

      getProgramSchedule() {
        return this.programSchedule || [];
      },
    },

    watch: {
      dialog(newDialog) {
        if (!newDialog) {
          this.selectedProgram = null;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/styles/colors.scss';

  .psychology-program-selection {
    min-height: 300px;
  }

  .v-list-item {
    border: 1px solid $spanishGray;
    max-height: 58px;

    &__content {
      text-align: left;
    }
  }

  .v-item-inactive-dark {
    color: $spanishGray !important;
    opacity: 0.5;

    i {
      color: $spanishGray !important;
    }
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    .psychology-program-selection {
      // 
    }
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .psychology-program-selection {
      // 
    }
  }

  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .psychology-program-selection {
      // 
    }
  }

  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .psychology-program-selection {
      // 
    }
  }
</style>