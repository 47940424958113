<template>
  <v-container
    class="patient-selection ma-auto my-0 pa-0 text-center"
  >
    <v-form
      autocomplete="off"
      class="py-4"
    >
      <h4
        class="outerSpace--text text-center font-weight-light"
      >
        {{ $t('general.select_options_below_patients') }}
      </h4>

      <v-chip-group
        column
        mandatory
      >
        <div
          v-for="(p, index) in patients"
          :key="index"
          class="chip-container text-left"
        >
          <h6
            v-if="index <= 1"
            class="text-h6 mt-6 outerSpace--text"
          >
            {{ index == 0 ? $t('general.holder') : $t('general.dependents') }}
          </h6>

          <v-chip
            :color="pIndex !== index ? 'outerSpace' : 'secondary'"
            :outlined="pIndex !== index"
            @click="selectPatient(index)"
            x-large
            class="chip-option font-weight-bold px-6 my-2"
          >
            <div
              class="chip-div"
            >
              <p
                class="font-weight-bold mb-1 pa-0 text-capitalize"
              >
                {{
                  p.social_name
                    ? p.social_name.toLowerCase()
                    : (p.name || p.nome).toLowerCase()
                }}
              </p>

              <p
                class="d-flex justify-space-between font-weight-medium ma-0 pa-0"
              >
                <span
                  :class="{ 'text-caption': $device.mobile }"
                >
                  {{ p.age }} {{ $t('general.years') }}
                </span>

<!--                <span-->
<!--                  v-if="p.has_login"-->
<!--                  :class="{ 'text-caption': $device.mobile }"-->
<!--                  class="red&#45;&#45;text"-->
<!--                >-->
<!--                  {{ $t('general.unavailable') }}-->
<!--                </span>-->
              </p>
            </div>
          </v-chip>
        </div>
      </v-chip-group>

      <v-row
        class="mt-6 px-4"
      >
        <v-col
          :class="{ 'text-center': $device.mobile }"
          class="d-flex justify-space-between px-0 pt-0"
          cols="12"
          md="12"
        >
          <v-btn
            :min-width="$device.mobile ? 145 : 195"
            @click="$emit('handleCancel')"
            class="text-capitalize ma-0"
            depressed
            height="46"
            max-width="208"
            outlined
            rounded
          >
            {{ $t('general.cancel') }}
          </v-btn>

          <v-btn
            :loading="loading"
            :min-width="$device.mobile ? 145 : 195"
            :class="{ 'disabled-button-dark': pIndex == null }"
            @click="confirmSelection"
            class="text-capitalize ma-0"
            color="outerSpace"
            dark
            depressed
            height="46"
            max-width="208"
            rounded
          >
            {{ $t('general.select') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <!-- Warning info for patient selection -->
    <GeneralModal
      :confirm-text="$t('general.ok_got_it')"
      :dialog="unavailableDialog"
      @handleClose="closeUnavailableDialog"
      @handleConfirm="closeUnavailableDialog"
      alert
      has-confirm
      width="440"
    >
      <h3
        class="text-center pb-2 outerSpace--text"
      >
        {{ $t('general.warning') }}
      </h3>

      <span>
        {{ $t('general.unavailable_patient_text') }}
      </span>
    </GeneralModal>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import GeneralModal from '@/components/general/GeneralModal';

  export default {
    name: 'PatientSelection',

    components: {
      GeneralModal,
    },

    props: {
      // Function to handle cancel action
      handleCancel: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },

      // Function to handle selection action
      handleSelect: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },
    },

    data: () => ({
      loading: false,
      pIndex: null,
      unavailableDialog: false,
    }),

    created() {
      if (!this.patients || !this.patients.length) {
        this.getPatientDependents();
      }
    },

    methods: {
      ...mapActions('data/patient', [
        'patientCompanyDependents', // -> map `this.patientCompanyDependents()` to `this.$store.dispatch('patientCompanyDependents')`
      ]),

      ...mapMutations('data/general', [
        'setGeneralError',
        'setGeneralErrorRedirectRoute',
      ]),

      ...mapMutations('data/patient', [
        'setDependents', // this.setDependents
      ]),

      ...mapMutations('data/schedule', [
        'setPatient', // this.setPatient
      ]),

      getPatientDependents(){
        this.loading = true;

        this.setDependents([]);

        this.patientCompanyDependents(this.companyId).then(() => {
          let newPatients = [];

          if (this.patients && this.patients.length) {
            // newPatients = this.patients.filter(o => !o.has_login);
            newPatients = this.patients;
          }

          let ownerAge = this.owner && this.owner.birthdate
            ? this.$moment().diff(this.owner.birthdate, 'years')
            : null

          if (!newPatients.length) {
            this.setPatient({
              ...this.owner,
              age: ownerAge,
              has_login: false,
            });
          }

          this.setDependents([
            {
              ...this.owner,
              age: ownerAge,
              has_login: false,
            },
            ...this.patients
          ]);
        }).catch((error) => {
          console.error(error);
          this.setGeneralError(true);
          this.setGeneralErrorRedirectRoute('home');
        }).finally(() => {
          this.loading = false;
        });
      },

      selectPatient(index) {
        // if (!this.patients[index].has_login) {
        //   this.pIndex = index;
        // } else {
        //   this.unavailableDialog = true;
        // }
        this.pIndex = index;
      },

      closeUnavailableDialog() {
        this.unavailableDialog = false;
      },

      confirmSelection() {
        this.setPatient(this.patients[this.pIndex]);

        this.$emit('handleSelect', this.patients[this.pIndex]);
      }
    },

    computed: {
      ...mapGetters('data/login', {
        companyId: 'getCompanyId',  // -> this.companyId
      }),

      ...mapGetters('data/patient', {
        owner: 'getProfile',  // -> this.owner
        patients: 'getDependents',  // -> this.patients
      }),
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/styles/colors.scss';

  .patient-selection {
    .chip-container {
      width: 100%;
    }

    .chip-option {
      border-radius: 35px;
      width: 100% !important;
      height: 72px !important;

      &.chip-disabled {
        border-color: $silverSand !important;
        color: $silverSand !important;
      }

      .chip-div {
        width: 100%;

        p, span {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    .patient-selection {
      //
    }
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .patient-selection {
      //
    }
  }

  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .patient-selection {
      //
    }
  }

  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .patient-selection {
      //
    }
  }
</style>