<template>
  <v-app>
    <v-main>
      <SplashScreen
        v-if="showSplashScreen"
      />

      <v-responsive
        v-else
        :class="{ 'menu-margin': !$device.mobile && !$device.mobile && hasSideMenu }"
      >
        <CentralBanner
          v-if="hasBanner"
        />

        <SideMenu
          v-if="hasSideMenu"
          @handleLogout="logout"
        />

        <router-view/>
      </v-responsive>

      <!-- General Error Modal -->
      <GeneralModal
        :dialog="generalError"
        :confirm-text="$t('general.ok_got_it')"
        @handleClose="handleConfirmLogout"
        @handleConfirm="handleConfirmLogout"
        error
        has-close
        has-confirm
        width="550"
      >
        <GeneralError/>
      </GeneralModal>

      <!-- Consent Term -->
      <GeneralModal
        :cancel-text="$t('general.close')"
        :content-title="$t('general.consent_term')"
        :dialog="consentTermDialog"
        :fullscreen="$device.mobile"
        @handleCancel="closeConsentTermDialog"
        @handleClose="closeConsentTermDialog"
        background-color="antiFlash"
        content-icon="fa-file-lines"
        has-cancel
        hide-header
        width="1000"
      >
        <ConsentTerm/>
      </GeneralModal>

      <!-- Privacy Policy -->
      <GeneralModal
        :cancel-text="$t('general.close')"
        :contentTitle="$t('general.privacy_policy')"
        :dialog="privacyPolicyDialog"
        :fullscreen="$device.mobile"
        @handleCancel="closePrivacyPolicyDialog"
        @handleClose="closePrivacyPolicyDialog"
        background-color="antiFlash"
        content-icon="fa-file-lines"
        has-cancel
        hide-header
        max-width="1000"
      >
        <PrivacyPolicy/>
      </GeneralModal>

      <!-- Warning info for patient logout -->
      <GeneralModal
        :dialog="hasLogout"
        :header-close="false"
        @handleCancel="handleClose"
        @handleClose="handleClose"
        @handleConfirm="handleConfirmLogout"
        background-color="antiFlash"
        has-cancel
        has-confirm
        has-header-close
        :header-title="$t('general.warning')"
        invert-actions
        width="470"
      >
        <span>{{ $t('general.warning_logout') }}</span>
      </GeneralModal>
    </v-main>
  </v-app>
</template>

<script>
  import {mapGetters, mapMutations} from 'vuex';
  import consts from '@/static/js/consts';
  import CentralBanner from '@/components/general/CentralBanner';
  import GeneralModal from '@/components/general/GeneralModal';
  import GeneralError from '@/components/general/GeneralError';
  import SplashScreen from '@/components/general/SplashScreen';
  import ConsentTerm from '@/components/general/ConsentTerm';
  import PrivacyPolicy from '@/components/general/PrivacyPolicy';
  import SideMenu from '@/components/general/SideMenu.vue';

  export default {
    name: 'App',

    components: {
      CentralBanner,
      GeneralModal,
      GeneralError,
      SplashScreen,
      ConsentTerm,
      PrivacyPolicy,
      SideMenu,
    },

    data: () => ({
      hasLogout: false,
      routesWithoutBanner: ['login', 'hardwarecheck', 'attendance'],
      routesWithoutSideMenu: ['login', 'hardwarecheck', 'attendance'],
    }),

    created() {
      this.$moment.locale('pt-br');
    },

    methods: {
      ...mapMutations('data/general', {
        generalReset: 'reset', // -> this.generalReset
        setGeneralError: 'setGeneralError', // -> this.setGeneralError
        setConsentTermDialog: 'setConsentTermDialog', // -> this.setConsentTermDialog
        setPrivacyPolicyDialog: 'setPrivacyPolicyDialog', // -> this.setPrivacyPolicyDialog
      }),

      ...mapMutations('data/login', {
        loginReset: 'reset', // -> this.loginReset
      }),

      ...mapMutations('data/patient', {
        patientReset: 'reset', // -> this.patientReset
      }),

      ...mapMutations('data/schedule', {
        scheduleReset: 'reset', // -> this.scheduleReset
      }),

      logout() {
        this.hasLogout = true;
      },

      closeConsentTermDialog() {
        this.setConsentTermDialog(false);
      },

      closePrivacyPolicyDialog() {
        this.setPrivacyPolicyDialog(false);
      },

      handleClose() {
        this.hasLogout = false;
      },

      handleConfirmLogout() {
        this.hasLogout = false;

        if (this.generalErrorRedirectRoute == 'login') {
          localStorage.clear();
          this.generalReset();
          this.loginReset();
          this.patientReset();
          this.scheduleReset();

          // Change theme colors to default
          let newTheme = {
            primary: consts.DEFAULT_COLORS.PRIMARY,
            secondary: consts.DEFAULT_COLORS.SECONDARY
          }

          this.$vuetify.theme.themes.dark = {
            ...this.$vuetify.theme.themes.dark,
            ...newTheme
          };

          this.$vuetify.theme.themes.light = {
            ...this.$vuetify.theme.themes.light,
            ...newTheme
          };
        }

        let redirectRoute = this.generalErrorRedirectRoute !== 'home'
          ? this.generalErrorRedirectRoute
          : '';

        if (this.$route.name === this.generalErrorRedirectRoute) {
          this.$router.go(`/${redirectRoute}`);
        } else {
          this.$router.push(`/${redirectRoute}`);
        }

        this.setGeneralError(false);
      },
    },

    computed: {
      ...mapGetters('data/general', {
        showSplashScreen: 'getShowSplashScreen', // -> this.showSplashScreen
        generalError: 'getGeneralError', // -> this.generalError
        generalErrorRedirectRoute: 'getGeneralErrorRedirectRoute', // -> this.generalErrorRedirectRoute
        consentTermDialog: 'getConsentTermDialog', // -> this.consentTermDialog
        privacyPolicyDialog: 'getPrivacyPolicyDialog', // -> this.privacyPolicyDialog
      }),

      hasSideMenu() {
        if (!this.$route) return false;

        return !this.routesWithoutSideMenu.includes(this.$route.name);
      },

      hasBanner() {
        if (!this.$route) return false;

        return !this.routesWithoutBanner.includes(this.$route.name);
      },
    },
  };
</script>

<style lang="scss">
  @import '@/styles/colors.scss';

  :root {
    --primary: #5949A7;
    --secondary: #00B7E6;
  }

  .v-picker {
    border: 1px solid $silverSand !important;
  }

  .menu-margin {
    max-width: calc(100% - 369px) !important;
    margin-left: 369px !important;
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px) and (max-device-width: 1024px) {
    .menu-margin {
      max-width: calc(100% - 293px) !important;
      margin-left: 293px !important;
    }
  }

  /* Global css goes here: */
  .application {
    font-family: "Roboto";
  }

  .background {
    &--primary {
      background-color: $primary;
    }

    &--secondary {
      background-color: $secondary;
    }

    &--silverSand {
      background-color: $silverSand;
    }

    &--outerSpace {
      background-color: $outerSpace;
    }

    &--spanishGray {
      background-color: $spanishGray;
    }

    &--antiFlash {
      background-color: $antiFlash;
    }

    &--antiFlashWhite {
      background-color: $antiFlashWhite;
    }

    &--cultured {
      background-color: $cultured;
    }

    &--error {
      background-color: $error;
    }

    &--folly {
      background-color: $folly;
    }

    &--deepPurple {
      background-color: $deepPurple;
    }

    &--electricRed {
      background-color: $electricRed;
    }

    &--lightSilver {
      background-color: $lightSilver;
    }

  }

  .v-dialog {
    box-shadow: none !important;
  }

  .v-btn {
    text-transform: initial !important;
  }

  .v-chip .v-chip__content {
    width: 100%;
  }

  /* Custom class for buttons */
  .outline-border {
    border: 1px solid #999999;
    background-color: white !important;
  }

  .disabled-button-dark {
    background-color: $outerSpace !important;
    color: white !important;
    opacity: 0.5;
    pointer-events: none;
  }

  /* Input autofill */
  input:-webkit-autofill,
  input:-internal-autofill-selected {
    transition: background-color 999999s ease-in-out 0s;

    &:hover,
    &:focus,
    &:active {
      transition: background-color 999999s ease-in-out 0s;
    }
  }

  .v-otp-input {
    .v-input input {
      color: $primary !important;
      font-size: 30px;
      padding: 0px;
    }

    .v-text-field--outlined fieldset {
      background-color: $cultured;
      border: none;
    }

    .v-text-field--outlined.v-input--is-focused fieldset,
    .v-text-field--outlined.v-input--has-state fieldset {
      border: none;
    }
  }

  .v-input--radio-group.v-input--radio-group--row .v-radio {
    margin-right: 0 !important;
  }

  .v-application--is-ltr .v-input--selection-controls__input {
    margin-right: 0 !important;
  }

  /* Scrollbar width */
  ::-webkit-scrollbar {
    width: 9px;
  }

  /* Scrollbar Track */
  ::-webkit-scrollbar-track {
    background-color: #E0E0E0;
    border-radius: 5px;
  }

  /* Scrollbar Handle */
  ::-webkit-scrollbar-thumb {
    background-color: $secondary;
    border-radius: 5px;
  }

  .v-date-picker-table {
    height: auto !important;
    padding: 1em !important;
    padding-top: 0 !important;

    table {
      tbody {
        tr:nth-child(6) {
          display: none;
        }
      }
    }
  }

  .v-date-picker-table--date td {
    border: 1px solid !important;
    border-color: #d3d3d3 !important;
    border-radius: 5px;
    box-shadow: 0px 0.5px #7b70704f;

    .theme--light.v-btn.v-btn--disabled {
      color: $spanishGray !important;
    }
  }

  .v-data-table {
    &.spaced > .v-data-table__wrapper > table {
      border-spacing: 0 14px !important;
    }
  }

  .v-pagination {
    justify-content: right;

    & > li {
      border-right: 1px solid $lightSilver;
      padding: 0 2px !important;

      &:first-child,
      &:last-child {
        display: none;
      }

      &:nth-last-child(-n + 2) {
        border-right: none;
      }
    }

    &.v-pagination {
      justify-content: right;
    }
  }

  .v-pagination__item {
    box-shadow: none !important;
    color: $outerSpace !important;
    margin: 0 !important;

    &--active {
      box-shadow: none !important;
      color: $outerSpace !important;
    }
  }

  .p-exist-attendance {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
  }

  .no-click {
    pointer-events: none;
  }

  .w-100 {
    width: 100%;
  }
</style>