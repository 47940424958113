<template>
  <v-container
    class="benefits-selection ma-auto my-0 pa-0 text-center"
  >
    <v-form
      autocomplete="off"
      class="py-4"
    >
      <h2
        :class="[ $device.mobile ? 'text-h6' : 'text-h5' ]"
        class="outerSpace--text text-center font-weight-medium"
      >
        {{ $t('general.title_select_benefits') }}
      </h2>

      <h5
        class="outerSpace--text text-center font-weight-light"
      >
        {{ $t('general.select_options_below_benefits') }}
      </h5>

      <v-card-text
        class="w-100"
      >
        <v-chip-group
          v-model="groupIndex"
          column
          mandatory
        >
          <v-chip
            v-for="(group, index) in groups"
            :color="groupIndex !== index ? 'outerSpace' : 'secondary'"
            :key="index"
            :outlined="groupIndex !== index"
            x-large
            class="chip-option font-weight-bold px-6 my-2"
          >
            <p
              :class="{ 'text-body-2': $device.mobile }"
              class="font-weight-bold ma-0 pa-0"
            >
              {{ group.name }}
            </p>
          </v-chip>
        </v-chip-group>
      </v-card-text>

      <v-row
        class="align-center d-flex flex-column justify-center mt-6"
      >
        <v-col
          :class="{ 'text-center': $device.mobile }"
          :cols="$device.mobile ? 12 : 6"
          :md="$device.mobile ? 12 : 6"
          class="px-0 pt-0"
        >
          <v-btn
            :loading="loading"
            :min-width="$device.mobile ? 165 : 195"
            :class="{ 'disabled-button-dark': groupIndex == null }"
            @click="confirmSelection"
            class="text-capitalize ma-0"
            color="outerSpace"
            dark
            depressed
            height="46"
            max-width="208"
            rounded
          >
            {{ $t('general.select') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';

  export default {
    name: 'BenefitsSelection',

    props: {
      // Function to handle selection action
      handleSelect: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },
    },

    data: () => ({
      loading: false,
      groupIndex: null,
    }),

    created() {
      if (!this.groups || !this.groups.length) {
        this.getBenefits();
      }
    },

    methods: {
      ...mapActions('data/login', [
        'getPatientGroups', // -> map `this.getPatientGroups()` to `this.$store.dispatch('getPatientGroups')`
      ]),

      ...mapActions('data/patient', [
        'patientCompanyDependents', // -> map `this.patientCompanyDependents()` to `this.$store.dispatch('patientCompanyDependents')`
      ]),

      ...mapActions('data/schedule', [
        'programGetDetails' // -> map `this.programGetDetails()` to `this.$store.dispatch('programGetDetails')`
      ]),

      ...mapMutations('data/general', [
        'setGeneralError',
      ]),

      ...mapMutations('data/login', [
        'setCompanyId', // this.setCompanyId
        'setCompanyStatusId', // this.setCompanyStatusId
        'setCompanyStatusName', // this.setCompanyStatusName
        'setFlavourId', // this.setFlavourId
        'setGroupId', // this.setGroupId
      ]),

      getBenefits(){
        this.loading = true;

        this.getPatientGroups().catch((error) => {
          console.error(error);
          // If benefits selection gets an error should logout user. Do NOT send route to 'setGeneralError'
          this.setGeneralError(true);
        }).finally(() => {
          this.loading = false;
        });
      },

      confirmSelection() {
        this.setCompanyId(this.groups[this.groupIndex].company_id);
        this.setCompanyStatusId(this.groups[this.groupIndex].company_status_id);
        this.setCompanyStatusName(this.groups[this.groupIndex].company_status_name);

        if (this.groups[this.groupIndex].flavour && this.groups[this.groupIndex].flavour.length) {
          this.setFlavourId(this.groups[this.groupIndex].flavour[0].id);
        }

        this.setGroupId(this.groups[this.groupIndex].id);
        this.getPrograms();
        this.getPatientDependents();
        this.$emit('handleSelect');
      },

      getPrograms() {
        if (this.companyId && this.groupId) {
          let payload = {
            company_id: this.companyId,
            group_id: this.groupId
          };
          if (!payload) {
            return;
          }
          this.loading = true;
          this.programGetDetails(payload)
          .catch((error) => {
            console.error(error);
            // If benefits selection gets an error should logout user. Do NOT send route to 'setGeneralError'
            this.setGeneralError(true);
          }).finally(() => {
            this.loading = false;
          });
        }
      },
      getPatientDependents(){
        this.patientCompanyDependents(this.companyId).catch((error) => {
          console.error(error);
          this.setGeneralError(true);
          this.setGeneralErrorRedirectRoute('home');
        }).finally(() => {
          this.loading = false;
        });
      }
    },

    computed: {
      ...mapGetters('data/login', {
        groups: 'getPatientGroups',  // -> this.groups
        groupId: 'getGroupId', // -> this.groupId
        companyId: 'getCompanyId',  // -> this.company_id
      }),
    },
  };
</script>

<style lang="scss" scoped>
  .benefits-selection {
    .chip-option {
      border-radius: 35px;
      width: 100% !important;
      height: 72px !important;

      p, span {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    .benefits-selection {
      //
    }
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .benefits-selection {
      //
    }
  }

  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .benefits-selection {
      //
    }
  }

  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .benefits-selection {
      //
    }
  }
</style>