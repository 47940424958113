import axios from 'axios';
import consts from '@/static/js/consts';

export default {
  /*
  * GET
  * @param member_id: int
  */
  patientProfile({ commit }, memberId) {
    return new Promise((resolve, reject) => {
      axios.get(`${consts.ENDPOINTS.PATIENT_PROFILE}?member_id=${memberId}`, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        resolve(commit('setProfile', response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    });
  },

  /*
  * GET
  * @param company_id: int
  */
  patientCompanyDependents({ commit }, company_id) {
    return new Promise((resolve, reject) => {
      axios.get(`${consts.ENDPOINTS.PATIENT_COMPANY_DEPENDENTS}?company_id=${company_id}`, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        resolve(commit('setDependents', response.data.data.dependents));
        resolve(commit('setDependentsRaw', response.data.data.dependents));
      }).catch(error => {
        reject(error.response);
      });
    });
  },

  /*
  * GET
  * @param limit: int
  * @param offset: int
  */
  patientAttendances({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.get(`${consts.ENDPOINTS.PATIENT_ATTENDANCES}?limit=${payload.limit || 20}&offset=${payload.offset || 0}`, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        let attendances = response.data.data.attendances;

        if (attendances && attendances.length) {
          attendances = attendances.filter((o, i, self) =>
            i === self.findIndex((t) => (
              t.case_id === o.case_id && t.case_attendance_id === o.case_attendance_id
            ))
          );
        }

        let programs = response.data.data.schedule_info;

        if(programs && programs.length) {
          resolve(commit('setProgramsScheduled', programs));
        } else {
          resolve(commit('setProgramsScheduled', []));
        }
        resolve(commit('setAttendances', attendances));
      }).catch(error => {
        reject(error.response);
      });
    });
  },

  /*
  * GET
  * @param member_id: int
  */
  patientEmailVerify({ commit }, memberId) {
    return new Promise((resolve, reject) => {
      axios.get(`${consts.ENDPOINTS.PATIENT_EMAIL_VERIFY}?member_id=${memberId}`, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        resolve(commit('setEmailVerified', response.data.data.email_verified));
      }).catch(error => {
        reject(error.response);
      });
    });
  },

  /*
  * POST
  * @param member_info: array
  */
  patientUpdate({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.MEMBER_UPDATE, payload, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        resolve(commit('setUpdate', response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    });
  },

  /*
  * POST
  * @param member_info: array
  */
  patientResendEmail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.PATIENT_RESEND_EMAIL, payload, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        resolve(commit('setUpdate', response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    });
  },

  /*
  * POST
  * @param member_id: integer
  * @param token: jwt
  */
  patientResetPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      payload.token = localStorage.getItem('token');
      axios.post(consts.ENDPOINTS.PATIENT_RESET_PASSWORD, payload, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        resolve(commit('setUpdate', response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    });
  },

  /*
   * GET
   * @param member_id: int
  */
  patientFirstAvailableDate({ commit }, date) {
    commit('setFirstAvailableDate', date);
  },
}