import axios from 'axios';
import consts from '@/static/js/consts';

export default {
  /*
  * POST
  * @param company_id: int
  * @param group_id: int
  */
  faqsGet({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.FAQS_GET, payload, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        resolve(commit('setFaqs', response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    });
  },
};