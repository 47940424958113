export default {
    profile: {
        title: 'Dados cadastrais',
        dependents: 'Dependentes',
        social_name: 'Nome Social',
        name: 'NOME COMPLETO',
        gender: 'SEXO',
        birthdate: 'DATA DE NASCIMENTO',
        cpf: 'CPF',
        email_address: 'ENDEREÇO DE E-MAIL',
        verify: 'Verificar',
        password: 'Senha',
        benefit: 'Benefício',
        phone: 'NÚMERO DE CELULAR',
        updated_info: 'As alterações foram salvas com sucesso!',
        gender_change: 'Alterar sexo',
        invalid_social_name: 'Nome social inválido',
        phone_change: 'Alterar número de celular',
        password_change: 'Nova senha',
        social_name_change: 'Alterar nome social',
        social_name_delete: 'Excluir nome social',
        sure_want_delete_social_name: 'Tem certeza que deseja excluir o nome social?',
        verify_email: 'Verificar E-mail',
        enter_social_name: 'Digite seu nome social:',
        verify_email_message_1: 'Você receberá uma mensagem contendo um link de validação ',
        verify_email_message_bold: 'no e-mail informado. ',
        verify_email_message_2: 'Verifique sua caixa de entrada e clique no link para confirmar sua conta.',
        verify_email_agree: 'OK, entendi'
    }
}