export default {
  reset(state) {
    state.currentStep = 0;
    state.acceptTerm = false;
    state.form = {
      name: null,
      cpf: null,
      birthdate: null,
      phone: null,
      gender: null,
      email: null,
      password: null,
    };
    state.groups ={
      id: null,
      name: null,
      company_id: null,
      legal_nature: null,
    };
  },

  incressCurrentStep(state) {
    state.currentStep++;
  },

  acceptTerm(state) {
    state.acceptTerm = true;
  },

  setPersonalInfo(state, payload) {
    state.form = {
      ...state.form,
      ...payload
    };
  },

  setLoginInfo(state, payload) {
    state.form = {
      ...state.form,
      ...payload
    };
  },

  setMemberId(state, memberId) {
    state.member_id = memberId;
  },

  setPhoneNumber(state, phone) {
    state.form.phone = phone;
  },
};