<template>
  <v-container class="home-view d-flex flex-column pa-0" max-width="100%">
    <div class="d-flex justify-center pa-7">
      <v-img :src="require('@/assets/general/success_doctors.png')" class="img-success" contain/>
    </div>
    <div class="d-flex justify-center pa-4">
      <div class="d-flex flex-column">
        <h1 class="text-center primary--text">{{ $t('general.success').toUpperCase() }}!</h1>
        <h3 class="text-center pa-5">{{ $t('general.schedule_attendance_success') }}</h3>
      </div>
    </div>
    <div class="d-flex justify-center pa-1">
      <v-card class="info d-flex flex-column mb-8 py-10 px-12" color="antiFlash" flat width="auto" outlined>
        <ul>
          <li class="text-md-left">
            <p>{{ $t('general.schedule_attendance_success_alert_medical') }}</p>
          </li>
          <li class="text-md-left">
            <p>{{ $t('general.schedule_attendance_success_alert_signal') }}</p>
          </li>
        </ul>
      </v-card>
    </div>
    <div class="d-flex justify-center pb-4">
      <v-btn @click="handleBack" class="font-weight-bold mx-3" color="secondary" dark height="46" outline-border rounded
             width="244">
        {{ $t('general.back_to_start') }}
      </v-btn>
    </div>
    <!-- Footer -->
    <HomeFooter/>
  </v-container>
</template>

<script>
import HomeFooter from '@/components/home/HomeFooter';
import helpers from '@/mixins/helpers';
import {mapMutations} from "vuex";

export default {
  name: 'ScheduleSuccessView',

  mixins: [helpers],

  components: {
    HomeFooter,
  },

  data: () => ({
    //
  }),

  mounted() {
    this.setBannerTitle(this.$t('general.banner_need_attendance'));
    this.setBannerIcon('fa-solid fa-hand-holding-medical');
  },

  methods: {
    ...mapMutations('data/general', [
      'setBannerTitle', // -> this.setBannerTitle
      'setBannerIcon', // -> this.setBannerIcon
    ]),


    handleBack() {
      this.$router.push('/');
    }
  },

  computed: {}
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.home-view {
  max-width: 100%;

  .content {
    min-height: calc(100vh - 246px - 97px);
    max-width: 1500px;
    padding-bottom: 107px !important;
    padding-top: 74px !important;

    .image {
      margin-top: 80px !important;
      z-index: 2;
    }

    .logo {
      z-index: 2;
    }

    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 268px;
    }

    .title {
      font-size: 40px !important;
    }

    .subtitle {
      font-size: 20px !important;
      margin: 40px auto 62px auto !important;
    }
  }
}

.info {

  li {
    list-style-type: none;
    position:relative;
    margin-bottom:0.5em;
  }
  li:before {
    content: '•';
    display: inline-block;
    position: absolute;
    left: -0.8em;
    top: -0.2em;
    color: $secondary;
    font-size: 25px;
  }
}

/* For Tablet View */
@media screen and (min-device-width: 641px) and (max-device-width: 1024px) {
  .home-view {
    .content {
      min-height: calc(100vh - 216px - 97px);
      padding-bottom: 85px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      padding-top: 74px !important;

      .image {
        margin: 0 auto 56px auto !important;
      }

      .components {
        flex-direction: column-reverse !important;
      }

      .title {
        font-size: 30px !important;
      }

      .subtitle {
        font-size: 17px !important;
        margin: 30px auto 40px auto !important;
      }
    }
  }
}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
  .home-view {
    .content {
      min-height: calc(100vh - 129px - 76px);
      padding-bottom: 38px !important;
      padding-top: 0 !important;

      .image {
        margin-top: 1px !important;
      }

      .title {
        font-size: 23px !important;
        white-space: pre-line;
      }

      .subtitle {
        font-size: 15px !important;
        margin: 27px auto 33px auto !important;
      }
    }
  }
}

/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  .home-view {
    .content {
      min-height: calc(100vh - 129px - 76px);
      padding-bottom: 38px !important;
      padding-top: 0 !important;

      .image {
        margin-top: 1px !important;
      }

      .title {
        font-size: 23px !important;
        white-space: pre-line;
      }

      .subtitle {
        font-size: 15px !important;
        margin: 27px auto 33px auto !important;
      }
    }
  }
}

/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {
  .home-view {
    .content {
      min-height: calc(100vh - 129px - 76px);
      padding-bottom: 38px !important;
      padding-top: 0 !important;

      .image {
        margin-top: 1px !important;
      }

      .title {
        font-size: 23px !important;
        white-space: pre-line;
      }

      .subtitle {
        font-size: 15px !important;
        margin: 27px auto 33px auto !important;
      }
    }
  }
}

.img-success {
  max-width: 436px;
}
</style>
    