import axios from 'axios';
import consts from '@/static/js/consts';

export default {
  /*
  * POST
  * @param birthdate: string
  * @param cpf: int
  * @param name: string
  */
  userCheckExistence({ commit }, payload = null) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.USER_CHECK_EXISTENCE, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        commit(resolve(response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    })
  },

  /*
  * POST
  * @param nome: string
  * @param sobrenome: string
  * @param cpf: int
  * @param data_nascimento: string
  * @param refresh: boolean
  */
  userDataCheck({ commit }, payload = {}) {
    payload = {
      user_info: {
        ...payload
      }
    };

    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.USER_DATA_CHECK, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        commit(resolve(response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    })
  },

  /*
  * POST
  * @param name: string
  * @param document: string
  * @param birthdate: string
  */
  memberDataCheck({ commit }, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.MEMBER_DATA_CHECK, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        commit(resolve(response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    })
  },

  /*
  * POST
  * @param cpf: string
  * @param name: string
  * @param email: string
  * @param phone: string
  * @param gender: string
  * @param birthdate: string
  * @param password: string
  * @param company_id: int
  * @param group_id: int
  */
  onboardingPatientSignup({ commit }, payload = null) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.ONBOARDING_PATIENT_SIGNUP, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        resolve(commit('setMemberId', response.data.data.id));
      }).catch(error => {
        reject(error.response);
      });
    })
  },
};