
<template>
  <v-container
    class="login-form ma-o pa-0"
  >
    <v-form
      autocomplete="off"
    >
      <v-row
        class="ma-0"
      >
        <v-col
          class="px-0 pt-0"
          cols="12"
          md="12"
        >
          <v-text-field
            v-model.trim="email"
            :error-messages="emailErrors"
            :label="$t('login.email')"
            :placeholder="$t('login.email_placeholder')"
            @blur="$v.email.$touch()"
            background-color="transparent"
            class="input-email cy-input-email pa-0"
            persistent-placeholder
            height="50"
          />
        </v-col>

        <v-col
          class="pa-0"
          cols="12"
          md="12"
        >
          <v-text-field
            v-model.trim="password"
            :error-messages="passwordErrors"
            :label="$t('login.password')"
            :placeholder="$t('login.password_placeholder')"
            :type="isVisible ? 'text' : 'password'"
            @blur="$v.password.$touch()"
            background-color="transparent"
            class="input-password cy-input-password pa-0"
            persistent-placeholder
            height="50"
          >
            <v-icon
              @click="isVisible = !isVisible"
              color="secondary"
              slot="append"
              small
            >
              {{ isVisible ? 'fa-eye-slash' : 'fa-eye'}}
            </v-icon>
          </v-text-field>
        </v-col>

        <v-col
          class="d-flex justify-center px-0"
          cols="12"
          md="12"
        >
          <vue-recaptcha
            :sitekey="siteKey"
            @verify="verifyRecaptcha"
            @expired="expiredRecaptcha"
            language="pt-br"
            loadRecaptchaScript
            ref="recaptcha"
          />
        </v-col>

        <v-col
          class="px-0"
          cols="12"
          md="12"
        >
          <v-btn
            :disabled="!recaptchaVerified"
            :loading="loading"
            @click="validateLogin"
            class="login-button cy-login-button white--text"
            color="primary"
            depressed
            height="60"
            rounded
            width="100%"
          >
            {{ $t('login.login') }}
          </v-btn>
        </v-col>

        <v-col
          class="px-0"
          cols="12"
          md="12"
        >
          <v-btn
            @click="$emit('handleRegister')"
            class="register-button cy-register-button secondary--text outline-border"
            depressed
            height="60"
            rounded
            width="100%"
          >
            {{ $t('login.register') }}
          </v-btn>
        </v-col>

        <v-col
          class="forgot-password-button cy-forgot-password-button px-0 text-center"
          cols="12"
          md="12"
        >
          <v-btn
            @click="$emit('handleForgotPassword')"
            class="cy-forgot-password"
            text
          >
            {{ $t('login.forgot_password') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <PreloaderSplash v-if="isLoading" />
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex';
  import { email, required } from 'vuelidate/lib/validators';
  import { VueRecaptcha } from 'vue-recaptcha';

  export default {
    name: 'LoginForm',

    components: {
      VueRecaptcha,
    },

    props: {
      // Function to handle login action
      handleLogin: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },

      // Function to handle register action
      handleRegister: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },
      
      // Function to handle forgot password action
      handleForgotPassword: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },
    },

    data: () => ({
      recaptchaVerified: false,
      email: null,
      password: null,
      loading: false,
      loginError: false,
      isVisible: false,
      groups: [],
      isLoading: false,
    }),
    created () {
      this.recaptchaVerified = process.env.VUE_APP_ENABLED_CAPTCHA;
    },

    methods: {
      ...mapActions('data/login', [
        'patientAuthorize', // -> map `this.patientAuthorize()` to `this.$store.dispatch('patientAuthorize')`
        'getPatientGroups', // -> map `this.getPatientGroups()` to `this.$store.dispatch('getPatientGroups')`
      ]),

      verifyRecaptcha(data) {
        this.recaptchaVerified = !!data;
      },

      expiredRecaptcha() {
        this.recaptchaVerified = false;
        this.$refs.recaptcha.reset();
      },

      validateLogin() {
        if (this.loading || !this.recaptchaVerified) return;

        this.loginError = false;
        this.$v.$touch();

        if (this.$v.$invalid) return;

        this.loading = true;

        let payload = {
          email: this.email,
          password: this.password
        }

        this.patientAuthorize(payload).then(() => {
          this.getBenefits();
        }).catch((error) => {
          console.error(error);
          this.loginError = true;
          this.$v.email.$touch();
          this.$v.password.$touch();
          this.loading = false;
        });
      },

      getBenefits() {
        this.loading = true;

        this.getPatientGroups().then(() => {
          this.$emit('handleLogin');
        }).catch((error) => {
          console.error(error);
          this.loginError = true;
        }).finally(() => {
          this.loading = false;
        });
      }
    },

    computed: {
      emailErrors() {
        const errors = [];
        if (!this.$v.email.$dirty) return errors;
        !this.$v.email.email && errors.push(this.$t('login.email_invalid'));
        !this.$v.email.required && errors.push(this.$t('general.required'));
        this.loginError && errors.push('');
        return errors;
      },

      passwordErrors() {
        const errors = [];
        if (!this.$v.password.$dirty) return errors;
        !this.$v.password.required && errors.push(this.$t('general.required'));
        this.loginError && errors.push(this.$t('login.login_error'));
        return errors;
      },

      siteKey() {
        return process.env.VUE_APP_RECAPTCHA_SITEKEY;
      },
    },

    validations: {
      email: {
        email,
        required,
      },
      password: {
        required,
      },
    }
  };
</script>

<style lang="scss" scoped>
  .login-form {
    // 
  }
   
  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    .login-form {
      // 
    }
  }
   
  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .login-form {
      // 
    }
  }
   
  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .login-form {
      // 
    }
  }
   
  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .login-form {
      // 
    }
  }
</style>
