export default {
    reschedule: {
        banner_title: "Reagendar consulta",
        banner_icon: "fa-duotone fa-hand-holding-medical",
        success_message: 'Sua consulta com um de nossos profissionais foi reagendada com sucesso!',
        tips: [
            "Fique atento às suas notificações. Você receberá um aviso quando o médico entrar na sala de vídeo;",
            "Antes da consulta, esteja em um local privativo. bem iluminado e verifique sua conexão com a internet.",
        ],
        warning_cant_reschedule_less_then_24h: "Não é possível reagendar o atendimento com menos de 24 horas para o início.",
        warning_empty_slots: "O profissional selecionado não possui agenda disponível no momento. Para continuar com esse profissional, ligue gratuitamente para nossa central de atendimento:",
    }
};