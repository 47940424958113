<template>
  <v-container
      class="feedback-success-view d-flex flex-column pa-0"
      max-width="100%"
  >
    <div class="central-text">
      <v-img
          :src="require('@/assets/general/success_doctors.svg')"
          contain
          height="334px"
          width="400px"
      />
    </div>
    <div class="central-text">
      <h2 class="title"> {{ $t('feedbackSuccess.tanks') }} <span
          v-if="feedback">{{ $t('feedbackSuccess.for_the_feedback') }}</span></h2>
    </div>
    <div class="central-text">
      <p class="thank-text">{{ $t('feedbackSuccess.tanks_title') }}
        <span style="font-weight: bold"> {{ $t('feedbackSuccess.teladoc_name') }} </span>
        <span v-if="feedback">{{ $t('feedbackSuccess.evaluation_text') }} </span>
      </p>
    </div>
    <div class="central-text">
      <v-btn rounded class="secondary col-sm-2" style="margin-bottom:40px" @click="goHome">{{
          $t('feedbackSuccess.back_to_start')
        }}
      </v-btn>
    </div>
    <!-- Footer -->
    <HomeFooter/>
  </v-container>
</template>

<script>
import HomeFooter from '@/components/home/HomeFooter';
import {mapGetters, mapMutations} from "vuex";

export default {
  name: 'FeedbackSuccessView',

  components: {
    HomeFooter,
  },

  data: () => ({
    loading: false
  }),
  computed: {
    ...mapGetters('data/attendance', {
      feedback: 'getFeedback',
    }),
  },
  mounted() {
    this.setBannerTitle(this.$t('general.banner_feedback'));
    this.setBannerIcon('fa-heart-pulse');
  },

  methods: {
    ...mapMutations('data/general', {
      setBannerTitle: 'setBannerTitle', // -> this.setBannerTitle
      setBannerIcon: 'setBannerIcon', // -> this.setBannerIcon
    }),

    goHome() {
      this.$router.push('/');
    },
  }

};
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.feedback-success-view {
  max-width: 100%;

  .content {
    min-height: calc(100vh - 246px - 97px);
  }

  .central-text {
    padding: 12px;
    justify-content: center;
    display: flex;

    .title {
      font-size: 26px !important;
      color: $primary;
    }

    .thank-text {
      font-size: 14px !important;
      text-align: center;
      padding-left: 8em;
      padding-right: 8em;
    }
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px) and (max-device-width: 1024px) {
    .feedback-success-view {
      .content {
        min-height: calc(100vh - 216px - 97px);
      }
    }
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .feedback-success-view {
      .content {
        min-height: calc(100vh - 129px - 76px);
      }
    }
  }

  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
    .feedback-success-view {
      .content {
        min-height: calc(100vh - 129px - 76px);
      }
    }
  }

  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {
    .feedback-success-view {
      .content {
        min-height: calc(100vh - 129px - 76px);

      }
    }
  }
}
</style>
