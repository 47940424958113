<template>
  <v-container
    class="forgot-password ma-0 pa-0"
  >
    <v-form
      autocomplete="off"
    >
      <v-row
        v-if="emailSent"
        class="ma-0"
      >

        <v-col
          class="px-0 pt-0"
          cols="12"
          md="12"
        >
          {{ $t('login.response_send_email_success') }}
        </v-col>

        <v-col
          class="d-flex justify-center pa-0 mt-6"
          cols="12"
          md="12"
        >
          <v-btn
            width="auto"
            :min-width="$device.mobile ? 135 : 165"
            @click="$emit('handleCancel')"
            class="cy-ok-button text-capitalize ma-0"
            color="outerSpace"
            dark
            depressed
            min-height="46"
            rounded
          >
            {{ $t('general.ok') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-row
        v-else
        class="ma-0"
      >
        <v-col
          class="px-0 pt-0"
          cols="12"
          md="12"
        >
          <v-text-field
            v-model.trim="email"
            :error-messages="emailErrors"
            :label="$t('login.enter_your_email')"
            :placeholder="$t('login.email_example')"
            @blur="$v.email.$touch()"
            background-color="transparent"
            class="cy-email-valid-text input-email pa-0"
            outlined
            persistent-placeholder
            height="50"
          />
        </v-col>

        <v-col
          class="d-flex justify-space-between px-0 pt-0"
          cols="12"
          md="12"
        >
          <v-btn
            width="auto"
            :min-width="$device.mobile ? 135 : 165"
            @click="$emit('handleCancel')"
            class="cy-cancel-button cancel outerSpace--text"
            depressed
            min-height="46"
            outlined
            rounded
          >
            {{ $t('general.cancel') }}
          </v-btn>

          <v-btn
            width="auto"
            :min-width="$device.mobile ? 135 : 165"
            :loading="loading"
            @click="validateForgotPassword"
            class="cy-send-button ma-0"
            color="outerSpace"
            dark
            depressed
            min-height="46"
            rounded
          >
            {{ $t('general.send') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex';
  import { email, required } from 'vuelidate/lib/validators';

  export default {
    name: 'ForgotPassword',

    props: {
      // Function to handle cancel action
      handleCancel: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },
    },

    data: () => ({
      email: null,
      emailSent: false,
      loading: false,
    }),

    methods: {
      ...mapActions('data/login', [
        'patientForgotPassword', // -> map `this.patientForgotPassword()` to `this.$store.dispatch('patientForgotPassword')`
      ]),

      validateForgotPassword() {
        this.$v.$touch();
        if (this.$v.$invalid) return;

        this.loading = true;
        let payload = { email: this.email };
        this.patientForgotPassword(payload).then((response) => {
          if (response.message === 'reset_password_email_sent') {
            this.emailSent = true;
          }
        }).catch((error) => {
          console.error(error);
        }).finally(() => {
          this.loading = false;
        });
      },
    },

    computed: {
      emailErrors() {
        const errors = [];
        if (!this.$v.email.$dirty) return errors;
        !this.$v.email.email && errors.push(this.$t('login.email_invalid'));
        !this.$v.email.required && errors.push(this.$t('general.required'));
        return errors;
      },
    },

    validations: {
      email: {
        email,
        required
      },
    }
  };
</script>

<style lang="scss" scoped>
.forgot-password {
  //
}

/* For Tablet View */
@media screen and (min-device-width: 641px)
and (max-device-width: 1024px) {
  .forgot-password {
    //
  }
}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
  .forgot-password {
    //
  }
}

/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px) and (device-width: 320px)
and (-webkit-min-device-pixel-ratio: 2) {
  .forgot-password {
    //
  }
}

/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) and (min-device-width: 375px)
and (-webkit-min-device-pixel-ratio: 3) {
  .forgot-password {
    //
  }
}
</style>
