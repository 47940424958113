<template>
  <div
    class="general-error"
  >
    <h3
      class="text-center outerSpace--text mb-3"
    >
      {{ $t('general.error') }}
    </h3>

    <p
      class="outerSpace--text ma-0"
    >
      {{ $t('general.general_error') }}
    </p>
  </div>
</template>

<script>
  export default {
    name: 'GeneralError',
  };
</script>

<style lang="scss" scoped>
  .general-error {
    // 
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    .general-error {
      //
    }
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .general-error {
      //
    }
  }

  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .general-error {
      //
    }
  }

  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .general-error {
      //
    }
  }
</style>
