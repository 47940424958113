<template>
  <v-container
    class="attendance-chat-button d-flex justify-center align-center pa-0"
  >
    <v-btn
      class="chat ma-0 pa-0"
      text
    >
      <v-img
        :src="require('@/assets/icons/chat.svg')"
        contain
        height="32"
        width="36"
      />
    </v-btn>
  </v-container>
</template>

<script>
  export default {
    name: 'AttendanceChatButton',
  };
</script>

<style lang="scss" scoped>
  @import '@/styles/colors.scss';

  .attendance-chat-button {
    background-color: white;
    border-radius: 20px;
    height: 36px;
    position: absolute;
    right: 20px;
    top: 0;
    width: 42px;

    .chat {
      // 
    }
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    .attendance-chat-button {
      // 
    }
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .attendance-chat-button {
      right: 10px;
    }
  }

  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .attendance-chat-button {
      right: 10px;
    }
  }

  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .attendance-chat-button {
      right: 10px;
    }
  }
</style>