<template>
  <v-container fluid>
    <v-row v-if="!$device.mobile">
      <v-col md="9" ref="subscriber">
        <div id="subscriber"></div>
      </v-col>

      <v-col md="3" class="patient-side">
        <v-row>
          <div @click="endAttendance" class="close-icon" >
            <v-icon small color="cultured" class="fa-solid fa-close" />
          </div>
        </v-row>

        <v-row>
          <v-col class="attendance-instructions-container">
            <v-img
              :src="require('@/assets/logos/teladoc_health_modal.svg')"
              class="attendance-instructions-logo"
              contain
            />
          </v-col>
        </v-row>

        <v-row class="text-center">
          <v-col cols="12">
            <div class="video-call">
              <div v-show="!camIsAvailable" class="video-call-off">
                <div>
                  <v-icon color="cultured" class="fa-solid fa-video-slash" />
                  <p>{{ $t('hardware_check.camera_disabled') }}</p>
                </div>
              </div>
              <div v-show="camIsAvailable" class="video-call-on">
                <video
                  autoplay
                  ref="video"
                  id="video"
                  style="width: 100%; height: 100%"
                >
                  <div ref="publisherDesktop"></div>
                </video>
              </div>

              <audio id="audio" />
            </div>
          </v-col>
        </v-row>

        <v-row class="text-center">
          <v-col>
            <div class="mb-2">
              <v-btn v-show="camIsAvailable" @click="setCamera(!camIsAvailable)"
                   depressed
                   color="cultured"
                   fab
                   class="control-video-call-button-cam">
                <v-icon small color="primary" class="fa-solid fa-video" />
              </v-btn>

              <v-btn v-show="!camIsAvailable" @click="setCamera(!camIsAvailable)"
                   depressed
                   outlined
                   fab
                   color="cultured"
                   class="control-video-call-button-cam">
                <v-icon small color="cultured" class=" fa-solid fa-video-slash" />
              </v-btn>
            </div>

            <div class="mb-2">
              <v-btn v-show="micIsAvailable" @click="setMicrophone(!micIsAvailable)"
                   depressed
                   color="cultured"
                   fab
                   class="control-video-call-button-cam">
                <v-icon small color="primary" class=" fa-solid fa-microphone" />
              </v-btn>

              <v-btn v-show="!micIsAvailable" @click="setMicrophone(!micIsAvailable)"
                   depressed
                   color="cultured"
                   outlined
                   fab
                   class="control-video-call-button-cam">
                <v-icon x-small color="cultured" class=" fa-solid fa-microphone-slash" />
              </v-btn>
            </div>

            <div>
              <v-btn
                @click="showEndAttendanceModal = !showEndAttendanceModal"
                depressed
                fab
                class="icon-hangup"
              >
                <v-img
                  :src="require('@/assets/icons/phone-hangup.svg')"
                  contain
                  width="12"
                  height="12"
                />
              </v-btn>
            </div>
          </v-col>
        </v-row>

      </v-col>
    </v-row>

    <div class="text-center" v-if="$device.mobile">
      <div v-show="!camIsAvailable" class="video-call-mobile video-call-mobile-off">
        <v-icon color="cultured" class="mt-14 fa-solid fa-video-slash" />
      </div>
      <div v-show="camIsAvailable" class="video-call-mobile">
        <video
          autoplay
          ref="video"
          id="video"
          style="width: 100%; height: 100%"
          playsinline
        >
          <div ref="publisherMobile"></div>
        </video>
      </div>
      <audio id="audio" />
      <v-btn style="z-index: 2" v-show="camIsAvailable" class="video-call-mobile-change-cam"
           @click="changeCamera"
           x-small
           depressed
           fab
           color="primary">
        <v-icon x-small color="cultured" class="fa-solid fa-camera-rotate" />
      </v-btn>
    </div>

    <div id="content" v-if="$device.mobile">
      <div class="subscriber-mobile"
         ref="subscriber">
        <div id="subscriber-mobile"></div>
      </div>
      <div>
        <v-row class="text-center">
          <v-col cols="12">
            <div>
              <div v-show="!camIsAvailable" class="video-call-mobile video-call-mobile-off">
                <v-icon color="cultured" class="mt-14 fa-solid fa-video-slash" />
              </div>
              <div v-show="camIsAvailable" class="video-call-mobile video-area">
                <video
                  autoplay
                  id="video"
                  style="width: 100%; height: 100%; z-index: 10"
                  playsinline
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </div>

      <div id="images">
        <v-row style="margin-top: -50px" class="text-center">
          <v-col>
            <v-btn @click="showEndAttendanceModal = !showEndAttendanceModal"
                 depressed
                 fab
                 class="icon-hangup">
              <v-img
                :src="require('@/assets/icons/phone-hangup.svg')"
                contain
                width="10"
                height="10"
              />
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="text-center mt-7">
          <v-col cols="6">
            <v-btn v-show="camIsAvailable" @click="setCamera(!camIsAvailable)"
                 depressed color="cultured"
                 rounded
                 class="control-video-call-button mx-1">
              <v-icon x-small color="primary" class=" fa-solid fa-video" />
            </v-btn>

            <v-btn v-show="!camIsAvailable" @click="setCamera(!camIsAvailable)"
                 depressed
                 outlined
                 color="cultured"
                 rounded
                 class="control-video-call-button mx-1">
              <v-icon x-small color="cultured" class=" fa-solid fa-video-slash" />
            </v-btn>
          </v-col>

          <v-col cols="6">
            <v-btn v-show="micIsAvailable" @click="setMicrophone(!micIsAvailable)"
                 depressed
                 rounded
                 color="cultured"
                 class="control-video-call-button mx-1">
              <v-icon x-small color="primary" class=" fa-solid fa-microphone" />
            </v-btn>

            <v-btn v-show="!micIsAvailable" @click="setMicrophone(!micIsAvailable)"
                 depressed
                 outlined
                 rounded
                 color="cultured"
                 class="control-video-call-button mx-1">
              <v-icon x-small color="cultured" class=" fa-solid fa-microphone-slash" />
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>

    <GeneralModal
        :end-attendance="true"
        :dialog="showEndAttendanceModal"
        has-cancel
        has-confirm
        :confirmText="$t('general.yes')"
        scrollable
        class="text-center"
        max-width="500"
        @handleCancel="closeEndAttendanceModal"
        @handleConfirm="confirmEndAttendance"
    >
      <EndAttendanceModal/>
    </GeneralModal>

    <GeneralModal
        :dialog="showModalAlterPermissionsDevices"
        persistent
        has-confirm
        :confirmText="$t('general.ok_got_it')"
        max-width="540"
        @handleConfirm="confirmAlterPermissionsDevices"
    >
      <AlterPermissionsDevicesModal/>
    </GeneralModal>
  </v-container>
</template>

<script>
  import GeneralModal from "@/components/general/GeneralModal.vue";
  import EndAttendanceModal from "@/components/attendance/EndAttendanceModal.vue";
  import {mapActions, mapGetters, mapMutations} from "vuex";
  import OT from "@opentok/client";
  import Pusher from "pusher-js";
  import AlterPermissionsDevicesModal from "@/components/attendance/AlterPermissionsDevicesModal.vue";
  import consts from '@/static/js/consts';

  export default {
    name: "VideoConsultation",

    components: {EndAttendanceModal, GeneralModal, AlterPermissionsDevicesModal},

    data: () => ({
      micIsAvailable: false,
      camIsAvailable: false,
      videoStream: null,
      audioStream: null,
      useFrontCamera: true,
      showHardwareUnavailableModal: false,
      showEndAttendanceModal: false,
      session: null,
      token: null,
      showPublisher: false,
      showSubscriber: false,
      publisher: null,
      credentials: {
        apiKey: null,
        sessionId: null,
        token: null,
      },
      publisherProperties: {
        insertMode: 'append',
        width: '100%',
        height: '100%'
      },
      constraints: {
        video: {
          aspectRatio: 4 / 3,
          width: {
            min: 100,
            ideal: 350,
            max: 350,
          }
        },
      },
      navigator: null,
      showModalAlterPermissionsDevices: false,
    }),

    async mounted() {
      this.navigator = await navigator;
      await this.setCamera(true);
      await this.setMicrophone(true);
      if (this.videoStream && this.audioStream) {
        this.$emit('handleHardwareAvailable', true);
      }
      if (!this.videoStream || !this.audioStream) {
        this.$router.push('/hardwarecheck');
      }
      const payload = {
        case_attendance_id: this.getCaseAttendanceV2(),
      };
      this.streamPatientGet(payload).then((res) => {
        this.credentials.apiKey = res.auth_details.api_key
        this.credentials.sessionId = res.auth_details.session_id
        this.credentials.token = res.auth_details.token;
        this.initializeSession();
      }).catch((error) => {
        console.error('error getting credentials: ', error);
      })

      this.getSocketInfoV2();
    },

    async beforeDestroy() {
      await this.disconnect();
    },

    methods: {
      ...mapMutations('data/attendance', [
        'setCaseAttendanceId',
      ]),

      ...mapMutations('data/general', [
        'setGeneralError',
        'setGeneralErrorRedirectRoute',
      ]),

      ...mapActions('data/attendance', [
        'streamPatientGet',
      ]),

      ...mapActions('data/general', [
        'socketGetInfo',
        'queueStatusChange',
      ]),

      getCaseAttendanceV2() {
        let caseAttendanceId = this.getCaseAttendanceId;

        if (!caseAttendanceId) {
          caseAttendanceId = localStorage.getItem("case_attendance_id");

          if (caseAttendanceId) {
            this.setCaseAttendanceId(caseAttendanceId);
            return caseAttendanceId;
          }

          this.setGeneralError(true);
          this.setGeneralErrorRedirectRoute('home');
        }

        return caseAttendanceId;
      },

      initializeSession() {
        const session = OT.initSession(this.credentials.apiKey, this.credentials.sessionId);
        this.session = session;

        const videoElement = this.$device.mobile ? this.$refs.publisherMobile : this.$refs.publisherDesktop

        const publisher = OT.initPublisher(videoElement, this.publisherProperties, (error) => {
          if (error) {
            console.error(error.message);
          }
        });
        this.publisher = publisher;

        session.connect(this.credentials.token, (error) => {
          if (error) {
            console.error(error.message);
          } else {
            session.publish(publisher, (error) => {
              if (error) {
                console.error(error.message);
              }
            });
          }
        });

        const subscriberElement = this.$refs.subscriber;
        if (subscriberElement) {
          session.on('streamCreated', (event) => {
            const properties = {
              insertMode: 'append',
              fitMode: "cover",
              width: '101.2%',
              height: '100%'
            };
            session.subscribe(event.stream, subscriberElement, properties, (error) => {
              if (error) {
                console.error(error.message);
              }
            });
          });
        }
      },

      getSocketInfoV2() {
        const payload = {
          case_attendance_id: this.getCaseAttendanceV2(),
        };
        this.socketGetInfo(payload)
          .then(() => {
            this.connectToPusher()
          })
          .catch(() => {
            this.setGeneralError(true);
            this.setGeneralErrorRedirectRoute('home');
          });
      },

      connectToPusher() {
        if (!this.getSocketInfo) {
          this.setGeneralError(true);
          this.setGeneralErrorRedirectRoute('home');
          return;
        }

        const {key, channel_name, options: {cluster}} = this.getSocketInfo;
        if (key) {
          this.pusher = new Pusher(key, {
            cluster: cluster
          });
        }
        this.pusher.subscribe(channel_name);

        this.pusher.bind('start_stream', () => {});

        this.pusher.bind('finish_stream', () => {
          this.setPublishVideoAvailable(false);
          this.setPublishAudioAvailable(false);
          localStorage.setItem('case_attendance_id', null);
          if (this.$route.name === 'attendance') {
            this.$router.push('/attendance/feedback');
          }
        });

        this.$emit('handleDoctorStartedStream')
      },

      setCamera(isAvailable) {
        this.camIsAvailable = isAvailable
        if (!isAvailable) {
          return this.stopVideoStream()
        }
        return this.startVideoStream()
      },

      async setMicrophone(isAvailable) {
        this.micIsAvailable = isAvailable
        if (!isAvailable) {
          return this.stopAudioStream()
        }
        return this.startAudioStream()
      },

       verifyMediaDevices() {
        if (
          !('mediaDevices' in navigator) ||
          !('getUserMedia' in navigator.mediaDevices)
        ) {
          this.showHardwareUnavailableModal = true;
        }
      },

      async startVideoStream() {
        this.verifyMediaDevices();
        this.stopVideoStream();
        this.constraints.video.facingMode = this.useFrontCamera ? 'user' : 'environment';
        const video = document.querySelector('#video');

        try {
          this.camIsAvailable = true;
          video.srcObject = await this.navigator.mediaDevices.getUserMedia(this.constraints);
          this.videoStream = video.srcObject;

          this.videoStream.oninactive = async () => {
            const status = await  navigator.permissions.query({name: "camera"});

            if (status.state == "denied"){
              this.showModalAlterPermissionsDevices = true
            }
          };

          this.setPublishVideoAvailable(true);
        } catch (err) {
          this.setPublishVideoAvailable(false);
          this.setPublishAudioAvailable(false);
          this.camIsAvailable = false;
        }
      },

      setPublishVideoAvailable(isAvailable) {
        if (this.publisher) {
          this.publisher.publishVideo(isAvailable);
        }
      },

      setPublishAudioAvailable(isAvailable) {
        if (this.publisher) {
          this.publisher.publishAudio(isAvailable);
        }
      },

      stopVideoStream() {
        if (this.videoStream) {
          this.videoStream.getVideoTracks().forEach((track) => {
            track.stop();
          });
        }
        this.setPublishVideoAvailable(false);
      },

      stopAudioStream() {
        if (this.audioStream) {
          this.audioStream.getAudioTracks().forEach((track) => {
            track.stop();
          });
        }
        this.setPublishAudioAvailable(false);
      },

      async startAudioStream() {
        this.verifyMediaDevices();
        this.stopAudioStream();
        const audio = document.querySelector('#audio');
        try {
          this.micIsAvailable = true;
          audio.srcObject = await navigator.mediaDevices.getUserMedia({audio: this.micIsAvailable});
          this.audioStream = audio.srcObject;

          this.videoStream.oninactive = async () => {
            const status = await  navigator.permissions.query({name: "microphone"});

            if (status.state == "denied"){
              this.showModalAlterPermissionsDevices = true
            }
          };

          this.setPublishAudioAvailable(true);
        } catch (err) {
          this.micIsAvailable = false;
          this.showHardwareUnavailableModal = true;

          this.setPublishAudioAvailable(false);
        }
      },

      changeCamera() {
        if (this.$device.mobile) {
          this.useFrontCamera = !this.useFrontCamera;
          this.startVideoStream();
          return;
        }

        setTimeout(() => {
          this.startVideoStream()
        }, 10);
      },

      closeEndAttendanceModal() {
        this.showEndAttendanceModal = !this.showEndAttendanceModal;
      },

      async confirmEndAttendance() {
        this.showEndAttendanceModal = !this.showEndAttendanceModal;
        this.setPublishVideoAvailable(false);
        this.setPublishAudioAvailable(false);
        let payload = {
          case_attendance_id: this.getCaseAttendanceId,
          status: consts.QUEUE_ATTENDANCE_STATUS.DOCTOR_PENDING
        };
        this.queueStatusChange(payload).catch(() => {
          this.setGeneralError(true);
          this.setGeneralErrorRedirectRoute('home');
        });
        localStorage.setItem('case_attendance_id', null);
        this.$router.push('/attendance/feedback');
      },

      async endAttendance() {
        localStorage.setItem('case_attendance_id', null);
        this.setCaseAttendanceId(null)
        this.$router.push('/');
      },

      async disconnect(){
        this.session.disconnect()
        this.session.unpublish(this.publisher)

        await this.setCamera(false);
        await this.setMicrophone(false);

        await this.stopVideoStream();
        await this.stopAudioStream();
      },

      confirmAlterPermissionsDevices(){
        this.$router.push('hardwarecheck')
      }
    },

    computed: {
      ...mapGetters('data/attendance', [
        'getCaseAttendanceId',
      ]),
      ...mapGetters('data/general', [
        'getSocketInfo',
      ]),
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/styles/colors.scss';

  .icon-hangup {
    background-color: $folly !important;
  }

  .container {
    padding: 0;
  }

  #content {
    background-color: $outerSpace;
    height: 100vh;
    overflow: auto;
  }


  #images {
    position: absolute;
    background: $primary;
    width: 100vw;
    height: 20%;
    bottom: 0;
  }

  .close-icon {
    position: absolute;
    right: 2vw;
    top: 1vh;
  }

  .patient-side {
    background-color: $outerSpace;
    height: 100vh;
    max-height: 100vh;
    max-width: 100vw;
    width: 100vw;
    overflow: auto;
  }

  .attendance-instructions-container {
    margin-top: 8vh;
    position: relative;
    display: flex;
    justify-content: center;
  }

  .attendance-instructions-logo {
    max-width: 240px;
  }

  .control-video-call-button-cam {
    margin-bottom: 2px;
  }

  .video-call {
    padding-right: 20px;
    margin: 0 auto;
    color: $cultured;
    aspect-ratio: 4/3;

    .video-call-off {
    background-color: black;
    height: 100%;
    //width: 350px;
    padding-top: 30%;
    }

    .video-call-on {
    height: 100%;
    }
  }

  .video-call-mobile-off {
    background: black;
  }

  .video-call-mobile {
    position: absolute;
    height: 149px;
    width: 112px;
    right: 2vw;
    top: 1vh;
    z-index: 1;
  }

  .video-call-mobile-change-cam {
    position: absolute;
    right: 45px;
    top: 140px;
  }


  //#subscriber {
  //  position: absolute;
  //  left: 0;
  //  top: 0;
  //  width: 100%;
  //  height: 100%;
  //}

  .subscriber-desktop {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .subscriber-mobile {
    height: 90vh;
  }

  #subscriber-mobile {
    z-index: -1;
  }

  #publisher {
    position: absolute;
    width: 360px;
    height: 240px;
    bottom: 10px;
    left: 10px;
    //z-index: 999;
    border: 3px solid white;
    border-radius: 3px;
  }
</style>