<template>
  <v-footer
    :height="$device.mobile ? '100' : '97'"
    class="home-footer text-center justify-center"
    justify="center"
    padless
    tile
  >
    <span
      :class="[ $device.mobile ? 'text-caption' : 'text-body1' ]"
    >
      {{ $t('general.footer') }}
    </span>
  </v-footer>
</template>

<script>
  export default {
    name: 'HomeFooter',

    data: () => ({
      //
    }),
  };
</script>

<style lang="scss" scoped>
  @import '@/styles/colors.scss';

  .home-footer {
    background-color: $outerSpace;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    color: white;
    padding: 1em;
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    .home-footer {
      // 
    }
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .home-footer {
      font-size: 10px;
    }
  }

  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .home-footer {
      // 
    }
  }

  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .home-footer {
      // 
    }
  }
</style>