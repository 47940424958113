import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = () => ({
  attendance: null,
  attendance_attachments: null,
  bannerIcon: null,
  bannerTitle: null,
  company: null,
  companyLogo: '',
  consentTerm: null,
  flavour: null,
  generalError: false,
  generalErrorRedirectRoute: 'login',
  privacyPolicy: null,
  consentTermDialog: false,
  privacyPolicyDialog: false,
  showSplashScreen: true,
  socketInfo: null,
  history_attendance: null,
  programs_info: null,
  doctors_info: null
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};