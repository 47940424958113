export default {
  reset(state) {
    state.case_attendance_id = null;
  },

  setCaseAttendanceId(state, payload) {
    state.case_attendance_id = payload;
  },

  setCancelReasons(state, payload) {
    state.cancel_reasons = payload;
  },

  setFeedback(state, payload) {
    state.feedback = payload;
  },

  setPatientLastAttendance(state, payload){
    state.patient_last_attendance = payload;
  },
};