<template>
  <v-container
    :class="{ 'mt-8 pa-0': $device.mobile }"
    class="attendance-professional"
  >
    <h3
      :class="[ $device.mobile ? 'font-weight-bold mb-3' : 'text-h6 mb-5' ]"
    >
      {{ $t('attendances_details.professional').toUpperCase() }}
    </h3>

    <!-- professional -->
    <v-card
      :width="$device.mobile ? '100%' : '300px'"
      class="d-flex align-center info-box pa-2 mb-4 mr-4"
      outlined
    >
      <v-avatar
        class="avatar mr-4"
        color="white"
        rounded
        size="60"
        tile
      >
        <v-progress-circular
          v-if="loading"
          :width="3"
          color="white"
          indeterminate
        />

        <v-img
          v-else-if="doctor_avatar"
          :src="doctor_avatar"
          alt="avatar"
        />

        <v-icon
          v-else
          color="outerSpace"
        >
          fa-user
        </v-icon>
      </v-avatar>

      <div
        class="d-flex flex-column"
      >
        <h4
          class="font-weight-bold text-caption outerSpace--text"
        >
          {{ attendance.doctor_name }}
        </h4>

        <p
          v-if="!occupation"
          class="ma-0 text-caption outerSpace--text text-wrap"
        >
          {{ $t('general.crm') }}: {{ attendance.doctor_crm }}
        </p>
        <p
          v-else
          class="ma-0 text-caption outerSpace--text text-wrap"
        >
          {{ occupation }}
        </p>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import consts from '@/static/js/consts';

  export default {
    name: 'AttendanceProfessional',
    
    data: () => ({
      loading: false,
      doctor_avatar: null,
      occupation: null,
    }),

    created() {
      this.getDoctorInfo();
    },

    methods: {
      ...mapActions('data/general', [
        'userGet', // -> map `this.userGet()` to `this.$store.dispatch('userGet')`
      ]),

      ...mapMutations('data/general', [
        'setGeneralError', // -> this.setGeneralError
        'setGeneralErrorRedirectRoute', // -> this.setGeneralErrorRedirectRoute
      ]),

      getDoctorInfo() {
        this.loading = true;

        let payload = {
          user_id: this.attendance.doctor_id,
        };

        this.userGet(payload).then((response) => {
          this.doctor_avatar = response.user_info.image_path;
          if (consts.PROGRAM_WELL_BEING_IDS.includes(this.attendance.program_id)) {
              this.occupation = response.role_info.role;
          }
        }).catch((error) => {
          console.error(error);
          this.setGeneralError(true);
          this.setGeneralErrorRedirectRoute('home');
        }).finally(() => {
          this.loading = false;
        });
      },
    },

    computed: {
      ...mapGetters('data/general', {
        attendance: 'getAttendance', // -> this.attendance
      }),
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/styles/colors.scss';

  .attendance-professional {
    .info-box {
      border-radius: 50px !important;
      min-width: 300px;

      .avatar {
        border: $antiFlash solid 1px !important;
        border-radius: 50px !important;
      }
    }
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    .attendance-professional {
      // 
    }
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .attendance-professional {
      // 
    }
  }

  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .attendance-professional {
      // 
    }
  }

  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .attendance-professional {
      // 
    }
  }
</style>