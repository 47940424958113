<template>
  <v-container
    class="login-footer ma-o pa-0 d-flex align-center justify-center"
  >
    <v-btn
      @click="$emit('handlePrivacyPolicy')"
      class="privacy-policy-button cy-privacy-policy-button button pa-0 mr-4"
      text
      height="36"
    >
      {{ $t('login.privacy_policy') }}
    </v-btn>

    <v-divider
      class="secondary"
      vertical
    />

    <div
      class="mx-4"
    >
      {{ portalWebVersion }}
    </div>

    <v-divider
      class="secondary"
      vertical
    />

    <v-btn
      @click="$emit('handleConsentTerm')"
      class="consent-term-button cy-consent-term-button button pa-0 ml-4"
      text
      height="36"
    >
      {{ $t('login.consent_term') }}
    </v-btn>
  </v-container>
</template>

<script>
  export default {
    name: 'LoginFooter',

    props: {
      // Function to handle privacy policy action
      handlePrivacyPolicy: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },

      // Function to handle consent term action
      handleConsentTerm: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },
    },

    data: () => ({
      // 
    }),

    computed: {
      portalWebVersion() {
        return `v${process.env.VUE_APP_VERSION}`;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .login-footer {
    .button {
      text-align: left;
      font-size: 13px;
      white-space: pre-wrap;
    }
  }
</style>
