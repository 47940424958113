<!-- THIS COMPONENT IS UST AN EXAMPLE, DO NOT EDIT! -->

<template>
  <v-container>
    <div>
      <v-img :src="companyLogo || require('@/assets/logos/teladoc_health.svg')" class="logo" contain max-height="100"
        max-width="265" />
    </div>

    <div v-if="propA" class="propA">
      {{ propA }}
    </div>

    <div v-if="propB" class="propB">
      {{ propB }}
    </div>

    <div class="propC">
      {{ propC }}
    </div>

    <div class="propD">
      {{ propD }}
    </div>

    <div class="propE">
      <span v-if="propE.name" id="name">
        {{ propE.name }}
      </span>

      <span v-if="propE.data" id="data">
        {{ propE.data }}
      </span>
    </div>

    <div class="actions">
      <v-text-field v-model="textField" class="textField" label="textField" />

      <div>
        <h2>Pressione F5 para Preloader Splash</h2>
      </div>

      <v-checkbox v-model="checkbox" class="checkbox" label="checkbox" />

      <v-btn @click="$emit('propG')" class="btn">
        button
      </v-btn>

      <v-btn @click="onButtonClick()" class="modal-button">
        open modal
      </v-btn>
      <v-btn @click="onButtonClickPhoneCode()" class="cy-btn-modal-phone-code modal-button">
        Validação de número de celular
      </v-btn>
    </div>

   <!--  <div>
      <template>
        <v-container class="container bg-surface-variant">
          <v-btn class="programs programs-button-especialidade cy-programs-button-especialidade black--white"
            v-bind:class="{ 'primary': !clicked, 'white': clicked }" v-on:click="buttonPrograms(speciality_programs)" depressed height="30"
            rounded width="200px">
            Especialidades médicas
          </v-btn>
          <v-btn class="programs programs-button-bem-estar cy-programs-button-bem-estar black--text" depressed
            v-bind:class="{ 'white': !clicked, 'primary': clicked, 'white--text': clicked }" v-on:click="buttonPrograms(health_programs)"
            height="30" rounded width="200px">
            Bem-estar
          </v-btn>
          <v-card v-scroll.self="onScroll" class="overflow-y-auto pa-2" height="400">
            <v-row no-gutters class="list-programs ma-0 pa-0">
              <v-col v-for="n in programs_list" :key="n" cols="12" md="4" class="program-item ma-0 pa-2">
                <v-btn class="button-item cy-button-item px-0 text-center" color="white" width="350px" rounded>
                  <v-img
                    :src="require('/home/fernandohenriquearaujo/Downloads/program15082022_f5a463004a46046b8febb48894b774ea_pediatra.png')"
                    class="alert-header"
                    contain
                    width="9.96px"
                    height="17.1px"
                  />
                  Programa {{ n }}
                  <v-icon class="icon-button" color="blue">
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </template>
    </div> -->


    <GeneralModal :dialog="dialog" :header-close="false" @handleCancel="handleCancel" @handleClose="handleClose"
      @handleConfirm="handleConfirm" @handleHeaderInfo="handleHeaderInfo" background-color="antiFlash" has-cancel
      has-confirm has-header-close has-header-info header-icon="fa-envelope" header-title="Teste Título" invert-actions
      width="900">
      <div>teste</div>
    </GeneralModal>

    <GeneralModal :dialog="dialogPhoneCode" :header-close="false" @handleClose="handleClosePhoneCode"
      @handleHeaderInfo="handleHeaderInfo" background-color="antiFlash" has-close logo invert-actions width="550">
      <PhoneCodeVerification />
    </GeneralModal>
    <!-- Footer -->
    <HomeFooter />
  </v-container>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import GeneralModal from './GeneralModal';
import PhoneCodeVerification from "@/components/general/PhoneCodeVerification.vue";
import HomeFooter from '@/components/home/HomeFooter';
import { maxLength, minLength, required } from "vuelidate/lib/validators";

export default {
  name: 'TestComponent',

  components: {
    GeneralModal,
    PhoneCodeVerification,
    HomeFooter
  },

  props: {
    // Basic type check
    //  (`null` and `undefined` values will allow any type)
    propA: Number,
    // Multiple possible types
    propB: [String, Number],
    // Required string
    propC: {
      type: String,
      required: true
    },
    // Number with a default value
    propD: {
      type: Number,
      default: 100
    },
    // Object with a default value
    propE: {
      type: Object,
      // Object or array defaults must be returned from
      // a factory function. The function receives the raw
      // props received by the component as the argument.
      default(rawProps = { name: 'test', data: 'test' }) {
        return rawProps;
      }
    },
    // Custom validator function
    propF: {
      validator(value) {
        // The value must match one of these strings
        return ['success', 'warning', 'danger'].includes(value)
      }
    },
    // Function with a default value
    propG: {
      type: Function,
      // Unlike object or array default, this is not a factory function - this is a function to serve as a default value
      default() {
        return () => console.log('Default function');
      }
    },
  },

  data: () => ({
    textField: '',
    checkbox: false,
    dialog: false,
    dialogPhoneCode: false,
    phone_number: null,
    loading: false,
    clicked: false,
    speciality_programs: [1,2,3,4,5,6],
    health_programs: [7,8,9],
    programs_list: []

  }),

  beforeCreate() {
    // 
  },

  created() {
    // 
  },

  beforeMount() {
    // 
  },

  mounted() {
    // this.changeTheme();
  },

  methods: {
    ...mapMutations('data/general', [
      'setCompanyLogo', // -> this.setCompanyLogo
    ]),

    onButtonClick() {
      this.dialog = true;
      console.log('onButtonClick');
    },

    onButtonClickPhoneCode() {
      this.dialogPhoneCode = true;
    },
    handleCancel() {
      this.dialog = false;
      console.log('handleCancel');
    },

    handleClose() {
      this.dialog = false;
      console.log('handleClose');
    },
    handleClosePhoneCode() {
      this.dialogPhoneCode = false;
    },
    handleClosePhoneChange() {
      this.dialogPhoneChange = false;
    },
    handleConfirm() {
      this.dialog = false;
      console.log('handleConfirm');
    },
    handleHeaderInfo() {
      console.log('handleHeaderInfo');
    },
    handleSelect() {
      console.log('handleSelect');
    },
    changeTheme() {
      setTimeout(() => {
        let response = {
          data: {
            splash_asset: 'https://storage.googleapis.com/download/storage/v1/b/teladoc-production/o/fc12ded802cecd33b0085f896196a265_8fa.png?generation=1610474126232448&alt=media',
            icon_asset: 'https://storage.googleapis.com/download/storage/v1/b/teladoc-production/o/fc12ded802cecd33b0085f896196a265_8fa.png?generation=1610474126232448&alt=media',
            main_color: '#0094D9',
            secondary_color: '#777777',
            flavour_id: 1,
            buttons: []
          },
          error: null
        }

        // Change store logo url
        this.setCompanyLogo(response.data.splash_asset);

        // Change theme colors
        let newTheme = {
          primary: response.data.main_color,
          secondary: response.data.secondary_color
        }
        this.$vuetify.theme.themes.dark = {
          ...this.$vuetify.theme.themes.dark,
          ...newTheme
        };
        this.$vuetify.theme.themes.light = {
          ...this.$vuetify.theme.themes.light,
          ...newTheme
        };
      }, 5000);
    },
    buttonPrograms(programs) {
      this.clicked = !this.clicked;
      this.programs_list = programs;
    }
  },

  computed: {
    ...mapGetters('data/general', {
      companyLogo: 'getCompanyLogo', // -> this.getCompanyLogo
    }),
  },
  validations: {
    phone_number: {
      required,
      minLength: minLength(12),
      maxLength: maxLength(12)
    },
  }
};
</script>
<style lang="scss" scoped>
.icon-button {
  padding-left: 155px;
}

.programs-button-bem-estar {
  left: 20px;
}

.list-programs {
  padding-top: 20px;
}

.container {
  padding-top: 30px;
}
</style>
