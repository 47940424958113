export default {
  reset (state) {
    state.patient = null
    state.datetime = null
    state.professional = null
    state.files = []
  },

  setPatient (state, payload) {
    state.patient = payload
  },

  setSchedule (state, payload) {
    state.patient = payload.patient
    state.program = payload.program
    state.datetime = payload.datetime
    state.professional = payload.professional
  },

  resetSchedule (state) {
    state.datetime = null
    state.professional = null
    state.files = []
  },

  resetDatetime (state) {
    state.datetime = null
  },

  resetFiles (state) {
    state.files = []
  },

  resetProgram (state) {
    state.program = null
  },

  resetPrograms (state) {
    state.program = null
    state.programs = null
  },

  resetSlots (state) {
    state.schedules_slots = null;
  },

  setProfessional (state, payload) {
    state.professional = payload;
  },

  setProgram (state, payload) {
    state.program = payload
  },

  setPrograms (state, payload) {
    state.programs = payload
  },

  setDatetime (state, payload) {
    return state.datetime = payload
  },

  setCheckDoubleBooking (state, payload) {
    return state.check_double_booking = payload
  },

  setScheduleSlots (state, payload) {
    state.schedules_slots = { ...state.schedules_slots, ...payload };
  },

  setFile (state, payload) {
    return state.files.push(payload)
  },

  removeFileIndex (state, index) {
    state.files.splice(index, 1)
  },

  setVerifySchedule (state, payload) {
    return state.verify_schedule = payload
  },

  setUserSchedulesIds (state, payload) {
    return state.user_schedules_ids = payload
  },

  setScheduleType (state, type) {
    return state.scheduleType = type
  },
}
