export default {
  getCaseAttendanceId: state => {
    return state.case_attendance_id;
  },

  getCancelReasons: state => {
    return state.cancel_reasons;
  },

  getFeedback: state => {
    return state.feedback;
  },

  getPatientLastAttendance: state => {
    return state.patient_last_attendance;
  },
};