<template>
  <v-container
    class="cy-login-info-form login-info-form ma-o pa-0"
  >
    <v-form
      autocomplete="off"
      :class="{ 'px-4': $device.mobile }"
    >
      <!-- Header -->
      <h1
        :class="[ $device.mobile ? 'text-h6' : 'text-h5' ]"
        class="my-4 outerSpace--text text-center"
      >
        {{ $t('register.create_login_password') }}
      </h1>

      <!-- Form -->
      <v-row
        class="ma-0 pt-6"
      >
        <v-col
          :class="{ 'py-0': $device.mobile }"
          class="px-0 pt-0"
          cols="12"
          md="12"
        >
          <v-text-field
            v-model="form.email"
            :class="{ 'my-0 py-0': $device.mobile }"
            :error-messages="emailErrors"
            :label="$t('register.email')"
            @blur="$v.form.email.$touch()"
            class="cy-input-email input-email pa-0"
            height="37"
            required
          />
        </v-col>

        <v-col
          :class="{ 'py-0': $device.mobile }"
          class="px-0 pt-0"
          cols="12"
          md="12"
        >
          <v-text-field
            v-model="emailConfirmation"
            :class="{ 'my-0 py-0': $device.mobile }"
            :error-messages="emailConfirmationErrors"
            :label="$t('register.email_confirmation')"
            @blur="$v.emailConfirmation.$touch()"
            class="cy-input-email-confirmation input-email-confirmation pa-0"
            height="37"
            required
          />
        </v-col>

        <v-col
          :class="{ 'py-0': $device.mobile }"
          class="px-0 pt-0"
          cols="12"
          md="12"
        >
          <v-text-field
            v-model="form.password"
            :class="{ 'my-0 py-0': $device.mobile }"
            :error-messages="passwordErrors"
            :label="$t('register.password')"
            :type="isPasswordVisible ? 'text' : 'password'"
            @blur="$v.form.password.$touch()"
            class="cy-input-password input-password pa-0"
            height="37"
            required
          >
            <v-icon
              @click="isPasswordVisible = !isPasswordVisible"
              color="secondary"
              slot="append"
              small
            >
              {{ isPasswordVisible ? 'fa-eye-slash' : 'fa-eye'}}
            </v-icon>
          </v-text-field>
        </v-col>

        <v-col
          :class="{ 'py-0': $device.mobile }"
          class="px-0 pt-0"
          cols="12"
          md="12"
        >
          <v-text-field
            v-model="passwordConfirmation"
            :class="{ 'my-0 py-0': $device.mobile }"
            :error-messages="passwordConfirmationErrors"
            :label="$t('register.password_confirmation')"
            :type="isPasswordConfirmationVisible ? 'text' : 'password'"
            @blur="$v.passwordConfirmation.$touch()"
            class="cy-input-password-confirmation input-password-confirmation pa-0"
            height="37"
            required
          >
            <v-icon
              @click="isPasswordConfirmationVisible = !isPasswordConfirmationVisible"
              color="secondary"
              slot="append"
              small
            >
              {{ isPasswordConfirmationVisible ? 'fa-eye-slash' : 'fa-eye'}}
            </v-icon>
          </v-text-field>
        </v-col>
      </v-row>

      <!-- Password Rules -->
      <v-row
        :class="{ 'mt-1': $device.mobile }"
      >
        <v-col
          class="d-flex pa-0"
          cols="12"
          md="12"
        >
          <v-card
            :height="$device.mobile ? '210' : '167'"
            class="pa-4 d-flex flex-column"
            color="#F7F6F1"
            width="444"
            flat
          >
            <!-- Password Rules title -->
            <h3
              :class="{ 'text-subtitle-2': $device.mobile }"
              class="secondary--text text-uppercase text-center mb-3"
            >
              {{ $t('register.password_rules') }}
            </h3>

            <!-- Password Rules box -->
            <div
              :class="[ $device.mobile ? 'flex-column' : 'flex-row' ]"
              class="d-flex justify-space-between"
            >
              <div
                class="d-flex flex-column"
              >
                <!-- Password minLength rule -->
                <p
                  :class="{
                    'text-subtitle-2 py-0 mb-2': $device.mobile,
                    'spanishGray--text': !form.password || !$v.form.password.minLength,
                    'outerSpace--text': form.password && $v.form.password.minLength,
                  }"
                  class="d-flex align-center"
                >
                  <v-icon
                    :color="!form.password || !$v.form.password.minLength ? 'spanishGray' : 'secondary'"
                    class="mr-2"
                    small
                  >
                    {{ !form.password || !$v.form.password.minLength ? 'fa-circle-minus' : 'fa-circle-check' }}
                  </v-icon>
                  {{ $t('register.password_rule_min') }}
                </p>

                <!-- Password uppercase rule -->
                <p
                  :class="{
                    'text-subtitle-2 py-0 mb-2': $device.mobile,
                    'spanishGray--text': !form.password || !$v.form.password.uppercase,
                    'outerSpace--text': form.password && $v.form.password.uppercase,
                  }"
                  class="d-flex align-center"
                >
                  <v-icon
                    :color="!form.password || !$v.form.password.uppercase ? 'spanishGray' : 'secondary'"
                    class="mr-2"
                    small
                  >
                    {{ !form.password || !$v.form.password.uppercase ? 'fa-circle-minus' : 'fa-circle-check' }}
                  </v-icon>
                  {{ $t('register.password_rule_uppercase') }}
                </p>
              </div>

              <div
                class="d-flex flex-column"
              >
                <!-- Password number rule -->
                <p
                  :class="{
                    'text-subtitle-2 py-0 mb-2': $device.mobile,
                    'spanishGray--text': !form.password || !$v.form.password.number,
                    'outerSpace--text': form.password && $v.form.password.number,
                  }"
                  class="d-flex align-center"
                >
                  <v-icon
                    :color="!form.password || !$v.form.password.number ? 'spanishGray' : 'secondary'"
                    class="mr-2"
                    small
                  >
                    {{ !form.password || !$v.form.password.number ? 'fa-circle-minus' : 'fa-circle-check' }}
                  </v-icon>
                  {{ $t('register.password_rule_number') }}
                </p>

                <!-- Password lowercase rule -->
                <p
                  :class="{
                    'text-subtitle-2 py-0 mb-2': $device.mobile,
                    'spanishGray--text': !form.password || !$v.form.password.lowercase,
                    'outerSpace--text': form.password && $v.form.password.lowercase,
                  }"
                  class="d-flex align-center"
                >
                  <v-icon
                    :color="!form.password || !$v.form.password.lowercase ? 'spanishGray' : 'secondary'"
                    class="mr-2"
                    small
                  >
                    {{ !form.password || !$v.form.password.lowercase ? 'fa-circle-minus' : 'fa-circle-check' }}
                  </v-icon>
                  {{ $t('register.password_rule_lowercase') }}
                </p>
              </div>
            </div>

            <!-- Password specialCharacter rule -->
            <p
              :class="{
                'text-subtitle-2 py-0 mb-2': $device.mobile,
                'spanishGray--text': !form.password || !$v.form.password.specialCharacter,
                'outerSpace--text': form.password && $v.form.password.specialCharacter,
              }"
              class="d-flex align-center"
            >
              <v-icon
                :color="!form.password || !$v.form.password.specialCharacter ? 'spanishGray' : 'secondary'"
                class="mr-2"
                small
              >
                {{ !form.password || !$v.form.password.specialCharacter ? 'fa-circle-minus' : 'fa-circle-check' }}
              </v-icon>
              {{ $t('register.password_rule_special_character') }}
            </p>
          </v-card>
        </v-col>
      </v-row>

      <!-- Action -->
      <v-row
        class="ma-0"
      >
        <v-col
          class="d-flex justify-center px-0"
          cols="12"
          md="12"
        >
          <v-btn
            :class="{ 'disabled-button-dark': disableNext }"
            @click="save"
            class="cy-confirm outline-border mb-0 mt-8 px-8"
            color="outerSpace"
            dark
            depressed
            height="46"
            rounded
            width="230"
          >
            {{ $t('general.next') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import { email, maxLength, minLength, required, sameAs } from 'vuelidate/lib/validators';
  import { helpers } from 'vuelidate/lib/validators';
  const emailPattern = helpers.regex('emailPattern', /^[\w@.-_]+$/);
  const uppercase = helpers.regex('uppercase', /[A-Z]/);
  const number = helpers.regex('uppercase', /[0-9]/);
  const lowercase = helpers.regex('lowercase', /[a-z]/);
  const specialCharacter = helpers.regex('specialCharacter', /[^A-Za-z0-9 ]/);

  export default {
    name: 'LoginInfoForm',

    props: {
      // Function to handle confirm action
      handleConfirm: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },
    },

    data: () => ({
      emailConfirmation: null,
      form: {
        email: null,
        password: null,
      },
      isPasswordVisible: false,
      isPasswordConfirmationVisible: false,
      loading: false,
      passwordConfirmation: null,
    }),

    methods: {
      ...mapActions('data/register', [
        'onboardingPatientSignup', // -> map `this.onboardingPatientSignup()` to `this.$store.dispatch('onboardingPatientSignup')`
      ]),

      ...mapMutations('data/register', [
        'setPersonalInfo', // this.setPersonalInfo
        'setLoginInfo', // this.setLoginInfo
      ]),

      save() {
        this.$v.$touch();

        if (this.$v.$invalid) return;

        this.setLoginInfo(this.form);

        this.loading = true;

        this.onboardingPatientSignup(this.registerData).then(() => {
          this.$emit('handleConfirm');
        }).catch((error) => {
          console.error(error);
        }).finally(() => {
          this.loading = false;
        });

      },
    },

    computed: {
      ...mapGetters('data/register', {
        registerData: 'getRegisterData', // -> this.registerData
      }),

      disableNext() {
        return !this.$v.form.email.required
          || !this.$v.form.password.required
          || !this.$v.emailConfirmation.required
          || !this.$v.passwordConfirmation.required
          || !this.$v.form.password.uppercase
          || !this.$v.form.password.number
          || !this.$v.form.password.lowercase
          || !this.$v.form.password.specialCharacter
          || !this.$v.form.password.minLength;
      },

      emailErrors() {
        const errors = [];
        if (!this.$v.form.email.$dirty) return errors;
        !this.$v.form.email.email && errors.push(this.$t('register.email_invalid'));
        !this.$v.form.email.emailPattern && errors.push(this.$t('register.email_invalid_characters'));
        !this.$v.form.email.required && errors.push(this.$t('general.required'));
        return errors;
      },

      passwordErrors() {
        const errors = [];
        if (!this.$v.form.password.$dirty) return errors;
        !this.$v.form.password.uppercase && errors.push('');
        !this.$v.form.password.number && errors.push('');
        !this.$v.form.password.lowercase && errors.push('');
        !this.$v.form.password.specialCharacter && errors.push('');
        !this.$v.form.password.maxLength && errors.push('');
        !this.$v.form.password.minLength && errors.push('');
        !this.$v.form.password.required && errors.push(this.$t('general.required'));
        return errors;
      },

      emailConfirmationErrors() {
        const errors = [];
        if (!this.$v.emailConfirmation.$dirty) return errors;
        !this.$v.emailConfirmation.email && errors.push(this.$t('register.email_invalid'));
        !this.$v.emailConfirmation.emailPattern && errors.push(this.$t('register.email_invalid_characters'));
        !this.$v.emailConfirmation.sameAsEmail && errors.push(this.$t('register.email_confirmation_different'));
        !this.$v.emailConfirmation.required && errors.push(this.$t('general.required'));
        return errors;
      },

      passwordConfirmationErrors() {
        const errors = [];
        if (!this.$v.passwordConfirmation.$dirty) return errors;
        !this.$v.passwordConfirmation.required && errors.push(this.$t('general.required'));
        !this.$v.passwordConfirmation.sameAsPassword && errors.push(this.$t('register.password_confirmation_invalid'));
        return errors;
      },
    },

    validations: {
      form: {
        email: {
          email,
          emailPattern,
          required,
        },
        password: {
          uppercase,
          number,
          lowercase,
          specialCharacter,
          maxLength: maxLength(20),
          minLength: minLength(8),
          required,
        },
      },
      emailConfirmation: {
        email,
        emailPattern,
        required,
        sameAsEmail: sameAs(function() {
          return this.form.email;
        }),
      },
      passwordConfirmation: {
        required,
        sameAsPassword: sameAs(function() {
          return this.form.password;
        }),
      },
    }
  };
</script>

<style lang="scss" scoped>
  .login-info-form {
    // 
  }
   
  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    .login-info-form {
      // 
    }
  }
   
  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .login-info-form {
      // 
    }
  }
   
  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .login-info-form {
      // 
    }
  }
   
  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .login-info-form {
      // 
    }
  }
</style>
