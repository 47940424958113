<template>
  <div
    class="preloader-splash d-flex align-center justify-center"
  >
    <!-- Loading -->
    <div
      v-if="loading"
      class="text-center"
    >
      <v-progress-circular
        :size="$device.mobile ? '40' : '80'"
        :width="$device.mobile ? '3' : '7'"
        color="primary"
        indeterminate
      />
    </div>

    <!-- Splash screen -->
    <div
      v-else
      class="preloader d-flex align-center"
    >
      <v-img
        :src="companyLogo || require('@/assets/logos/teladoc_health.svg')"
        contain
      />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import consts from '@/static/js/consts';

  export default {
    name: 'PreloaderSplash',

    data: () => ({
      loading: true,
    }),

    mounted() {
      this.getFlavour();
    },

    methods: {
      ...mapActions('data/general', [
        'flavourSettingsGet', // -> map `this.flavourSettingsGet()` to `this.$store.dispatch('flavourSettingsGet')`
      ]),

      ...mapMutations('data/general', [
        'setGeneralError', // -> this.setGeneralError()
        'setShowSplashScreen', // -> this.setShowSplashScreen()
      ]),

      getFlavour() {
        if (!this.flavour_id) {
          document.documentElement.style.setProperty('--primary', consts.DEFAULT_COLORS.PRIMARY);
          document.documentElement.style.setProperty('--secondary', consts.DEFAULT_COLORS.SECONDARY);

          this.loading = false;

          return setTimeout(() => {
            this.setShowSplashScreen();

            if (this.$route.name !== 'home') {
              this.$router.push('/');
            }
          }, 2000);
        }

        this.loading = true;

        let payload = {
          flavour_id: this.flavour_id
        };

        this.flavourSettingsGet(payload).then((response) => {
          // Change theme colors
          let newTheme = {
            primary: response.main_color,
            secondary: response.secondary_color
          }

          this.$vuetify.theme.themes.dark = {
            ...this.$vuetify.theme.themes.dark,
            ...newTheme
          };

          this.$vuetify.theme.themes.light = {
            ...this.$vuetify.theme.themes.light,
            ...newTheme
          };

          document.documentElement.style.setProperty('--primary', newTheme.primary);
          document.documentElement.style.setProperty('--secondary', newTheme.secondary);

          setTimeout(() => {
            this.setShowSplashScreen();

            if (this.$route.name !== 'home') {
              this.$router.push('/');
            }
          }, 2000);
        }).catch((error) => {
          console.error(error);
          // If flavour gets an error should logout user. Do NOT send route to 'setGeneralError'
          this.setGeneralError(true);
        }).finally(() => {
          this.loading = false;
        });
      },
    },

    computed: {
      ...mapGetters('data/general', {
        company: 'getCompany',  // -> this.company
        companyLogo: 'getCompanyLogo',  // -> this.companyLogo
        flavour: 'getFlavour',  // -> this.flavour
      }),

      ...mapGetters('data/login', {
        flavour_id: 'getFlavourId',  // -> this.flavour_id
      }),
    },
  }
</script>

<style lang="scss" scoped>
  .preloader-splash {
    width: 100vw;
    height: 100vh;
    background-color: #FFF;

    animation: fadeInAnimation ease .5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    .preloader {
      animation: logoAnimation ease 2s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }

    100% {
      opacity:1;
    }
  }

  @keyframes logoAnimation {
    0% {
      opacity: 0;
      height: 100vh;
      width: 100vw;
    }

    20% {
      opacity: 1;
      height: 50vh;
      width: 50vw;
    }

    80% {
      opacity: 1;
      height: 50vh;
      width: 50vw;
    }

    100% {
      opacity: 0;
      height: 50vh;
      width: 50vw;
    }
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    .preloader-splash {
      // 
    }
  }
   
  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .preloader-splash {
      // 
    }

    @keyframes logoAnimation {
      0% {
        opacity: 0;
        height: 100vh;
        width: 100vw;
      }

      20% {
        opacity: 1;
        height: 50vh;
        width: 80vw;
      }

      80% {
        opacity: 1;
        height: 50vh;
        width: 80vw;
      }

      100% {
        opacity: 0;
        height: 50vh;
        width: 80vw;
      }
    }
  }
   
  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .preloader-splash {
      // 
    }

    @keyframes logoAnimation {
      0% {
        opacity: 0;
        height: 100vh;
        width: 100vw;
      }

      20% {
        opacity: 1;
        height: 50vh;
        width: 80vw;
      }

      80% {
        opacity: 1;
        height: 50vh;
        width: 80vw;
      }

      100% {
        opacity: 0;
        height: 50vh;
        width: 80vw;
      }
    }
  }
   
  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .preloader-splash {
      // 
    }

    @keyframes logoAnimation {
      0% {
        opacity: 0;
        height: 100vh;
        width: 100vw;
      }

      20% {
        opacity: 1;
        height: 50vh;
        width: 80vw;
      }

      80% {
        opacity: 1;
        height: 50vh;
        width: 80vw;
      }

      100% {
        opacity: 0;
        height: 50vh;
        width: 80vw;
      }
    }
  }
</style>

