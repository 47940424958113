<template>
  <v-container
    class="feedback-view d-flex flex-column pa-0"
    max-width="100%"
  >
    <!-- Loader -->
    <v-card
      v-if="loading"
      class="content text-center ma-0 px-0 py-16"
      width="100%"
      flat
    >
      <v-progress-circular
        :size="$device.mobile ? 40 : 70"
        :width="$device.mobile ? 4 : 7"
        color="primary"
        indeterminate
      />
    </v-card>

    <!-- Home Components -->
    <v-card
        v-else
    >
      <div :class="[ $device.mobile ? 'pa-0' : 'pa-8' ]" class="content mb-10">
        <div>
          <v-form>
            <v-container v-if="getQuestions">
              <v-row class="questions_group_row"
                 :key="index" v-for="(item, index) in getQuestions.questions">
                <v-col
                  v-if="item.active"
                  cols="12"
                  md="12"
                  class="questions_group"
                >
                  <p>
                    {{ $t('feedback.' + item.question + '.part_1') }}
                    <b>{{ $t('feedback.' + item.question + '.part_2') }}</b>
                    {{ $t('feedback.' + item.question + '.part_3') }}
                  </p>
                  <v-radio-group
                    class="radio-group"
                    v-model="form[item.question]"
                    row
                  >
                    <div class="d-flex flex-column justify-center align-center mr-3" :key="n"
                       v-for="n in 11">
                      <v-radio
                        color="secondary"
                        :value="n-1"
                      />
                      <span><strong :class="[ form[item.question] == n-1
                      ? 'active-radio' : 'inactive-radio' ]">{{ n - 1 }}</strong></span>
                    </div>

                  </v-radio-group>
                  <v-textarea
                      v-model="form[item.question + '_message']"
                      color="secondary"
                      :label="$t('feedback.how_can_we_improve')"
                      auto-grow
                      outlined
                      rows="5"
                      row-height="15"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row class="form-btn">
                <v-col
                    class="not_evaluate_col"
                    cols="6"
                    md="3"
                    sm="3"
                >
                  <v-btn @click="notEvaluateOption" rounded large elevation="0"
                       class="mb-0 not_evaluate_btn btn-lg col-12">
                    {{ $t('feedback.not_evaluate') }}
                  </v-btn>
                </v-col>
                <v-col
                    class="evaluate_col d-flex justify-end"
                    cols="6"
                    md="3"
                    sm="3"
                >
                  <v-btn @click="evaluateOption" :disabled="evaluateBtn" elevation="0"
                       color="outerSpace" rounded
                       large
                       class="evaluate_btn col-12">
                    {{ $t('feedback.evaluate') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </div>
        <div class="d-flex flex-wrap align-center feedback-img">
          <v-img
              contain
              height="20em"
              width="30em"
              :src="require('@/assets/general/home_doctors.svg')"
          />
        </div>
      </div>
    </v-card>

    <!-- Footer -->
    <HomeFooter/>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import { required } from 'vuelidate/lib/validators';
  import HomeFooter from '@/components/home/HomeFooter';

  export default {
    name: 'FeedbackView',

    components: {
      HomeFooter,
    },

    data: () => ({
      loading: false,
      form: {},
    }),

    created() {
      this.loading = true;
      this.checkHasCaseAttendanceId();
    },

    mounted() {
      this.setBannerTitle(this.$t('general.banner_feedback'));
      this.setBannerIcon('fa-heart-pulse');
    },

    methods: {
      ...mapActions('data/feedback', [
        'getRatingQuestions',
        'sendRatingQuestions'
      ]),

      ...mapMutations('data/attendance', [
        'setCaseAttendanceId',
        'setFeedback',
      ]),

      ...mapMutations('data/general', [
        'setBannerTitle',
        'setBannerIcon',
        'setGeneralError',
        'setGeneralErrorRedirectRoute',
      ]),

      checkHasCaseAttendanceId() {
        if (!this.getCaseAttendanceId) {
          this.setGeneralError(true);
          this.setGeneralErrorRedirectRoute('home');
          this.loading = false;
        } else {
          this.getRatingQuestions().then(() => {
            this.loading = false
          }).catch(() => {
            this.loading = false
            this.setGeneralError(true);
            this.setGeneralErrorRedirectRoute('home');
          });
        }
      },

      evaluateOption() {
        let questions = this.getQuestions.questions
        let data = []

        for (let item of questions) {
          if (item.active) {
            data.push({
              'case_attendance_id': this.getCaseAttendanceId,
              'member_id': this.getMemberId,
              'rating_question_id': item.id,
              'rating': this.form[item.question],
              'message': this.form[item.question + '_message'] ?? ''
            });
          }
        }

        this.sendRatingQuestions({feedback: data}).then(() => {
          this.setFeedback(true);
          this.setCaseAttendanceId(null);
          this.goFeedbackSuccess();
        }).catch(() => {
          this.setGeneralError(true);
          this.setGeneralErrorRedirectRoute('home');
        });
      },

      notEvaluateOption() {
        this.setFeedback(false);
        this.setCaseAttendanceId(null);
        this.goFeedbackSuccess();
      },

      goFeedbackSuccess() {
        this.$router.push('/feedback/success');
      },
    },

    computed: {
      ...mapGetters('data/attendance', {
        getCaseAttendanceId: 'getCaseAttendanceId',
      }),

      ...mapGetters('data/login', {
        getMemberId: 'getMemberId',
      }),

      ...mapGetters('data/feedback', {
        getQuestions: 'getQuestions',
      }),

      evaluateBtn: function () {
        this.$v.$touch();
        return this.$v.$invalid
      },

      formValidation() {
        let questions = {
          form: {}
        };

        if (this.getQuestions.questions.length > 0) {
          this.getQuestions.questions.forEach(attr => {
            if (attr.active) {
              questions.form[attr.question] = {
                required
              }
            }
          });
        }

        return {form: questions.form};
      }
    },

    validations() {
      return this.formValidation;
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/styles/colors.scss';

  .feedback-view {
    max-width: 100%;

    .content {
      font-size: 16px;
      padding: 1em !important;
      display: flex;
      justify-content: center;
      min-height: calc(100vh - 246px - 97px);
      margin-top: 2em;

      .form-btn {
        display: flex;
        justify-content: flex-end;

        .v-btn {
          width: 11em;
          height: 2.6em;
          font-size: 18px;
        }
      }

      /* For Tablet View */
      @media screen and (min-device-width: 641px) and (max-device-width: 1024px) {
        margin-top: 0;
        min-height: calc(100vh - 216px - 97px);
        flex-direction: column-reverse;

        .feedback-img {
          margin-top: 4em;
        }
      }

      /* For Mobile Phones Portrait or Landscape View */
      @media screen and (max-device-width: 640px) {
        margin-top: 0;
        min-height: calc(100vh - 129px - 76px);

        /* For iPhone 5 Portrait or Landscape View */
        @media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
          min-height: calc(100vh - 129px - 76px);
        }

        /* For iPhone 6 and 6 plus Portrait or Landscape View */
        @media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {
          min-height: calc(100vh - 129px - 76px);
        }
        .radio-group-btn {
          margin: 2px;
        }

        .feedback-img {
          display: none !important;
        }

        .form-btn {
          margin-top: 2em;
          justify-content: space-between;

          .v-btn {
            height: 2.6em;
            font-size: 16px;
          }
        }

        .questions_group {
          margin-top: 2em;
          background: #F5FAF4;
          border: 1px solid #D8D8D8;
          font-size: 14px;
        }

        .radio-group {
          font-size: 14px;

          div {
            margin: auto !important;
          }
        }

        .not_evaluate_col {
          padding-left: 0 !important;
        }

        .evaluate_col {
          padding-right: 0 !important;
        }
      }
    }
  }

  .active-radio {
    color: $secondary;
  }

  .inactive-radio {
    color: $outerSpace;
  }

  .not_evaluate_btn {
    background-color: #FFFFFF !important;
    border: 1px solid #C4C4C4;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #444444;
  }

  .evaluate_btn {
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF !important;
  }

  .v-btn--disabled {
    color: #FFFFFF !important;
  }
</style>
