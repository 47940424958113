<template>
  <v-container
      class="privacy-policy ma-auto my-0 pa-0"
  >
    <v-card
        :class="[
        $device.mobile ? 'pr-2' : 'pr-9',
        loading ? 'pt-0' : 'pt-8'
      ]"
        :loading="loading"
        class="overflow-hidden pb-5 pl-8"
        flat
        height="100%"
        outlined
    >
      <v-card
          class="cy-privacy-policy-content text overflow-y-auto pl-0 pr-5 py-0"
          flat
          height="100%"
      >
        {{ privacyPolicy }}
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations
} from 'vuex';

export default {
  name: 'PrivacyPolicy',

  data: () => ({
    loading: false,
    error: false,
  }),

  created() {
    if (!this.privacyPolicy) {
      this.loading = true;

      this.getPrivacyPolicy().then(() => {
        this.error = false;
      }).catch((error) => {
        this.error = error;
      }).finally(() => {
        this.loading = false;
      });
    }
  },

  methods: {
    ...mapActions('data/general', [
      'getPrivacyPolicy', // -> map `this.PrivacyPolicy()` to `this.$store.dispatch('getPrivacyPolicy')`
    ]),

    ...mapMutations('data/general', [
      'setPrivacyPolicy', // -> this.setPrivacyPolicy
    ]),
  },

  computed: {
    ...mapGetters('data/general', {
      privacyPolicy: 'getPrivacyPolicy', // -> this.privacyPolicy
    }),
  }
};
</script>

<style lang="scss" scoped>
.privacy-policy {
  height: 100%;

  .text {
    white-space: pre-line;
  }

}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
  .privacy-policy {
    .text {
      white-space: pre-line;
    }
  }
}

/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px) and (device-width: 320px)
and (-webkit-min-device-pixel-ratio: 2) {
  .privacy-policy {
    .text {
      white-space: pre-line;
    }
  }
}

/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) and (min-device-width: 375px)
and (-webkit-min-device-pixel-ratio: 3) {
  .privacy-policy {
    .text {
      white-space: pre-line;
    }
  }
}
</style>
