<template>
  <div>
    <v-row>
      <HardwareUnavailableModal :dialog="showHardwareUnavailableModal"/>

      <v-col cols="5">
      </v-col>

      <v-col cols="12" sm="12" md="7">
        <v-row class="text-center">
          <v-col cols="12">
            <div
              :class="{ 'fullscreen': videoFullscreen }"
              class="video-call"
            >
              <div
                v-show="!camIsAvailable"
                class="video-call-off"
              >
                <div>
                  <v-icon color="cultured" class="fa-solid fa-video-slash" />

                  <p>{{ $t('hardware_check.camera_disabled') }}</p>
                </div>
              </div>

              <div
                v-show="camIsAvailable"
                class="video-call-on video-area"
              >
                <video
                  autoplay
                  id="video"
                  style="width: 100%; height: 100%; z-index: 10"
                  playsinline
                />

                <v-btn class="btn-change-cam" icon @click="changeCamera()">
                  <div class="icons">
                    <div class="icon-front">
                      <v-icon color="cultured" class="fa-sharp fa-solid fa-square"></v-icon>
                    </div>

                    <div class="icon-back">
                      <v-icon color="cultured" class="fa-sharp fa-regular fa-square"></v-icon>
                    </div>
                  </div>
                </v-btn>
              </div>

              <audio id="audio" />
            </div>
          </v-col>
        </v-row>

        <v-row class="text-center">
          <v-col>
            <v-btn v-show="camIsAvailable" @click="setCamera(!camIsAvailable)" depressed color="cultured"
                rounded small
                class="control-video-call-button mx-1">
              <v-icon x-small color="primary" class=" fa-solid fa-video"></v-icon>
            </v-btn>

            <v-btn v-show="!camIsAvailable" @click="setCamera(!camIsAvailable)" depressed outlined
                color="cultured" rounded small
                class="control-video-call-button mx-1">
              <v-icon x-small color="cultured" class=" fa-solid fa-video-slash"></v-icon>
            </v-btn>

            <v-btn v-show="micIsAvailable" @click="setMicrophone(!micIsAvailable)" depressed rounded small
                class="control-video-call-button mx-1">
              <v-icon x-small color="primary" class=" fa-solid fa-microphone"></v-icon>
            </v-btn>

            <v-btn v-show="!micIsAvailable" @click="setMicrophone(!micIsAvailable)" depressed outlined
                 rounded small
                 class="control-video-call-button mx-1">
              <v-icon x-small color="cultured" class=" fa-solid fa-microphone-slash"></v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import HardwareUnavailableModal from '@/components/hardwareCheck/HardwareUnavailableModal.vue';

  export default {
    name: 'VideoCall',

    components: {
      HardwareUnavailableModal
    },

    data: () => ({
      videoFullscreen: false,
      micIsAvailable: false,
      camIsAvailable: false,
      videoStream: null,
      audioStream: null,
      useFrontCamera: true,
      showHardwareUnavailableModal: false,
      constraints: {
        video: {
          aspectRatio: 4 / 3,
          width: {
            min: 100,
            ideal: 350,
            max: 350,
          }
        },
      }
    }),

    async mounted() {
      await this.setCamera(true);
      await this.setMicrophone(true);
      if (!this.videoStream || !this.audioStream) {
        this.$emit('handleHardwareAvailable', true);
      }
    },

    async beforeDestroy() {
      await this.setCamera(false);
      await this.setMicrophone(false);
    },

    methods: {
      setCamera(isAvailable) {
        this.camIsAvailable = isAvailable
        if (!isAvailable) {
          return this.stopVideoStream()
        }
        return this.startVideoStream()
      },
      async setMicrophone(isAvailable) {
        this.micIsAvailable = isAvailable
        if (!isAvailable) {
          return this.stopAudioStream()
        }
        return this.startAudioStream()
      },

      verifyMediaDevices() {
        if (
          !('mediaDevices' in navigator) ||
          !('getUserMedia' in navigator.mediaDevices)
        ) {
          this.showHardwareUnavailableModal = true;
        }
      },

      async startVideoStream() {
        this.verifyMediaDevices();
        this.stopVideoStream();

        this.constraints.video.facingMode = this.useFrontCamera ? 'user' : 'environment';
        const video = document.querySelector('#video');

        try {
          this.camIsAvailable = true;
          video.srcObject = await navigator.mediaDevices.getUserMedia(this.constraints);
          this.videoStream = video.srcObject;
        } catch (err) {
          this.camIsAvailable = false;
        }
      },

      stopVideoStream() {
        if (this.videoStream) {
          this.videoStream.getVideoTracks().forEach((track) => {
            track.stop();
          });
        }
      },

      stopAudioStream() {
        if (this.audioStream) {
          this.audioStream.getAudioTracks().forEach((track) => {
            track.stop();
          });
        }
      },

      async startAudioStream() {
        this.verifyMediaDevices();
        this.stopAudioStream();
        const audio = document.querySelector('#audio');
        try {
          this.micIsAvailable = true;
          audio.srcObject = await navigator.mediaDevices.getUserMedia({audio: this.micIsAvailable});
          this.audioStream = audio.srcObject;
        } catch (err) {
          this.micIsAvailable = false;
          this.showHardwareUnavailableModal = true;
        }
      },

      changeCamera() {
        if (this.$device.mobile) {
          this.useFrontCamera = !this.useFrontCamera;
          this.startVideoStream();
          return;
        }

        this.videoFullscreen = !this.videoFullscreen;

        if (this.videoFullscreen) {
          this.constraints.video.aspectRatio = 16 / 9;
        }
        if (!this.videoFullscreen) {
          this.constraints.video.aspectRatio = 4 / 3;
        }

        setTimeout(() => {
          this.startVideoStream()
        }, 10);
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/styles/colors.scss';

  .icons {
    position: relative;
  }

  .icon-front {
    position: absolute;
    left: -7px;
    top: 7px;
  }

  .icon-back {
    position: static;
  }

  .video-area {
    position: relative;
  }

  .btn-change-cam {
    position: absolute;
    right: 2px;
  }

  .video-call {
    aspect-ratio: 4/3;
    color: $cultured;
    margin: 0 auto;
    position: relative;
    width: 350px;

    &.fullscreen {
      aspect-ratio: 16/9;
      background-color: $outerSpace;
      height: 100vh;
      left: 0;
      position: absolute;
      top: 0;
      width: 100vw;
      z-index: 10;

      .video-call-on,
      .video-call-off {
        height: 100%;
        width: 100%;
      }

      .btn-change-cam {
        right: 10px;
      }
    }

    .video-call-off {
      background-color: black;
      height: 100%;
      width: 350px;
      padding-top: 30%;
    }

    .video-call-on {
      height: 100%;
      width: 350px;
    }
  }

  .control-video-call-button {
    color: $cultured;
  }
</style>