<template>
  <v-container
    :class="{ 'mt-6 pa-0': $device.mobile }"
    class="attendance-files"
  >
    <h3
      :class="[ $device.mobile ? 'font-weight-bold mb-3' : 'text-h6 mb-2' ]"
    >
      {{ $t('attendances_details.orders_exams_photos').toUpperCase() }}
    </h3>

    <div
      :class="{ 'pa-5': $device.mobile }"
      class="files-contailer d-flex flex-column"
    >
      <p
        v-if="$device.mobile"
        class="outerSpace--text"
      >
        {{ $t('attendances_details.wish_to_attach_files') }}
      </p>

      <h4
        v-else
        class="mb-8 spanishGray--text"
      >
        <span
          class="font-weight-medium"
        >
          {{ $t('attendances_details.attach_up_to') }}
        </span>

        <span
          class="font-weight-bold"
        >
          {{ $t('attendances_details.five_files_types') }}
        </span>
      </h4>

      <div
        class="align-center d-flex flex-wrap"
      >
        <!-- Files -->
        <v-card
          v-for="(file, index) in files"
          :class="[ $device.mobile ? 'mr-0' : 'mr-3' ]"
          :key="index"
          :width="$device.mobile ? '100%' : '228px'"
          @click="openFile(file)"
          class="d-flex file-box py-4 pl-6 pr-2 mb-4"
          outlined
        >
          <v-icon
            class="mr-4"
            color="secondary"
          >
            fa-regular fa-file 
          </v-icon>

          <div
            class="file-info d-flex flex-column"
          >
            <p
              class="file-name ma-0 pa-1 text-caption outerSpace--text"
            >
              {{ file.name || file.attachment }}
            </p>
          </div>

          <v-btn
            :loading="loading || loadingRemoveFiles"
            @click="openFileToRemoveDialog(file)"
            @mouseover="file.remove_over = true"
            @mouseleave="file.remove_over = false"
            class="ml-1"
            icon
            text
          >
            <v-icon
              color="spanishGray"
            >
              fa-trash 
            </v-icon>
          </v-btn>
        </v-card>

        <!-- Add Button -->
        <v-card-actions
          :class="{ 'd-flex flex-grow-1': $device.mobile }"
        >
          <v-btn
            v-if="files.length < maxFilesLimit"
            :class="[ $device.mobile ? 'mb-0' : 'mb-4' ]"
            :loading="loading || loadingAddFiles"
            :width="$device.mobile ? '100%' : '228px'"
            @click="addFiles"
            class="px-12"
            color="primary"
            rounded
          >
            <v-icon
              class="mr-2"
              color="white"
              small
            >
              fa-circle-plus
            </v-icon>

            {{ $t('general.add') }}
          </v-btn>

          <input
            ref="uploader"
            class="d-none"
            type="file"
            accept="application/pdf, application/doc, image/jpg"
            @change="onFileChanged"
          >
        </v-card-actions>
      </div>
    </div>

    <!-- Warning info for patient selection -->
    <GeneralModal
      :confirm-text="$t('general.yes')"
      :dialog="fileToRemoveDialog"
      @handleCancel="closeFileToRemoveDialog"
      @handleClose="closeFileToRemoveDialog"
      @handleConfirm="removeFile"
      alert
      has-cancel
      has-confirm
      width="440"
    >
      <h3
        class="text-center pb-2 outerSpace--text"
      >
        {{ $t('general.warning') }}
      </h3>

      <span>
        {{ $t('attendances_details.are_you_sure_you_want_delete_file') }}
      </span>
    </GeneralModal>
  </v-container>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import GeneralModal from '@/components/general/GeneralModal';

  export default {
    name: 'AttendanceFiles',
    
    components: {
      GeneralModal,
    },
    
    data: () => ({
      fileToRemove: null,
      fileToRemoveDialog: false,
      loading: false,
      loadingAddFiles: false,
      loadingRemoveFiles: false,
      selectedFiles: null,
      maxFilesLimit: 5,
    }),

    created() {
      this.listFiles();
    },

    methods: {
      ...mapActions('data/general', [
        'attendanceListAttachment', // -> map `this.attendanceListAttachment()` to `this.$store.dispatch('attendanceListAttachment')`
        'attendanceSaveAttachment',  // -> map `this.attendanceSaveAttachment()` to `this.$store.dispatch('attendanceSaveAttachment')`
        'attendanceDeleteAttachment',  // -> map `this.attendanceDeleteAttachment()` to `this.$store.dispatch('attendanceDeleteAttachment')`
      ]),

      ...mapMutations('data/general', [
        'setAttendanceAttachments', // -> this.setAttendanceAttachments
        'setGeneralError', // -> this.setGeneralError
        'setGeneralErrorRedirectRoute', // -> this.setGeneralErrorRedirectRoute
      ]),

      addFiles() {
        this.loadingAddFiles = true
        window.addEventListener('focus', () => {
          this.loadingAddFiles = false
        }, { once: true })

        this.$refs.uploader.click()
      },

      onFileChanged(e) {
        this.selectedFiles = e.target.files
        this.saveFiles();
      },

      saveFiles() {
        this.loading = true;

        let payload = {
          case_attendance_id: this.attendance.case_attendance_id,
          attachment: this.selectedFiles,
        };

        this.attendanceSaveAttachment(payload).then(() => {
          this.listFiles();
        }).catch((error) => {
          console.error(error);
          this.setGeneralError(true);
          this.setGeneralErrorRedirectRoute('home');
        }).finally(() => {
          this.loading = false;
        });
      },

      openFileToRemoveDialog(file) {
        this.fileToRemove = file;
        this.fileToRemoveDialog = true;
      },

      closeFileToRemoveDialog() {
        this.fileToRemove = null;
        this.fileToRemoveDialog = false;
      },

      removeFile() {
        this.loadingRemoveFiles = true;
        this.fileToRemoveDialog = false;

        let payload = {
          id: this.fileToRemove.id,
        };

        this.attendanceDeleteAttachment(payload).then(() => {
          this.listFiles();
        }).catch((error) => {
          console.error(error);
          this.setGeneralError(true);
          this.setGeneralErrorRedirectRoute('home');
        }).finally(() => {
          this.loadingRemoveFiles = false;
          this.fileToRemove = null;
        });
      },

      listFiles() {
        this.loading = true;

        let payload = {
          case_attendance_id: this.attendance.case_attendance_id,
        };

        this.attendanceListAttachment(payload).catch((error) => {
          console.error(error);
          this.setGeneralError(true);
          this.setGeneralErrorRedirectRoute('home');
        }).finally(() => {
          this.loading = false;
        });
      },

      openFile(file) {
        if (file.remove_over) return;

        window.location.href = file.attachment;
      },
       extrairNomeArquivo(string) {
        // Verifica se a string contém barras ("/") como separadores
        if (string.includes('/')) {
          // Divide a string em partes usando as barras ("/") como separadores
          let parts = string.split('/');

          // Obtém a última parte que contém o nome do arquivo
          let filename = parts.pop();

          return filename;
        }

        // Caso não haja barras ("/") na string, utiliza a função anterior para extrair o nome do arquivo
        // Expressão regular para extrair o nome do arquivo
        let regex = /([^/]+)$/;

        // Executa a correspondência da expressão regular na string
        let match = regex.exec(string);

        // Verifica se houve correspondência
        if (match && match.length > 1) {
          // Retorna o valor correspondente ao nome do arquivo
          return match[1];
        }

        // Caso não haja correspondência, retorna a string original
        return string;
      },
    },

    computed: {
      ...mapGetters('data/general', {
        attendance: 'getAttendance', // -> this.attendance
        attachments: 'getAttendanceAttachments', // -> this.attachments
      }),

      files() {
        if (this.attachments && this.attachments.length > 0) {
          this.attachments.forEach(attach => {
            let url = attach.attachment;

            // Dividir a URL em partes usando '/' como separador
            let parts = url.split('/');

            // Obter a última parte que contém o nome do arquivo
            let filename = parts[parts.length - 1];

            // Remover qualquer parâmetro de consulta (?generation=1687355153938752&alt=media)
            filename = filename.split('?')[0];
            let decodedString = decodeURIComponent(filename);
            let name = this.extrairNomeArquivo(decodedString);
            attach.name = name.slice(33);
          });
        }
        return this.attachments || [];
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import '@/styles/colors.scss';

  .attendance-files {
    .files-contailer {
      .file-box {
        border-radius: 50px !important;
        min-width: 300px;

        .file-info {
          flex-grow: 1;
          text-overflow: ellipsis;
          overflow: hidden;

          .file-name {
            height: 20px;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px)
  and (max-device-width: 1024px) {
    .attendance-files {
      // 
    }
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .attendance-files {
      .files-contailer {
        border: 1px solid $lightSilver;
      }
    }
  }

  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .attendance-files {
      .files-contailer {
        border: 1px solid $lightSilver;
      }
    }
  }

  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .attendance-files {
      .files-contailer {
        border: 1px solid $lightSilver;
      }
    }
  }
</style>