<template>
  <v-card
    v-if="loading"
    class="content text-center"
    flat
    width="100%"
  >
    <v-progress-circular
      :size="$device.mobile ? 40 : 70"
      :width="$device.mobile ? 4 : 7"
      color="primary"
      indeterminate
    />
  </v-card>

  <v-card
    v-else
    :class="[ $device.mobile ? 'px-4 py-1' : 'pa-1' ]"
    flat
  >
    <v-row>
      <p
        class="text-left pt-3"
      >
        {{ $t('schedule.select_date') }}
      </p>
      <!-- Select Date -->
      <v-col
        v-if="!$device.mobile || ($device.mobile && !select_schedule_date)"
        cols="12"
        class="pa-0"
      >
        <p
          class="font-weight-bold text-uppercase mb-0"
        >
          {{ $t('schedule.month_day') }}
        </p>

        <div
          class="calendar-container"
        >
          <v-date-picker
            v-model="select_schedule_date"
            :allowed-dates="allowedDates"
            :header-date-format="customFormatHeader"
            :max="range_days.max"
            :min="range_days.min"
            :show-current="range_days.correct_day"
            @update:picker-date="updatePickedMonth"
            class="mx-auto my-0"
            width="100%"
            locale="pt-br"
            next-icon="fa-sharp fa-solid fa-circle-chevron-right"
            no-title
            prev-icon="fa-sharp fa-solid fa-circle-chevron-left"
          />

          <div
            v-if="loadingCalendar"
            class="calendar-loader align-center d-flex justify-center ma-0 pa-0"
            flat
          >
            <v-progress-circular
              :size="$device.mobile ? 30 : 40"
              :width="$device.mobile ? 3 : 4"
              color="primary"
              indeterminate
            />
          </div>
        </div>
      </v-col>

      <!-- Mobile Date -->
      <v-col
        v-else-if="$device.mobile && select_schedule_date"
        cols="12"
        class="pa-0"
      >
        <v-card
          @click="resetDateAndHour"
          class="d-flex info-box mb-3 mt-0 mx-0 py-4 px-6"
          outlined
          width="100%"
        >
          <v-icon
            class="mr-4"
            color="secondary"
          >
            fa-regular fa-calendar-days
          </v-icon>

          <div
            class="d-flex flex-column flex-grow-1"
          >
            <h4
              class="font-weight-bold"
            >
              {{ $t('schedule.date') }}
            </h4>

            <p
              class="ma-0 "
            >
              {{ $moment(select_schedule_date).format('DD/MM/YYYY') }}
            </p>
          </div>

          <div
            class="d-flex"
          >
            <v-icon
              color="black"
            >
              mdi-chevron-right
            </v-icon>
          </div>
        </v-card>
      </v-col>

      <!-- Select Hour -->
      <v-col
        v-if="
          (!$device.mobile && select_schedule_date) ||
          ($device.mobile && select_schedule_date && !select_schedule_hour)
        "
        cols="12"
        class="mt-4 pa-0"
      >
        <p
          class="font-weight-bold text-uppercase mb-0"
        >
          {{ $t('schedule.available_hours_text') }}
        </p>

        <v-row class="ma-0 pa-0">
          <v-col
            cols="12"
            class="d-flex pa-0"
          >
            <div
              class="box-select-hours d-flex justify-center"
            >
              <v-btn
                v-for="(item, index) in list_schedule_hours"
                :color="btnSelected === index ? 'secondary' : ''"
                :ref="'btn-' + index"
                :key="index"
                @click="setSelectSlotOption(item, index)"
                class="hours-btn"
                elevation="1"
                x-small
              >
                {{ item.hour }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <!-- Mobile Hour -->
      <v-col
        v-else-if="$device.mobile && select_schedule_date && select_schedule_hour"
        cols="12"
        class="pa-0"
      >
        <v-card
          @click="resetHour"
          class="d-flex info-box mb-3 mt-0 mx-0 py-4 px-6"
          outlined
          width="100%"
        >
          <v-icon
            class="mr-4"
            color="secondary"
          >
            fa-regular fa-calendar-days
          </v-icon>

          <div
            class="d-flex flex-column flex-grow-1"
          >
            <h4
              class="font-weight-bold"
            >
              {{ $t('schedule.hour') }}
            </h4>

            <p
              class="ma-0 "
            >
              {{ select_schedule_hour }}
            </p>
          </div>

          <div
            class="d-flex"
          >
            <v-icon
              color="black"
            >
              mdi-chevron-right
            </v-icon>
          </div>
        </v-card>
      </v-col>

      <!-- Actions -->
      <v-col
        v-show="select_schedule_date && select_schedule_hour"
        class="mt-4"
      >
        <v-row>
          <v-col class="pl-0">
            <v-btn
              @click="resetHour"
              block
              class="back-bt"
              elevation="0"
              outlined
              rounded
            >
              {{ $t('general.back') }}
            </v-btn>
          </v-col>

          <v-col class="pr-0">
            <v-btn block
                   class="next-btn"
                   elevation="0"
                   rounded
                   @click="saveReschedule">
              {{ $t('general.next') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
  import calendar from '@/mixins/calendar';

  export default {
    name: 'AttendanceRescheduleModal',

    mixins: [calendar],

    props: {
      handleEmptySlots: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },
    },

    data: () => ({
      loading: false,
      loadingCalendar: false,
      calendarInCurrentMonth: true,
      updatedTimestamp: null,
      select_schedule_date: null,
      select_schedule_hour: null,
      selected_reset_option: null,
      is_family_medicine: false,
      show_info_exist_attendance: false,
      available_slots_days: [],
      professionals: [],
      select_schedule_option: null,
      btnSelected: null,
      scheduled_appointments: [],
      schedule_role_name: null,
      lastAttendanceForProgramList: [],
      isMultipleAttendance: false,
      program: null
    }),

    created() {
      this.loading = true;
    },

    mounted() {
      this.program = this.getProgram;

      if (this.program.id == 7) {
        this.is_family_medicine = true
        this.schedule_role_name = 'programCalendar'
      } else {
        this.schedule_role_name = 'scheduleUserCalendar';
      }

      this.checkExistAttendance();
    },

    methods: {
      ...mapMutations('data/general', [
        'setGeneralError',
        'setGeneralErrorRedirectRoute',
      ]),

      checkExistAttendance() {
        const patientID = this.getPatientLastAttendance().attendances?.patient_info?.id;

        this.lastAttendanceForProgramList = (this.getPatientLastAttendance().attendances?.programs_scheduled
            || []).filter(program => program.program_id === this.program.id && program.member_id === patientID);

        if (this.attendances?.length > 0) {
          this.handleCurrentAttendances();
        }

        if (this.program.attendances_qty > 1 && this.lastAttendanceForProgramList.length > 1) {

          this.lastAttendanceForProgramList = this.lastAttendanceForProgramList
              .filter(i => i.case_attendance_id != this.attendance.case_attendance_id);

          this.isMultipleAttendance = true;
        }

        this.scheduleSlotsReview(true);
      },

      async getSlotsFilter() {
        //this function seems not to be used
        const doubleBooking = this.getCheckDoubleBooking.double_booking;

        if (this.is_family_medicine) {
          return {
            rules: [
              {
                name: 'programCalendar',
                filters: {
                  program_id: this.program.id,
                  timestamp: this.updatedTimestamp || this.$moment().unix(),
                  days_ahead: this.daysAhead,
                  double_booking: doubleBooking,
                  company_id: null,
                  member_id: null
                }
              }
            ]
          };
        }

        return {
          rules: [
            {
              name: 'scheduleUserCalendar',
              filters: {
                user_schedule_id: this.attendance.user_schedule_id,
                timestamp: this.updatedTimestamp || this.$moment().unix(),
                days_ahead: this.daysAhead,
                double_booking: doubleBooking,
                company_id: null,
                member_id: null
              },
            }
          ]
        };
      },

      setSelectSlotOption(value, index) {
        this.btnSelected = index;

        this.select_schedule_option = value;
        this.select_schedule_hour = value.hour;

        if (!this.is_family_medicine) {
          const schedules = this.getScheduleSlot();

          this.professionals = schedules.scheduleInfo.reduce((result, item) => {
            if (typeof this.available_slots_days[value.available_slot_index]
                .user_schedules === 'object') {

              if (Object.values(this.available_slots_days[value.available_slot_index]
                  .user_schedules).find((userScheduleId) => {
                    return userScheduleId == item.schedule_id
                  })) {
                result.push(item);
              }
              return result;
            }

            if (this.available_slots_days[value.available_slot_index]
                .user_schedules.find((userScheduleId) => {
                  return userScheduleId == item.schedule_id
                })) {
              result.push(item);
            }
            return result;
          }, []);
        }
      },

      saveReschedule() {
        const payload = {
          case_attendance_id: this.attendance.case_attendance_id,
          due_date: this.select_schedule_date,
          due_time: this.$moment
            .unix(this.select_schedule_option.timestamp)
            .utc(false)
            .format('HH:mm:ss'),
          double_booking: this.getCheckDoubleBooking.double_booking
        }

        this.caseAttendanceReschedule(payload).then(() => {
          this.goScheduleDetails();
        }).catch(error => {
          console.log(error);
          this.setGeneralError(true);
          this.setGeneralErrorRedirectRoute('home');
        });
      },

      goScheduleDetails() {
        this.$router.push('/attendances/reschedulesuccess');
      },

      updatePickedMonth(value) {
        let newValueMoment = this.$moment(`${value}-01`);
        let isCurrentMonth = this.$moment().month() === newValueMoment.month();

        if (this.calendarInCurrentMonth == isCurrentMonth) {
          // Prevents double calls for EP
          return;
        }

        this.calendarInCurrentMonth = isCurrentMonth;

        this.updatedTimestamp = this.calendarInCurrentMonth
          ? this.$moment().unix()
          : newValueMoment.unix();

        this.loadingCalendar = true;
        this.scheduleSlotsReview(false);
      },
    },

    computed: {
      ...mapGetters('data/patient', {
        attendances: 'getAttendances',
      }),

      ...mapGetters('data/general', {
        attendance: 'getAttendance',
      }),
    },

    watch: {
      available_slots_days(slots) {
        if (!slots.length) {
          this.$emit('handleEmptySlots');
        }
      }
    },
  };
</script>


<style lang="scss" scoped>
  @import '@/styles/colors.scss';

  .v-picker {
    border: 1px solid $silverSand !important;
  }

  .box-select-hours {
    justify-content: flex-start;
    flex-wrap: wrap !important;
    border: 1px solid #D8D8D8;
    min-height: 10em;
    overflow-y: auto;
    height: 136px;
    padding: 24px 16px;
    gap: 16px;

    &::-webkit-scrollbar {
      width: 0! important
    }

    @media screen and (max-device-width: 640px) {
      height: 224px;
      width: 100%;
      padding: 6px;
      gap: 0.58em;
    }
  }

  .active {
    background-color: white;
    color: black;
  }

  .hours-btn {
    background: #FFFFFF !important;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    border-radius: 5px !important;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    color: $outerSpace;
    width: 71px !important;
    height: 36px !important;
    padding: 0px 8px !important;

    @media screen and (max-device-width: 640px) {
      height: 237px;
      width: 319px;
      font-size: 18px;
      margin: 5px 0px 9px 0px;
    }
  }

  .info-box {
    border-radius: 50px !important;
    min-width: 300px;

    h4, p {
      height: 20px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .next-btn {
    background-color: $outerSpace !important;
    color: $antiFlashWhite !important;
    //width: 12em !important;
  }

  .back-btn {
    background-color: white !important;
    color: $outerSpace !important;
    //width: 12em !important;
    border-color: $spanishGray !important;
    border: 0.6px solid;
  }

  .calendar-container {
    position: relative;
  }

  .calendar-loader {
    background-color: #FFFFFF90;
    bottom: 0;
    height: 284px;
    position: absolute;
    width: 292px;
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px) and (max-device-width: 1024px) {

  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .v-picker {
      width: 100%;
    }

    .calendar-loader {
      width: 100%;
    }
  }
</style>