import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = () => ({
  acceptTerm: false,
  currentStep: 0,
  form: {
    name: null,
    cpf: null,
    birthdate: null,
    phone: null,
    gender: null,
    email: null,
    password: null,
  },
  member_id: null,
  warning_types: {
    USER_ALREADY_REGISTERED: 'user-already-registered',
    USER_UNDER_AGE: 'user-under-age',
    USER_NOT_REGISTERED: 'user-not-registered',
  },
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};