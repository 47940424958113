import axios from 'axios';
import consts from '@/static/js/consts';

export default {
  /*
  * POST
  * @param email: string
  * @param password: string
  */
  patientAuthorize({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.PATIENT_AUTHORIZE, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        localStorage.setItem('token', response.data.data.token);
        localStorage.setItem('member_id', response.data.data.member_id);
        resolve(
          commit('setToken', response.data.data.token),
          commit('setMemberId', response.data.data.member_id)
        );
      }).catch(error => {
        reject(error.response);
      });
    });
  },

  /*
  * POST
  * @param email: string
  */
  patientForgotPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(consts.ENDPOINTS.PATIENT_FORGOT_PASSWORD, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        commit(resolve(response.data.data));
      }).catch(error => {
        reject(error.response);
      });
    });
  },

  /*
  * GET
  * @param company_id: int
  */
  getPatientGroups({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(consts.ENDPOINTS.PATIENT_GROUP, {
        headers: {
          'Content-Type': 'application/json',
          'token': localStorage.getItem('token')
        }
      }).then(response => {
        let groups = response.data.data.groups;

        if (groups && groups.length == 1) {
          commit('setCompanyId', groups[0].company_id);
          commit('setCompanyStatusId', groups[0].company_status_id);
          commit('setCompanyStatusName', groups[0].company_status_name);

          if (groups[0].flavour && groups[0].flavour.length) {
            commit('setFlavourId', groups[0].flavour[0].id);
          }

          commit('setGroupId', groups[0].id);
        }

        resolve(commit('setPatientGroups', groups));
      }).catch(error => {
        reject(error.response);
      });
    })
  },
}