<template>
  <v-container
    class="consent-term ma-auto my-0 pa-0"
  >
    <v-card
      :class="[
        $device.mobile ? 'pr-2' : 'pr-9',
        loading ? 'pt-0' : 'pt-8'
      ]"
      :loading="loading"
      :height="hasAcceptTerm ? 'calc(100% - 162px)' : '100%'"
      class="overflow-hidden pb-5 pl-8"
      flat
      outlined
    >
      <v-card
        class="cy-accept-term-content text overflow-y-auto pl-0 pr-5 py-0"
        flat
        height="100%"
      >
        {{ consentTerm }}
      </v-card>
    </v-card>

    <v-card
      v-if="hasAcceptTerm && !loading"
      :class="[ $device.mobile ? 'pr-2' : 'pr-9' ]"
      class="align-center d-flex flex-column justify-center mt-2"
      height="154"
    >
      <div
        class="d-flex mb-3"
      >
        <v-switch
          v-model="acceptTerm"
          class="accept-switch cy-accept-term-switch ma-0 pa-0"
          hide-details
        />

        <span
          class="silverSand--text"
        >
          {{ $t('register.read_and_accept_terms') }}
        </span>
      </div>

      <v-btn
        :class="{ 'disabled-button-dark': !acceptTerm }"
        @click="$emit('handleAcceptTerm')"
        class="accept-button cy-accept-term-button mx-auto my-0 px-8"
        color="outerSpace"
        dark
        depressed
        min-height="46"
        min-width="195"
        rounded
        width="297"
      >
        {{ $t('register.accept_terms_above') }}
      </v-btn>
    </v-card>
  </v-container>
</template>

<script>
  import {
    mapActions,
    mapGetters,
    mapMutations
  } from 'vuex';

  export default {
    name: 'ConsentTerm',

    props: {
      // Shows the display of the accept term button
      hasAcceptTerm: {
        type: Boolean,
        default: false
      },

      // Function to handle accept term button and action
      handleAcceptTerm: {
        type: Function,
        default() {
          return () => console.log('Default function');
        }
      },
    },

    data: () => ({
      loading: false,
      acceptTerm: false,
    }),

    created() {
      if (!this.consentTerm) {
        this.loading = true;

        this.getConsentTerm().catch((error) => {
          console.error(error);
        }).finally(() => {
          this.loading = false;
        });
      }
    },

    methods: {
      ...mapActions('data/general', [
        'getConsentTerm', // -> map `this.getConsentTerm()` to `this.$store.dispatch('getConsentTerm')`
      ]),

      ...mapMutations('data/general', [
        'setConsentTerm', // -> this.setConsentTerm
      ]),
    },

    computed: {
      ...mapGetters('data/general', {
        consentTerm: 'getConsentTerm', // -> this.consentTerm
      }),
    }
  };
</script>

<style lang="scss" scoped>
  .consent-term {
    height: 100%;

    .text {
      white-space: pre-line;
    }

    .accept-switch {
      transform: scale(0.8);
    }
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .consent-term {
      .text {
        white-space: pre-line;
      }
    }
  }
   
  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .consent-term {
      .text {
        white-space: pre-line;
      }
    }
  }
   
  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .consent-term {
      .text {
        white-space: pre-line;
      }
    }
  }
</style>
