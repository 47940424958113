<template>
  <v-container class="programs-view d-flex flex-column pa-0" max-width="100%">
    <v-card
      v-if="loading || loadingPrograms"
      class="content text-center ma-0 px-0 py-16"
      width="100%"
      flat
    >
      <v-progress-circular
        :size="$device.mobile ? 40 : 70"
        :width="$device.mobile ? 4 : 7"
        color="primary"
        indeterminate
      />
    </v-card>

    <v-card
      v-else
      :class="[ $device.mobile ? 'pa-0' : 'pa-8' ]"
      class="content mb-10"
      flat
    >
      <v-row :class="[ $device.mobile ? 'mr-1 ml-1' : 'mr-9 ml-9' ]">
        <v-col cols="12">
          <v-row no-gutters class="title-programs ma-0 pa-0">
            <h1 v-if="isSpecialityProgram && isHealthCareProgram">{{ $t('general.programs_title_text') }} </h1>

            <h1 v-if="!isSpecialityProgram || !isHealthCareProgram">{{ $t('general.programs_title_text_2') }} </h1>
          </v-row>

          <v-row>
            <v-col class="subtitle-programs">
              <h3 v-if="isSpecialityProgram && isHealthCareProgram">{{ $t('general.programs_subtitle_text') }}</h3>

              <h3 v-if="!isSpecialityProgram || !isHealthCareProgram">{{ $t('general.programs_subtitle_text_2') }}</h3>
            </v-col>
          </v-row>

          <v-row no-gutters class="my-5">
            <v-btn-toggle
              v-model="selectOptionProgram"
              :class="[ $device.mobile ? 'w-100' : '']"
              background-color="silverSand"
              borderless
              class="pa-1"
              color="spanishGray"
              dense
              mandatory
              rounded
            >
              <v-btn
                v-if="isSpecialityProgram"
                :class="[ $device.mobile ? 'w-60' : '']"
                @click="buttonPrograms(speciality_programs)"
                class="rounded-pill"
                value="speciality_programs"
              >
                {{ $t('general.programs_speciality') }}
              </v-btn>

              <v-btn
                v-if="isHealthCareProgram"
                v-on:click="buttonPrograms(health_programs)"
                :class="[ $device.mobile ? 'w-40' : '']"
                class="rounded-pill"
                rounded
                value="health_programs"
              >
                {{ $t('general.programs_health_care') }}
              </v-btn>
            </v-btn-toggle>
          </v-row>

          <v-row>
            <v-card class="overflow-y-auto w-100 ma-2 card-programs" flat>
              <v-list rounded class="d-flex flex-wrap gap">
                <v-list-item
                  v-for="(item, index) in programs_list"
                  v-show="item.program_id !== optProgramId || !shouldHideOptProgram"
                  :key="index"
                  :value="item.id"
                  :class="{
                    'v-item-inactive-dark': (
                       (restrictedProgramsAge.includes(item.program_id) && profile.age < 14)
                      || getProgramSchedule.includes(item.program_id)
                      || (item.program_id === 7 && omvDisabled)
                    ),
                    'v-list-item-max-w': !$device.mobile
                  }"
                  @click="scheduleAttendance(item)"
                  class="v-list-item"
                >
                  <v-list-item-avatar>
                    <v-img :src="item.asset"/>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.translate }}
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-icon size="15" color="secondary">
                    fa-solid fa-chevron-right
                  </v-icon>
                </v-list-item>
              </v-list>
            </v-card>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <!-- Footer -->
    <HomeFooter/>

    <!-- Psychology Programs Modal -->
    <GeneralModal
      :dialog="psychologyProgramsDialog"
      :header-title="$t('general.psychology')"
      @handleClose="psychologyProgramsDialog = false"
      has-close
      width="549"
    >
      <PsychologyProgramSelection
        :dialog="psychologyProgramsDialog"
      />
    </GeneralModal>

    <!-- Warning Modal -->
    <GeneralModal
      :confirm-text="$t('general.ok_got_it')"
      :dialog="warningDialog"
      @handleConfirm="closeWarning"
      @handleClose="closeWarning"
      alert
      has-confirm
      width="478"
    >
      <div class="cy-warning">
        <h3 class="text-center mb-3">
          {{ $t('general.warning') }}
        </h3>

        <p class="body-1 mb-0">
          {{ message }}
        </p>
      </div>
    </GeneralModal>

    <!-- Warning Modal 2 -->
    <GeneralModal
      :alert="showInfoExistAttendance"
      :confirm-text="$t('general.ok_got_it')"
      :dialog="showInfoExistAttendance"
      :has-confirm="showInfoExistAttendance"
      @handleConfirm="handleConfirmDialogExistAttendance"
      persistent
      width="549"
    >
      <h2 class="text-center">
        {{ $t('general.warning') }}
      </h2>

      <p class="p-exist-attendance text-left pt-4">
        {{ $t('schedule.warning_exist_immediate_attendance.part_1') }}
      </p>

      <p class="p-exist-attendance text-left pt-4">
        {{ $t('schedule.warning_exist_immediate_attendance.part_2') }}
        <strong> {{ $t('schedule.warning_exist_immediate_attendance.part_3') }}</strong>
      </p>
    </GeneralModal>
  </v-container>
</template>

<script>
  import {mapActions, mapGetters, mapMutations} from 'vuex';
  import consts from '@/static/js/consts';
  import HomeFooter from '@/components/home/HomeFooter';
  import GeneralModal from '@/components/general/GeneralModal';
  import PsychologyProgramSelection from '@/components/general/PsychologyProgramSelection';

  export default {
    name: 'ProgramsView',

    components: {
      HomeFooter,
      GeneralModal,
      PsychologyProgramSelection,
    },

    data: () => ({
      loading: false,
      loadingPrograms: false,
      optProgramId: 67,
      shouldHideOptProgram: true,
      clicked: false,
      speciality_programs: [],
      health_programs: [],
      programs_list: [],
      hidden_psychology_programs: [67], // Only OPT for now
      isSpecialityProgram: false,
      isHealthCareProgram: false,
      psychologyProgramsDialog: false,
      warningDialog: false,
      programId: null,
      message: "",
      programSchedule: [],
      omvDisabled: false,
      showInfoExistAttendance: false,
      selectOptionProgram: null,
    }),

    created() {
      // Get patient profile if needed
      if (!this.profile) {
        this.getPatientProfile();
      }
      const {program_info = [], program_well_being = []} = this.programsInfo.data;

      this.programSchedule = this.getProgramsScheduled
        .filter(({member_id}) => member_id == this.profile.id)
        .filter(item => {
          if (item.program_id === 7) {
            this.checkExistOmvAttendance();
          }

          let program = program_info.find(o => o.program_id == item.program_id)
            || program_well_being.find(o => o.program_id == item.program_id);

          if (program) {
            // Psychology path
            if (consts.PSYCHOLOGY_PROGRAMS_IDS.includes(program.program_id)) {
              let hasOptAccess = program_well_being.find(o => this.hidden_psychology_programs.includes(o.program_id));

              // If has OPT access shouldn't block it's program buttom.
              if (hasOptAccess) {
                return false;
              }
            }

            return item.program_id != 7 && item.count_scheduled >= +program.attendances_qty;
          } else {
            return item.program_id != 7
          }
        })
        .map(({program_id}) => program_id);

      this.getPrograms();
    },

    mounted() {
      this.setBannerTitle(this.$t('general.banner_need_attendance'));
      this.setBannerIcon('fa-solid fa-hand-holding-medical');
    },

    methods: {
      ...mapActions('data/attendance', [
        'getPatientLastAttendance',
      ]),

      ...mapActions('data/patient', [
        'patientProfile'
      ]),

      ...mapActions('data/schedule', [
        'programGetDetails'
      ]),

      ...mapMutations('data/general', [
        'setBannerTitle',
        'setBannerIcon',
        'setGeneralError',
        'setGeneralErrorRedirectRoute',
      ]),

      ...mapMutations('data/schedule', [
        'setProgram',
        'resetFiles',
      ]),

      getPatientProfile() {
        this.loading = true;

        this.patientProfile(this.member_id).catch((error) => {
          console.error(error);
          this.setGeneralError(true);
          this.setGeneralErrorRedirectRoute('home');
        }).finally(() => {
          this.loading = false;
        });
      },

      getPrograms() {
        if (this.programsInfo) {
          const {program_info = [], program_well_being = []} = this.programsInfo.data;
          this.speciality_programs = [...program_info];
          this.health_programs = [...program_well_being];

          let psychologyProgramsAccess = [...this.health_programs].filter(o => consts.PSYCHOLOGY_PROGRAMS_IDS.includes(o.program_id));

          this.shouldHideOptProgram = psychologyProgramsAccess.length > 1
            && psychologyProgramsAccess.find(o => o.program_id == this.optProgramId);

          let totalProgramsLength = (this.speciality_programs?.length || 0) + (this.health_programs?.length || 0);

          if (totalProgramsLength === 1) {
            if (this.speciality_programs.length === 1) {
              this.scheduleAttendance(this.speciality_programs[0])
            } else if (this.health_programs.length === 1) {
              this.scheduleAttendance(this.health_programs[0])
            }
          } else {
            this.isSpecialityProgram = this.speciality_programs.length > 0;
            this.isHealthCareProgram = this.health_programs.length > 0;

            if (this.isHealthCareProgram && this.isSpecialityProgram) {
              this.selectOptionProgram = 'speciality_programs';
            } else if (this.isHealthCareProgram) {
              this.selectOptionProgram = 'health_programs';
            } else if (this.isSpecialityProgram) {
              this.programs_list = program_info;
              this.selectOptionProgram = 'speciality_programs';
            } else {
              this.programs_list = [];
            }
          }
        }
      },

      buttonPrograms() {
        switch (this.selectOptionProgram) {
          case 'speciality_programs':
            this.programs_list = this.speciality_programs;
            break;
          case 'health_programs':
            this.programs_list = this.health_programs;
            break;
          default:
            this.programs_list = [];
            break;
        }
      },

      scheduleAttendance(data) {
        this.programId = data.program_id;

        // Psychology path
        if (consts.PSYCHOLOGY_PROGRAMS_IDS.includes(this.programId)) {
          let hasOptAccess = this.programs_list.find(o => this.hidden_psychology_programs.includes(o.program_id));

          // If has OPT access should open a modal.
          if (hasOptAccess) {
            return this.psychologyProgramsDialog = true;
          }
        }

        if (
          (this.programId == 7 && this.omvDisabled) ||
          this.programSchedule.some(item => item === this.programId && this.programId != 7)
        ) {
          this.showInfoExistAttendance = true;
        } else {
          let hasWarningType = Object.keys(this.warningTypes).indexOf(this.programId.toString());

          // Has warnings
          if (hasWarningType !== -1) {
            if (this.restrictedProgramsAge.includes(this.programId) && this.profile.age < 14) {
              this.warningDialog = true;
              this.message = this.$t(this.warningTypes[this.programId]);
              return;
            }

            if (this.warningPrograms.includes(this.programId)) {
              this.setProgram({
                name: data.translate,
                id: data.program_id,
                occupation_id: data.occupation_id,
                speciality_id: data.speciality_id,
                attendances_qty: data.attendances_qty,
                attendance_interval: data.attendance_interval,
                days_ahead: data.days_ahead,
                is_program_well_being: Boolean(this.programsInfo.data.program_well_being.find(i => i.program_id == data.program_id))
              });

              this.warningDialog = true;
              this.message = this.$t(this.warningTypes[this.programId]);
            }
          }

          // Can Schedule
          if (!this.warningDialog) {
            this.setProgram({
              name: data.translate,
              id: data.program_id,
              occupation_id: data.occupation_id,
              speciality_id: data.speciality_id,
              attendances_qty: data.attendances_qty,
              attendance_interval: data.attendance_interval,
              days_ahead: data.days_ahead,
              is_program_well_being: Boolean(this.programsInfo.data.program_well_being.find(i => i.program_id == data.program_id))
            });

            this.resetFiles();
            this.$router.push('/schedule');
          }
        }
      },

      closeWarning() {
        this.$emit('handleWarning');
        this.warningDialog = false;

        if (this.profile.age > 14 || !this.restrictedProgramsAge.includes(this.programId)) {
          this.resetFiles();
          this.$router.push('/schedule');
        }
      },

      checkExistOmvAttendance() {
        this.loading = true;

        let payload = {
          member_id: this.profile.id,
          program_id: 7,
          company_id: this.company_id,
          group_id: this.group_id,
          holder_dependents: this.getDependents
            .filter(item => !item.has_login && item.id != this.profile.id)
        }
        
        this.getPatientLastAttendance(payload).then(() => {
          const lastAttendances = this.patientLastAttendance;

          if (lastAttendances) {
            let hasOmvImmediate = lastAttendances.attendances.programs_immediate.find(i =>
              i.program_id === 7 && i.member_id === lastAttendances.attendances.patient_info.id
            );
            let hasOmvScheduled = lastAttendances.attendances.programs_scheduled.find(i =>
              i.program_id === 7 && i.member_id === lastAttendances.attendances.patient_info.id
            );

            if (hasOmvImmediate && hasOmvScheduled) {
              this.omvDisabled = true;
            }
          }
        }).catch(() => {
          this.setGeneralError(true);
          this.setGeneralErrorRedirectRoute('home');
        })
        .finally(() => {
          this.loading = false;
        });

      },

      handleConfirmDialogExistAttendance() {
        this.showInfoExistAttendance = false;

        const {program_info = [], program_well_being = []} = this.programsInfo.data;

        if (program_info.length + program_well_being.length === 1) {
          this.$router.push('/');
        }
      },
    },

    computed: {
      ...mapGetters('data/attendance', {
        patientLastAttendance: 'getPatientLastAttendance',
      }),

      ...mapGetters('data/general', {
        companyLogo: 'getCompanyLogo',
      }),

      ...mapGetters('data/login', {
        company_id: 'getCompanyId', 
        group_id: 'getGroupId',
        member_id: 'getMemberId',
      }),

      ...mapGetters('data/patient', {
        getProgramsScheduled: 'getProgramsScheduled',
        getDependents: 'getDependents',
      }),

      ...mapGetters('data/schedule', {
        profile: 'getPatient',
        programInfo: 'getProgram',
        programsInfo: 'getPrograms',
        restrictedProgramsAge: 'getRestrictedPrograms',
        warningPrograms: 'getWarningPrograms',
        warningTypes: 'getWarningTypes',
      }),

      getProgramSchedule() {
        return this.programSchedule || [];
      },
    },

    watch: {
      selectOptionProgram() {
        this.buttonPrograms()
      },
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/styles/colors.scss';

  .programs-view {
    max-width: 100%;

    .content {
      font-size: 16px;
      padding: 1em !important;
      min-height: calc(100vh - 246px - 97px);
      margin-top: 2em;
    }
  }

  /* For Tablet View */
  @media screen and (min-device-width: 641px) and (max-device-width: 1024px) {
    .programs-view {
      .content {
        min-height: calc(100vh - 216px - 97px);
      }
    }
  }

  /* For Mobile Phones Portrait or Landscape View */
  @media screen and (max-device-width: 640px) {
    .programs-view {
      padding-bottom: 54px !important;

      .content {
        min-height: calc(100vh - 129px - 76px);
        padding-bottom: 38px !important;
        padding-top: 0 !important;
      }
    }
  }

  /* For iPhone 5 Portrait or Landscape View */
  @media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
    .programs-view {
      padding-bottom: 54px !important;

      .content {
        min-height: calc(100vh - 129px - 76px);
        padding-bottom: 38px !important;
        padding-top: 0 !important;
      }
    }
  }

  /* For iPhone 6 and 6 plus Portrait or Landscape View */
  @media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {
    .programs-view {
      padding-bottom: 54px !important;

      .content {
        min-height: calc(100vh - 129px - 76px);
        padding-bottom: 38px !important;
        padding-top: 0 !important;
      }
    }
  }

  .w-100 {
    width: 100%;
  }

  .w-60 {
    width: 60%;
  }

  .w-40 {
    width: 40%;
  }

  .subtitle-programs {
    font-family: 'Roboto';
    font-style: normal;
    font-size: 0.9em;
    color: $spanishGray;
  }

  .title-programs {
    font-family: 'Roboto';
    font-style: normal;
    line-height: 29px;
    color: $outerSpace;
    font-size: 0.8em;
  }

  .v-item--active {
    background-color: $primary !important;
    border-color: $primary !important;
    color: $antiFlash !important;
  }

  .v-item-inactive-dark {
    color: $spanishGray !important;
    opacity: 0.5;

    i {
      color: $spanishGray !important;
    }
  }

  .theme--light.v-btn.v-btn--has-bg {
    background-color: $silverSand;
    color: $outerSpace;
    font-size: 15px;
    @media screen and (max-device-width: 640px) {
      font-size: 3.5vw;
    }
    font-weight: 400;
  }

  .gap {
    gap: 10px;
  }

  .v-list-item {
    border: 1px solid $spanishGray;
    max-height: 58px;
  }

  .v-list-item-max-w {
    max-width: 270px !important;
  }

  .card-programs {
    max-height: 480px;
  }
</style>
